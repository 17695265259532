import AggregationRequest from 'src/components/TableReporting/models/AggregationRequest';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { OperationType } from 'src/components/TableReporting/models/groupOperation';
import { downloadReportingCallSummaryAgregation } from 'src/modules/Productivity/modules/Calls/infrastructure/api/getReportingCallSummaryApi';
import { reportSchedulesDialerbot } from '../infrastructure/api/dialerBotCampaigns';
import { CallResultType } from '../models/dialerbot';
import {
  DateOperator,
  FieldDataType,
  Filter,
  StringOperator
} from 'src/components/DialTable/models/filters';

const BASEFILTERS: Filter = {
  field: 'creationDate',
  operator: DateOperator.NOT_NULL,
  dataType: FieldDataType.DATE,
  values: []
};

export const dialerbotDownloadReport = async (
  campaignId: string,
  name: string,
  uploadedSchedulesId?: string,
  callResult?: CallResultType
) => {
  const response = await reportSchedulesDialerbot(
    campaignId,
    name,
    uploadedSchedulesId,
    callResult
  );
  const blob = new Blob([response.data], {
    type: 'application/xlsx'
  });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name + '.xlsx';
  document.body.appendChild(link);
  link.click();
  return response;
};

export const downloadReportByAgregation = async (
  aggregationRequest: AggregationRequest,
  name: string,
  companyId: string
) => {
  const resp = await downloadReportingCallSummaryAgregation(
    aggregationRequest,
    name,
    companyId
  );
  return resp;
};

export const getDownloadFiltersByScheduleIdByCallResult = (
  campaignId: string,
  scheduleDatabaseId: string,
  callResult: CallResultType | string,
  companyId: string
) => {
  let filtersArray = [];
  filtersArray.push(BASEFILTERS);
  filtersArray.push(getFieldEqualsObject('campaignDataCampaignId', campaignId));
  filtersArray.push(
    getFieldEqualsObject('dialerbotDataScheduleDatabaseId', scheduleDatabaseId)
  );
  filtersArray.push(getFieldEqualsObject('callResult', callResult));
  filtersArray.push(getFieldEqualsObject('companyId', companyId));
  return filtersArray;
};

export const getDownloadFiltersByCampaignByCallResult = (
  campaignId: string,
  callResult: CallResultType | string,
  companyId: string
) => {
  let filtersArray = [];
  filtersArray.push(BASEFILTERS);
  filtersArray.push(getFieldEqualsObject('campaignDataCampaignId', campaignId));
  filtersArray.push(getFieldEqualsObject('callResult', callResult));
  filtersArray.push(getFieldEqualsObject('companyId', companyId));
  return filtersArray;
};

export const getDownloadFiltersByScheduleId = (
  campaignId: string,
  scheduleDatabaseId: string,
  companyId: string
) => {
  let filtersArray = [];
  filtersArray.push(BASEFILTERS);
  filtersArray.push(getFieldEqualsObject('campaignDataCampaignId', campaignId));
  filtersArray.push(getFieldEqualsObject('companyId', companyId));
  filtersArray.push(
    getFieldEqualsObject('dialerbotDataScheduleDatabaseId', scheduleDatabaseId)
  );
  return filtersArray;
};

export const getDownloadFilters = (
  campaignId: string,
  companyId: string
): Filter[] => {
  let filtersArray = [];
  filtersArray.push(BASEFILTERS);
  filtersArray.push(getFieldEqualsObject('campaignDataCampaignId', campaignId));
  filtersArray.push(getFieldEqualsObject('companyId', companyId));
  return filtersArray;
};

export const getAggregationObject = (
  filters: Filter[],
  groupByFields: string[] = ['dialerbotDataScheduleId']
): AggregationRequest => {
  return {
    filters,
    groupByFields,
    sort: {
      sort: 'creationDate',
      direction: DirectionType.ASC
    },
    groupOperations: getGroupOperations(),
    pageNumber: 0,
    pageSize: -1,
    merged: true
  };
};

export const getGroupOperations = () => {
  return [
    {
      operation: OperationType.FIRST,
      operationField: '_id',
      resultName: 'CALL ID'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'callMode',
      resultName: 'CALL MODE'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'creationDate',
      resultName: 'LAUNCH DATE'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'destinationNumber',
      resultName: 'DESTINATION NUMBER'
    },
    {
      operation: OperationType.COUNT,
      operationField: '',
      resultName: 'ATTEMPTS'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'callResult',
      resultName: 'CALL RESULT'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'hangupCode',
      resultName: 'HANGUP CODE'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'routeDataName',
      resultName: 'ROUTE NAME'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'dialerbotDataFilename',
      resultName: 'DIALERBOT FILENAME'
    },
    {
      operation: OperationType.FIRST,
      operationField: 'dialerbotDataUploadDate',
      resultName: 'DIALERBOT UPLOAD DATE'
    }
  ];
};
export const getDownloadPagination = () => {
  return { page: 0, size: -1 };
};

export const getDownloadKeys = () => {
  return [
    'id',
    'callMode',
    'creationDate',
    'destinationNumber',
    'callResult',
    'hangupCode',
    'routeDataName',
    'campaignDataName',
    'dialerbotDataFilename',
    'dialerbotDataUploadDate'
  ];
};
export const getFieldEqualsObject = (type: string, value: string): Filter => {
  const filter: Filter = {
    field: type,
    dataType: FieldDataType.STRING,
    operator: StringOperator.EQUALS,
    values: [value]
  };

  return filter;
};
