import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { getVariableByIdDomain } from 'src/modules/Admin/modules/Acd/modules/Variables/domain/getVariableByIdDomain';
import { Variable } from 'src/modules/Admin/modules/Acd/modules/Variables/models/models';
import AuthManager from 'src/services/authentication/manager';
import { logger } from 'src/utils/logger';

const CARREFOUR_ID = '645e2b9ce339381ed1b65400';
const VARIABLE_ID = '64a40efb2cfee97b8e359afc';

export const VariableStatus: React.FC = () => {
  const theme = useTheme();
  const companyId = AuthManager.getLoggedUserCompanyId();
  const [variable, setVariable] = useState<Variable>();
  const ref = useRef<HTMLDivElement>();

  //Esto es provisional
  const isCarrefour = companyId === CARREFOUR_ID;

  const variableValue = variable?.value;

  function getVariable() {
    if (!isCarrefour) return;
    getVariableByIdDomain(VARIABLE_ID)
      .then((variable) => {
        setVariable(variable);
      })
      .catch((err) => {
        logger.error(err);
      });
  }

  function animateBox() {
    if (ref?.current) {
      if (ref.current.style.height === '20px') {
        ref.current.style.height = '10px';
        ref.current.style.width = '10px';
      } else {
        ref.current.style.height = '20px';
        ref.current.style.width = '20px';
      }
    }
  }

  useEffect(() => {
    getVariable();
    const interval = setInterval(() => {
      getVariable();
    }, 60000);

    const animation = setInterval(animateBox, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(animation);
    };
  }, [companyId, variable?.value]);

  if (!isCarrefour) return null;
  if (!variableValue) return null;
  return (
    <Tooltip
      title={`Carrefour Locución activa: ${variable.value}`}
      followCursor
    >
      <Stack alignItems={'center'} justifyContent={'center'} height={60}>
        <Box
          ref={ref}
          sx={{
            bgcolor: theme.colors.error.main,
            borderRadius: '50%',
            transitionDuration: '1000ms'
          }}
        ></Box>
      </Stack>
    </Tooltip>
  );
};
