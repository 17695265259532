import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ConversationListItem } from 'src/components/ConversationHistory/components/ConversationListItem';
import { sortTicketConversationSummaries } from '../../../utils/sortTicketConversations';
import AddTicketConversationButton from './AddTicketConversationButton';
import ActionConversation from 'src/modules/Productivity/modules/Conversations/views/components/ActionConversation';
import { Conversation } from 'src/models/conversations/conversations';
import { useGetConversationByIdQuery } from 'src/services/socialmedia/application/query/useGetConversationsQuery';
import { conversationToConversationTableColumn } from 'src/services/socialmedia/domain/conversation/transformConversation';
import { Ticket, TicketConversationSummary } from '../../../../models/tickets';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TicketParticipants from '../../TicketParticipants';
import { User } from 'src/models/users';
import TicketContacts from '../../TicketContacts';
import { getTicketConversationSummaryFrom } from '../../../../domain/getTickets';

interface Props {
  currentConversation?: Conversation;
  conversations?: TicketConversationSummary[];
  setConversations?: Dispatch<SetStateAction<TicketConversationSummary[]>>;
  /** Required to display the ticket involved contacts. */
  ticket?: Ticket;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
  // A User array with at least the users involved in the  conversation. If it is not received, it is fetched.
  involvedUsers?: User[];
}

const TicketConversations = (props: Props) => {
  const {
    currentConversation,
    conversations,
    setConversations,
    ticket,
    setTicket,
    involvedUsers
  } = props;
  const [selectedConversationSummary, setSelectedConversationSummary] =
    useState<TicketConversationSummary>(null);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation>(null);

  const currentConversationSummary =
    getTicketConversationSummaryFrom(currentConversation);

  const isCurrentConversationAdded = conversations?.some(
    (conversation) => conversation?.id === currentConversation?.id
  );
  const isCurrentConversation = (id: string) => id === currentConversation?.id;

  const { t } = useTranslation();

  const handleRemoveConversation = () => {
    const filteredConversations = conversations.filter(
      (conversation) => conversation.id !== currentConversation.id
    );
    const newConversationIds = filteredConversations.map(
      (conversation) => conversation.id
    );
    if (setConversations) setConversations(filteredConversations);
    if (setTicket)
      setTicket((prev) => ({
        ...prev,
        conversationSummaries: filteredConversations,
        conversationIds: newConversationIds
      }));
  };

  const selectedConversationQuery = useGetConversationByIdQuery(
    selectedConversationSummary?.id,
    !!selectedConversationSummary
  );

  useEffect(() => {
    let isMounted = true;
    if (!!selectedConversationQuery?.data) {
      conversationToConversationTableColumn(
        selectedConversationQuery?.data
      ).then((resp) => {
        if (isMounted) {
          setSelectedConversation(resp);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [selectedConversationQuery?.data]);

  const isOpenActionConversation = Boolean(selectedConversation);

  const onCloseActionConversation = () => {
    setSelectedConversationSummary(null);
    setSelectedConversation(null);
  };

  const AddTicketConversationBtn = (
    <AddTicketConversationButton
      currentConversation={currentConversation}
      setTicket={setTicket}
    />
  );

  return (
    <Box>
      {/* Conversation details */}
      {!!isOpenActionConversation && (
        <ActionConversation
          isOpen={isOpenActionConversation}
          onClose={onCloseActionConversation}
          conversation={selectedConversation}
        />
      )}
      {/* No conversations and no current conversation */}
      {conversations?.length === 0 && !currentConversation && (
        <Box display="flex" justifyContent="center" p={1} width="100%">
          <Typography fontWeight="bold">{t('No Conversations')}</Typography>
        </Box>
      )}
      {/* No conversations and current conversation */}
      {conversations?.length === 0 &&
        currentConversation &&
        !isCurrentConversationAdded && (
          <Stack direction="row" overflow="scroll" py={2} spacing={1}>
            {AddTicketConversationBtn}
          </Stack>
        )}
      {/* Conversations and current conversation */}
      {conversations?.length > 0 && (
        <Stack direction="row" overflow="scroll" py={2} spacing={1}>
          {currentConversation &&
            !isCurrentConversationAdded &&
            AddTicketConversationBtn}
          {sortTicketConversationSummaries(
            conversations,
            currentConversationSummary
          )?.map((conversation) => (
            <ConversationListItem
              key={conversation.id}
              conversationSummary={conversation}
              setConversationSummary={() => {
                setSelectedConversationSummary(conversation);
              }}
              onClose={
                isCurrentConversation(conversation.id) &&
                isCurrentConversationAdded
                  ? handleRemoveConversation
                  : undefined
              }
            />
          ))}
        </Stack>
      )}
      <TicketParticipants ticket={ticket} involvedUsers={involvedUsers} />
      <TicketContacts ticket={ticket} />
    </Box>
  );
};

export default TicketConversations;
