import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { DialerBotProvider } from 'src/modules/DialerBot/views/contexts/DialerBotContext';
import {
  checkReadDialerbotCampaignPermissions,
  checkReadServicePermissions,
  checkReadTicketViewPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Services = Loader(lazy(() => import('../modules/DaServices/views')));
const Tickets = Loader(lazy(() => import('../modules/Tickets/views')));
const DialerBotView = Loader(
  lazy(() => import('src/modules/Dashboard/views/DialerBot'))
);
const CompaniesReborn = Loader(
  lazy(() => import('../modules/CompaniesReborn'))
);
const RobinsonList = Loader(
  lazy(() => import('../modules/RobinsonList/views'))
);
const MassiveSms = Loader(
  lazy(() => import('../modules/MassiveSmsLaunching/views'))
);

const operationsRoutes: RouteObject[] = [
  {
    path: 'services',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadServicePermissions}>
        <Services />
      </Restricted>
    )
  },
  {
    path: 'tickets',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadTicketViewPermissions}>
        <Tickets />
      </Restricted>
    )
  },
  {
    path: 'robinson-list',
    element: (
      <Restricted navigate permissionCheckerFn={() => true}>
        <RobinsonList />
      </Restricted>
    )
  },
  {
    path: 'dialerbot',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadDialerbotCampaignPermissions}
      >
        <DialerBotProvider>
          <DialerBotView />
        </DialerBotProvider>
      </Restricted>
    )
  },
  {
    path: 'massive-sms',
    element: (
      <Restricted navigate permissionCheckerFn={() => true}>
        <MassiveSms />
      </Restricted>
    )
  },
  {
    path: 'create-showflow',
    element: <CompaniesReborn />
  }
];

export default operationsRoutes;
