import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Box, Button, useTheme } from '@mui/material';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import AddTicketButton from '../../../../../Admin/modules/Operations/modules/Tickets/views/components/AddTicketButton';
import HelperPanelHeader from '../components/HelperPanelHeader';
import {
  ReportingTicket,
  Ticket
} from 'src/modules/Admin/modules/Operations/modules/Tickets/models/tickets';
import {
  getInitialSelectedFiltersCallback,
  onDeleteTicket,
  onSubmitTicket,
  getReportingTicketColumns,
  getDataCallback,
  getCustomFilterValueOptionsCallback,
  getAvailableFiltersCallback,
  getInitialTicketColumnsVisibility,
  initialSorting
} from 'src/modules/Admin/modules/Operations/modules/Tickets/views/utils/ticketTableUtils';
import Scrollbar from 'src/components/Scrollbar';
import TicketDataView from 'src/modules/Admin/modules/Operations/modules/Tickets/views/components/TicketDataView';
import { ArrowBackIosNew } from '@mui/icons-material';
import TicketTitle from 'src/modules/Admin/modules/Operations/modules/Tickets/views/components/TicketTitle';
import { TicketsDomain } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/TicketsDomain';
import { InboxPanelsContext } from 'src/contexts/InboxPanelsContext';
import AuthManager from 'src/services/authentication/manager';
import { HelperTab } from '../../HelperMenu/items';
import { DialTable } from 'src/components/DialTable';
import { TicketDataViewSkeleton } from 'src/modules/Admin/modules/Operations/modules/Tickets/views/components/TicketDataViewSkeleton';
import { queryClient } from 'src/utils/queryClient';
import { checkReadTicketPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { SortingState } from '@tanstack/react-table';
import { ContactUI } from 'src/modules/Contacts/models/contacts';
import { TicketConversationButtons } from 'src/modules/Admin/modules/Operations/modules/Tickets/views/components/TicketConversationButtons';
import {
  CTIBarContext,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

interface Props {
  conversation: Conversation;
  showHelperTabButtons?: boolean;
  disableCreateTicket?: boolean;
  hideHeader?: boolean;
  selectedTicket?: Ticket;
  setSelectedTicket?: Dispatch<SetStateAction<Ticket>>;
}

const TicketsPanel = (props: Props) => {
  const {
    conversation,
    showHelperTabButtons,
    disableCreateTicket = false,
    hideHeader = false,
    selectedTicket: selectedTicketProps,
    setSelectedTicket: setSelectedTicketProps
  } = props;
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const [isLoadingTicket, setIsLoadingTicket] = useState(false);
  const { hasAccess } = useContext(PermissionsContext);
  const hasTicketAccess = hasAccess(checkReadTicketPermissions);

  const companyId = AuthManager.getLoggedUserCompanyId();
  const getAvailableFilters = getAvailableFiltersCallback(companyId);

  const [selectedReportingTicket, setSelectedReportingTicket] =
    useState<ReportingTicket>(null);
  const { setSelectedTicket: setSelectedTicketCTIContext, openDialpad } =
    useDialpadStateContext();

  const {
    isEditingOrAddingTicket: isEditingOrAddingTicketContext,
    setIsEditingOrAddingTicket: setIsEditingOrAddingTicketContext,
    selectedTicket: selectedTicketContext,
    setSelectedTicket: setSelectedTicketContext
  } = useContext(InboxPanelsContext);

  const theme = useTheme();

  const [selectedTicket, setSelectedTicket] = setSelectedTicketProps
    ? [selectedTicketProps, setSelectedTicketProps]
    : [selectedTicketContext, setSelectedTicketContext];

  const [isEditingOrAddingTicket, setIsEditingOrAddingTicket] =
    setSelectedTicketProps
      ? useState(false)
      : [isEditingOrAddingTicketContext, setIsEditingOrAddingTicketContext];

  const onClickReportingTicket = (clickedReportingTicket: ReportingTicket) => {
    setSelectedReportingTicket(clickedReportingTicket);
  };

  const onClose = (e: MouseEvent<HTMLButtonElement>) => {
    setSelectedReportingTicket(null);
    setIsEditingOrAddingTicket(false);
  };

  const customFilterValueOptions =
    getCustomFilterValueOptionsCallback(conversation);

  const getInitialSelectedFilters =
    getInitialSelectedFiltersCallback(conversation);

  const getInitialColumnVisibility = useCallback(
    getInitialTicketColumnsVisibility,
    []
  );

  useEffect(() => {
    let isMounted = true;
    if (selectedReportingTicket) {
      setIsLoadingTicket(true);
      TicketsDomain.getTicketById(selectedReportingTicket?.id)
        .then((resp) => {
          if (isMounted) {
            setSelectedTicket(resp);
            setIsEditingOrAddingTicket(true);
          }
        })
        .finally(() => {
          setIsLoadingTicket(false);
        });
    } else {
      if (isMounted) {
        setSelectedTicket(null);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedReportingTicket]);

  const getColumnsVisibility = useCallback(getInitialColumnVisibility, []);
  const getColumns = useCallback(getReportingTicketColumns, []);

  useEffect(() => {
    // Invalidate queries because the filters are not cached by the DialTable
    queryClient.invalidateQueries({
      queryKey: ['reportingTicket', conversation?.id]
    });
  }, [conversation?.id]);

  const getInputValue = (contact: ContactUI, channel: ChannelType) => {
    let inputValue = '';
    switch (channel) {
      case ChannelType.CALL:
        inputValue = contact?.phone ?? '';
        break;
      case ChannelType.EMAIL:
        inputValue = contact?.email ?? '';
        break;
      case ChannelType.TELEGRAM:
        inputValue = contact?.telegram;
        break;
      case ChannelType.WHATSAPP:
        inputValue = contact?.phone;
        break;
      case ChannelType.INSTAGRAM:
        inputValue = contact?.instagram;
        break;
    }
    return inputValue;
  };
  const onClickChannelContact = ({
    contact,
    channel
  }: {
    contact: ContactUI;
    channel: ChannelType;
  }) => {
    const inputValue = getInputValue(contact, channel);
    openDialpad({ channel, inputValue });
    setSelectedTicketCTIContext(selectedTicket);
  };

  return (
    <>
      <Helmet>
        <title>Helper Panel - Tickets</title>
      </Helmet>
      {!hideHeader && (
        <HelperPanelHeader
          helperTab={HelperTab.TICKETS}
          showHelperTabButtons={showHelperTabButtons}
        />
      )}

      <Box
        p={1}
        height={hideHeader ? '100%' : `calc(100% - ${theme.header.height})`}
      >
        <Scrollbar>
          {isLoadingTicket && <TicketDataViewSkeleton />}
          {!isEditingOrAddingTicket && (
            <>
              {!disableCreateTicket && (
                <Box px={2} py={1}>
                  <AddTicketButton
                    onClick={() => {
                      setSelectedTicket(null);
                      setIsEditingOrAddingTicket(true);
                    }}
                  />
                </Box>
              )}

              <DialTable<ReportingTicket>
                key={conversation?.id}
                queryKey={['reportingTicket', conversation?.id]}
                getDataFn={getDataCallback(companyId)}
                getColumnsFn={getColumns}
                getInitialColumnVisibilityFn={getColumnsVisibility}
                getAvailableFilters={getAvailableFilters}
                getInitialSelectedFilters={getInitialSelectedFilters}
                customFilterValueOptions={customFilterValueOptions}
                onClickRow={
                  hasTicketAccess ? onClickReportingTicket : undefined
                }
                state={{ sorting }}
                onSortingChange={setSorting}
                disableSetUrlSearchParams
                enableSortingRemoval={false}
              />
            </>
          )}

          {isEditingOrAddingTicket && (
            <Box height={`calc(100% - 40px)`}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={0.5}
                px={1}
              >
                <TicketTitle ticket={selectedTicket} />
                {!!selectedTicket && (
                  <TicketConversationButtons
                    ticket={selectedTicket}
                    onClick={onClickChannelContact}
                  />
                )}
                <Button
                  id="ticket-panel-back-btn"
                  color="secondary"
                  variant="text"
                  size="small"
                  startIcon={<ArrowBackIosNew />}
                  onClick={onClose}
                >
                  {t('Back')}
                </Button>
              </Box>
              <TicketDataView
                key={conversation?.id + selectedTicket?.id}
                onClose={onClose}
                ticket={selectedTicket}
                conversation={conversation}
                onSubmit={onSubmitTicket(selectedTicket)}
                onDelete={async (ticket) => {
                  setSelectedReportingTicket(null);
                  await onDeleteTicket(ticket);
                  queryClient.invalidateQueries({
                    queryKey: ['reportingTicket']
                  });
                }}
              />
            </Box>
          )}
        </Scrollbar>
      </Box>
    </>
  );
};

export default TicketsPanel;
