export enum PathNameContacts {
  ADMIN_CONTACTS = 'view-contacts',
  ADMIN_CONTACT_GROUPS = 'contact-groups',
  ADMIN_TAGS = 'tags',
  ADMIN_CONTACT_FIELDS = 'contact-fields',
  DASHBOARD_CONTACTS = 'contacts'
}

export enum ContactsTabs {
  CONTACT_GROUPS_TAB = 'contactGroups',
  CONTACTS_TAB = 'contacts',
  TAGS_TAB = 'tags',
  CONTACT_FIELDS_TAB = 'fields'
}

export enum routesAdminContacts {
  ROUTE_ADMIN_CONTACTS = '/dashboard/admin/contacts/view-contacts',
  ROUTE_ADMIN_CONTAC_GROUPS = '/dashboard/admin/contacts/contact-groups',
  ROUTE_CONTAC_FIELDS = '/dashboard/admin/contacts/contact-fields',
  ROUTE_TAGS = '/dashboard/admin/contacts/tags'
}

export const DASHBOARD_CONTACT_PATHNAME = '/dashboard/contacts';

export enum ButtonNameContacts {
  CONTACT_GROUP_NAME = 'Add group',
  CONTACT_NAME = 'Upload contacts',
  TAG_NAME = 'Create new tag',
  CONTACT_FIELD_NAME = 'Create new field'
}
