import { MouseEventHandler, useContext } from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import PageHeaderDocs from '../PageHeaderDocs';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import { Permissions } from 'src/models/permissionGroups';
import { PermissionsContext } from 'src/contexts/PermissionsContext';

interface AdminHeaderPropTypes {
  heading: string;
  subheading: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonName?: string;
  permissionCheckerFn?: (permissions: Permissions) => boolean;
}

const AdminHeader: React.FC<AdminHeaderPropTypes> = ({
  heading,
  subheading,
  onClick,
  buttonName,
  permissionCheckerFn
}) => {
  const { hasAccess } = useContext(PermissionsContext);
  const isAllowed = permissionCheckerFn ? hasAccess(permissionCheckerFn) : true;
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" px={5}>
        <PageHeaderDocs heading={heading} subheading={subheading} />
        {buttonName && isAllowed && (
          <Stack justifyContent="center">
            <Button
              id="btnName-AdminHeader"
              variant="contained"
              color="secondary"
              onClick={onClick}
              sx={{ borderColor: 'secondary.main' }}
              startIcon={<AddIcon />}
            >
              {t(buttonName)}
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  );
};
export default AdminHeader;
