import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;
const BASE_MS = process.env.REACT_APP_BASE_SOCIALMEDIA_MS as string;
const API_NOTES = process.env.REACT_APP_API_NOTES as string;

export const getNotesApi: Function = (conversationId: string) => {
  const path = BASE_URL + BASE_MS + API_NOTES;

  const params = {
    conversationId: conversationId
  };

  return axios.get(path, { params }).then((res) => {
    return res.data;
  });
};

export const getNotesContactApi: Function = (contactId: string) => {
  const path = BASE_URL + BASE_MS + API_NOTES;

  const params = {
    contactId: contactId
  };

  return axios.get(path, { params }).then((res) => {
    return res.data;
  });
};
