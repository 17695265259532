import { Contact } from 'src/modules/Contacts/models/contacts';
import { updateContactApi } from '../infraestructure/api/updateContactApi';

import {queryClient} from 'src/utils/queryClient';

/** UPDATE CONTACT
 *
 */
export const updateContact = async (contact: Contact): Promise<Contact> => {
  const updatedContact = await updateContactApi(contact);
  queryClient.setQueryData(['contact', contact.id], updatedContact);
  return updatedContact;
};
