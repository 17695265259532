import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useState } from 'react';
import ShowFlowTicketViewer from '..';
import { Ticket } from '../../../../models/tickets';

interface Props {
  ticket: Ticket;
}

export default function ShowflowWithverticalAccordion({ ticket }: Props) {
  const [isExpanded, setIsExpanded] = useState(true);
  const theme = useTheme();
  return (
    <>
      <motion.div
        animate={{
          width: isExpanded ? '50%' : 'min-content'
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(1)
        }}
        transition={{
          type: 'spring',
          duration: 0.3,
          bounce: 0
        }}
      >
        <Stack
          direction="row"
          alignItems={'center'}
          width="100%"
          justifyContent={'space-between'}
          px={isExpanded ? 2 : 0.5}
          sx={{
            background: theme.colors.alpha.black[10],
            borderRadius: '6px',
            height: '48px',
            cursor: 'pointer',
            columnGap: 1
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <>
            {isExpanded && (
              <Typography fontWeight={'bold'}>{t('Script')}</Typography>
            )}

            {isExpanded ? (
              <ChevronLeft />
            ) : (
              <Tooltip arrow title={t('Show script')}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <ChevronRight />
                </Box>
              </Tooltip>
            )}
          </>
        </Stack>
        <Box padding={1} sx={{}}>
          {isExpanded && <ShowFlowTicketViewer ticket={ticket} />}
        </Box>
      </motion.div>
    </>
  );
}
