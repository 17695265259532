import {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useState
} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';
import { CallType } from 'src/modules/Admin/modules/Configurations/modules/CallerIds/models/callerId';
import { CountryCode } from 'libphonenumber-js';
import i18n from 'src/utils/react-libreria-html/src/i18n';
import { unformatPhoneNumber } from 'src/utils/formatPhoneNumber';
import { set } from 'nprogress';

declare type BaseTextFieldProps = Omit<
  TextFieldProps,
  | 'onChange'
  | 'select'
  | 'type'
  | 'multiline'
  | 'defaultValue'
  | 'value'
  | 'variant'
>;

interface Props extends BaseTextFieldProps {
  disabledInternal?: boolean;
  /**The default value is 'Call type' */
  label?: string | null;
  size?: 'small' | 'medium';
  /**
   * International formatted phone number.
   * Use unformatPhoneNumber function from 'src/utils/formatPhoneNumber.ts' to unformat the phone number
   */
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  callType?: CallType;
  setCallType: Dispatch<SetStateAction<CallType>>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  countryCode?: CountryCode;
  setCountryCode?: Dispatch<SetStateAction<CountryCode>>;
  langOfCountryName?: string;
  disableCallTypeSelector?: boolean;
  countryCallingCode?: string;
  setCountryCallingCode?: Dispatch<SetStateAction<string>>;
}

const PhoneInput = (props: Props) => {
  const {
    disabledInternal = false,
    label,
    size,
    phoneNumber,
    setPhoneNumber,
    callType = 'External',
    setCallType,
    onKeyDown,
    countryCode,
    setCountryCode,
    langOfCountryName,
    disableCallTypeSelector,
    countryCallingCode,
    setCountryCallingCode,
    ...restTextFieldProps
  } = props;

  const { t }: { t: any } = useTranslation();

  const language = i18n.language || navigator.language.split('-')[0];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCallType?.((event.target as HTMLInputElement).value as CallType);
    setPhoneNumber('');
  };

  return (
    <>
      {!disableCallTypeSelector && (
        <FormControl sx={{ mb: 1 }}>
          {label !== null && (
            <FormLabel id="radio-buttons-group-label-call-type">
              {t(label) || t('Call type')}
            </FormLabel>
          )}
          <RadioGroup
            row
            aria-labelledby="radio-buttons-group-label-call-type"
            value={callType}
            name="radio-buttons-group-call-type"
            onChange={handleChange}
            aria-disabled={disableCallTypeSelector}
          >
            <FormControlLabel
              value="External"
              control={<Radio color="secondary" size="small" />}
              label={t('External')}
            />
            {!disabledInternal && (
              <FormControlLabel
                value="Internal"
                control={<Radio color="secondary" size="small" />}
                label={t('Internal')}
                disabled={disableCallTypeSelector}
              />
            )}
            <FormControlLabel
              value="Special"
              control={<Radio color="secondary" size="small" />}
              label={t('Special')}
              disabled={disableCallTypeSelector}
            />
          </RadioGroup>
        </FormControl>
      )}

      {callType === 'Internal' || callType === 'Special' ? (
        <TextField
          id="input-internal-external-phone-number"
          size={size}
          label={callType === 'Internal' ? t('Extension') : t('Phone number')}
          type="tel"
          value={phoneNumber}
          variant="outlined"
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
          required
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment:
              phoneNumber?.length > 0 && !props.disabled ? (
                <ClearIcon
                  fontSize="medium"
                  sx={{ cursor: 'pointer' }}
                  onClick={(): void => setPhoneNumber('')}
                />
              ) : null
          }}
          {...restTextFieldProps}
        />
      ) : (
        <MuiTelInput
          id="input-external-phone-number"
          size={size}
          defaultCountry={countryCode}
          focusOnSelectCountry
          forceCallingCode
          langOfCountryName={language}
          label={t('Phone number')}
          value={'+' + phoneNumber}
          variant="outlined"
          onChange={(phoneNumber, info) => {
            setCountryCallingCode?.(info.countryCallingCode);
            setPhoneNumber(unformatPhoneNumber(phoneNumber));
          }}
          required
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment:
              phoneNumber?.length > 0 && !props.disabled ? (
                <ClearIcon
                  fontSize="medium"
                  sx={{ cursor: 'pointer' }}
                  onClick={(): void => {
                    setPhoneNumber(countryCallingCode);
                  }}
                />
              ) : null
          }}
          {...restTextFieldProps}
        />
      )}
    </>
  );
};

export default PhoneInput;
