import { WhatsappAuthInfoApiProps } from '../infrastructure/auth/sendWhatsappAuthInfoApi';

export interface WhatspAppAccount {
  app?: string;
  appId?: string;
  companyId: string;
  description?: string;
  enabled: boolean;
  id?: string;
  key?: string;
  name: string;
  phoneNumber: string;
  provider?: WhatsAppProvider;
  metaAuthData?: WhatsappAuthInfoApiProps;
}

export type WhatsAppProvider = 'Meta' | 'Gupshup';

export type WhatsAppAccountStatus = WhatspAppAccountStatus;

export type WhatspAppAccountKeys = keyof WhatspAppAccount;

export type WhatspAppAccountTypeForm = WhatspAppAccountForm;

export enum WhatspAppAccountForm {
  EDIT = 'edit',
  CREATE = 'create'
}

export enum WhatspAppAccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  DRAFT = 'draft'
}
