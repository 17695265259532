import { useEffect, useRef } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import AuthManager from 'src/services/authentication/manager';
import {
  getOption,
  getPaginatedElements,
  getValueOptions
} from '../utils/getOptionValues';
import { Option } from '../models/option';
import { DataCategory, DateOperator, DialTableFilter } from '../models/filters';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { PaginatedElements, PaginationValues } from 'src/models/pagination';
import { useInView } from 'react-intersection-observer';
import useDebounce from 'src/hooks/useDebounce';

interface Params<T> {
  filterToEdit: DialTableFilter<T>;
  onChangeFilterValues: (
    newValues: string[],
    newFilter: DialTableFilter<T>,
    selectedValueOptions?: Option[]
  ) => void;
  getCustomFilterValueOptionsFn?: (
    dataCategory: DataCategory,
    companyId: string,
    input?: string
  ) => Promise<PaginatedElements<Option>>;
  input?: string;
}

export const useDialTableFilterValueOptions = <T extends Object>(
  params: Params<T>
) => {
  const {
    filterToEdit,
    onChangeFilterValues,
    getCustomFilterValueOptionsFn,
    input
  } = params;
  const placeHolderDataRef = useRef<PaginatedElements<Option>>(undefined);
  const { ref, inView } = useInView();
  const companyId = AuthManager.getLoggedUserCompanyId();

  const debouncedInput = useDebounce(input, 500);

  const infiniteResultQuery = useInfiniteQuery({
    queryKey: [
      'optionValues',
      // if no dataCategory, the fieldName is used as qeuryKey
      !filterToEdit?.dataCategory ? filterToEdit?.fieldName : undefined,
      filterToEdit?.dataCategory,
      debouncedInput
    ],
    queryFn: async ({ pageParam }) => {
      const page = pageParam ?? 0;
      const size = 25;
      const pagination: PaginationValues = { page, size };
      // FILTER WITH OPTIONS
      // If the filter has options, the customFilterValueOptions table prop and the options related to DataCategory filter prop are ignored
      if (!!filterToEdit?.options?.getValueOptionListFn) {
        const { getValueOptionListFn } = filterToEdit?.options;
        const options = await getValueOptionListFn?.(pagination);
        placeHolderDataRef.current = options;
        return options;
      }

      // FILTER WITH NO DATA CATEGORY
      if (!filterToEdit?.dataCategory) {
        const emptyOptionValues = getPaginatedElements<Option>([]);
        placeHolderDataRef.current = emptyOptionValues;
        return Promise.resolve(emptyOptionValues);
      }

      // DATA WITH DATA CATEGORY
      const optionValues = await getValueOptions({
        dataCategory: filterToEdit?.dataCategory,
        companyId,
        page,
        size,
        getCustomFilterValueOptionsFn,
        input: debouncedInput
      });
      placeHolderDataRef.current = optionValues;

      return optionValues;
    },
    getPreviousPageParam: (firstPage) =>
      firstPage?.currentPage > 0 ? firstPage?.currentPage - 1 : undefined,
    getNextPageParam: (lastPage) =>
      lastPage?.currentPage < lastPage?.totalPages - 1
        ? lastPage?.currentPage + 1
        : undefined,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  });

  const { fetchNextPage } = infiniteResultQuery;

  const booleanValueOptions: Option[] = [
    { label: 'yes', value: 'true' },
    { label: 'no', value: 'false' }
  ];

  const getCalendarValue = (filterToEdit: DialTableFilter<T>) => {
    if (filterToEdit?.operator !== DateOperator.BETWEEN) {
      return new Date(filterToEdit?.values?.[0] ?? Date.now()) as Value;
    }
    const dates =
      filterToEdit.values.length > 0
        ? (filterToEdit.values.map((date: string) => {
            return new Date(date);
          }) as Value)
        : ([new Date(Date.now()), new Date(Date.now())] as Value);

    return dates;
  };

  const calendarValue = getCalendarValue(filterToEdit);

  const onChangeCalendarValue = (value) => {
    // DATE
    if (!Array.isArray(value)) {
      const date = (value as Date).toISOString();
      const newFilter = { ...filterToEdit, values: [date] };
      const selectedOption = getOption({
        option: date,
        labelExtractor: (date) => date,
        valueExtractor: (date) => date
      });
      onChangeFilterValues([date], newFilter, [selectedOption]);
    } else {
      // DATE RANGE
      const dates = value.map((date) => (date as Date).toISOString());
      const selectedOptions = dates?.map((date) =>
        getOption({
          option: date,
          labelExtractor: (date) => date,
          valueExtractor: (date) => date
        })
      );
      const newFilter = { ...filterToEdit, values: dates };
      onChangeFilterValues(dates, newFilter, selectedOptions);
    }
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return {
    ref,
    filterToEdit,
    infiniteResultQuery,
    calendarValue,
    onChangeCalendarValue,
    booleanValueOptions,
    onChangeFilterValues
  };
};
