import axios from 'axios';
import { Contact } from 'src/modules/Contacts/models/contacts';
import { UploadCsvParameters } from '../../views/components/contacts/UploadCSVContacts/models/models';
import { CONTACTS, CONTACTS_UPLOAD_CSV } from 'src/utils/apiRoutes/msContacts';

export const createContactApi = async (contact: Contact): Promise<Contact> => {
  const data = contact;

  const resp = await axios.post(CONTACTS, data);
  return resp.data;
};

export const uploadCSVContactsApi = async (
  uploadCsvParams: UploadCsvParameters
) => {
  const {
    file,
    csvDelimiter,
    contactGroupId,
    fieldMapping,
    ignoreInvalid,
    createTagWhenNotFound,
    tagsColumn,
    tagsDelimiter,
    identificationFieldId
  } = uploadCsvParams;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fieldMapping', JSON.stringify(fieldMapping));
  const response = await axios.post(CONTACTS_UPLOAD_CSV, formData, {
    params: {
      contactGroupId,
      csvDelimiter,
      ignoreInvalid: true,
      createTagWhenNotFound,
      tagsColumn,
      tagsDelimiter,
      identificationFieldId
    }
  });

  return response.data as boolean;
};
