import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { VideoCallParticipant, VideoCallRoom } from '../types/videoCallTypes';
import { getVideoRoomById } from '../domain/rooms';
import { isVideoCallConversation } from '../utils/isVideoCallConversation';

export interface VideocallContextVideoRoom {
  room?: VideoCallRoom;
  me?: VideoCallParticipant;
  setMe?: Dispatch<SetStateAction<VideoCallParticipant>>;
  setRoom?: Dispatch<SetStateAction<VideoCallRoom>>;
  error: boolean;
  connectionError: boolean;
}

function useVideoRoom(isLoggedIn: boolean): VideocallContextVideoRoom {
  const [searchParams, _] = useSearchParams();
  const [room, setRoom] = useState<VideoCallRoom | null>(null);
  const [me, setMe] = useState<VideoCallParticipant | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [connectionError, setConnectionError] = useState<boolean>(false);
  const conversation = getCurrentConversation(true);

  let roomId;
  if (!conversation) roomId = searchParams.get('roomId');
  else if (isVideoCallConversation(conversation)) roomId = conversation?.id;

  useEffect(() => {
    if (roomId)
      getVideoRoomById(roomId, !isLoggedIn).then((room) => {
        if (room) setRoom(room);
        // There is an error if the room is equals to null
        setError(room == null || room == undefined);
        setConnectionError(room == undefined);
      });
  }, [roomId, isLoggedIn]);

  return { room, setRoom, error, me, setMe, connectionError };
}

export default useVideoRoom;
