import {
  Alert,
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { Delete, Download, Info } from '@mui/icons-material';
import { DataFileUpload } from 'src/modules/DialerBot/models/dialerbot';
import { getCSVHeadersAndDelimiter } from 'src/modules/Contacts/views/components/contacts/UploadCSVContacts/components/LinkFieldsWithCSV/utils/getCSVHeaders';

export const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
    border: 1px dashed ${theme.colors.secondary.main};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.secondary.lighter};
      border-color: ${theme.colors.secondary.dark};
    }
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.secondary.main};
      font-weight: ${theme.typography.fontWeightBold};
    `
);

const DividerContrast = styled(Divider)(
  ({ theme }) => `
      background: ${theme.colors.alpha.trueWhite[10]};
    `
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.success.light};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.error.light};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    color: ${theme.colors.secondary.main};
    background: ${theme.colors.secondary.lighter};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

interface Props {
  setFiles?: Dispatch<SetStateAction<File[]>>;
  onAcceptFiles?: (acceptedFiles: File[]) => void;
  /**An Object with the keys set to the MIME type and the values an Array of file extensions 
   * 
   * e.g.: {
    'audio/mpeg': ['.mp3'],
    'audio/wav': ['.wav']
  }; */
  accept?: { [key: string]: string[] };
  multiple?: boolean;
  hideIcon?: boolean;
  hideFilesInfo?: boolean;
  files?: File[];
  onValuesFile?: (data: DataFileUpload) => void;
  onChange?: (file: File) => void;
  showDownloadButton?: boolean;
  info?: ReactNode;
}

const FileDropZone = (props: Props) => {
  const {
    accept,
    multiple = false,
    hideIcon = false,
    hideFilesInfo = false,
    setFiles,
    onAcceptFiles,
    files,
    info
  } = props;

  const { t }: { t: any } = useTranslation();
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  useEffect(() => {
    setDroppedFiles(files);
  }, [files?.length]);

  const theme = useTheme();
  const handleDrop = (files: File[]) => {
    const file: File = files[0];
    if (file.type === 'text/csv') {
      getCSVHeadersAndDelimiter(file).then((response) => {
        setValuesRow(response.delimiter, response.headers, file);
      });
    } else {
      addFiles(files);
    }
  };

  const setValuesRow = (
    detectedDelimiter: string,
    firstRow: string[],
    file: File
  ) => {
    // Add file to state
    addFiles([file]);
    // save column names
    props.onValuesFile?.({
      delimiter: detectedDelimiter,
      nameColumn: firstRow
    });
    props.onChange?.(file);
  };

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    inputRef
  } = useDropzone({
    accept: accept ? accept : {},
    multiple: multiple,
    onDrop: handleDrop
  });

  const addFiles = (newFiles: File[]) => {
    if (newFiles.length === 0) return;
    if (multiple) {
      setDroppedFiles((prev) => [...prev, ...newFiles]);
      if (setFiles) {
        setFiles((prev) => [...prev, ...newFiles]);
      }
    } else {
      setDroppedFiles([...newFiles]);
      if (setFiles) {
        setFiles([...newFiles]);
        props.onChange?.(newFiles[0]);
      }
    }
  };

  const deleteFile = (index: number) => {
    if (multiple) {
      setDroppedFiles((prev) => [...prev.filter((_file, i) => i !== index)]);
      if (setFiles) {
        setFiles((prev) => [...prev.filter((_file, i) => i !== index)]);
      }
    } else {
      inputRef.current.value = '';
      setDroppedFiles([]);
      if (setFiles) {
        setFiles([]);
      }
    }
  };

  useEffect(() => {
    if (onAcceptFiles) {
      onAcceptFiles([...acceptedFiles]);
    }
  }, [acceptedFiles]);

  const renderFileItems = droppedFiles?.map((file, index) => (
    <ListItem disableGutters component="div" key={index}>
      <ListItemText
        title={file.name}
        primaryTypographyProps={{
          variant: 'h5',
          noWrap: true,
          flexShrink: 0,
          flexGrow: 1,
          textOverflow: 'ellipsis',
          minWidth: '50px'
        }}
        primary={file.name}
      />
      <b>{file.size} bytes</b>
      {props.showDownloadButton && (
        <IconButton
          sx={{ ml: 1 }}
          onClick={() => {
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(fileURL); // Limpia el URL temporal
          }}
        >
          <Download fontSize="small" color="secondary" />
        </IconButton>
      )}
      {
        <IconButton
          sx={{ ml: 1 }}
          onClick={() => {
            deleteFile(index);
          }}
        >
          <Delete fontSize="small" color="error" />
        </IconButton>
      }
      <DividerContrast />
    </ListItem>
  ));

  return (
    <>
      <BoxUploadWrapper
        {...getRootProps()}
        sx={{
          borderColor:
            (isDragAccept && theme.colors.success.dark) ||
            (isDragReject && theme.colors.error.dark),
          background:
            (isDragAccept && theme.colors.success.lighter) ||
            (isDragReject && theme.colors.error.lighter)
        }}
      >
        <input {...getInputProps()} />
        {isDragAccept && (
          <>
            <AvatarSuccess variant="rounded">
              <CheckTwoToneIcon />
            </AvatarSuccess>
            <TypographyPrimary
              sx={{
                mt: 1.5,
                color: theme.colors.success.main
              }}
            >
              {t('Drop the files to start uploading')}
            </TypographyPrimary>
          </>
        )}
        {isDragReject && (
          <>
            <AvatarDanger variant="rounded">
              <CloseTwoToneIcon />
            </AvatarDanger>
            <TypographyPrimary
              sx={{
                mt: 1.5,
                color: theme.colors.error.main
              }}
            >
              {t('You cannot upload these file types')}
            </TypographyPrimary>
          </>
        )}
        {!isDragActive && (
          <>
            {!hideIcon && (
              <AvatarWrapper variant="rounded">
                <CloudUploadTwoToneIcon />
              </AvatarWrapper>
            )}
            <TypographyPrimary
              sx={{
                mt: hideIcon ? undefined : 1.5
              }}
            >
              {t('Drag & drop files here')}
            </TypographyPrimary>
            {!!info && info}
          </>
        )}
      </BoxUploadWrapper>
      {!hideFilesInfo && droppedFiles?.length > 0 && (
        <Box>
          <Alert
            sx={{
              py: 0,
              mt: 2
            }}
            severity="success"
          >
            {t('You have uploaded')} <b>{renderFileItems.length}</b>{' '}
            {renderFileItems.length > 1 ? t('files') : t('file')}!
          </Alert>
          <DividerContrast
            sx={{
              mt: 2
            }}
          />
          <List disablePadding component="div">
            {renderFileItems}
          </List>
        </Box>
      )}
    </>
  );
};

export default FileDropZone;
