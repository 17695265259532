import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import { EmailEditorMode } from '../../models/models';
import { useContext, useEffect, useState } from 'react';
import { AttachFile, DeleteOutline, OpenInNew } from '@mui/icons-material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor, SerializedEditor } from 'lexical';
import AttachmentFileContainer from 'src/modules/Dashboard/views/Inbox/TelegramPanel/AttachmentFileContainer';
import { setCampaignSelector } from 'src/modules/CTI/components/ExtendedBar/NewConversation/utils/setCampaignSelector';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';
import { useAlert } from 'src/hooks/useAlert';
import { motion } from 'framer-motion';
import { useCustomEventListener } from 'react-custom-events';
import { SendEmailMessageData } from '../../hooks/useEmailEditor';
import {
  CTIBarContext,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import { useLocation } from 'react-router-dom';

interface Props {
  message: SendEmailMessageData;
  setMessage: React.Dispatch<React.SetStateAction<SendEmailMessageData>>;
  mode: EmailEditorMode;
  onClose: () => void;
  handleSend: (params: {
    editor: LexicalEditor;
    files: File[];
    ticketId: string;
  }) => Promise<void>;
  setCampaignId: (id: string) => void;
  campaignId: string;
  onExternalButtonClick: (html: SerializedEditor) => void;
}

function EmailTopbar({
  message,
  setMessage,
  mode,
  onClose,
  handleSend,
  setCampaignId,
  campaignId,
  onExternalButtonClick
}: Props) {
  const [isEditingCC, setIsEditingCC] = useState(mode === EmailEditorMode.New);

  const showCC = message.cc.length > 0 || isEditingCC;
  const [editor] = useLexicalComposerContext();
  const { selectedTicket } = useDialpadStateContext();
  const [files, setFiles] = useState<File[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const campaignsQuery = useUserCampaignsQuery(true);

  const location = useLocation();
  const isOnExternal = location.pathname.includes('send-email');

  const ticketId = selectedTicket?.id;

  useEffect(() => {
    setCampaignSelector(
      'selectedEmailCampaign',
      setCampaigns,
      setCampaignId,
      campaignsQuery?.data?.elements,
      'Email',
      selectedTicket?.serviceId
    );
  }, [campaignsQuery?.dataUpdatedAt]);

  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  useCustomEventListener('email-send-error', (e) => {
    setIsLoading(false);
  });

  const disableSendButton =
    !message.to.length || !message.subject || isLoading || !campaignId;

  return (
    <Box
      style={{
        borderBottom: '1px solid #E0E0E0'
      }}
    >
      {/* FIRST ROW WITH SEND BUTTON, ATTACHMENTS, CLOSE BUTTON... */}
      <Stack
        p={1}
        pb={0}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width="100%"
      >
        <Stack direction={'row'} columnGap={1.5} alignItems={'center'}>
          <Button
            variant="contained"
            color="secondary"
            disabled={disableSendButton}
            style={{
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={async () => {
              setIsLoading(true);
              showAlert(t('Sending email') + '...', 'info', 3000);
              handleSend({ editor, files, ticketId });
            }}
          >
            <motion.div
              layout
              style={{
                height: 40,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px'
              }}
            >
              {!isLoading ? (
                <motion.span
                  key={'text'}
                  exit={{
                    opacity: 0
                  }}
                >
                  {t('Send')}
                </motion.span>
              ) : (
                <CircularProgress key={'progress'} size={20} color="inherit" />
              )}
            </motion.div>
          </Button>
          <Tooltip arrow placement="right" title={t('Attach file')}>
            <IconButton
              onClick={() => {
                //let user select file
                const input = document.createElement('input');
                input.type = 'file';
                input.multiple = true;
                input.click();
                input.onchange = (e) => {
                  const newFiles = (e.target as HTMLInputElement).files;
                  if (newFiles) {
                    setFiles([...files, ...newFiles]);
                  }
                };
              }}
            >
              <AttachFile />
            </IconButton>
          </Tooltip>
          {mode === EmailEditorMode.New && (
            <TextField
              select
              size="small"
              label={t('Campaign')}
              sx={{ width: '200px' }}
              value={campaignId}
              key={campaignId}
              onChange={(e) => {
                setCampaignId(e.target.value);
                localStorage.setItem('selectedEmailCampaign', e.target.value);
              }}
            >
              {campaigns.map((campaignItem, idx) => {
                return (
                  <MenuItem key={idx} value={campaignItem.id}>
                    {campaignItem.name}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </Stack>
        <Stack direction="row">
          {mode !== EmailEditorMode.New && (
            <IconButton color="error" onClick={onClose}>
              <DeleteOutline />
            </IconButton>
          )}
          {!isOnExternal && (
            <IconButton
              onClick={() => {
                editor.update(() => {
                  const serializedEditor = editor.toJSON();
                  onExternalButtonClick(serializedEditor);
                });
              }}
            >
              <OpenInNew />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {/* TO */}
      <Stack
        px={1.5}
        minHeight={'58px'}
        direction={'row'}
        alignItems={'center'}
        width="100%"
        columnGap={1}
      >
        <Typography width={'34px'} variant="body1" fontWeight={'bold'}>
          {t('To')}:
        </Typography>

        <Stack
          py={1.5}
          direction="row"
          alignItems={'center'}
          flexWrap={'wrap'}
          rowGap={0.5}
          columnGap={0.5}
          flexGrow={1}
        >
          {message.to.map((email, index) => (
            <Chip
              key={index}
              label={email}
              onDelete={() =>
                setMessage((prev) => {
                  return {
                    ...prev,
                    to: prev.to.filter((_, i) => i !== index)
                  };
                })
              }
            />
          ))}
          <TextField
            autoFocus
            placeholder={t('Enter an email') + '...'}
            sx={{
              flexGrow: 2,
              '& .MuiInputBase-root': {
                '& fieldset': {
                  border: 'none'
                }
              }
            }}
            inputProps={{
              style: {
                padding: '0',
                border: 'none'
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const mail = (e.target as HTMLInputElement).value;

                setMessage((prev) => {
                  return {
                    ...prev,
                    to: [...prev.to, mail]
                  };
                });
                (e.target as HTMLInputElement).value = '';
              }
            }}
            onBlur={(e) => {
              const mail = (e.target as HTMLInputElement).value;
              if (mail === '') return;
              setMessage((prev) => {
                return {
                  ...prev,
                  to: [...prev.to, mail]
                };
              });
              (e.target as HTMLInputElement).value = '';
            }}
          />
        </Stack>

        {!showCC && <Button onClick={() => setIsEditingCC(true)}>CC</Button>}
      </Stack>
      {/* CC */}
      {showCC && (
        <Stack
          pl={1.5}
          minHeight={'58px'}
          direction={'row'}
          alignItems={'center'}
          width="100%"
          columnGap={1}
        >
          <Typography width={'34px'} variant="body1" fontWeight={'bold'}>
            CC:
          </Typography>
          {!isEditingCC && (
            <div
              style={{
                minHeight: '58px',
                flexGrow: 1,
                borderTop: '1px solid #E0E0E0',
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '2px'
              }}
            >
              <Typography
                variant="body1"
                onClick={() => setIsEditingCC(true)}
                sx={{
                  paddingX: '2px',
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    backgroundColor: '#F5F5F5',
                    borderRadius: '5px'
                  }
                }}
              >
                {message.cc.join(', ')}
              </Typography>
            </div>
          )}
          {isEditingCC && (
            <Stack
              py={1.5}
              direction="row"
              alignItems={'center'}
              flexWrap={'wrap'}
              rowGap={0.5}
              columnGap={0.5}
              flexGrow={1}
              minHeight={'58px'}
              sx={{
                borderTop: '1px solid #E0E0E0'
              }}
            >
              {message.cc.map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  onDelete={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setMessage((prev) => {
                      return {
                        ...prev,
                        cc: prev.cc.filter((_, i) => i !== index)
                      };
                    });
                  }}
                />
              ))}
              <TextField
                placeholder={t('Enter an email') + '...'}
                autoFocus
                sx={{
                  flexGrow: 2,
                  '& .MuiInputBase-root': {
                    '& fieldset': {
                      border: 'none'
                    }
                  }
                }}
                inputProps={{
                  style: {
                    padding: '0',
                    paddingBottom: '1px',
                    border: 'none'
                  }
                }}
                onBlur={(e) => {
                  const mail = (e.target as HTMLInputElement).value;
                  if (mail === '') return;
                  setMessage((prev) => {
                    return {
                      ...prev,
                      cc: [...prev.cc, mail]
                    };
                  });
                  (e.target as HTMLInputElement).value = '';
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    const mail = (e.target as HTMLInputElement).value;

                    setMessage((prev) => {
                      return {
                        ...prev,
                        cc: [...prev.cc, mail]
                      };
                    });
                    (e.target as HTMLInputElement).value = '';
                  }
                }}
              />
            </Stack>
          )}
        </Stack>
      )}
      {
        <Stack
          pl={1.5}
          height={'58px'}
          direction={'row'}
          alignItems={'center'}
          width="100%"
          columnGap={1}
          style={{
            borderTop: '1px solid #E0E0E0'
          }}
        >
          <TextField
            autoFocus
            sx={{
              flexGrow: 2,
              '& .MuiInputBase-root': {
                '& fieldset': {
                  border: 'none'
                }
              }
            }}
            inputProps={{
              style: {
                padding: '0',
                border: 'none'
              }
            }}
            placeholder={t('Subject')}
            value={message.subject}
            onChange={(e) => {
              setMessage((prev) => {
                return {
                  ...prev,
                  subject: e.target.value
                };
              });
            }}
          />
        </Stack>
      }
      {files.length > 0 && (
        <Stack
          p={1.5}
          direction={'row'}
          alignItems={'center'}
          maxWidth="100%"
          style={{
            overflowX: 'auto',
            scrollbarColor: 'black',
            scrollbarWidth: 'thin',
            scrollbarGutter: 'stable'
          }}
          columnGap={1.5}
        >
          {files.map((file, index) => {
            return (
              <AttachmentFileContainer
                key={index}
                file={file}
                onRemove={() => {
                  setFiles(files.filter((_, i) => i !== index));
                }}
                setFile={() => {}}
              />
            );
          })}
        </Stack>
      )}
    </Box>
  );
}

export default EmailTopbar;
