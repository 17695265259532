import { Conversation } from 'src/models/conversations/conversations';
import { getMessages } from '../messages/getMessages';
import { addConversationMessages } from './setConversationMessages';
import { CHAT_MESSAGES_PAGE_SIZE } from 'src/models/conversations/messages';

/** UPDATE CONVERSATION MESSAGES
 *
 * Fetches the messages from a conversation and updates it.
 */
export const refreshConversationMessages = async (
  conversation: Conversation,
  page: number = 0
) => {
  // if (conversation.status != 'Assigned') return;
  if (!conversation || conversation?.channel == 'Call') return;

  const paginatedMessages = await getMessages({
    conversationId: conversation?.id,
    size: CHAT_MESSAGES_PAGE_SIZE,
    page
  });
  // Limpiando mensajes repetidos..
  // TODO: Elminar cuando ya no haga falta.
  const msgIds = [];
  const messages = paginatedMessages.elements.filter((msg) => {
    if (!msgIds.includes(msg.messageId)) {
      msgIds.push(msg.messageId);
      return true;
    }

    return false;
  });

  const newConversation = {
    ...conversation,
    totalMessages: paginatedMessages.totalItems,
    unreadIncomingMessages: false
  } as Conversation;

  addConversationMessages(newConversation, messages);
};
