import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';
import { timeZoneArray } from 'src/modules/Admin/modules/Operations/modules/DaServices/views/utils/defaultDaServices';

interface Props {
  timeZone: string;
  setTimeZone: (timeZone: string) => void;
}

export function DialTableTimeZoneSelector({ timeZone, setTimeZone }: Props) {
  return (
    <Autocomplete
      id="workDayTable-timezone-selector"
      options={timeZoneArray}
      size="small"
      value={timeZone}
      onChange={(_, newValue) => {
        setTimeZone(newValue as string);
      }}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          label={t('Time zone')}
        />
      )}
      sx={{ width: '200px' }}
    />
  );
}
