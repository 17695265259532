import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'src/utils/queryClient';
import { getVariablesApi } from '../infrastructure/getVariablesApi';
import { Variable } from '../models/models';
import { VariableFilterProps } from '../domain/getVariablesDomain';

export const getCompanyVariablesQuery = (filters: VariableFilterProps) => {
  return queryClient.fetchQuery(
    ['variablesAcd', filters?.companyId, filters?.contactGroupId],
    () => getVariablesApi(filters),
    { staleTime: 1000 * 60 * 5 }
  );
};

export const useCompanyVariablesQuery = (filters: VariableFilterProps) => {
  return useQuery<Variable[], Error>(
    ['variablesAcd', filters?.companyId, filters?.contactGroupId],
    () => getVariablesApi(filters),
    { staleTime: 1000 * 60 * 5 }
  );
};
