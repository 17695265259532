import { useEffect, useState } from 'react';
import { getEmailTextWithParsedInlineAttachments } from '../utils/parseHtmlAttachments';
import { EmailMessage } from 'src/models/conversations/messages';

function useEmailParser(message: EmailMessage) {
  const [htmlMessage, setHtmlMessage] = useState('');

  useEffect(() => {
    if (!message) return;
    getEmailTextWithParsedInlineAttachments(message).then(
      async (parsedHtml) => {
        setHtmlMessage(parsedHtml);
      }
    );
  }, [message?.message]);
  return { htmlMessage };
}

export default useEmailParser;
