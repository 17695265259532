import {
  Box,
  useTheme,
  alpha,
  Typography,
  Divider,
  MenuItem,
  ListItemText,
  styled,
  MenuList,
  Button,
  Grid,
  Tooltip
} from '@mui/material';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { Menu } from 'src/models/menus';
import { useContext } from 'react';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { AdminPanelSettings } from '@mui/icons-material';
import usePermissions from 'src/hooks/usePermissions';

interface MegaMenuPropTypes {
  title: string;
  subtitle?: string;
  items: Menu[];
  footer?: {
    text: string;
    link: string;
  };
}

export const MegaMenu: React.FC<MegaMenuPropTypes> = ({
  title,
  subtitle,
  items,
  footer
}) => {
  const { hasAccess } = useContext(PermissionsContext);
  const { isSuperAdmin } = usePermissions();
  const theme = useTheme();

  const MenuListWrapperSecondary = styled(MenuList)(
    ({ theme }) => `

  & .MuiMenuItem-root {
      border-radius: 50px;
      min-width: 100px;
      position: relative;
      color: ${theme.colors.alpha.black[70]};
      margin-top:-10px;
      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.alpha.black[10]};
          color: ${theme.colors.alpha.black[100]};
      }

      &:last-child {
          margin-bottom: 5px;
      }
    }
`
  );

  const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(15)};
      border-radius: ${theme.general.borderRadius};
      margin-bottom:10px;
      margin-top:5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
    font-weight:bold;    
`
  );

  return (
    <Box
      sx={{
        width: 950
      }}
    >
      <Box
        sx={{
          p: 1,
          background: alpha(theme.colors.alpha.black[100], 0.06)
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography
            sx={{
              pb: 0.5
            }}
            variant="h4"
          >
            {t(title)}
          </Typography>
          <Typography noWrap variant="subtitle2">
            {t(subtitle)}
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Grid container spacing={0.5}>
        {items.map((element, index) => {
          return (
            <Grid
              item
              key={index}
              sx={{
                marginTop: -2
              }}
              sm={3}
            >
              <MenuListWrapperSecondary key={index} disablePadding>
                <LabelWrapper sx={{ mt: 1, mb: 2 }}>
                  <Box
                    sx={{
                      backgroundColor: element.color,
                      borderRadius: 2,
                      height: 25,
                      width: 25,
                      textAlign: 'center',
                      marginRight: '2px',
                      marginBottom: '3px'
                    }}
                  >
                    <element.icon
                      fontSize="small"
                      sx={{
                        marginTop: 0.3,
                        fontWeight: 'bold',
                        color: element.iconColor
                      }}
                    />
                  </Box>
                  {t(element.option)}
                </LabelWrapper>
                {element.items.map((item) => {
                  if (
                    item?.permissionsCheckerFn &&
                    !hasAccess(item?.permissionsCheckerFn)
                  )
                    return <></>;
                  return (
                    <MenuItem
                      key={item.name}
                      component={NavLink}
                      to={item.link}
                      sx={{
                        pr: '6px',
                        py: '4px',
                        mb: '14px'
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'h5'
                        }}
                        primary={t(item.name)}
                      />
                      {item.onlySuperAdmin && isSuperAdmin && (
                        <AdminPanelSettings
                          color="inherit"
                          fontSize="small"
                          sx={{ marginBottom: 0.25 }}
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </MenuListWrapperSecondary>
            </Grid>
          );
        })}
      </Grid>
      {footer ? (
        <>
          <Divider />
          <Box
            sx={{
              m: 2,
              textAlign: 'center'
            }}
          >
            <Button size="small" color="primary" id="btnFooter-MegaMenu">
              {t(footer.text)}
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
