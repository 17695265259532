import { useContext, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { useAlert } from 'src/hooks/useAlert';
import { ContactField, ContactGroup } from '../../models/contacts';
import {
  GetCompanyContactFieldsFilter,
  useGetContactFields
} from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import { ContactsDomain } from '../../domain/ContactsDomain';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { ColumnDef, Row } from '@tanstack/react-table';
import { IconButton, Stack } from '@mui/material';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkDeleteContactFieldsPermissions,
  checkUpdateContactGroupPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import AuthManager from 'src/services/authentication/manager';
import { getNameContactGroup } from '../../domain/getContactGroups';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { DialTableStatusLabel } from 'src/components/DialTable/components/DialTableStatusLabel';
import {
  DialTableBulkActionsContext,
  DialTableFiltersContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';

export const useContactFields = () => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const { hasAccess } = useContext(PermissionsContext);
  const companyId = AuthManager.getLoggedUserCompanyId();
  const [fieldToEdit, setFieldToEdit] = useState<ContactField | undefined>(
    undefined
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [contactsToDelete, setContactsToDelete] = useState<ContactField[]>([]);
  const [contactGroupsData, setContactGroupsData] = useState<ContactGroup[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<Row<ContactField>[]>([]);
  const [filters, setFilters] = useState<GetCompanyContactFieldsFilter>({
    companyId,
    contactGroupId: undefined
  });

  const contactFieldsQuery = useGetContactFields(filters);
  const data = contactFieldsQuery.data?.elements;

  const onDeleteContactFields = async (contactFields: ContactField[]) => {
    try {
      ContactsDomain.deleteContactFields(contactFields).then(() => {
        emitCustomEvent('table-data-refetch');
        showAlert(
          t(
            `The contact field${
              contactFields.length > 1 ? 's' : ''
            } ${contactFields
              .map((field) => field.name)
              .join(', ')} was successfully deleted`
          ),
          'success',
          2000
        );
        emitCustomEvent(REFETCH_DATA_EVENT_NAME);
      });
    } catch (error) {
      showAlert(
        t(
          `The contact field${
            contactFields.length > 1 ? 's' : ''
          } ${contactFields
            .map((contact) => contact.name)
            .join(', ')} could not be deleted`
        ),
        'error',
        2000
      );
    }
    selectedRows.map((row) => row.toggleSelected());
    setSelectedRows([]);
    setContactsToDelete([]);
    setIsOpenDeleteModal(false);
  };

  const getColumns: () => Promise<ColumnDef<ContactField>[]> = async () => [
    {
      accessorKey: 'name',
      id: 'name',
      header: t('Name')
    },
    {
      accessorKey: 'type',
      id: 'type',
      header: t('Field type')
    },
    {
      accessorKey: 'contactGroupId',
      id: 'contactGroupId',
      header: t('Contact group'),
      cell: (props) => (
        <>
          {getNameContactGroup(props.getValue() as string, contactGroupsData)}
        </>
      )
    },
    {
      accessorKey: 'mainField',
      id: 'mainField',
      header: t('Main field'),
      cell: ({ getValue }) => <DialTableStatusLabel enabled={!!getValue()} />
    }
  ];

  const getRowActions = ({
    rowData: field
  }: DialTableRowActionsContext<ContactField>) => [
    <IconButton
      key="editContacField"
      title={t('Edit')}
      size="small"
      color="secondary"
      onClick={() => {
        setFieldToEdit(field);
      }}
      disabled={!hasAccess(checkUpdateContactGroupPermissions)}
    >
      <Edit fontSize="small" />
    </IconButton>,
    <IconButton
      key="deleteContactField"
      title={t('Delete contact field')}
      size="small"
      color="error"
      onClick={() => {
        setContactsToDelete([field]);
        setIsOpenDeleteModal(true);
      }}
      disabled={
        !hasAccess(checkUpdateContactGroupPermissions) ||
        !hasAccess(checkDeleteContactFieldsPermissions)
      }
    >
      <Delete fontSize="small" />
    </IconButton>
  ];

  const getBulkActions = ({
    selectedData: fields,
    selectedRowModel
  }: DialTableBulkActionsContext<ContactField>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          color="error"
          title={t('Delete selected fields')}
          size="small"
          onClick={() => {
            setContactsToDelete([...fields]);
            setIsOpenDeleteModal(true);
            setSelectedRows(selectedRowModel.rows);
          }}
          disabled={!hasAccess(checkUpdateContactGroupPermissions)}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  const getAvailableFilters = () => {
    const filters: DialTableFilter<ContactField>[] = [
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'contactGroupId',
        label: t('Contact group'),
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: DataCategory.CONTACT_GROUP_ID
      }
    ];
    return Promise.resolve(filters);
  };

  function applyFilters({
    filtersObject
  }: DialTableFiltersContext<ContactField>) {
    const params: GetCompanyContactFieldsFilter = {
      companyId,
      contactGroupId: filtersObject['contactGroupId']?.[0] ?? undefined
    };
    setFilters(params);
  }

  return {
    fieldToEdit,
    setFieldToEdit,
    contactsToDelete,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    onDeleteContactFields,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    companyId,
    setContactGroupsData,
    data,
    filters,
    applyFilters
  };
};
