import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { ChannelType } from "src/models/conversations/conversations";
import useBarState, { BarState } from "src/modules/CTI/hooks/useBarState";
import useDialpadState, {
  DialpadState,
} from "src/modules/CTI/hooks/useDialpadState";

export enum NewConversationMenu {
  Call = "keyboard",
  Email = "email",
  Telegram = "telegram",
  WhatsApp = "whatsapp",
  Videocall = "videocall",
  Instagram = "instagram",
}

export enum CTIPopoverNames {
  TRANSFER = "Transfer",
  NEW_CONVERSATION = "New conversation",
  DEVICES = "Devices configuration",
  CONFERENCE = "Conference",
}

interface CTIBarContext {
  activeTab: "dialpad" | "contacts" | "recent";
  setActiveTab: Dispatch<SetStateAction<"dialpad" | "contacts" | "recent">>;
  dialpadState: DialpadState;
  openPopover: (name: string) => void;
  callToTransferID: string;
  setCallToTransferID: Dispatch<SetStateAction<string>>;
  barState: BarState;
  userHasExtension: boolean;
  activeRecordings: ActiveRecordings;
  setActiveRecordings: Dispatch<SetStateAction<ActiveRecordings>>;
  channelsWithAccess: NewConversationMenu[];
  isChannelEnabled: (channel: ChannelType) => boolean;
}
export interface ActiveRecordings {
  //callID: recordingId
  [callId: string]: string;
}

export const channelToDialpadTab = {
  [ChannelType.CALL]: NewConversationMenu.Call,
  [ChannelType.EMAIL]: NewConversationMenu.Email,
  [ChannelType.TELEGRAM]: NewConversationMenu.Telegram,
  [ChannelType.WHATSAPP]: NewConversationMenu.WhatsApp,
  [ChannelType.INSTAGRAM]: NewConversationMenu.Instagram,
} as Record<ChannelType, NewConversationMenu>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CTIBarContext = createContext<CTIBarContext>({} as CTIBarContext);

export const CTIBarProvider = ({ children }) => {
  const barState = useBarState();

  const userHasExtension = !!localStorage.getItem("extensionData");

  const [activeTab, setActiveTab] = useState<"dialpad" | "contacts" | "recent">(
    "dialpad"
  );

  const openPopover = (name: CTIPopoverNames) => {
    barState.setIsBarExtended(true);
    setTimeout(() => {
      emitCustomEvent("Open popover", name);
    }, 0);
  };

  // Dialpad tab
  const dialpadState = useDialpadState({ openPopover, setActiveTab });
  const { channelsWithAccess, isChannelEnabled, setInputValue, setCallType } =
    dialpadState;

  //Id of the call to transfer in an attended transfer
  const [callToTransferID, setCallToTransferID] = useState(null);

  const [activeRecordings, setActiveRecordings] = useState(
    {} as ActiveRecordings
  );

  useCustomEventListener("callUser", (extension: string) => {
    openPopover(CTIPopoverNames.NEW_CONVERSATION);
    setInputValue(extension);
    setCallType("Internal");
  });

  return (
    <CTIBarContext.Provider
      value={{
        barState,
        userHasExtension,
        activeTab,
        setActiveTab,
        dialpadState,
        openPopover,
        callToTransferID,
        setCallToTransferID,
        activeRecordings,
        setActiveRecordings,
        channelsWithAccess,
        isChannelEnabled,
      }}
    >
      {children}
    </CTIBarContext.Provider>
  );
};

export function useDialpadStateContext() {
  const { dialpadState } = useContext(CTIBarContext);
  return dialpadState;
}
