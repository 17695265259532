import type { MouseEventHandler, ReactNode } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InboxIcon from '@mui/icons-material/Inbox';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import { Permissions } from 'src/models/permissionGroups';
import { logoutCurrentUser } from 'src/services/authentication/domain/logoutCurrentUser';
import {
  checkAdminPermissions,
  checkContactsPermissions,
  checkInboxPermissions,
  checkProductivityPermissions,
  checkReadAnalyzePermissions,
  checkReadAnnouncementPermissions,
  checkReadApiKeysPermissions,
  checkReadCallerIdsPermissions,
  checkReadContactFieldsPermissions,
  checkReadContactGroupPermissions,
  checkReadContactPermissions,
  checkReadContactTagsPermissions,
  checkReadContactsAndContactGroupsPermissions,
  checkReadDialerbotCampaignPermissions,
  checkReadEmailAccountPermissions,
  checkReadExtensionPermissions,
  checkReadExternalActionsPermissions,
  checkReadInboundCampaignPermissions,
  checkReadInstagramAccountPermissions,
  checkReadKeyWordsGroupsPermissions,
  checkReadMusicOnHoldPermissions,
  checkReadOutboundCampaignPermissions,
  checkReadQuestionGroupsPermissions,
  checkReadRobinsonListsPermissions,
  checkReadServicePermissions,
  checkReadTelegramAccountPermissions,
  checkReadTicketPermissions,
  checkReadTimeConditionPermissions,
  checkReadUserPermissions,
  checkReadUserRolePermissions,
  checkReadVariablePermissions,
  checkReadWebChatAccountPermissions,
  checkReadWhatsAppAccountPermissions,
  checkUpdateServicePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
  permissionCheckerFn?: (permissions: Permissions) => boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface MenuItemExtended extends MenuItem {
  isVisible: boolean;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      // {
      //   name: 'Home',
      //   icon: HomeIcon,
      //   link: '/dashboard/home'
      // },
      {
        name: 'Inbox',
        icon: InboxIcon,
        link: '/dashboard/inbox',
        permissionCheckerFn: checkInboxPermissions
      },
      {
        name: 'Services',
        icon: ArticleIcon,
        link: '/dashboard/services',
        permissionCheckerFn: checkUpdateServicePermissions
      },

      // COULD CHANGE
      {
        name: 'Contacts',
        icon: PersonIcon,
        link: '/dashboard/contacts',
        permissionCheckerFn: checkContactsPermissions
      },
      {
        name: 'Calendar',
        icon: CalendarTodayIcon,
        link: '/dashboard/schedule',
        permissionCheckerFn: (permissions) => true
      },
      {
        name: 'Productivity',
        icon: BarChartIcon,
        link: '/dashboard/productivity/live',
        permissionCheckerFn: checkProductivityPermissions
      },
      {
        name: 'Admin',
        icon: SettingsIcon,
        link: '/dashboard/admin',
        items: [
          {
            name: 'General Settings',

            items: [
              {
                name: 'Companies',
                link: '/dashboard/admin/settings/companies'
              },
              {
                name: 'Extensions',
                link: '/dashboard/admin/settings/extensions',
                permissionCheckerFn: checkReadExtensionPermissions
              },
              {
                name: 'Caller Ids',
                link: '/dashboard/admin/settings/caller-ids',
                permissionCheckerFn: checkReadCallerIdsPermissions
              }
            ]
          },
          {
            name: 'Operations',
            items: [
              {
                name: 'Services',
                link: '/dashboard/admin/operations/services',
                permissionCheckerFn: checkReadServicePermissions
              },
              {
                name: 'Tickets',
                link: '/dashboard/admin/operations/tickets',
                permissionCheckerFn: checkReadTicketPermissions
              },
              {
                name: 'Motores de marcacion',
                link: '/dashboard/admin/operations/dialerbot',
                permissionCheckerFn: checkReadDialerbotCampaignPermissions
              },
              {
                name: 'Robinson List',
                link: '/dashboard/admin/operations/robinson-list',
                permissionCheckerFn: checkReadRobinsonListsPermissions
              },
              {
                name: 'Massive SMS',
                link: '/dashboard/admin/operations/massive-sms'
              }
            ]
          },
          {
            name: 'Integrations',
            items: [
              {
                name: 'External Actions',
                link: '/dashboard/admin/integrations/externalActions',
                permissionCheckerFn: checkReadExternalActionsPermissions
              }
            ]
          },
          {
            name: 'Contact Center',
            items: [
              {
                name: 'Chat',
                link: '/dashboard/admin/contact-center/chat',
                permissionCheckerFn: checkReadWebChatAccountPermissions
              },
              {
                name: 'Email',
                link: '/dashboard/admin/contact-center/email',
                permissionCheckerFn: checkReadEmailAccountPermissions
              },
              {
                name: 'Instagram',
                link: '/dashboard/admin/contact-center/instagram',
                permissionCheckerFn: checkReadInstagramAccountPermissions
              },
              {
                name: 'Telegram',
                link: '/dashboard/admin/contact-center/telegram',
                permissionCheckerFn: checkReadTelegramAccountPermissions
              },
              {
                name: 'WhatsApp',
                link: '/dashboard/admin/contact-center/whatsapp',
                permissionCheckerFn: checkReadWhatsAppAccountPermissions
              }
            ]
          },
          {
            name: 'Management',
            items: [
              {
                name: 'Users',
                link: '/dashboard/admin/management/view-users',
                permissionCheckerFn: checkReadUserPermissions
              },
              {
                name: 'Roles and Permissions',
                link: '/dashboard/admin/management/roles-and-permissions',
                permissionCheckerFn: checkReadUserRolePermissions
              },
              {
                name: 'API Keys',
                link: '/dashboard/admin/management/api-keys',
                permissionCheckerFn: checkReadApiKeysPermissions
              }
            ]
          },
          {
            name: 'Acd',
            items: [
              {
                name: 'Music On Hold',
                link: '/dashboard/admin/acd/moh',
                permissionCheckerFn: checkReadMusicOnHoldPermissions
              },
              {
                name: 'Inbound Routes',
                link: '/dashboard/admin/acd/routes',
                permissionCheckerFn: checkReadInboundCampaignPermissions
              },
              {
                name: 'Variables',
                link: '/dashboard/admin/acd/variables',
                permissionCheckerFn: checkReadVariablePermissions
              },
              {
                name: 'Intention model',
                link: '/dashboard/admin/acd/ai-models'
              },
              {
                name: 'Temporal conditions',
                link: '/dashboard/admin/acd/schedule-groups',
                permissionCheckerFn: checkReadTimeConditionPermissions
              },
              {
                name: 'Announcements',
                link: '/dashboard/admin/acd/announcements',
                permissionCheckerFn: checkReadAnnouncementPermissions
              },

              {
                name: 'Outbound Routes',
                link: '/dashboard/admin/acd/outbound-routes',
                permissionCheckerFn: checkReadOutboundCampaignPermissions
              }
            ]
          },
          {
            name: 'Quality',
            items: [
              {
                name: 'Keyword Groups',
                link: '/dashboard/admin/quality/keyword-groups',
                permissionCheckerFn: checkReadKeyWordsGroupsPermissions
              },
              {
                name: 'Analyzed Recordings',
                link: '/dashboard/admin/quality/analyzed-recordings',
                permissionCheckerFn: checkReadAnalyzePermissions
              },
              {
                name: 'Questions Groups',
                link: '/dashboard/admin/quality/questions-groups',
                permissionCheckerFn: checkReadQuestionGroupsPermissions
              }
            ]
          },
          {
            name: 'Contacts',
            items: [
              {
                name: 'Contacts',
                link: '/dashboard/admin/contacts/view-contacts',
                permissionCheckerFn: checkReadContactPermissions
              },
              {
                name: 'Tags',
                link: '/dashboard/admin/contacts/tags',
                permissionCheckerFn: (permissions: Permissions) =>
                  checkReadContactTagsPermissions(permissions) &&
                  checkReadContactsAndContactGroupsPermissions(permissions)
              },
              {
                name: 'Contacts fields',
                link: '/dashboard/admin/contacts/contact-fields',
                permissionCheckerFn: (permissions: Permissions) =>
                  checkReadContactTagsPermissions(permissions) &&
                  checkReadContactsAndContactGroupsPermissions(permissions)
              },
              {
                name: 'Contacts groups',
                link: '/dashboard/admin/contacts/contact-groups',
                permissionCheckerFn: checkReadContactGroupPermissions
              }
            ]
          }
        ],
        permissionCheckerFn: checkAdminPermissions
      }
    ]
  },
  {
    heading: 'Others',
    items: [
      {
        name: 'Log out',
        onClick: (e) => {
          logoutCurrentUser();
        },
        icon: PowerSettingsNewIcon
      }
    ]
  }
];

export default menuItems;
