import UsersDomain from 'src/services/users/domain/UserDomain';
import { DataCategory } from '../models/filters';
import { Option } from '../models/option';
import { PaginatedElements } from 'src/models/pagination';
import {
  callModeOptions,
  callResultOptions
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import DaServicesDomain from 'src/modules/Admin/modules/Operations/modules/DaServices/domain/DaServicesDomain';
import { ContactsDomain } from 'src/modules/Contacts/domain/ContactsDomain';
import ExtensionsDomain from 'src/modules/Admin/modules/Configurations/modules/Extensions/domain/ExtensionsDomain';
import { TicketsDomain } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/TicketsDomain';
import {
  useStatusReasonOptions,
  userStatusTypeOptions
} from 'src/models/users';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';
import {
  ChannelType,
  ConversationStatus
} from 'src/models/conversations/conversations';
import { getContactGroups } from 'src/modules/Contacts/domain/getContactGroups';
import { getContactTags } from 'src/modules/Contacts/domain/getContactTags';
import { DirectionType } from 'src/components/TableReporting/models/direction';

export type StringExtractor<T> = keyof T | ((data: T) => string | keyof T);

export const getStringOption = <T>(
  option: T,
  extractor: StringExtractor<T>
) => {
  if (!option || !extractor) return '';
  if (typeof extractor === 'string') return String(option?.[extractor]) ?? '';
  if (typeof extractor === 'function') return String(extractor?.(option)) ?? '';
  if (typeof option !== 'object') return String(option) ?? '';
};

export const getPaginatedElements = <T>(elements: T[]) => {
  const paginatedElements: PaginatedElements<T> = {
    currentPage: 0,
    elements,
    totalItems: elements.length,
    totalPages: 1
  };
  return paginatedElements;
};

export const getOption = <T>(params: {
  option: T;
  labelExtractor: StringExtractor<T>;
  valueExtractor: StringExtractor<T>;
  descriptionExtractor?: StringExtractor<T>;
}) => {
  const { option, labelExtractor, valueExtractor, descriptionExtractor } =
    params;
  const newOption: Option = {
    label: getStringOption(option, labelExtractor),
    value: getStringOption(option, valueExtractor),
    description: getStringOption(option, descriptionExtractor)
  };
  return newOption;
};

export const getOptions = <T>(params: {
  options: T[];
  labelExtractor: StringExtractor<T>;
  valueExtractor: StringExtractor<T>;
  descriptionExtractor?: StringExtractor<T>;
}) => {
  const { options, labelExtractor, valueExtractor, descriptionExtractor } =
    params;
  return options.map((op) => {
    const option: Option = getOption({
      option: op,
      labelExtractor,
      valueExtractor,
      descriptionExtractor
    });
    return option;
  });
};

export const getPaginatedOptions = <T>(params: {
  paginatedOptions: PaginatedElements<T>;
  labelExtractor: StringExtractor<T>;
  valueExtractor: StringExtractor<T>;
  descriptionExtractor?: StringExtractor<T>;
}) => {
  const {
    paginatedOptions,
    labelExtractor,
    valueExtractor,
    descriptionExtractor
  } = params;
  const elements = getOptions({
    options: paginatedOptions.elements,
    labelExtractor,
    valueExtractor,
    descriptionExtractor
  });
  const optionsPaginated: PaginatedElements<Option> = {
    ...paginatedOptions,
    elements
  };
  return optionsPaginated;
};

export const getValueOptions = async (params: {
  dataCategory: DataCategory;
  companyId: string;
  page: number;
  size: number;
  /** Callback called to return custom filter option values. The custom options will not overwrite the default options.
   *
   * This callback can be used to consider other DataCategory that are not already considered by the table.
   */
  getCustomFilterValueOptionsFn?: (
    dataCategory: DataCategory,
    companyId: string,
    input?: string
  ) => Promise<PaginatedElements<Option>>;
  input?: string;
}) => {
  const {
    dataCategory,
    companyId,
    page,
    size,
    getCustomFilterValueOptionsFn,
    input
  } = params;
  switch (dataCategory) {
    // case DataCategory.ACCOUNT_ID:
    case DataCategory.AGENT_ID:
      const agentOptions = await getAgentOptions({
        companyId,
        page,
        size,
        input
      });
      return agentOptions;
    // case DataCategory.AGENT_TYPE:
    case DataCategory.CALL_MODE:
      const callModeOptions = getCallModeOptions(input);
      return callModeOptions;
    case DataCategory.CALL_RESULT:
      const callResultOptions = getCallResultOptions(input);
      return callResultOptions;
    case DataCategory.CAMPAIGN_ID:
      const campaignOptions = await getCampaignOptions({
        companyId,
        page,
        size,
        input
      });
      return campaignOptions;
    case DataCategory.CHANNEL:
      const channelOptions = getChannelOptions(input);
      return channelOptions;
    case DataCategory.COMPANY_ID:
      const companyOptions = getCompanyOptions(input);
      return companyOptions;
    case DataCategory.CONTACT_ID:
      const contactOptions = await getContactOptions({ companyId, page, size });
      return contactOptions;
    case DataCategory.CONTACT_GROUP_ID:
      const contactGroupOptions = await getContactGroupOptions({
        companyId,
        page,
        size,
        input
      });
      return contactGroupOptions;
    case DataCategory.CONTACT_TAG_ID:
      const contactTagOptions = await getContactTagOptions({
        companyId,
        page,
        size,
        input
      });
      return contactTagOptions;
    // case DataCategory.CONVERSATION_SUMMARIES_ID:
    // case DataCategory.DATABASE_ID:
    // case DataCategory.DATE:
    case DataCategory.EXTENSION_ID:
      const extensionOptions = await getExtensionOptions({
        companyId,
        page,
        size,
        input
      });
      return extensionOptions;
    case DataCategory.SERVICE_ID:
      const serviceOptions = await getServiceOptions(companyId, input);
      return serviceOptions;
    case DataCategory.STATUS:
      const statusOptions = getStatusOptions(input);
      return statusOptions;
    case DataCategory.SUBRESOLUTION_ID:
      const subResolutionOptions = await getTicketSubResolutionOptions(
        companyId,
        input
      );
      return subResolutionOptions;
    case DataCategory.TAG_ID:
      const tagOptions = await getTicketTagOptions(companyId, input);
      return tagOptions;
    // case DataCategory.TRUE_OR_FALSE_OR_BOTH:
    // case DataCategory.TYPE:
    // case DataCategory.UPLOAD_STATUS:
    case DataCategory.USER_STATUS_REASON:
      const statusReasonOptions = getUserStatusReasonOptions(input);
      return statusReasonOptions;
    case DataCategory.USER_STATUS_TYPE:
      const statusTypeOptions = getUserStatusTypeOptions(input);
      return statusTypeOptions;
    case DataCategory.USER_STATUS_NAME:
      const statusNameOptions = await getUserStatusNameOptions(
        companyId,
        input
      );
      return statusNameOptions;
    // case DataCategory.VARIABLES:
    // case DataCategory.WHATSAPP_AGENT_TYPE:
    default:
      const customOptions = await getCustomFilterValueOptionsFn?.(
        dataCategory,
        companyId,
        input
      );
      return customOptions;
  }
};

const isSubstring = <T extends string>(item: T, input: string) =>
  !input || (!!input && item.toLowerCase().includes(input.toLowerCase()));

const compareString = (a: string, b: string) => a.localeCompare(b);

export const getAgentOptions = async (params: {
  companyId: string;
  page: number;
  size: number;
  input?: string;
}) => {
  const { companyId, page, size, input } = params;
  const agents = await UsersDomain.getAllUsers({
    companyId,
    page,
    size,
    sortBy: 'username',
    sortDir: DirectionType.ASC,
    username: input
  });
  const paginatedOptions = getPaginatedOptions({
    paginatedOptions: agents,
    labelExtractor: 'username',
    valueExtractor: 'id',
    descriptionExtractor: 'description'
  });
  return paginatedOptions;
};

export const getCallModeOptions = (input?: string) => {
  const options = getOptions({
    options: callModeOptions,
    labelExtractor: (callMode) => callMode as string,
    valueExtractor: (callMode) => callMode as string
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getCallResultOptions = (input?: string) => {
  const options = getOptions({
    options: callResultOptions,
    labelExtractor: (callResult) => callResult as string,
    valueExtractor: (callResult) => callResult as string
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getCampaignOptions = async (params: {
  companyId: string;
  page: number;
  size: number;
  input?: string;
}) => {
  const { companyId, page, size, input } = params;
  const paginatedCampaigns = await DaServicesDomain.getAllCampaigns({
    companyId,
    page,
    size,
    name: input,
    sortBy: 'name',
    sortDir: DirectionType.ASC
  });
  const paginatedOptions = getPaginatedOptions({
    paginatedOptions: paginatedCampaigns,
    labelExtractor: 'name',
    valueExtractor: 'id'
  });

  return paginatedOptions;
};

export const getChannelOptions = (input?: string) => {
  const options = getOptions({
    options: Object.values(ChannelType),
    labelExtractor: (channel) => channel as string,
    valueExtractor: (channel) => channel as string
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getCompanyOptions = async (input?: string) => {
  const companies = await UsersDomain.getAllCompanies({
    sortDir: DirectionType.ASC,
    sortBy: 'name'
  });
  const options = getOptions({
    options: companies,
    labelExtractor: 'name',
    valueExtractor: 'id'
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const paginatedOptions = getPaginatedElements(filteredOptions);
  return paginatedOptions;
};

export const getContactOptions = async (params: {
  companyId: string;
  page: number;
  size: number;
}) => {
  const { companyId, page, size } = params;

  const paginatedContacts = await ContactsDomain.getContacts({
    companyId,
    page,
    size
  });
  const contactsWithNames = await Promise.all(
    paginatedContacts.elements.map(async (contact) => {
      const contactName = await getContactName(contact);
      return { ...contact, name: contactName };
    })
  );
  const contactOptions = getOptions({
    options: contactsWithNames,
    labelExtractor: 'name',
    valueExtractor: 'id'
  });
  const paginatedOptions: PaginatedElements<Option> = {
    ...paginatedContacts,
    elements: contactOptions
  };
  return paginatedOptions;
};

export const getContactGroupOptions = async (params: {
  companyId: string;
  page: number;
  size: number;
  input?: string;
}) => {
  const { companyId, page, size, input } = params;
  const paginatedContactGroups = await getContactGroups({
    companyId,
    name: input,
    sortBy: 'name',
    sortDir: DirectionType.ASC,
    page,
    size
  });
  const paginatedOptions = getPaginatedOptions({
    paginatedOptions: paginatedContactGroups,
    labelExtractor: 'name',
    valueExtractor: 'id'
  });

  return paginatedOptions;
};

export const getContactTagOptions = async (params: {
  companyId: string;
  page: number;
  size: number;
  input?: string;
}) => {
  const { companyId, page, size } = params;
  const paginatedContactTags = await getContactTags({
    companyId,
    page,
    size,
    sortBy: 'name',
    sortDir: DirectionType.ASC
  });
  const paginatedOptions = getPaginatedOptions({
    paginatedOptions: paginatedContactTags,
    labelExtractor: 'name',
    valueExtractor: 'id'
  });
  return paginatedOptions;
};

export const getExtensionOptions = async (params: {
  companyId: string;
  page: number;
  size: number;
  input?: string;
}) => {
  const { companyId, page, size, input } = params;
  const paginatedExtensions = await ExtensionsDomain.getExtensions({
    companyId,
    page,
    size,
    sortBy: 'extension',
    sortDir: DirectionType.ASC,
    extension: input
  });
  const paginatedOptions = getPaginatedOptions({
    paginatedOptions: paginatedExtensions,
    labelExtractor: 'extension',
    valueExtractor: 'id'
  });
  return paginatedOptions;
};

export const getServiceOptions = async (companyId: string, input?: string) => {
  const services = await DaServicesDomain.retrieveAllDaServices({
    companyId,
    sortBy: 'name',
    sortDir: DirectionType.ASC,
    name: input,
    findSubstring: true
  });

  const paginatedOptions = getPaginatedOptions({
    paginatedOptions: services,
    labelExtractor: 'name',
    valueExtractor: 'id'
  });
  return paginatedOptions;
};

export const getStatusOptions = async (input?: string) => {
  const options = getOptions({
    options: Object.values(ConversationStatus),
    labelExtractor: (status) => status as string,
    valueExtractor: (status) => status as string
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getTicketSubResolutionOptions = async (
  companyId: string,
  input?: string
) => {
  const subResolutions = await TicketsDomain.getTicketSubResolutions({
    companyId
  });
  const options = getOptions({
    options: subResolutions,
    labelExtractor: 'name',
    valueExtractor: 'id',
    descriptionExtractor: 'type'
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getTicketTagOptions = async (
  companyId: string,
  input?: string
) => {
  const ticketTags = await TicketsDomain.getTicketTags({
    companyId
  });
  const options = getOptions({
    options: ticketTags,
    labelExtractor: 'name',
    valueExtractor: 'id'
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getUserStatusReasonOptions = (input?: string) => {
  const options = getOptions({
    options: useStatusReasonOptions,
    labelExtractor: (status) => status,
    valueExtractor: (status) => status
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option?.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getUserStatusTypeOptions = (input?: string) => {
  const options = getOptions({
    options: userStatusTypeOptions,
    labelExtractor: (type) => type,
    valueExtractor: (type) => type
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};

export const getUserStatusNameOptions = async (
  companyId: string,
  input?: string
) => {
  const statusNames = await UsersDomain.getCompanyUserStatusById(companyId);
  const options = getOptions({
    options: statusNames,
    labelExtractor: 'name',
    valueExtractor: 'name',
    descriptionExtractor: 'type'
  });
  const filteredOptions = options.filter((option) =>
    isSubstring(option.label, input)
  );
  const sortedOptions = filteredOptions.sort((a, b) =>
    compareString(a.label, b.label)
  );
  const paginatedOptions = getPaginatedElements(sortedOptions);
  return paginatedOptions;
};
