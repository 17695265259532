import { useContext } from 'react';
import {
  FinishReason,
  VideocallPhase,
  VideocallRoomContext
} from './context/VideocallRoomContext';
import { CircularProgress, Stack, useTheme } from '@mui/material';
import { Videocam } from '@mui/icons-material';
import CopyButton from './components/CopyButton';
import PreVideoCallRoom from './components/PhasePreroom';
import InCallVideoRoom from './components/PhaseIncall/InCallVideoRoom';
import { VideocallFinished } from './components/PhaseFinished';
import { t } from 'i18next';
import { useCustomEventListener } from 'react-custom-events';
import { RTCSession } from 'jssip/lib/RTCSession';
import VideoCallPanelHeader from './components/VideoCallPanelHeader';
import useUpdate from 'src/hooks/useUpdateEffect';
import useCameraPermission from './hooks/useCameraPermission';
import VideoWebRTCDevices from './utils/videocalls-webrtc-devices';

interface VideocallViewProps {
  isAgent: boolean;
}
export function VideoCallsView({ isAgent }: VideocallViewProps) {
  const videoCallsContext = useContext(VideocallRoomContext);

  const { videocallPhase, finishedReason } = videoCallsContext.phase;
  const { videoRoom, streams } = videoCallsContext;

  const inPreroom = videocallPhase === VideocallPhase.PREROOM;
  const inCall = videocallPhase === VideocallPhase.INCALL;
  const inFinishedView = videocallPhase === VideocallPhase.FINISHED;
  const hasError = videoRoom.error;
  const isConnectionError = videoRoom.connectionError;

  useCustomEventListener('webrtc:on-call-started', (session) => {
    videoCallsContext.rtcSession.setCurrentRTCSession(session as RTCSession);
  });

  const hasPermissionToUseCamera = useCameraPermission();

  async function handlePermissions() {
    const localStream = await VideoWebRTCDevices.requestLocalStream({
      enableVideo: true
    });
    if (!localStream) return;

    streams.local.getTracks().forEach((track) => {
      streams.local.removeTrack(track);
    });
    localStream.getTracks().forEach((track) => {
      streams.local.addTrack(track);
    });

    streams.setLocalStream(localStream.clone());

    VideoWebRTCDevices.replaceStreamOnRTCSession({
      stream: videoCallsContext.streams.local,
      rtcSession: videoCallsContext.rtcSession.currentRTCSession,
      kind: 'video'
    });
  }

  useUpdate(() => {
    if (hasPermissionToUseCamera) {
      handlePermissions();
    }
  }, [hasPermissionToUseCamera]);

  if (!videoRoom.room && !videoRoom.error) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw'
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack direction="row" width="100%" sx={{ height: '100vh' }}>
      <Stack direction="column" flex="1" sx={{ overflow: 'auto' }}>
        {isAgent && (
          <VideoCallPanelHeader
            icon={Videocam}
            title={t('Videocall')}
            button={
              inCall ? <CopyButton roomId={videoRoom.room?.id} /> : undefined
            }
          />
        )}
        {hasError && (
          <VideocallFinished
            finishedReason={
              isConnectionError
                ? FinishReason.NO_CONNECTION
                : FinishReason.ERROR
            }
          />
        )}

        {!hasError && inPreroom && <PreVideoCallRoom />}

        {!hasError && inCall && <InCallVideoRoom />}

        {!hasError && inFinishedView && (
          <VideocallFinished finishedReason={finishedReason} />
        )}
      </Stack>
    </Stack>
  );
}
