import wsManager from 'src/services/websocket/manager';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import WebRTCService from 'src/services/webrtc/webrtc.service';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { getSessionObject } from '../utils/helpers';
import { setSession } from 'src/store/slices/session/session';
import JsSIP from 'jssip';
import { useCustomEventListener } from 'react-custom-events';
import { logger } from 'src/utils/logger';
import { RenewWSMessage } from 'src/models/auth';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { getWebsocketIdDomain } from 'src/services/websocket/domain/getWebsocketIdDomain';
import { refreshRequestDomain } from 'src/services/authentication/domain/refreshRequestDomain';
import { logoutCurrentUser } from 'src/services/authentication/domain/logoutCurrentUser';
import AuthManager from 'src/services/authentication/manager';

let wsTimeoutId: NodeJS.Timeout;
let webrtcTimeoutId: NodeJS.Timeout;

const useSession = () => {
  const [webRTC, setWebRTC] = useState<JsSIP.WebSocketInterface>();
  const [wsClient, setWsClient] = useState<W3CWebSocket>();
  const dispatch = useDispatch();
  const renewTimeoutRef = useRef(null);
  const isRenewingTokenRef = useRef(false);

  const connectWebsocket = async (): Promise<W3CWebSocket> => {
    const wsClient = await wsManager.startWs();
    setWsClient(wsClient);

    return wsClient;
  };

  const connectWebRTC = (): JsSIP.WebSocketInterface => {
    const extensionData = localStorage.getItem('extensionData');
    if (!extensionData) return;
    const webRTCClient = WebRTCService.initWebRTC(getSessionObject().voipData);
    setWebRTC(webRTCClient);

    return webRTCClient;
  };

  const renewToken = () => {
    const websocketId = getWebsocketIdDomain(false);
    refreshRequestDomain(websocketId);
  };

  const handleRenewToken = (token?: string, expiration?: string) => {
    const thresholdMin = 20 * 1000;
    const thresholdMax = 40 * 1000;
    // The threshold should be smaller than tokenExpirationTime - currentTime
    // The threshold is obtained randomly to prevent multiple browser tabs from sending token renewal requests at the same time
    const threshold =
      Math.floor(Math.random() * (thresholdMax - thresholdMin + 1)) +
      thresholdMin;
    // The token comes from token param or from cookies
    const authToken = token ?? AuthManager.getToken();
    // The expiration date comes from expiration param or from cookies
    const tokenExpirationTime = expiration
      ? new Date(expiration).getTime()
      : Number(AuthManager.getExpiration());
    const currentTime = new Date().getTime();
    const isTokenExpired =
      !tokenExpirationTime || !authToken || currentTime > tokenExpirationTime;

    if (!isRenewingTokenRef.current) {
      clearTimeout(renewTimeoutRef.current);

      if (isTokenExpired) {
        logoutCurrentUser();
      } else {
        isRenewingTokenRef.current = true;
        const timeout = tokenExpirationTime - currentTime - threshold;
        renewTimeoutRef.current = setTimeout(() => {
          const currentTime = new Date().getTime();
          // If the current time is smaller than the difference between the token expiration time and the start threshold,
          // the token is not renewed. So, another tab has already renewed the token
          if (currentTime >= tokenExpirationTime - thresholdMax) {
            renewToken();
          }
          isRenewingTokenRef.current = false;
        }, timeout);
      }
    }
  };

  useCustomEventListener(
    'realtime-connection-changed',
    (status: boolean) => {
      logger.log('Connection changed! Status: ', status);

      if (!status && !wsTimeoutId) {
        wsTimeoutId = setTimeout(() => {
          connectWebsocket();
          wsTimeoutId = null;
        }, 5000);
      }
    },
    []
  );

  useCustomEventListener(
    'webrtc-connection-changed',
    (status: boolean) => {
      logger.log('WEBRTC Connection changed! Status: ', status);

      if (!status && !webrtcTimeoutId) {
        webrtcTimeoutId = setTimeout(() => {
          connectWebRTC();
          webrtcTimeoutId = null;
        }, 5000);
      }
    },
    []
  );

  // RENEW THE TOKEN WHEN RENEW EVENT
  useCustomEventListener(CustomEventNames.RENEW, (data: RenewWSMessage) => {
    handleRenewToken(data?.token, data?.expiration);
  });

  // RENEW THE TOKEN WHEN THE COMPONENT IS MOUNTED. AUTH DATA SHOULD COME FROM COOKIES.
  // The login function should set the auth cookies.
  // The compoonent must be mounted after cookies have been set, i.e., the user is already logged successfully.
  useEffect(() => {
    handleRenewToken();
    return () => {
      clearTimeout(renewTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    // Connecting Websocket and WebRTC for the first time
    function connect() {
      connectWebsocket();
      connectWebRTC();
    }
    connect();
  }, []);

  useEffect(() => {
    dispatch(setSession(getSessionObject()));
  }, [wsClient, webRTC]);
};

export default useSession;
