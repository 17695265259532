import axios from 'axios';
import { WHATSAPP_AUTH_INFO } from 'src/utils/apiRoutes/msWhatsapp';

export interface WhatsappAuthInfoApiProps {
  oauth_code: string;
  phone_id: string;
  waba_id: string;
}

export async function sendWhatsappAuthInfoApi(
  authData: WhatsappAuthInfoApiProps
) {
  const response = await axios.get(WHATSAPP_AUTH_INFO, {
    params: {
      ...authData
    }
  });

  return response.data;
}
