import axios from 'axios';
import { Contact } from 'src/modules/Contacts/models/contacts';
import { PaginatedElements } from 'src/models/pagination';
import { ContactsRequestParams } from 'src/modules/Contacts/models/contacts';
import {
  CONTACTS,
  CONTACTS_ID,
  CONTACTS_LIST
} from 'src/utils/apiRoutes/msContacts';
import { replaceURLParams } from 'src/services/utils';

export const getContactApi = async (id: string): Promise<Contact> => {
  const path = replaceURLParams(CONTACTS_ID, { '{id}': id });
  const resp = await axios.get(path);
  return resp.data;
};

export const getContactsApi = async (
  params: ContactsRequestParams
): Promise<PaginatedElements<Contact>> => {
  const resp = await axios.get(CONTACTS, {
    params,
    paramsSerializer: {
      indexes: null
    }
  });
  return resp.data;
};

export const getContactsListApi = async (
  contactIds: string[]
): Promise<Contact[]> => {
  const resp = await axios.post(CONTACTS_LIST, contactIds);
  return resp.data;
};
