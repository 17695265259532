import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextFormatType
} from 'lexical';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { $isLinkNode } from '@lexical/link';
import { alignOptions, textActions } from './utils/textActions';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  alpha,
  useTheme
} from '@mui/material';
import { TextEditorState, textEditorInitialState } from './utils/initialState';
import { useCallback, useEffect, useState } from 'react';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL
} from '@lexical/selection';
import { getSelectedNode } from './utils/getSelectedNode';
import EditorFontSize from './components/FontSize';
import { ImageOutlined } from '@mui/icons-material';
import { InsertImageButton } from './components/InsertImageButton';

interface Props {
  hideImageButton?: boolean;
}

export const EditorTopBar = ({ hideImageButton = false }: Props) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);

  const handleOnClick = (formatType: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  };

  const handleOnClickAlign = (formatType: ElementFormatType) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, formatType);
  };

  const [selectedTextState, setSelectedTextState] = useState(
    textEditorInitialState
  );

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();

      const fontSize = $getSelectionStyleValueForProperty(
        selection,
        'font-size',
        '16px'
      );

      // Prepare new state object
      let newState = {
        isBold: selection.hasFormat('bold'),
        isItalic: selection.hasFormat('italic'),
        isUnderline: selection.hasFormat('underline'),
        isStrikethrough: selection.hasFormat('strikethrough'),
        isSubscript: selection.hasFormat('subscript'),
        isSuperscript: selection.hasFormat('superscript'),
        isCode: selection.hasFormat('code'),
        isRTL: $isParentElementRTL(selection),
        isLink: false,
        rootType: 'root',
        selectedElementKey: elementKey,
        fontSize: fontSize ? fontSize : selectedTextState.fontSize,
        fontColor: $getSelectionStyleValueForProperty(
          selection,
          'color',
          '#000'
        ),
        bgColor: $getSelectionStyleValueForProperty(
          selection,
          'background-color',
          '#fff'
        ),
        fontFamily: $getSelectionStyleValueForProperty(
          selection,
          'font-family',
          'Arial'
        )
      } as TextEditorState;

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        newState.isLink = true;
      }

      let matchingParent;
      if ($isLinkNode(parent)) {
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      newState.elementFormat = $isElementNode(matchingParent)
        ? matchingParent.getFormatType()
        : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || 'left';

      newState.elementFormat = newState.elementFormat
        ? newState.elementFormat
        : 'left';

      setSelectedTextState((prevState) => ({ ...prevState, ...newState }));
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setSelectedTextState((prevState) => ({
          ...prevState,
          isEditable: editable
        }));
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      })
    );
  }, [$updateToolbar, activeEditor, editor]);

  const theme = useTheme();

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      p={0.5}
      px={2}
      columnGap={0.5}
      flexWrap={'wrap'}
      sx={{
        borderBottom: '1px solid #e0e0e0'
      }}
    >
      <EditorFontSize editor={editor} selectedTextState={selectedTextState} />
      <Divider orientation="vertical" flexItem />
      {textActions.map((action, index) => {
        return (
          <IconButton
            color={selectedTextState[action.checker] ? 'secondary' : 'default'}
            size="small"
            key={index}
            onClick={() => handleOnClick(action.formatType as TextFormatType)}
            sx={{
              backgroundColor: selectedTextState[action.checker]
                ? alpha(theme.colors.secondary.main, 0.1)
                : ''
            }}
          >
            {<action.icon />}
          </IconButton>
        );
      })}
      <Divider orientation="vertical" flexItem />
      {alignOptions.map((action, index) => {
        return (
          <IconButton
            color={
              selectedTextState.elementFormat === action.formatType
                ? 'secondary'
                : 'default'
            }
            size="small"
            key={index}
            onClick={() =>
              handleOnClickAlign(action.formatType as ElementFormatType)
            }
            sx={{
              backgroundColor:
                selectedTextState.elementFormat === action.formatType
                  ? alpha(theme.colors.secondary.main, 0.1)
                  : ''
            }}
          >
            {<action.icon />}
          </IconButton>
        );
      })}

      {!hideImageButton && (
        <>
          <Divider orientation="vertical" flexItem />
          <InsertImageButton />
        </>
      )}
    </Stack>
  );
};
