import {
  Box,
  Button,
  CardHeader,
  Dialog,
  Divider,
  Stack,
  Tab,
  Tabs,
  TextField,
  IconButton,
  styled
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { Bubble } from '../../tabs/StyleTabs/BubbleTab/Bubble';
import { ChatConverstionTab } from '../../tabs/StyleTabs/ConversationTab/ChatConversationTab';
import ContactTab from '../../tabs/StyleTabs/ContactTab/ContactTab';
import { t } from 'i18next';
import { UploadedImage, WebChatStyle } from '../../../models/webChatStyle';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { useAlert } from 'src/hooks/useAlert';
import { createWebChatStyle } from '../../../../../../../../../services/webchat/infraestructure/webChatStyle/postWebChatStyle';
import { useWebChatStyle } from '../../utils/useWebChatStyle';
import { Formik, FormikProps } from 'formik';
import { updateWebChatStyle } from '../../../../../../../../../services/webchat/infraestructure/webChatStyle/putWebChatStyle';
import { emitCustomEvent } from 'react-custom-events';
import { WebChatAccount } from '../../../models/webChatAccount';
import { downloadAttachment } from '../../../../../../../../../services/webchat/infraestructure/webChatAttachment/downloadAttachment';
import AuthManager from 'src/services/authentication/manager';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { CloseTwoTone } from '@mui/icons-material';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkCreateWebChatStylesPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  editWCS?: WebChatStyle;
  formikProps?: FormikProps<WebChatAccount>;
  setSelected?: Dispatch<SetStateAction<WebChatStyle>>;
}

const tabs = ['Bubble', 'Conversation', 'Contact'];

export const CreateStyle: React.FC<Props> = ({
  show,
  setShow,
  editWCS,
  formikProps,
  setSelected
}) => {
  const { defaultWebChatStyle, schemaValidations } = useWebChatStyle();

  const [newBubbles, setNewBubbles] = useState<File[]>([]);
  const [updatedBubble, setUpdatedBubble] = useState<boolean>(false);

  const [logoImage, setLogoImage] = useState<File>(null);
  const [updatedLogo, setUpdatedLogo] = useState<boolean>(false);

  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);

  const trans = t;
  const { hasAccess } = useContext(PermissionsContext);

  const companyId = AuthManager.getLoggedUserCompanyId();

  const formMode = editWCS ? 'Edit' : 'Create';

  const [tabValue, setTabValue] = useState('Bubble');
  const [webChatStyle, setwebChatStyle] =
    useState<WebChatStyle>(defaultWebChatStyle);

  const handleTabsChange = (e, value) => {
    setTabValue(value);
  };

  const { showAlert } = useAlert();

  const setSavedLogoImage = async () => {
    const logoId = editWCS.contactStyle.logo;
    try {
      const logoFile = await downloadAttachment(logoId, 'logo');
      setLogoImage(logoFile);
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
  };

  const setUploadedBubbleImages = async () => {
    const uploadedImages = editWCS.bubbleStyle.uploadedImages;

    try {
      const newFiles: UploadedImage[] = [];
      for (const img of uploadedImages) {
        const id = img.attachmentId;
        const bubbleImageFile = await downloadAttachment(id, 'bubbleImage');
        newFiles.push({
          image: bubbleImageFile,
          attachmentId: id,
          mimeType: img.mimeType
        });
      }
      setUploadedImages(newFiles);
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
  };

  useEffect(() => {
    if (show && editWCS !== null && editWCS !== undefined) {
      if (editWCS.contactStyle.logo != '') {
        setSavedLogoImage();
      }
      if (editWCS.bubbleStyle.uploadedImages.length > 0) {
        setUploadedBubbleImages();
      }
    }
  }, [show]);

  const handleSaveClick = async (webChatStyle: WebChatStyle) => {
    try {
      if (formMode === 'Create') {
        const webChatStyleToPost: WebChatStyle = {
          ...webChatStyle,
          companyId
        };
        const wcs = await createWebChatStyle(
          webChatStyleToPost,
          logoImage,
          newBubbles
        );
        if (setSelected) {
          setSelected(wcs);
          formikProps.setFieldValue('styleId', wcs.id);
        }
        showAlert(t('WebChat style created succesfully'), 'success', 3000);
      } else {
        if (updatedBubble && updatedLogo) {
          await updateWebChatStyle(webChatStyle, logoImage, newBubbles);
        } else if (updatedLogo) {
          await updateWebChatStyle(webChatStyle, logoImage);
        } else if (updatedBubble) {
          await updateWebChatStyle(webChatStyle, undefined, newBubbles);
        } else {
          await updateWebChatStyle(webChatStyle);
        }
        showAlert(t('WebChat style edited succesfully'), 'success', 3000);
      }
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }

    setTabValue('Bubble');
    emitCustomEvent(REFETCH_DATA_EVENT_NAME);
    restartData();
  };

  const restartData = () => {
    setTabValue('Bubble');
    setShow(false);
    setLogoImage(null);
    setUploadedImages([]);
    setUpdatedBubble(false);
    setUpdatedLogo(false);
    setNewBubbles([]);
    emitCustomEvent(REFETCH_DATA_EVENT_NAME);
  };

  return (
    <Dialog
      open={show}
      fullWidth={true}
      maxWidth={'lg'}
      sx={{ marginTop: '0px' }}
    >
      <Stack direction="column" width="100%">
        <CardHeader
          title={
            formMode === 'Create'
              ? t('New webchat style')
              : t('Edit webchat style')
          }
          subheader={
            formMode === 'Create'
              ? t('Use this dialog window to create new webchat style')
              : t('Use this dialog window to edit a webchat style')
          }
          action={
            <IconButton
              sx={{
                m: 1
              }}
              size="small"
              color="secondary"
              onClick={restartData}
            >
              <CloseTwoTone />
            </IconButton>
          }
        />
        <Divider />

        <Formik
          initialValues={editWCS ?? webChatStyle}
          onSubmit={(webChatStyle: WebChatStyle) => {
            handleSaveClick(webChatStyle);
          }}
          validationSchema={schemaValidations}
        >
          {(formikProps): JSX.Element => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ padding: 0 }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            >
              <Box width="100%" height="calc(100vh - 151px)">
                <Stack p={2} direction={'row'} spacing={2}>
                  <TextField
                    label={t('Style name')}
                    id="name-CreateStyle"
                    name="name"
                    value={formikProps.values.name}
                    onChange={(e) =>
                      formikProps.setFieldValue('name', e.target.value)
                    }
                    required
                  />
                  <TextField
                    label={t('Style description')}
                    fullWidth
                    id="description-CreateStyle"
                    name="description"
                    value={formikProps.values.description}
                    onChange={(e) =>
                      formikProps.setFieldValue('description', e.target.value)
                    }
                  />
                </Stack>
                <Stack
                  py={1.5}
                  px={2}
                  sx={{ background: '#F6F8FB' }}
                  borderTop={1}
                  borderBottom={1}
                  borderColor={'lightgray'}
                >
                  <Tabs
                    onChange={handleTabsChange}
                    value={tabValue}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    {tabs.map((t, index) => {
                      return <Tab key={index} label={trans(t)} value={t}></Tab>;
                    })}
                  </Tabs>
                </Stack>
                <Stack>
                  {tabValue === 'Bubble' && (
                    <Bubble
                      formikProps={formikProps}
                      setUpdatedBubble={setUpdatedBubble}
                      uploadedImages={uploadedImages}
                      setUploadedImages={setUploadedImages}
                      newBubbles={newBubbles}
                      setNewBubbles={setNewBubbles}
                    />
                  )}
                  {tabValue === 'Conversation' && (
                    <ChatConverstionTab formikProps={formikProps} />
                  )}
                  {tabValue === 'Contact' && (
                    <ContactTab
                      formikProps={formikProps}
                      logoImage={logoImage}
                      setLogoImage={setLogoImage}
                      setUpdatedLogo={setUpdatedLogo}
                    />
                  )}
                </Stack>
                <Divider />
                <Stack
                  px={1.5}
                  py={1.5}
                  width="100%"
                  direction={{ xs: 'column-reverse', sm: 'row' }}
                  justifyContent={'space-between'}
                  spacing={1.5}
                >
                  <Button
                    variant="outlined"
                    onClick={restartData}
                    color="secondary"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!hasAccess(checkCreateWebChatStylesPermissions)}
                  >
                    {t(formMode)}
                  </Button>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Stack>
    </Dialog>
  );
};
