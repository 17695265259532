import { useEffect, useState } from 'react';
import { Contact, ContactTag } from 'src/modules/Contacts/models/contacts';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import ContactDataView from './ContactDataView';
import { Conversation } from 'src/models/conversations/conversations';
import { useGetContactFields } from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import ContactDataListView from './ContactDataListView';
import AddContactDataView from './CreateContactDataView/AddContactDataView';
import UpdateContactDataView from './CreateContactDataView/UpdateContactDataView';
import HelperPanelHeader from '../components/HelperPanelHeader';
import ContactActionsHeader from './ContactActionsHeader';
import NoteListView from '../NotesPanel/NoteListView';
import Scrollbar from 'src/components/Scrollbar';
import { ConversationList } from 'src/components/ConversationHistory/components/ConversationList';
import { getCompanyContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import { HelperTab } from '../../HelperMenu/items';
import { getDaServiceById } from 'src/services/daServices/utils';
import { assignContactToConversationApi } from 'src/services/coordination/infrastructure/assignContactToConversationApi';
import { getConversationByIdFromApi } from 'src/services/socialmedia/domain/conversation/getConversation';
import { setConversationStore } from 'src/services/socialmedia/infraestructure/store/setConversationStore';
import { t } from 'i18next';
import ContactInfoTicketsPanel from '../TicketsPanel/ContactInfoTicketsPanel';
import { useServiceByIdQuery } from 'src/services/users/application/query/getUsersDataQueries';

interface ContactDataPanelProps {
  conversation?: Conversation;
  showConversationHistory?: boolean;
  showHelperTabButtons?: boolean;
}

const ContactDataPanel = (props: ContactDataPanelProps) => {
  const { conversation, showConversationHistory, showHelperTabButtons } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [webChatFieldsContact, setWebChatFieldsContact] =
    useState<Contact | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined
  );

  const theme = useTheme();

  // GET ALL TAGS OPTIONS
  const [tags, setTags] = useState<ContactTag[]>([]);
  useEffect(() => {
    let isMounted = true;
    getCompanyContactTagsQuery({
      companyId: conversation.companyId
    }).then((response) => {
      if (isMounted) {
        setTags(response?.elements);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!selectedContact) {
      setEditingIndex(undefined);
    }
  }, [selectedContact]);

  const companyFieldsQuery = useGetContactFields(
    { companyId: conversation.companyId },
    !!conversation
  );

  const serviceQuery = useServiceByIdQuery(conversation?.serviceId);
  const conversationService = serviceQuery?.data;
  const contactGroupId = conversationService?.contactGroupId;

  const isLoading = companyFieldsQuery.isLoading;

  const handleAssignContact = async (contact: Contact) => {
    if (conversation?.id && editingIndex !== undefined) {
      setEditingIndex(undefined);
      setSelectedContact(null);
      const service = await getDaServiceById(conversation.serviceId);
      if (!service) return;

      await assignContactToConversationApi({
        conversationId: conversation.id,
        contactId: contact.id,
        pos: editingIndex
      });

      const newConversation = await getConversationByIdFromApi(conversation.id);
      if (!newConversation) return;
      newConversation.messages = conversation?.messages;
      newConversation.totalMessages = conversation?.totalMessages;

      setIsEditing(false);
      setIsAdding(false);
      setConversationStore(newConversation);
    }
  };

  if (isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />;
      </Box>
    );

  return (
    <>
      {!isEditing && !isAdding && (
        <>
          <HelperPanelHeader
            helperTab={HelperTab.CONTACT_DATA}
            showHelperTabButtons={showHelperTabButtons}
          />
          {conversation.involvedContacts.length > 0 && (
            <>
              <Box
                px={2}
                display="flex"
                alignItems="center"
                justifyContent="start"
                sx={{ mt: 2 }}
                height="30px"
              >
                <Typography variant="h5" color={'black'} noWrap>
                  {`${conversation.involvedContacts.length} ${
                    conversation.involvedContacts.length === 1
                      ? t('Participant')
                      : t('Participants')
                  }`}
                </Typography>
              </Box>
              <Divider sx={{ mx: 2 }} />
            </>
          )}
        </>
      )}

      {!selectedContact && (
        <Box
          height={
            showConversationHistory
              ? '100%'
              : `calc(100% - ${theme.header.height})`
          }
        >
          <ContactDataListView
            setEditingIndex={setEditingIndex}
            key={conversation.id}
            companyFields={companyFieldsQuery?.data?.elements}
            conversation={conversation}
            setSelectedContact={setSelectedContact}
            setIsAdding={setIsAdding}
            setIsEditing={setIsEditing}
            setWebChatFieldsContact={setWebChatFieldsContact}
          />
        </Box>
      )}
      {selectedContact && (
        <Grid
          container
          columns={{
            sm: 1,
            md: showConversationHistory ? 3.5 : 2,
            lg: showConversationHistory ? 7 : 2
          }}
          padding={1}
          height={'100%'}
        >
          <Grid item height="100%" xs={2}>
            {showConversationHistory && (
              <HelperPanelHeader helperTab={HelperTab.CONTACT_DATA} />
            )}
            <Box
              height={
                isEditing || isAdding
                  ? '100%'
                  : !showConversationHistory
                    ? `calc(100% - ${theme.header.height} - 40px)`
                    : `calc(100% - ${theme.header.height})`
              }
            >
              {!isEditing && !isAdding && (
                <>
                  <ContactActionsHeader
                    onClickBackButton={() => {
                      setIsEditing(false);
                      setSelectedContact(null);
                    }}
                    onClickEditButton={() => {
                      setIsEditing(true);
                    }}
                  />
                  <Box height={`calc(100% - 40px)`}>
                    <ContactDataView
                      key={conversation.id}
                      contact={selectedContact}
                    />
                  </Box>
                </>
              )}
              {isEditing && (
                <UpdateContactDataView
                  key={conversation.id}
                  contact={selectedContact}
                  tags={tags}
                  companyFields={companyFieldsQuery?.data?.elements}
                  setContact={setSelectedContact}
                  setIsOpen={setIsEditing}
                  onSave={handleAssignContact}
                  saveButtonLabel={
                    editingIndex !== undefined && t('Save and Assign')
                  }
                />
              )}
              {isAdding && (
                <AddContactDataView
                  key={conversation.id}
                  contact={webChatFieldsContact ?? selectedContact}
                  tags={tags}
                  companyFields={companyFieldsQuery?.data?.elements}
                  setContact={setSelectedContact}
                  setIsOpen={setIsAdding}
                  defaultContactGroupId={contactGroupId}
                  onSave={handleAssignContact}
                  saveButtonLabel={
                    editingIndex !== undefined ? t('Save and Assign') : null
                  }
                />
              )}
            </Box>
          </Grid>
          {showConversationHistory && selectedContact && (
            <>
              <Grid item xs={1.5} height="100%">
                <HelperPanelHeader helperTab={HelperTab.CONVERSATION_HISTORY} />
                <Box height={`calc(100% - ${theme.header.height})`}>
                  <Scrollbar>
                    <ConversationList
                      itemsPerPage={5}
                      selectedContact={selectedContact}
                    />
                  </Scrollbar>
                </Box>
              </Grid>
              <Grid item xs={1} height="100%">
                <HelperPanelHeader helperTab={HelperTab.NOTES} />
                <Box height={`calc(100% - ${theme.header.height})`}>
                  <NoteListView
                    key={selectedContact?.id + '-notes'}
                    contact={selectedContact}
                    filterNote="contactsNotes"
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5} height="100%">
                <Box height={`100%`}>
                  <Scrollbar>
                    <ContactInfoTicketsPanel conversation={conversation} />
                  </Scrollbar>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default ContactDataPanel;
