import { useState } from 'react';
import { t } from 'i18next';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';

import { DialTableStatusLabel } from 'src/components/DialTable/components/DialTableStatusLabel';
import {
  KnowledgeDocumentation,
  KnowledgeDocumentationStatus
} from '../../InboundRoutes/infrastructure/api/knowledgeModel/getKnowledgeDocumentationApi';
import { getKnowledgeDocumentationQuery } from '../../InboundRoutes/domain/knowledgeDocumentation/getKnowledgeModels';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

export function useKnowledgeModelsTable() {
  const [itemsToDelete, setItemsToDelete] = useState<KnowledgeDocumentation[]>(
    []
  );

  const getColumnsFn = async (): Promise<
    ColumnDef<KnowledgeDocumentation>[]
  > => {
    return [
      {
        accessorKey: 'name',
        header: t('Name'),
        id: 'name'
      },
      {
        accessorKey: 'webpage',
        header: t('Webpage'),
        id: 'webpage',
        cell: ({ row }) => {
          const webpage = row.original.webpage;
          return (
            <Link to={webpage} target="_blank">
              {webpage}
            </Link>
          );
        }
      },
      {
        accessorKey: 'contentLabel',
        header: t('Content label'),
        id: 'contentLabel',
        cell: ({ row }) => {
          const contentLabel = row.original.contentLabel;
          return (
            <Typography
              maxWidth={'20vw'}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {contentLabel}
            </Typography>
          );
        }
      },
      {
        accessorKey: 'removeLabels',
        header: t('Removed labels'),
        id: 'removeLabels',
        cell: ({ row }) => {
          const removeLabels = row.original.removeLabels;
          return (
            <Tooltip
              title={
                <Stack direction="column" rowGap={0.5}>
                  {removeLabels.map((label, idx) => (
                    <Typography key={idx}>{label}</Typography>
                  ))}
                </Stack>
              }
              arrow
              placement="bottom"
            >
              <Typography
                maxWidth={'20vw'}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {removeLabels.join(', ')}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        accessorKey: 'status',
        header: t('Status'),
        id: 'status',
        cell: ({ row }) => {
          const status = row.original.status;
          const color = {
            [KnowledgeDocumentationStatus.CREATED]: 'success',
            [KnowledgeDocumentationStatus.PENDING]: 'error'
          };
          return (
            <DialTableStatusLabel color={color[status]}>
              {status === KnowledgeDocumentationStatus.CREATED
                ? t('CREATED_SINGULAR')
                : t(status)}
            </DialTableStatusLabel>
          );
        }
      }
    ];
  };

  const getDataFn = async () => {
    const data = await getKnowledgeDocumentationQuery({
      companyId: getLoggedUserCompanyId()
    });
    const paginated = getPaginatedElements(data);
    return paginated;
  };

  const getRowActionsFn = (row) => {
    const model = row.rowData;
    return [
      <IconButton
        size="small"
        color="error"
        key={model.id}
        onClick={() => {
          setItemsToDelete([model]);
        }}
      >
        <Delete />
      </IconButton>
    ];
  };

  return {
    getColumnsFn,
    getDataFn,
    getRowActionsFn,
    itemsToDelete,
    setItemsToDelete
  };
}
