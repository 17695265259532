import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import AdminHeader from 'src/components/AdminHeader';
import { CreateKnowledgeModel } from './components/CreateUpdateKnowledgeModel';
import { useState } from 'react';
import { DialTable } from 'src/components/DialTable';
import { KnowledgeDocumentation } from '../InboundRoutes/infrastructure/api/knowledgeModel/getKnowledgeDocumentationApi';
import { Stack } from '@mui/material';
import { deleteKnowledgeDocumentationItems } from '../InboundRoutes/domain/knowledgeDocumentation/deleteKnowledgeModel';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { useKnowledgeModelsTable } from './hooks/useKnowledgeModelsTable';
import { useAlert } from 'src/hooks/useAlert';

export function KnowledgeModels() {
  const {
    getColumnsFn,
    getDataFn,
    getRowActionsFn,
    itemsToDelete,
    setItemsToDelete
  } = useKnowledgeModelsTable();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { showAlert } = useAlert();

  return (
    <>
      <Helmet>
        <title>{t('Knowledge models')}</title>
      </Helmet>
      {/* HEADER */}
      <AdminHeader
        heading={t('Knowledge models')}
        subheading={t(
          'Here you can find the models created to be used in inboundn routes'
        )}
        buttonName="Create knowledge model"
        onClick={() => setIsFormOpen(true)}
        permissionCheckerFn={() => true}
      />
      <CreateKnowledgeModel
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      />
      <ConfirmationModal
        isOpen={itemsToDelete.length > 0}
        data={itemsToDelete}
        dataKey="name"
        mode={ConfirmationModalModes.DELETE}
        onConfirm={async (items) => {
          try {
            await deleteKnowledgeDocumentationItems(
              items.map((item) => item.id)
            );
            showAlert(t('Knowledge models deleted successfully'), 'success');
          } catch (error) {
            showAlert(t('Error deleting knowledge model'), 'error');
          }
          setItemsToDelete([]);
        }}
      />
      <Stack px={4.5} py={2}>
        <DialTable<KnowledgeDocumentation>
          queryKey={['knowledgeDocumentation']}
          getColumnsFn={getColumnsFn}
          getDataFn={getDataFn}
          getRowActionsFn={getRowActionsFn}
        />
      </Stack>
    </>
  );
}
