import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { UserProvider } from '../views/UsersView/context/userContext';
import Restricted from 'src/components/Restricted';
import {
  checkReadUserPermissions,
  checkReadUserRolePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// example import component
// const Routes = Loader(lazy(() => import('../views/RoutesView')));
const RolesPermissions = Loader(
  lazy(() => import('../views/RolesPermissionsView'))
);

const ApiKeys = Loader(lazy(() => import('../modules/ApiKeys/views')));
const Users = Loader(lazy(() => import('../views/UsersView')));

const managementRoutes: RouteObject[] = [
  {
    path: 'roles-and-permissions',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadUserRolePermissions}>
        <RolesPermissions />
      </Restricted>
    )
  },
  {
    path: 'view-users',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadUserPermissions}>
        <UserProvider>
          <Users />
        </UserProvider>
      </Restricted>
    )
  },
  {
    path: 'api-keys',
    element: <ApiKeys />
  }
];

export default managementRoutes;
