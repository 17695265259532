import { Grid } from '@mui/material';
import { CardVideoStream } from './components/CardVideoStream';

export const VideoStream = () => {
  return (
    <Grid
      sx={{ px: 4, py: 4 }}
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <CardVideoStream />
      </Grid>
    </Grid>
  );
};
