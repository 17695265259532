import { CircularProgress, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { AudioOutlinedSelect } from 'src/modules/VideoCall/components/ActionsBar/components/AudioOutlinedSelect';
import { CameraOutlinedSelect } from 'src/modules/VideoCall/components/ActionsBar/components/CameraOutlinedSelect';
import { VoiceOutlinedSelect } from 'src/modules/VideoCall/components/ActionsBar/components/VoiceOutilnedSelect';
import { PreroomMobileView } from './components/PreroomMobileView';
import { t } from 'i18next';
import { VideocallRoomContext } from 'src/modules/VideoCall/context/VideocallRoomContext';
import VideoWebRTCDevices from 'src/modules/VideoCall/utils/videocalls-webrtc-devices';
import { WarningAmberOutlined } from '@mui/icons-material';
import useCameraPermission from 'src/modules/VideoCall/hooks/useCameraPermission';
import stopStream from 'src/modules/VideoCall/utils/stopStream';

interface Props {
  isMobile?: boolean;
  direction: 'row' | 'column';
}

export function PrepareDevices({ isMobile, direction }: Props) {
  const permissionToUseCamera = useCameraPermission();
  const { streams, devices } = useContext(VideocallRoomContext);
  const localVideo = streams.local;

  const videoRef = useRef<HTMLVideoElement>(null);
  const { facingMode, setFacingMode } = devices;
  const [settings, setSettings] = useState<MediaTrackSettings | null>(null);

  if (localVideo && videoRef.current) {
    videoRef.current.srcObject = localVideo;
  }

  async function handleRequest() {
    const stream = await VideoWebRTCDevices.requestLocalStream({
      enableVideo: true,
      constraints: {
        audio: true,
        video: {
          facingMode: { ideal: facingMode },
          aspectRatio: !isMobile ? 16 / 9 : undefined
        }
      }
    });
    if (!stream) return;

    // Delete previous stream to relase the camera, otherwise the camera will be active until the page is closed
    stopStream(streams.local);

    streams.setLocalStream(stream.clone());
    videoRef.current.srcObject = stream;

    const newSettings = stream?.getVideoTracks?.()[0]?.getSettings?.();
    setSettings(newSettings);
  }

  useEffect(() => {
    handleRequest();
  }, [facingMode]);

  useEffect(() => {
    if (permissionToUseCamera) {
      handleRequest();
    }
  }, [permissionToUseCamera]);

  if (isMobile) {
    return (
      <PreroomMobileView
        settings={settings}
        toggleCamera={() => {
          setFacingMode(facingMode === 'user' ? 'environment' : 'user');
        }}
        stream={localVideo}
        videoRef={videoRef}
      />
    );
  }

  return (
    <Stack
      width={direction === 'row' ? '50%' : '100%'}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      paddingY={2}
    >
      <div
        style={{
          width: 'min(80%, 800px)',
          aspectRatio: '16 / 9',
          borderRadius: '10px',
          border: '1px solid #E0E0E0',
          background: '#F3F3F3',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {!permissionToUseCamera && (
          <Stack alignItems={'center'} rowGap={1}>
            <WarningAmberOutlined />
            <Typography variant="h4">
              {t('Seems like you have blocked the camera access')}
            </Typography>
            <Typography variant="body2">
              {t('Please enable the camera access to continue')}
            </Typography>
          </Stack>
        )}
        {permissionToUseCamera && !localVideo && (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}
        {
          <video
            style={{
              objectFit: 'cover',
              height: '100%',
              display: permissionToUseCamera ? 'block' : 'none'
            }}
            ref={videoRef}
            autoPlay
            muted
          />
        }
      </div>

      {localVideo && (
        <Stack direction="row" paddingTop={2} spacing={1}>
          <CameraOutlinedSelect showIcon={true} />

          <AudioOutlinedSelect showIcon={true} width={'100px'} />

          <VoiceOutlinedSelect showIcon={true} width={'100px'} />
        </Stack>
      )}
    </Stack>
  );
}

export default PrepareDevices;
