import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  Contact,
  ContactsRequestParams
} from 'src/modules/Contacts/models/contacts';
import { Conversation } from 'src/models/conversations/conversations';
import {
  getContact,
  getContactByFieldValue,
  getContactsFromConversation
} from 'src/modules/Contacts/domain/getContacts';
import { queryClient } from 'src/utils/queryClient';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';

export const useGetContactsByFieldQuery = (
  params: ContactsRequestParams
): UseQueryResult<Contact> => {
  return useQuery<Contact, Error>(
    ['contactsByField', params],
    () => getContactByFieldValue(params),
    { enabled: true }
  );
};

export const getContactsByFieldQuery = (
  params: ContactsRequestParams
): Promise<Contact> => {
  return queryClient.fetchQuery(['contactsByField', params], {
    queryFn: () => getContactByFieldValue(params),
    staleTime: 60000
  });
};

export const getContactQueryNoHook = async (
  params: ContactsRequestParams
): Promise<Contact> => {
  const contact = await queryClient.fetchQuery(['contact', params.id], {
    queryFn: () => getContact(params),
    staleTime: 60000
  });
  return contact;
};

export const getContactNameQueryNoHook = async (
  params: ContactsRequestParams
): Promise<string> => {
  const contact = await getContactQueryNoHook(params);
  if (!contact) return null;

  const display_name = await getContactName(contact);

  return display_name;
};

export const getContactNameQuery = (
  params: ContactsRequestParams
): UseQueryResult<string> => {
  return useQuery<string, Error>(
    ['contactName', params.id],
    () => getContactNameQueryNoHook(params),
    { enabled: true, staleTime: 0 }
  );
};

export const getContactQuery = (
  params: ContactsRequestParams
): UseQueryResult<Contact> => {
  return useQuery<Contact, Error>(
    ['contact', params.id],
    () => getContactQueryNoHook(params),
    { enabled: true, staleTime: 0 }
  );
};

export const getContactsFromConversationQuery = (
  conversation: Conversation,
  waitFor: any
): UseQueryResult<Contact[]> => {
  return useQuery<Contact[], Error>(
    ['contactsConversation', conversation.id],
    () => getContactsFromConversation(conversation),
    { enabled: !!waitFor, staleTime: 0 }
  );
};
