import { useContext } from 'react';
import * as Yup from 'yup';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { useAlert } from 'src/hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { WhatsAppAccountContext } from '../context/whatsAppAccountContext';
import { addWhatsAppAccount } from 'src/services/whatsapp/domain/account/addWhatsAppAccount';
import { updateEnableWhatsAppAccount } from 'src/services/whatsapp/domain/account/updateWhatsAppAccount';
import {
  WhatspAppAccountForm,
  WhatspAppAccount,
  WhatspAppAccountTypeForm
} from 'src/services/whatsapp/models/WhatsAppAccount';
import { WhatsAppTemplateStatus } from 'src/services/whatsapp/models/WhatsAppTemplate';
import { useTheme } from '@mui/material';

interface Params {
  submitWhatsAppAccount?: (values: WhatspAppAccount) => Promise<void>;
}

export const useWhatsAppAccount = (params: Params = {}) => {
  const { submitWhatsAppAccount: submitWhatsAppAccountParams } = params;
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { colors } = useTheme();
  const { setOpenWhatsAppAccountModal, whatsAppAccount, typeForm } = useContext(
    WhatsAppAccountContext
  );
  const getInitialValues = (typeForm: WhatspAppAccountTypeForm) => {
    const validType: WhatspAppAccount = {
      id: typeForm === WhatspAppAccountForm.CREATE ? '' : whatsAppAccount.id,
      companyId:
        typeForm === WhatspAppAccountForm.CREATE
          ? ''
          : whatsAppAccount.companyId,
      description:
        typeForm === WhatspAppAccountForm.CREATE
          ? ''
          : whatsAppAccount.description,
      enabled:
        typeForm === WhatspAppAccountForm.CREATE
          ? true
          : whatsAppAccount.enabled,
      name:
        typeForm === WhatspAppAccountForm.CREATE ? '' : whatsAppAccount.name,
      phoneNumber:
        typeForm === WhatspAppAccountForm.CREATE
          ? ''
          : whatsAppAccount.phoneNumber
    };
    return validType;
  };

  const getSchemaValidation = (typeForm: WhatspAppAccountTypeForm) => {
    const schemaValidation = Yup.object({
      name: Yup.string().required('The name is required'),
      description: Yup.string().required('The description is required'),
      provider:
        typeForm === WhatspAppAccountForm.CREATE
          ? Yup.string().required('The provider is required')
          : Yup.string().optional(),
      phoneNumber:
        typeForm === WhatspAppAccountForm.CREATE
          ? Yup.string().required('The phone number is required')
          : Yup.string().optional(),
      app:
        typeForm === WhatspAppAccountForm.CREATE
          ? Yup.string().required('The App number is required')
          : Yup.string().optional(),
      key:
        typeForm === WhatspAppAccountForm.CREATE
          ? Yup.string().required('The API key is required')
          : Yup.string().optional()
    });
    return schemaValidation;
  };

  const getStatusCard = (status: WhatsAppTemplateStatus) => {
    let icon: JSX.Element = null;
    let backgroundIcon: string = '';
    let title: string = '';
    let titleColor: string = '';
    let backgroundChip: string = '';
    let colorChip: string = '';
    let titleChip: string = '';
    switch (status) {
      case WhatsAppTemplateStatus.APPROVED:
        icon = (
          <GppGoodOutlinedIcon
            sx={{
              color: colors.success.main,
              background: colors.success.lighter,
              borderRadius: '50%'
            }}
          />
        );
        backgroundIcon = colors.success.main;
        title = 'Approved';
        titleColor = colors.success.main;
        backgroundChip = colors.success.lighter;
        colorChip = colors.success.main;
        titleChip = 'approved';
        break;
      case WhatsAppTemplateStatus.REJECTED:
        icon = (
          <ErrorOutlineOutlinedIcon
            sx={{
              color: colors.error.main,
              background: colors.error.lighter,
              borderRadius: '50%'
            }}
          />
        );
        backgroundIcon = colors.error.main;
        title = 'Rejected';
        titleColor = colors.error.main;
        backgroundChip = colors.error.lighter;
        colorChip = colors.error.main;
        titleChip = 'rejected';
        break;
      case WhatsAppTemplateStatus.PENDING:
        icon = (
          <CachedOutlinedIcon
            sx={{
              color: colors.warning.main,
              background: colors.warning.lighter,
              borderRadius: '50%'
            }}
          />
        );
        backgroundIcon = colors.warning.main;
        title = 'Waiting for approval';
        titleColor = colors.warning.main;
        backgroundChip = colors.warning.lighter;
        colorChip = colors.warning.main;
        titleChip = 'waiting for approval';
        break;

      case WhatsAppTemplateStatus.FAILED:
        icon = (
          <CloseOutlinedIcon
            sx={{
              color: colors.error.main,
              background: colors.error.lighter,
              borderRadius: '50%'
            }}
          />
        );
        backgroundIcon = colors.error.main;
        title = 'Failed';
        titleColor = colors.error.main;
        backgroundChip = colors.error.lighter;
        colorChip = colors.error.main;
        titleChip = 'failed';
        break;

      default:
        icon = null;
        backgroundIcon = '';
        title = '';
    }

    return {
      icon,
      backgroundIcon,
      title,
      titleColor,
      backgroundChip,
      colorChip,
      titleChip
    };
  };

  const submitWhatsAppAccount = !!submitWhatsAppAccountParams
    ? submitWhatsAppAccountParams
    : async (values: WhatspAppAccount) => {
        try {
          if (typeForm === 'create') {
            await addWhatsAppAccount(values);
          } else {
            await updateEnableWhatsAppAccount(values);
          }
          showAlert(
            t(
              `The WhatsApp Account was sucessfully ${
                typeForm === 'create' ? 'created' : 'updated'
              }`
            ),
            'success',
            3000
          );
          setOpenWhatsAppAccountModal(false);
        } catch (error) {
          showAlert(getErrorMessage(error).errorMessage, 'error', 3000);
        }
      };

  return {
    getInitialValues,
    getSchemaValidation,
    getStatusCard,
    submitWhatsAppAccount
  };
};
