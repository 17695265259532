import { useContext, useEffect, useMemo } from 'react';
import {
  channelToDialpadTab,
  NewConversationMenu
} from 'src/contexts/CTIBarContext';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { ChannelType } from 'src/models/conversations/conversations';
import {
  Campaign,
  isVideoCallCampaign,
  isOutboundCampaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import {
  checkReadCallOutboundCampaignPermissions,
  checkReadEmailCampaignPermissions,
  checkReadTelegramCampaignPermissions,
  checkReadWhatsAppCampaignPermissions,
  checkReadVideocallCampaignPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';

interface Props {
  conversationSelected: NewConversationMenu;
  setConversationSelected: (conversationSelected: NewConversationMenu) => void;
}

function useEnabledChannels({
  conversationSelected,
  setConversationSelected
}: Props) {
  const { hasAccess, loading, userPermissions } =
    useContext(PermissionsContext);

  const userHasExtension = !!localStorage.getItem('extensionData');

  const campaignsQuery = useUserCampaignsQuery(true);
  const userCampaigns = campaignsQuery?.data?.elements ?? [];

  const channelsPermissionChecker = {
    [NewConversationMenu.Call]: checkReadCallOutboundCampaignPermissions,
    [NewConversationMenu.Email]: checkReadEmailCampaignPermissions,
    [NewConversationMenu.Telegram]: checkReadTelegramCampaignPermissions,
    [NewConversationMenu.WhatsApp]: checkReadWhatsAppCampaignPermissions,
    [NewConversationMenu.Instagram]: checkReadVideocallCampaignPermissions,
    [NewConversationMenu.Videocall]: checkReadVideocallCampaignPermissions
  };

  function getDialpadTabFromCampaign(campaign: Campaign) {
    if (isVideoCallCampaign(campaign)) {
      return NewConversationMenu.Videocall;
    }

    return channelToDialpadTab[campaign.channel];
  }

  const channelsWithAccess = useMemo(() => {
    const enabledTabs: NewConversationMenu[] = [];
    // Get all the channels that the user is assigned to (check outbound campaigns)
    userCampaigns.forEach((campaign) => {
      const dialpadTab = getDialpadTabFromCampaign(campaign);
      if (enabledTabs.includes(dialpadTab)) return;
      if (enabledTabs.length === Object.keys(NewConversationMenu).length)
        return;

      if (!isOutboundCampaign(campaign)) return;

      enabledTabs.push(dialpadTab);
    });

    // Remove channels with no access
    enabledTabs.forEach((tab, index) => {
      // If the user has no extension, remove the call channel
      if (
        [NewConversationMenu.Call, NewConversationMenu.Videocall].includes(
          tab
        ) &&
        !userHasExtension
      ) {
        enabledTabs.splice(index, 1);
      }

      // Check if user is able to read campaigns
      if (!hasAccess(channelsPermissionChecker[tab])) {
        enabledTabs.splice(index, 1);
      }
    });

    return enabledTabs;
  }, [userCampaigns?.length, loading]);

  function selectInitialSelectedConversation() {
    if (conversationSelected) return;
    if (!userPermissions) return;
    if (channelsWithAccess.length === 0) return;

    const priorityOrder = [
      NewConversationMenu.Call,
      NewConversationMenu.Telegram,
      NewConversationMenu.Email,
      NewConversationMenu.WhatsApp,
      NewConversationMenu.Instagram,
      NewConversationMenu.Videocall
    ];

    const firstChannelWithAccess = priorityOrder.find((channel) =>
      channelsWithAccess.includes(channel)
    );

    if (firstChannelWithAccess) {
      setConversationSelected(firstChannelWithAccess);
    }
  }

  useEffect(() => {
    selectInitialSelectedConversation();
  }, [channelsWithAccess.length]);

  function isChannelEnabled(channel: ChannelType) {
    const tab = channelToDialpadTab[channel];
    return channelsWithAccess.includes(tab);
  }

  return { channelsWithAccess, isChannelEnabled };
}

export default useEnabledChannels;
