import {
  AuthEventSubtypes,
  EventTypes,
  WSMessage
} from 'src/services/websocket/utils/notifications/dataMsgs';

export enum App {
  DIALAPPLET = 'DIALAPPLET',
  SALESFORCE = 'SALESFORCE',
  ZOHO = 'ZOHO'
}

export interface VoipData {
  complexExtension: string;
  domain: string;
  extension: string;
  password: string;
}

export interface AuthData {
  app: App;
  companyId: string;
  expiration: string;
  token: string;
  userId: string;
  voipData: VoipData;
}
export enum MFACode {
  CONFIGURE_MFA = 'CONFIGURE_MFA',
  ENTER_MFA_CODE = 'ENTER_MFA_CODE'
}

export interface AuthIncompleteData {
  code: MFACode;
  error: string;
}

export interface RenewWSMessage extends WSMessage {
  type: EventTypes.AUTH;
  subtype: AuthEventSubtypes.RENEW;
  token: string;
  websocketId: string;
  timestamp: string;
  expiration?: string;
}
