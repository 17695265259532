import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import AutofocusPlugin from './plugins/AutoFocusPlugin';
import { EditorTopBar } from './components/ActionsBar';
import TabIndentPlugin from './plugins/TabIndent';
import DragDropPaste from './plugins/DragDropPaste';
import { ImagePlugin } from './plugins/ImagePlugin/ImagePlugin';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  SerializedEditor
} from 'lexical';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { PLAYGROUND_TRANSFORMERS } from './plugins/MarkdownTransformersPlugin';

import { $createReplyingToNode } from './nodes/ReplyingToNode';
import { parseMailAttachmentsBeforeSend } from 'src/modules/Shared/utils/attachments';
import { EmailMessage } from 'src/models/conversations/messages';
import { $createSignatureNode } from './nodes/SignatureNode';
import CopyPastePlugin from 'src/modules/Admin/modules/Operations/modules/DaServices/views/form/tabs/ShowflowTab/components/CopyPastePlugin';

interface Props {
  serializedEditor: SerializedEditor;
  messageToReply?: EmailMessage;
  messageToReplyHtml?: string;
  parsedSignature: string;
}

function Editor({
  serializedEditor,
  messageToReply,
  messageToReplyHtml,
  parsedSignature
}: Props) {
  const [editor] = useLexicalComposerContext();

  function appendSignature() {
    const paragraph = $createParagraphNode();
    paragraph.append($createTextNode(''));

    $getRoot().append(paragraph);
    const signatureNode = $createSignatureNode({
      content: parsedSignature
    });

    $getRoot().append(signatureNode);
  }

  // Editor initial content
  useEffect(() => {
    editor.update(() => {
      $getRoot().clear();
    });

    // CASE: We open the editor in a exclusive window and we have to import the previous state
    if (serializedEditor) {
      editor.update(() => {
        const parsedEditorState = editor.parseEditorState(
          serializedEditor.editorState
        );
        editor.setEditorState(parsedEditorState);
      });
      return;
    }

    // CASE: We are replying to a message, we have to parse the message and add it to the bottom
    if (messageToReplyHtml) {
      parseMailAttachmentsBeforeSend(messageToReplyHtml).then(
        (parsedInlineMessage) => {
          editor.update(() => {
            const nodes = [
              $createParagraphNode().append($createTextNode('')),
              $createReplyingToNode({
                message: messageToReply,
                parsedHtml: messageToReplyHtml,
                parsedInlineMessage
              })
            ];
            if (parsedSignature && !serializedEditor) {
              appendSignature();
            }

            $getRoot().append(...nodes);

            $getRoot().selectStart();
          });
        }
      );
      return;
    }

    // CASE: We are creating a new email from CTI
    if (!messageToReply && !serializedEditor) {
      editor.update(() => {
        appendSignature();
      });
    }
  }, [messageToReplyHtml, parsedSignature]);

  return (
    <>
      <EditorTopBar hideImageButton />
      <RichTextPlugin
        placeholder={<></>}
        contentEditable={
          <ContentEditable
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              padding: '9px',
              position: 'relative',
              minHeight: '150px'
            }}
          />
        }
        ErrorBoundary={LexicalErrorBoundary}
      />

      <CopyPastePlugin />
      <MarkdownShortcutPlugin transformers={PLAYGROUND_TRANSFORMERS} />
      <HistoryPlugin />
      <AutofocusPlugin />
      <TabIndentPlugin />
      <DragDropPaste />
      <ImagePlugin />
    </>
  );
}

export default Editor;
