import { CustomRND } from '../components/CustomRND';
import { ExtendedBar } from '../components/ExtendedBar/ExtendedBar';
import { useSpeakerTest } from '../components/ExtendedBar/hooks/useSpeakerTest';
import { MainButtons } from '../components/MainButtonsComponent';
import { MissedCalls } from '../components/MissedCalls';

export const CTIBar: React.FC = () => {
  //This hook needs to be called once.
  const speakersTest = useSpeakerTest();
  return (
    <CustomRND>
      <MainButtons />
      <ExtendedBar speakersTest={speakersTest} />
      <MissedCalls />
    </CustomRND>
  );
};
