import { $generateNodesFromDOM } from '@lexical/html';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
  $isElementNode,
  LexicalEditor,
  LexicalNode
} from 'lexical';
import { $isVariableNode } from 'src/components/TextInputWithVariables/lexicalNode/VariableNode';
import { Variable } from 'src/modules/Admin/modules/Acd/modules/Variables/models/models';

interface Props {
  editor: LexicalEditor;
  text: string;
  variables: Variable[];
  companyId: string;
  // if true, the text will be inserted on the cursor position instead of replacing the current content
  insert?: boolean;
}

export function updateEditor({
  editor,
  text,
  variables,
  companyId,
  insert
}: Props) {
  editor.update(() => {
    // text between $ and $ replace with <code> tag
    function textToShowflowHtml(text: string) {
      return text.replace(/\$.*?\$/g, (match) => {
        return `<code>${match}</code>`;
      });
    }

    const parser = new DOMParser();

    const textWithVariables = textToShowflowHtml(text);

    const html = parser.parseFromString(textWithVariables, 'text/html');

    let nodes = $generateNodesFromDOM(editor, html);

    nodes.forEach((node) => {
      setVariableIdToNode(node);
    });

    if (insert) {
      $insertNodes(nodes);
      return;
    }

    $getRoot().clear();
    $getRoot().select();
    $insertNodes(nodes);
  });

  function setVariableIdToNode(node: LexicalNode) {
    if ($isVariableNode(node)) {
      node.setVariables?.(variables as Variable[]);
      node.setCompanyId?.(companyId);
    } else if ($isElementNode(node)) {
      node.getChildren().forEach((child) => {
        setVariableIdToNode(child);
      });
    }
  }
}
