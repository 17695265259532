import UsersDomain from 'src/services/users/domain/UserDomain';
import { DataCategory } from '../models/filters';
import { getOption } from './getOptionValues';
import DaServicesDomain from 'src/modules/Admin/modules/Operations/modules/DaServices/domain/DaServicesDomain';
import { ContactsDomain } from 'src/modules/Contacts/domain/ContactsDomain';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';
import ExtensionsDomain from 'src/modules/Admin/modules/Configurations/modules/Extensions/domain/ExtensionsDomain';
import { TicketsDomain } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/TicketsDomain';
import { Option } from '../models/option';
import { getContactGroupById } from 'src/modules/Contacts/domain/getContactGroups';
import { getContactTag } from 'src/modules/Contacts/domain/getContactTags';

const DEFAULT_NAME = 'Unknown';

export const getOptionValue = async (
  value: string,
  dataCategory: DataCategory,
  getValueOptionFn?: (
    dataCategory: DataCategory,
    value: string
  ) => Promise<Option>
) => {
  switch (dataCategory) {
    // case DataCategory.ACCOUNT_ID:
    case DataCategory.AGENT_ID:
      const agent = await getAgentOption(value);
      return agent;
    // case DataCategory.AGENT_TYPE:
    case DataCategory.CALL_MODE:
      const callMode = getCallModeOption(value);
      return callMode;
    case DataCategory.CALL_RESULT:
      const callResult = await getCallResultOption(value);
      return callResult;
    case DataCategory.CAMPAIGN_ID:
      const campaign = await getCampaignOption(value);
      return campaign;
    case DataCategory.CHANNEL:
      const channel = await getChannelOption(value);
      return channel;
    case DataCategory.COMPANY_ID:
      const company = await getCompanyOption(value);
      return company;
    case DataCategory.CONTACT_ID:
      const contact = await getContactOption(value);
      return contact;
    case DataCategory.CONTACT_GROUP_ID:
      const contactGroup = await getContactGroupOption(value);
      return contactGroup;
    case DataCategory.CONTACT_TAG_ID:
      const contactTag = await getContactTagOption(value);
      return contactTag;
    // case DataCategory.CONVERSATION_SUMMARIES_ID:
    // case DataCategory.DATABASE_ID:
    case DataCategory.DATE:
      const date = getDateOption(value);
      return date;
    case DataCategory.EXTENSION_ID:
      const extension = await getExtensionOption(value);
      return extension;
    case DataCategory.SERVICE_ID:
      const service = await getServiceOption(value);
      return service;
    case DataCategory.STATUS:
      const status = getStatusOption(value);
      return status;
    case DataCategory.SUBRESOLUTION_ID:
      const subResolution = await getTicketSubResolutionOption(value);
      return subResolution;
    case DataCategory.TAG_ID:
      const tag = await getTicketTagOption(value);
      return tag;
    // case DataCategory.TRUE_OR_FALSE_OR_BOTH:
    // case DataCategory.TYPE:
    // case DataCategory.UPLOAD_STATUS:
    case DataCategory.USER_STATUS_REASON:
      const reason = await getUserStatusReasonOption(value);
      return reason;
    case DataCategory.USER_STATUS_TYPE:
      const userStatusType = getUserStatusTypeOption(value);
      return userStatusType;
    case DataCategory.USER_STATUS_NAME:
      const userStatusName = getUserStatusNameOption(value);
      return userStatusName;
    // case DataCategory.VARIABLES:

    // case DataCategory.WHATSAPP_AGENT_TYPE:
    default:
      const customOption = await getValueOptionFn?.(dataCategory, value);
      return customOption;
  }
};

export const getAgentOption = async (id: string) => {
  const agent = await UsersDomain.getUserById(id);
  const option = getOption({
    option: agent,
    valueExtractor: (agent) => agent.id,
    labelExtractor: (agent) => agent.username ?? DEFAULT_NAME
  });
  return option;
};

export const getCallModeOption = (id: string) => {
  const option = getOption({
    option: id,
    valueExtractor: (id) => id,
    labelExtractor: (id) => id
  });
  return option;
};

export const getCallResultOption = async (id: string) => {
  const option = getOption({
    option: id,
    valueExtractor: (id) => id,
    labelExtractor: (id) => id
  });
  return option;
};

export const getCampaignOption = async (id: string) => {
  const campaign = await UsersDomain.getCampaignById(id);
  const option = getOption({
    option: campaign,
    valueExtractor: (campaign) => campaign.id,
    labelExtractor: (campaign) => campaign.name ?? DEFAULT_NAME
  });
  return option;
};

export const getChannelOption = async (id: string) => {
  const option = getOption({
    option: id,
    valueExtractor: (id) => id,
    labelExtractor: (id) => id
  });
  return option;
};

export const getCompanyOption = async (id: string) => {
  const company = await UsersDomain.retrieveCompanyById(id);
  const option = getOption({
    option: company,
    valueExtractor: (company) => company.id,
    labelExtractor: (company) => company.name ?? DEFAULT_NAME
  });
  return option;
};

export const getContactOption = async (id: string) => {
  const contact = await ContactsDomain.getContact({ id });
  const contactName = await getContactName(contact);

  const option = getOption({
    option: contact,
    valueExtractor: (contact) => contact.id,
    labelExtractor: () => contactName ?? DEFAULT_NAME
  });
  return option;
};

export const getContactGroupOption = async (id: string) => {
  const contactGroup = await getContactGroupById(id);
  const option = getOption({
    option: contactGroup,
    valueExtractor: (contactGroup) => contactGroup.id,
    labelExtractor: (contactGroup) => contactGroup.name
  });
  return option;
};

export const getContactTagOption = async (id: string) => {
  const contactGroup = await getContactTag(id);
  const option = getOption({
    option: contactGroup,
    valueExtractor: (contactTag) => contactTag.id,
    labelExtractor: (contactTag) => contactTag.name
  });
  return option;
};

export const getDateOption = (isostringDate: string) => {
  const option = getOption({
    option: isostringDate,
    valueExtractor: (isostringDate) => isostringDate,
    labelExtractor: (isostringDate) => new Date(isostringDate).toLocaleString()
  });

  return option;
};

export const getExtensionOption = async (id: string) => {
  const extension = (await ExtensionsDomain.getExtensions({ id }))
    .elements?.[0];
  const option = getOption({
    option: extension,
    valueExtractor: (extension) => extension.id,
    labelExtractor: (extension) => extension.extension ?? DEFAULT_NAME
  });
  return option;
};

export const getServiceOption = async (id: string) => {
  const service = await DaServicesDomain.retrieveDaServiceById(id);
  const option = getOption({
    option: service,
    valueExtractor: (service) => service.id,
    labelExtractor: (service) => service.name ?? DEFAULT_NAME
  });
  return option;
};

export const getStatusOption = async (id: string) => {
  const option = getOption({
    option: id,
    valueExtractor: (status) => status,
    labelExtractor: (status) => status
  });
  return option;
};

export const getTicketSubResolutionOption = async (id: string) => {
  const ticketSubResolution =
    await TicketsDomain.getTicketSubResolutionById(id);
  const option = getOption({
    option: ticketSubResolution,
    valueExtractor: (subResolution) => subResolution.id,
    labelExtractor: (subResolution) => subResolution.name ?? DEFAULT_NAME
  });
  return option;
};

export const getTicketTagOption = async (id: string) => {
  const ticketTag = await TicketsDomain.getTicketTagById(id);
  const option = getOption({
    option: ticketTag,
    valueExtractor: (tag) => tag.id,
    labelExtractor: (tag) => tag.name ?? DEFAULT_NAME
  });
  return option;
};

export const getUserStatusReasonOption = async (id: string) => {
  const option = getOption({
    option: id,
    valueExtractor: (reason) => reason,
    labelExtractor: (reason) => reason
  });
  return option;
};

export const getUserStatusTypeOption = async (id: string) => {
  const option = getOption({
    option: id,
    valueExtractor: (type) => type,
    labelExtractor: (type) => type
  });
  return option;
};

export const getUserStatusNameOption = async (id: string) => {
  const option = getOption({
    option: id,
    labelExtractor: (id) => id,
    valueExtractor: (id) => id
  });
  return option;
};
