import { VideocamOffTwoTone, VideocamTwoTone } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { useContext } from 'react';
import { VideoStreamContext } from '../../context';
import { CameraSelect } from 'src/components/WebRTCDevices/CameraSelect';

export const CameraControl = () => {
  const { isCameraOn, toggleCamera } = useContext(VideoStreamContext);

  return (
    <Box>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems="center"
        spacing={2}
      >
        <Stack direction={'row'}>
          <IconButton onClick={toggleCamera}>
            {isCameraOn ? (
              <VideocamTwoTone color="secondary" />
            ) : (
              <VideocamOffTwoTone color="secondary" />
            )}
          </IconButton>
          <CameraSelect />
        </Stack>
      </Stack>
    </Box>
  );
};
