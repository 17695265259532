import { Conversation } from 'src/models/conversations/conversations';
import AuthManager from 'src/services/authentication/manager';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { logger } from 'src/utils/logger';
import { Variable } from '../../models/models';

const getConversationParam = (conversation: Conversation, paramKey: string) => {
  if (!conversation || !paramKey) return '';
  const loggedUser = getConnectedRTUser(false);
  const extension = AuthManager.getUserExtension();

  const paramsObject = {
    conversationId: conversation?.id,
    convCreationDate: conversation?.metadata?.creationDate,
    convModificationDate: conversation?.metadata?.modificationDate,
    agentName: loggedUser?.username,
    agentExtension: extension,
    ddi: conversation?.originAgent?.ddi
  };
  return paramsObject?.[paramKey] ?? conversation?.[paramKey] ?? '';
};

const logUndefinedVariable = (variableId: string) => {
  logger.error(`Varible with id ${variableId} is null or undefined.`);
};

export const getVariableValueFromConversation = async (
  id: string,
  conversation: Conversation
) => {
  // CONVERSATION PARAM
  if (id.includes('C_')) {
    const variableId = id.replace('C_', '');
    const variable = getConversationParam(conversation, variableId);
    if (!variable) {
      logUndefinedVariable(id);
    }
    return {
      id: id,
      value: variable ?? ''
    };
  }
  // ROUTE VARIABLE
  if (id.includes('V_')) {
    const variableId = id.replace('V_', '');
    return {
      id: id,
      value:
        (conversation?.variables?.[variableId] as unknown as Variable)?.value ??
        ''
    };
  }
  // OTHER VARIABLE
  if (id.includes('O_')) {
    const variableId = id.replace(/^O_/, '');

    const variable = getConversationParam(conversation, variableId);
    if (!variable) {
      logUndefinedVariable(id);
    }
    return {
      id: id,
      value: variable ?? ''
    };
  }
};
