import { Close, Dialpad } from '@mui/icons-material';
import {
  Button,
  Card,
  Grow,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  useTheme
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { sendDtmfTone } from 'src/services/webrtc/webrtc-devices';
import { callKeysCTI } from '../NewConversation/DialPad';
import { CTIBarContext } from 'src/contexts/CTIBarContext';

export const PadButton: React.FC = () => {
  function playDtmfSound() {
    const audio = document.getElementById('dtmfTone') as HTMLAudioElement;
    audio.volume = 0.3;
    audio?.play();
  }
  const { barState } = useContext(CTIBarContext);
  const { setKeepBarExtended, positionY, isDragging, lockButtonState } =
    barState;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const showAboveCTI = positionY / window.innerHeight <= 0.5;

  useEffect(() => {
    //If the pad is open, keep the bar extended.
    setKeepBarExtended(isOpen ? true : lockButtonState !== 0);
  }, [isOpen]);

  return (
    <>
      <Tooltip title={'Pad'}>
        <IconButton
          onClick={() => {
            setIsOpen((opened) => !opened);
          }}
        >
          <Dialpad fontSize="medium"></Dialpad>
        </IconButton>
      </Tooltip>
      <Grow
        style={{
          transformOrigin: showAboveCTI ? 'top' : 'bottom'
        }}
        in={isOpen}
        mountOnEnter
        unmountOnExit
      >
        <Card
          sx={{
            position: 'absolute',
            bottom: !showAboveCTI ? '110%' : 'inherited',
            top: showAboveCTI ? '110%' : 'inherited',
            right: 0
          }}
        >
          <Stack
            px={2}
            direction="row"
            justifyContent={'space-between'}
            alignItems="center"
            sx={{ backgroundColor: '#F6F8FB' }}
          >
            <h2>Dialpad</h2>
            <IconButton
              sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
              color={'secondary'}
              onClick={(): void => {
                setIsOpen(false);
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Stack>
          <Stack
            direction={'column'}
            justifyContent="center"
            alignItems="center"
            py={1}
            px={2}
          >
            <audio id="dtmfTone" src="/sounds/dtmf.wav" />
            {callKeysCTI &&
              callKeysCTI.map((g, idx) => {
                return (
                  <Stack
                    direction="row"
                    key={idx}
                    width={'100%'}
                    justifyContent="space-around"
                    py={1}
                  >
                    {g.map((k) => {
                      return (
                        <Button
                          onClick={() => {
                            if (isDragging) return;
                            sendDtmfTone(k);
                            playDtmfSound();
                          }}
                          key={k}
                          sx={{ fontSize: '20px' }}
                          id="btnKey-PadButton"
                        >
                          {k}
                        </Button>
                      );
                    })}
                  </Stack>
                );
              })}
          </Stack>
        </Card>
      </Grow>
    </>
  );
};

/*
 <CtiPopover
      title="Dialpad"
      icon={
        <Tooltip title={'Pad'}>
          <IconButton>
            <Dialpad fontSize="medium"></Dialpad>
          </IconButton>
        </Tooltip>
      }
      hideBackdrop
    >
*/
