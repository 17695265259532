const enJSON = {
  NONDISPLAYEDNAME: '',
  Blueprints: 'Blueprints',
  'Extended Sidebar': 'Extended Sidebar',
  'Accent Sidebar': 'Accent Sidebar',
  'Accent Header': 'Accent Header',
  'Boxed Sidebar': 'Boxed Sidebar',
  'Collapsed Sidebar': 'Collapsed Sidebar',
  'Bottom Navigation': 'Bottom Navigation',
  'Top Navigation': 'Top Navigation',
  'Lead Developer': 'Lead Developer',
  'Mega menu': 'Mega menu',
  Doctors: 'Doctors',
  Reports: 'Reports',
  'Custom dashboard built using the included components':
    'Custom dashboard built using the included components',
  Hospital: 'Hospital',
  Export: 'Export',
  'Last year': 'Last year',
  Expenses: 'Expenses',
  Products: 'Products',
  Statistics: 'Statistics',
  General: 'General',
  Automation: 'Automation',
  'Data Display': 'Data Display',
  Calendar: 'Calendar',
  Mailbox: 'Mailbox',
  Dashboards: 'Dashboards',
  CREATED_SINGULAR: 'Created',
  Analytics: 'Analytics',
  Banking: 'Banking',
  Commerce: 'Commerce',
  Crypto: 'Crypto',
  Finance: 'Finance',
  Fitness: 'Fitness',
  LoginSession: 'Login',
  Healthcare: 'Healthcare',
  'Doctors Page': 'Doctors Page',
  'Hospital Overview': 'Hospital Overview',
  Helpdesk: 'Helpdesk',
  Learning: 'Learning',
  Monitoring: 'Monitoring',
  Tasks: 'Tasks',
  Applications: 'Applications',
  'File Manager': 'File Manager',
  'Jobs Platform': 'Jobs Platform',
  Messenger: 'Messenger',
  'Projects Board': 'Projects Board',
  Management: 'Management',
  Users: 'Users',
  'List All': 'List All',
  'User Profile': 'User Profile',
  Projects: 'Projects',
  Shop: 'Shop',
  'Products List': 'Products List',
  'View Product': 'View Product',
  'Create Product': 'Create Product',
  Invoices: 'Invoices',
  'View Single': 'View Single',
  'Auth Pages': 'Auth Pages',
  Login: 'Login',
  Basic: 'Basic',
  Cover: 'Cover',
  Register: 'Register',
  Wizard: 'Wizard',
  'Recover Password': 'Recover Password',
  Status: 'Status',
  Foundation: 'Foundation',
  'Extra Pages': 'Extra Pages',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Coming Soon',
  Maintenance: 'Maintenance',
  Overview: 'Overview',
  'Layout Starters': 'Layout Starters',
  'Starter Kit 1': 'Starter Kit 1',
  'Starter Kit 2': 'Starter Kit 2',
  Documentation: 'Documentation',
  Welcome: 'Welcome',
  Help: 'Help',
  'Features tour': 'Features tour',
  'Getting started guide': 'Getting started guide',
  'Contact support': 'Contact support',
  Version: 'Version',
  Search: 'Search',
  Notifications: 'Notifications',
  Settings: 'Settings',
  'Language Switcher': 'Language Switcher',
  'Sign out': 'Sign out',
  'Sign in': 'Sign in',
  'Start being productive': 'Start being productive',
  User: 'User',
  Password: 'Password',
  'Change password': 'Change password',
  'Without extension': 'Without extension',
  'Have a problem ?': 'Have a problem ?',
  'Click here': 'Click here',
  'Something went wrong: Check your user or password':
    'Something went wrong: Check your user or password',
  'Fill in the fields below to sign into your account.':
    'Fill in the fields below to sign into your account..',
  'Change Theme': 'Change Theme',
  'View all notifications': 'View all notifications',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Tokyo NextJS Typescript Admin Dashboard',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes',
  'Browse Live Preview': 'Browse Live Preview',
  'Live Preview': 'Live Preview',
  'Key Features': 'Key Features',
  dashboards: 'dashboards',
  applications: 'applications',
  'management sections': 'management sections',
  'dark/light themes': 'dark/light themes',
  components: 'components',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Some of the features that make Tokyo one of the best admin templates available today',
  'Design Source Files': 'Design Source Files',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first',
  'We only translated a small part of the template, for demonstration purposes':
    'We only translated a small part of the template, for demonstration purposes',
  Asigned: 'Asigned',
  Queue: 'Queue',
  Queues: 'Queues',
  Calls: 'Calls',
  CallAction: 'Call',
  Attend: 'Attend',
  Pending: 'Pending',
  Routes: 'Routes',
  Interactions: 'Interactions',
  Election: 'Election',
  Comprobations: 'Comprobations',
  Method: 'Method',
  Operations: 'Operations',
  Integrations: 'Integrations',
  'Contact Center': 'Contact Center',
  Quality: 'Quality',
  Contacts: 'Contacts',
  'In Firefox you can not change output':
    'In Firefox you can not change output',
  'General Settings': 'General Settings',
  Actions: 'Actions',
  Companies: 'Companies',
  Assigned: 'Assigned',
  Create: 'Create',
  Add: 'Add',
  Update: 'Update',
  'No Options': 'No Options',
  'Internal Chat': 'Internal Chat',
  'Configure Web Client': 'Configure Web Client',
  'Events Panel': 'Events Panel',
  'FTP Servers': 'FTP Servers',
  'Web Events': 'Web Events',
  'Create service': 'Create service',
  'View services': 'View services',
  'Create showflow': 'Create showflow',
  'View ShowFlows': 'View ShowFlows',
  'View showflows': 'View showflows',
  Tipology: 'Tipology',
  Comments: 'Comments',
  'API Methods': 'API Methods',
  'Api methods': 'Api methods',
  Webservices: 'Webservices',
  'API Tokens': 'API Tokens',
  'Api tokens': 'Api tokens',
  'Create User': 'Create User',
  'View Users': 'View Users',
  Skills: 'Skills',
  'Roles and Permissions': 'Roles and Permissions',
  'Agent groups': 'Agent groups',
  'Agent states': 'Agent states',
  Patterns: 'Patterns',
  'Schedule groups': 'Schedule groups',
  'Voice messages': 'Voice messages',
  Task: 'Task',
  'View Contacts': 'View Contacts',
  'Import CSV': 'Import CSV',
  Blacklist: 'Blacklist',
  'Creating user': 'Creating user',
  'No Calls': 'No Calls',
  'Contact Data': 'Contact Data',
  'Conversations History': 'Conversations History',
  'Call Schedule': 'Call Schedule',
  Notes: 'Notes',
  'New call incoming': 'New call incoming',
  Typology: 'Typology',
  Conversations: 'Conversations',
  'New Telegram Conversation': 'New Telegram Conversation',
  'New Email Conversation': 'New Email Conversation',
  'Your message  here...': 'Your message  here...',
  Send: 'Send',
  'Select an agent:': 'Select an agent:',
  To: 'To',
  Subject: 'Subject',
  'Text here': 'Text here',
  Transfer: 'Transfer',
  Spy: 'Spy',
  'Choose an extension for transfering': 'Choose an extension for transfering',
  'No extensions available': 'No extensions available',
  'Choose a mode for spying': 'Choose a mode for spying',
  Agent: 'Agent',
  Available: 'Available',
  Busy: 'Busy',
  Unavailable: 'Unavailable',
  'Error changing status': 'Error changing status',
  'Disabled while in call': 'Disabled while in call',
  'Attended transfer': 'Attended transfer',
  Loading: 'Loading',
  'Dialerbot Campaigns': 'Dialerbot Campaigns',
  'These are your dialertbots': 'These are your dialertbots',
  'Create new dialerbot': 'Create new dialerbot',
  'Search by campaign, channel or status...':
    'Search by campaign, channel or status...',
  'In call': 'In call',
  'Parked call': 'Parked call',
  'The title field is required': 'The title field is required',
  'The timeout field is required': 'The timeout field is required',
  Title: 'Title',
  'The Max calls per second field is required':
    'The Max calls per second field is required',
  'The Max simultaneus calls field is required':
    'The Max simultaneus calls field is required',
  'The Amd Detection field is required': 'The Amd Detection field is required',
  'The Pause Holidays field is required':
    'The Pause Holidays field is required',
  Description: 'Description',
  'Select Company': 'Select Company',
  'Select Service': 'Select Service',
  'DialerBot configuration': 'DialerBot configuration',
  Dates: 'Dates',
  'Select Queue Percentage': 'Select Queue Percentage',
  'Select Outbound Route': 'Select Outbound Route',
  'Select Inbound Route': 'Select Inbound Route',
  'AMD detection': 'AMD detection',
  'Launch date': 'Launch date',
  'Pause on holidays': 'Pause on holidays',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  'There was an error, please try again later':
    'There was an error, please try again later',
  'The server was not able to complete your request':
    'The server was not able to complete your request',
  'All Days': 'All Days',
  'Monday to Friday': 'Monday to Friday',
  'Create a new dialerbot campaign': 'Create a new dialerbot campaign',
  'Use this dialog window to Add Dialerbot':
    'Use this dialog window to Add Dialerbot',
  Cancel: 'Cancel',
  'Upload CSV': 'Upload CSV',
  'Keep as draft': 'Keep as draft',
  'Max calls per second': 'Max calls per second',
  'Max simultaneus calls': 'Max simultaneus calls',
  CAMPAIGN: 'CAMPAIGN',
  CHANNEL: 'CHANNEL',
  'LAUNCH DATE': 'LAUNCH DATE',
  PROGRESS: 'PROGRESS',
  STATUS: 'STATUS',
  ACTIONS: 'ACTIONS',
  'Rows per page': 'Rows per page',
  Active: 'Active',
  Finished: 'Finished',
  Unstarted: 'Unstarted',
  Progressive: 'Progressive',
  Predictive: 'Predictive',
  'Predictive configuration': 'Predictive configuration',
  'Progressive Configuration': 'Progressive Configuration',
  'Abandoned rate': 'Abandoned rate',
  'Force autoanswer': 'Force autoanswer',
  'Recordings configuration': 'Recordings configuration',
  'Recordings percentage': 'Recordings percentage',
  'Start date': 'Start date',
  'Are you sure to delete this dialerbot?':
    'Are you sure to delete this dialerbot?',
  'Are you sure to delete several dialerbots?':
    'Are you sure to delete several dialerbots?',
  Delete: 'Delete',
  'The delimiter field is required': 'The delimiter field is required',
  Delimiter: 'Delimiter',
  'The phonenumber field is required': 'The phonenumber field is required',
  'Name of PhoneColumn': 'Name of PhoneColumn',
  'Select Holiday': 'Select Holiday',
  Holiday: 'Holiday',
  'drag and drop the files here': 'drag and drop the files here',
  Upload: 'Upload',
  'Create Dialerbot': 'Create Dialerbot',
  'No documents uploaded': 'No documents uploaded',
  'Dialerbot finished': 'Dialerbot finished',
  'Add new CSV': 'Add new CSV',
  Done: 'Done',
  'Export Result CSV': 'Export Result CSV',
  'Stop Dialerbot': 'Stop Dialerbot',
  "We couldn't find any users matching your search criteria":
    'We couldn`t find any users matching your search criteria',
  Undefined: 'Undefined',
  'No results': 'No results',
  Showing: 'Showing',
  'Start Dialerbot': 'Start Dialerbot',
  'Dialerbot execution not found': 'Dialerbot execution not found',
  Until: 'Until',
  'Select the hours of weeks to be executed:':
    'Select the hours of weeks to be executed:',
  'Cannot start a dialerbot that is paused by time of day configuration':
    'Cannot start a dialerbot that is paused by time of day configuration',
  'The dialerBot was updated': 'The dialerBot was updated',
  'Update a dialerbot campaign': 'Update a dialerbot campaign',
  'Use this dialog window to update a dialerbot':
    'Use this dialog window to update a dialerbot',
  'The document was deleted successfully':
    'The document was deleted successfully',
  'The dialerBot was paused successfully':
    'The dialerBot was paused successfully',
  'The report was generated successfully':
    'The report was generated successfully',
  'The dialerBot was started successfully':
    'The dialerBot was started successfully',
  'A connection error occurred, contact the administrator':
    'A connection error occurred, contact the administrator',
  Company: 'Company',
  Channel: 'Channel',
  'Edit the hours of weeks to be executed:':
    'Edit the hours of weeks to be executed:',
  'Dates configuration': 'Dates configuration',
  'Start time must be before end time.': 'Start time must be before end time.',
  'Dialerbot Name': 'Dialerbot Name',
  'The dialerbot name field is required':
    'The dialerbot name field is required',
  'Refresh table': 'Refresh table',
  'Delete one or several dialerbots': 'Delete one or several dialerbots',
  'Pause one or several dialerbots': 'Pause one or several dialerbots',
  'Start one or several dialerbots': 'Start one or several dialerbots',
  'Update Dialerbot': 'Update Dialerbot',
  'Delete dialerbot': 'Delete dialerbot',
  'Close conversation': 'Close conversation',
  'Transfer call': 'Transfer call',
  'Transfer failed': 'Transfer failed',
  'Hangup call': 'Hangup call',
  'Park Call': 'Park Call',
  'Unpark call': 'Unpark call',
  "We're working on implementing new awesomes features!":
    'We`re working on implementing new awesomes features!',
  'Do you have any problem?': 'Do you have any problem?',
  'The email was sent successfully': 'The email was sent successfully',
  'You must enter the destination email':
    'You must enter the destination email',
  'Telegram message has been sent!': 'Telegram message has been sent!',
  'Error sending the message': 'Error sending the message',
  'Call launched!': 'Call launched!',
  'Error launching the call': 'Error launching the call',
  'Are you sure to remove the following DialerBots?':
    'Are you sure to remove the following DialerBots?',
  schedules: 'schedules',
  'Here you can edit,add or delete your schedules':
    'Here you can edit,add or delete your schedules',
  NAME: 'NAME',
  'A call cannot be transfered to itself or to the same extension that it is already talking with.':
    'A call cannot be transfered to itself or to the same extension that it is already talking with.',
  'The call ended successfully': 'The call ended successfully',
  'An error occurred in the connection': 'An error occurred in the connection',
  'The call was successfully transferred':
    'The call was successfully transferred',
  'The call was successfully unparked': 'The call was successfully unparked',
  'The call was successfully parked': 'The call was successfully parked',
  'The call was successfully captured': 'The call was successfully captured',
  'The conversation was closed successfully':
    'The conversation was closed successfully',
  'The call is being spied on successfully':
    'The call is being spied on successfully',
  'Do not disturb': 'Do not disturb',
  'Open SGI': 'Open SGI',
  'Open Fichaje': 'Open Fichaje',
  'Reset calls': 'Reset calls',
  'Disable do not disturb mode to launch calls':
    'Disable do not disturb mode to launch calls',
  'Do not disturb enabled': 'Do not disturb enabled',
  'New conversation': 'New conversation',
  'Devices configuration': 'Devices configuration',
  'Maximize bar': 'Maximize bar',
  'Compress bar': 'Compress bar',
  'Your session is about to expire': 'Your session is about to expire',
  "I'm still here": 'I`m still here',
  'Conversation history': 'Conversation history',
  Outbound: 'Outbound',
  Inbound: 'Inbound',
  Opened: 'Opened',
  Closed: 'Closed',
  'Save the contact to see the history': 'Save the contact to see the history',
  'The user could not be deleted': 'The user could not be deleted',
  'The user could not be created': 'The user could not be created',
  'Update User': 'Update User',
  'The user could not be updated': 'The user could not be updated',
  'The user was successfully created': 'The user was successfully created',
  'The user was successfully updated': 'The user was successfully updated',
  Username: 'Username',
  Extension: 'Extension',
  'The user was successfully cloned': 'The user was successfully cloned',
  'The user could not be cloned': 'The user could not be cloned',
  'Queue Percentage': 'Queue Percentage',
  'OutBound Route': 'Outbound route',
  'Outbound route': 'Outbound route',
  'Inbound Route': 'Inbound Route',
  'Inbound route': 'Inbound Route',
  'Max calls simultaneous': 'Max calls simultaneous',
  'AMD Detection': 'AMD Detection',
  Service: 'Service',
  'General Configuration': 'General Configuration',
  'Select Start Date': 'Select Start Date',
  Account: 'Account',
  Templates: 'Templates',
  'Create new user': 'Create new user',
  'There are the users for company': 'There are the users for company',
  'All Users': 'All Users',
  'Users Group': 'Users Group',
  'Add user': 'Add user',
  'Add users CSV': 'Add users CSV',
  'Create user group': 'Create user group',
  'Add users group CSV': 'Add users group CSV',
  'WhatspApp Accounts': 'WhatspApp Accounts',
  'All aspects related to WhatspApp can be managed from this page':
    'All aspects related to WhatspApp can be managed from this page',
  'Add WhatspApp account': 'Add WhatspApp account',
  Accounts: 'Accounts',
  'Meta templates': 'Meta templates',
  'New WhatsApp Conversation': 'New WhatsApp Conversation',
  Number: 'Number',
  Select: 'Select',
  Selected: 'Selected',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character':
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  'The name is required': 'The name is required',
  'The password is required': 'The password is required',
  'The email is required': 'The email is required',
  'The description is required': 'The description is required',
  'The company is required': 'The company is required',
  'The Asign extension is required': 'The Asign extension is required',
  'The extension is required': 'The extension is required',
  'The email format is invalid': 'The email format is invalid',
  'Unlock bar': 'Unlock bar',
  'Lock bar': 'Lock bar',
  'Please retype your password': 'Please retype your password',
  'Your passwords do not match': 'Your passwords do not match',
  'The password can not be longer than 16 characters':
    'The password can not be longer than 16 characters',
  Preview: 'Preview',
  'No User selected': 'No User selected',
  'Delete one or several users': 'Delete one or several users',
  'Disable one or several users': 'Disable one or several users',
  'Enable one or several users': 'Enable one or several users',
  'The user was enabled sucessfully': 'The user was enable sucessfully',
  'The user was disabled sucessfully': 'The user was disable sucessfully',
  'Create new WhatspApp Account': 'Create new WhatspApp Account',
  'Use this dialog window to add a new WhatspApp Account':
    'Use this dialog window to add a new WhatspApp Account',
  'Create account': 'Create account',
  'Edit/Update WhatspApp Account': 'Edit/Update WhatspApp Account',
  'Use this dialog window to edit/update WhatspApp Account':
    'Use this dialog window to edit/update WhatspApp Account',
  'Update account': 'Update account',
  'The key is required': 'The key is required',
  'The phone number is required': 'The phone number is required',
  'The provider is required': 'The provider is required',
  'The WhatsApp account was successfully created':
    'The WhatsApp account was successfully created',
  'The WhatsApp account was successfully updated':
    'The WhatsApp account was successfully updated',
  'The WhatsApp account could not be created':
    'The WhatsApp account could not be created',
  'The WhatsApp account could not be updated':
    'The WhatsApp account could not be updated',
  'No WhatsApp account selected': 'No WhatsApp account selected',
  'Delete one or several WhatsApp account':
    'Delete one or several WhatsApp account',
  'Disable one or several WhatsApp account':
    'Disable one or several WhatsApp account',
  'Enable one or several WhatsApp account':
    'Enable one or several WhatsApp account',
  'The WhatsApp account was enable sucessfully':
    'The WhatsApp account was enable sucessfully',
  'The WhatsApp account was disable sucessfully':
    'The WhatsApp account was disable sucessfully',
  'No Routes selected': 'No Routes selected',
  'The selected route is already enabled':
    'The selected route is already enabled',
  'The selected route is already disabled':
    'The selected route is already disabled',
  'The route was enable sucessfully': 'The route was enable sucessfully',
  'The route was disable sucessfully': 'The route was disable sucessfully',
  'The files were uploaded!': 'The files were uploaded!',
  'The file was deleted!': 'The file was deleted!',
  'The Dialerbot execution has started!':
    'The Dialerbot execution has started!',
  'Execution is already paused.': 'Execution is already paused.',
  'Download Report': 'Download Report',
  'Generate report': 'Generate report',
  'Report name': 'Report name',
  'Duplicate fields found in the CSV file.':
    'Duplicate fields found in the CSV file.',
  'That date is already selected': 'That date is already selected',
  'Create a new': 'Create a new',
  ' dialerbot campaign': ' dialerbot campaign',
  'Update a': 'Update a',
  'Use this dialog window to ': 'Use this dialog window to ',
  'Add Dialerbot': 'Add Dialerbot',
  'update a dialerbot': 'update a dialerbot',
  Name: 'Name',
  'Please create a dialerbot first!!': 'Please create a dialerbot first!!',
  'No dialerbot selected': 'No dialerbot selected',
  'Droping the files here ...': 'Droping the files here ...',
  Field: 'Field',
  'not found in the file': 'not found in the file',
  'Download report': 'Download report',
  'Ignore wrong lines': 'Ignore wrong lines',
  'The selected user is already disabled':
    'The selected user is already disabled',
  'The selected user is already enabled':
    'The selected user is already enabled',
  'The selected WhatsApp account is already enabled':
    'The selected WhatsApp account is already enabled',
  'The selected WhatsApp account is already disabled':
    'The selected WhatsApp account is already disabled',
  'There is elements without edge': 'There is elements without edge',
  'Full Route was updated':
    'La ruta completa fue actualizadaFull Route was updated',
  Nodes: 'Nodes',
  Save: 'Save',
  'Publish now': 'Publish now',
  'Write Route title here': 'Write Route title here',
  'Route title': 'Route title',
  'Route History': 'Route History',
  'Search node...': 'Search node...',
  'Inbound Routes': 'Inbound Routes',
  'Create new Route': 'Create new Route',
  'Disable one or several routes': 'Disable one or several routes',
  'Enable one or several routes': 'Enable one or several routes',
  'Delete one or several routes': 'Delete one or several routes',
  'Edit route': 'Edit route',
  'Delete route': 'Delete route',
  'Create new inbound route': 'Create new inbound route',
  'Create a new route': 'Create a new route',
  'Create Route': 'Create Route',
  Close: 'Close',
  'No elements': 'No elements',
  'Time condition': 'Time condition',
  'Send email': 'Send email',
  HangUp: 'HangUp',
  Hangup: 'Hangup',
  PlayBack: 'PlayBack',
  'Time groups': 'Time groups',
  'These are the time groups': 'These are the time groups',
  'Create extensions': 'Create extensions',
  'Create new extension': 'Create new extension',
  'Use this dialog window to add a new time group':
    'Use this dialog window to add a new time group',
  'Create time group': 'Create time group',
  'The time group was successfully created':
    'The time group was successfully created',
  'The time group could not be created': 'The time group could not be created',
  'Create Extension': 'Create Extension',
  'Write here the time group name...': 'Write here the time group name...',
  'The time group was successfully deleted':
    'The time group was successfully deleted',
  'The time group could not be deleted': 'The time group could not be deleted',
  'Copy time group': 'Copy time group',
  'Edit time group': 'Edit time group',
  'Delete time group': 'Delete time group',
  'No time groups selected': 'No time groups selected',
  'Clone one or several time groups': 'Clone one or several time groups',
  'Delete one or several time group': 'Delete one or several time group',
  'The temporal range was successfully deleted':
    'The temporal range was successfully deleted',
  'The temporal range could not be deleted':
    'The temporal range could not be deleted',
  'The temporal range was successfully updated':
    'The temporal range was successfully updated',
  'The temporal range could not be updated':
    'The temporal range could not be updated',
  'The time group was successfully copied':
    'The time group was successfully copied',
  'The time group could not be copied': 'The time group could not be copied',
  'Select existing ': 'Select existing ',
  Interaction: 'Interaction',
  Choice: 'Choice',
  Verification: 'Verification',
  Registration: 'Registration',
  Finalize: 'Finalize',
  'Update time group': 'Update time group',
  'Use this dialog window to update a time group':
    'Use this dialog window to update a time group',
  'The temporal range was successfully copied':
    'The temporal range was successfully copied',
  'The temporal range could not be copied':
    'The temporal range could not be copied',
  'Copy temporal range': 'Copy temporal range',
  'Edit temporal range': 'Edit temporal range',
  'Delete temporal range': 'Delete temporal range',
  'Use this dialog window to add a new extension':
    'Use this dialog window to add a new extension',
  'Create new time range': 'Create new time range',
  'Update a time range': 'Update a time range',
  'Use this dialog window to add a new time range':
    'Use this dialog window to add a new time range',
  'Use this dialog window to update a time range':
    'Use this dialog window to update a time range',
  'Create temporal range': 'Create temporal range',
  'Update temporal range': 'Update temporal range',
  'The final month cannot be less than the initial month':
    'The final month cannot be less than the initial month',
  'Start Month': 'Start Month',
  'End Month': 'End Month',
  'Start Week Day': 'Start Week Day',
  'End Week Day': 'End Week Day',
  'Start Month Day': 'Start Month Day',
  'End Month Day': 'End Month Day',
  'Initial time': 'Initial time',
  'End time': 'End time',
  'The end time is required': 'The end time is required',
  'The start time is required': 'The start time is required',
  'The start month is required': 'The start month is required',
  'The end month is required': 'The end month is required',
  'The start week day is required': 'The start week day is required',
  'The start month day is required': 'The start month day is required',
  'The time range was successfully created':
    'The time range was successfully created',
  'The time range could not be created': 'The time range could not be created',
  'The time range was successfully updated':
    'The time range was successfully updated',
  'The time range could not be updated': 'The time range could not be updated',
  'Phone number': 'Phone number',
  Or: 'Or',
  'Number to call': 'Number to call',
  'Use the original caller id': 'Use the original caller id',
  'Call as anonymous': 'Call as anonymous',
  'Create Node': 'Create Node',
  'Update Node': 'Update Node',
  'The Dialerbot execution depends on configuration!':
    'The Dialerbot execution depends on configuration!',
  'The download has started!': 'The download has started!',
  'The download has finished successfully!':
    'The download has finished successfully!',
  'Something went wrong please contact your administrator!':
    'Something went wrong please contact your administrator!',
  'The number must be less or equal to 200':
    'The number must be less or equal to 200',
  'Repite Password': 'Repite Password',
  'Asign extension': 'Asign extension',
  'Delete report': 'Delete report',
  'Create new service': 'Create new service',
  'Must select company first': 'Must select company first',
  You: 'You',
  Announcement: 'Announcement',
  Announcements: 'Announcements',
  'Time Conditions': 'Time Conditions',
  'Send Email': 'Send Email',
  'Send SMS': 'Send SMS',
  Redirects: 'Redirects',
  'Call Forwardings': 'Call Forwardings',
  Publish: 'Publish',
  Back: 'Back',
  Hangups: 'Hangups',
  SendEmail: 'SendEmail',
  SendSMS: 'SendSMS',
  TimeCondition: 'TimeCondition',
  AriQueue: 'AriQueue',
  'Unique field': 'Campo único',
  'Announcement configuration': 'Announcement configuration',
  'Ivr configuration': 'Ivr configuration',
  'Crossroad configuration': 'Crossroad configuration',
  'TimeCondition configuration': 'TimeCondition configuration',
  'SendEmail configuration': 'SendEmail configuration',
  'SendSMS configuration': 'SendSMS configuration',
  'AriQueue configuration': 'AriQueue configuration',
  'Hangup configuration': 'Hangup configuration',
  'Redirect configuration': 'Redirect configuration',
  'Call Forwarding configuration': 'Call Forwarding configuration',
  CallForwarding: 'CallForwarding',
  on: 'on',
  at: 'at',
  'Edit/Update user': 'Edit/Update user',
  'Use this dialog window to edit/update a user':
    'Use this dialog window to edit/update a user',
  'Use this dialog window to add a new user':
    'Use this dialog window to add a new user',
  'Load Phones': 'Load Phones',
  'In Progress': 'In Progress',
  Desactivate: 'Desactivate',
  Pendings: 'Pendientes',
  completed: 'completed',
  'No answer': 'No answer',
  'New schedule': 'New schedule',
  Robinson: 'Robinson',
  Rejected: 'Rejected',
  Answerphone: 'Answerphone',
  Congestion: 'Congestion',
  Invalid: 'Invalid',
  'Hide details': 'Hide details',
  'Show details': 'Show details',
  'Dont call': 'Dont call',
  Reschedule: 'Reschedule',
  'Field deleted': 'Field deleted',
  'Could not delete field': 'Could not delete field',
  'Configure reschedules': 'Configure reschedules',
  'Dialerbot reschedules configuration': 'Dialerbot reschedules configuration',
  'Select the number of call retries:': 'Select the number of call retries:',
  'Select the percentage reserved of relaunches:':
    'Select the percentage reserved of relaunches:',
  'Select the call results for reschedules and the delay for each:':
    'Select the call results for reschedules and the delay for each:',
  Attempts: 'Attempts',
  relaunches: 'relaunches',
  'delay(min)': 'delay(min)',
  Attended: 'Attended',
  Unanswered: 'Unanswered',
  Answeringmachine: 'Answeringmachine',
  Day: 'Day',
  Month: 'Month',
  Year: 'Year',
  'Next step': 'Next step',
  'The number must be  equal to larger than to 1':
    'The number must be  equal to larger than to 1',
  'The field is required': 'The field is required',
  'Are you sure to remove the following?':
    'Are you sure to remove the following?',
  'The final week day cannot be less than the initial week day':
    'The final week day cannot be less than the initial week day',
  'The final day cannot be less than the initial day':
    'The final day cannot be less than the initial day',
  'The final hour cannot be less than the initial hour':
    'The final hour cannot be less than the initial hour',
  'The initial hour cannot be less than the final hour':
    'The initial hour cannot be less than the final hour',
  'The file you are trying to upload is already uploaded':
    'The file you are trying to upload is already uploaded',
  'Select a time range': 'Select a time range',
  'Add existing time range': 'Add existing time range',
  'Add temporal range': 'Add temporal range',
  'You have to select one temporal range':
    'You have to select one temporal range',
  'The temporal range already exists: ': 'The temporal range already exists: ',
  'Select roles...': 'Select roles...',
  'Outbound Routes': 'Outbound Routes',
  'Add number restrictions': 'Add number restrictions',
  'Create new outbound route': 'Create new outbound route',
  'Add number restriction': 'Add number restriction',
  'Create new number restriction': 'Create new number restriction',
  Length: 'Length',
  'Start with': 'Start with',
  'Length must be a number': 'Length must be a number',
  'The length is required': 'The length is required',
  'The start with is required': 'The start with is required',
  'The number restriction was successfully added':
    'The number restriction was successfully added',
  'Update number restriction': 'Update number restriction',
  'The number restriction was successfully updated':
    'The number restriction was successfully updated',
  'The ddi is required': 'The ddi is required',
  'The ddi must be a number': 'The ddi must be a number',
  'The route was successfully created': 'The route was successfully created',
  'The route was sucessfully enabled': 'The route was sucessfully enabled',
  'The route was sucessfully disabled': 'The route was sucessfully disabled',
  'The route was successfully updated': 'The route was successfully updated',
  'At least one of the fields is required':
    'At least one of the fields is required',
  BETWEEN: 'Between',
  GREATER_THAN: 'Greater than',
  GREATER_THAN_OR_EQUALS: 'Greater than or equals',
  LESS_THAN: 'Less than',
  LESS_THAN_OR_EQUALS: 'Less than or equals',
  EQUALS: 'Equals',
  NOT_EQUALS: 'Not equals',
  CONTAINS: 'Contains',
  equals: 'equals',
  'not equals': 'not equals',
  'greater than': 'greater than',
  'greater than or equals': 'greater than or equals',
  'less than': 'less than',
  'less than or equals': 'less than or equals',
  between: 'between',
  'not null': 'not null',
  contains: 'contains',
  companyId: 'Company',
  callMode: 'Call mode',
  callResult: 'Call result',
  recorded: 'Recorded',
  originNumber: 'Origin number',
  destinationNumber: 'Destination number',
  creationDate: 'Creation date',
  ddi: 'DDI',
  callDuration: 'Call duration',
  overallDuration: 'Overall duration',
  campaignDataCampaignId: 'Campaign',
  serviceDataId: 'Service',
  contactDataContactId: 'Contact',
  agentDataId: 'Agent',
  'Add caller id': 'Add caller id',
  'Select caller id': 'Select caller id',
  Prefix: 'Prefix',
  'The prefix must be numeric': 'The prefix must be numeric',
  'The caller id is already added': 'The caller id is already added',
  'Sort ascending': 'Sort ascending',
  'Sort descending': 'Sort descending',
  'Search email': 'Search email',
  'Copy user': 'Copy user',
  'Edit user': 'Edit user',
  'Delete user': 'Delete user',
  campaignDataName: 'Campaign',
  agentDataName: 'Agent',
  routeDataName: 'Inbound Route',
  serviceDataName: 'Service',
  waitingTime: 'Waiting time',
  nonDisplayedName: '',
  username: 'username',
  date: 'date',
  firstLogin: 'first Login',
  lastLogin: 'last Login',
  firstLogout: 'first Logout',
  lastLogout: 'last Logout',
  formattedDuration: 'formatted Duration',
  Filters: 'Filters',
  'Massive SMS': 'Massive SMS',
  'Select existing contacts': 'Select existing contacts',
  'Create new file': 'Create new file',
  'Use this dialog window to add dialerbot file':
    'Use this dialog window to add dialerbot file',
  'Create File': 'Create File',
  'Call until contact': 'Call until contact',
  'Call all PhoneNumbers': 'Call all PhoneNumbers',
  'Tag field is required': 'Tag field is required',
  'Select contact tags...': 'Select contact tags...',
  'Contact tags': 'Contact tags',
  'Add contact tags': 'Add contact tags',
  'Add contact fields': 'Add contact fields',
  'Contact field is required': 'Contact field is required',
  'Select phone contact fields...': 'Select phone contact fields...',
  'Phone contact fields': 'Phone contact fields',
  'Add or update contacts': 'Add or update contacts',
  'Server connection error': 'Server connection error',
  'Add numbers': 'Add numbers',
  'Dialerbot type': 'Dialerbot type',
  'The Inbound Route field is required': 'The Inbound Route field is required',
  'The min value is 1': 'The min value is 1',
  'The max value is 100': 'The max value is 100',
  'The value must be numeric': 'The value must be numeric',
  'The min values is 5': 'The min values is 5',
  'Default time zone': 'Default time zone',
  'Time zone': 'Time zone',
  'Change time zone': 'Change time zone',
  'Record percentage': 'Record percentage',
  'AI Models': 'AI Models',
  'Here you can find the models created to be used in the NLU node':
    'Here you can find the models created to be used in the NLU node',
  'Create AI MODEL': 'Create AI MODEL',
  'Create new AI MODEL': 'Create new AI MODEL',
  'Use this dialog window to add new AI MODEL':
    'Use this dialog window to add new AI MODEL',
  'Write here the AI model name': 'Write here the AI model name',
  'Write here a description...': 'Write here a description...',
  Modify: 'Modify',
  Schedule: 'Schedule',
  'Copy AI MODEL': 'Copy AI MODEL',
  'Edit AI MODEL': 'Edit AI MODEL',
  'Delete AI MODEL': 'Delete AI MODEL',
  'Edit one or several AI MODEL': 'Edit one or several AI MODEL',
  'Delete one or several AI MODELS': 'Delete one or several AI MODELS',
  'You must enter at least one model': 'You must enter at least one model',
  'The AI MODEL was successfully registered':
    'The AI MODEL was successfully registered',
  'No AI MODEL selected': 'No AI MODEL selected',
  'Write the intention name here...': 'Write the intention name here...',
  'Answering machine detection': 'Answering machine detection',
  'Add label': 'Add label',
  Label: 'Label',
  'Update AI MODEL': 'Update AI MODEL',
  'Type intention name': 'Type intention name',
  'First edit the intention opened': 'First edit the intention opened',
  'First save the intention name': 'First save the intention name',
  'You have to write one intention': 'You have to write one intention',
  'You have to write one tag in every intention':
    'You have to write one tag in every intention',
  'The priority was updated successfully':
    'The priority was updated successfully',
  'Priority Hight': 'Priority Hight',
  'Priority Medium': 'Priority Medium',
  'Priority Low': 'Priority Low',
  Language: 'Language',
  Example: 'Example',
  'Error, please contact the administrator.':
    'Error, please contact the administrator.',
  'Could not detect delimiter automatically.':
    'Could not detect delimiter automatically.',
  'You have selected': 'You have selected',
  file: 'file',
  'Drag & drop files here': 'Drag & drop files here',
  'Data was updated successfully': 'Data was updated successfully',
  'Progressive configuration': 'Progressive configuration',
  'Use this dialog window to add a new Predictive file':
    'Use this dialog window to add a new predictive file',
  'Use this dialog window to add a new Progressive file':
    'Use this dialog window to add a new progressive file',
  'Use this dialog window to add a new DialerBot file':
    'Use this dialog window to add a new DialerBot file',
  'The Predictive execution depends on configuration!':
    'The Predictive execution depends on configuration!',
  'The Progressive execution depends on configuration!':
    'The Progressive execution depends on configuration!',
  CREATE_NEW_COMPANY: 'Create new Company',
  'Update a company': 'Update a company',
  'Use this dialog window to update a company':
    'Use this dialog window to update a company',
  'Update company': 'Update company',
  'Create company': 'Create company',
  Licenses: 'Licenses',
  'Number of licenses': 'Number of licenses',
  'Use this dialog window to create a new company':
    'Use this dialog window to create a new company',
  Extensions: 'Extensions',
  'Displayed Extensions': 'Displayed Extensions',
  'Typology for maximum rescheduling achieved':
    'Typology for maximum rescheduling achieved',
  'Ticket sub-typologies': 'Ticket sub-typologies',
  'Add ticket sub-typologies to configure a relaunch':
    'Add ticket sub-typologies to configure a relaunch',
  'Sub-typologies': 'Sub-typologies',
  'Create campaign': 'Create campaign',
  'Update campaign': 'Update campaign',
  'Use this dialog window to create a campaign':
    'Use this dialog window to create a campaign',
  'Use this dialog window to update a campaign':
    'Use this dialog window to update a campaign',
  'End date': 'End date',
  MOH_TITLE: 'Music On Hold',
  MOH_SUBTITLE: 'Manage Music On Hold',
  MOH_CREATE_BUTTON: 'Create Music On Hold',
  MOH_CREATE_BUTTON_NEW: 'Create new queue Music On Hold',
  MOH_CREATE_TITLE: 'Create new Music On Hold',
  MOH_CREATE_SUBTITLE: 'Use this dialog window to update a Music On Hold',

  MOH_UPDATE_TITLE: '',
  MOH_UPDATE_SUBTITLE: '',

  MOH_COMPANY: 'Company',
  MOH_NAME: 'Name',
  MOH_ORDER: 'Order',
  MOH_AUDIOS: 'Audios',
  MOH_ADD_NEW: 'Add new Announcement',
  MOH_CANCEL_BUTTON: 'Cancel',
  MOH_UPDATE_BUTTON: 'Update',
  MOH_SUCCESS_CREATE_MESSAGE: 'Music On Hold successfully created',
  MOH_SUCCESS_UPDATE_MESSAGE: 'Music On Hold successfully updated',
  MOH_DELETE_BUTTON: 'Remove Music On Hold',
  MOH_COMPANY_REQUIRED: 'The Company field is required',
  MOH_NAME_REQUIRED: 'The Name field is required',
  MOH_ORDER_REQUIRED: 'The Order field is required',
  MOH_AUDIOS_REQUIRED: 'The Audios field is required',
  Random: 'Random',
  Sequential: 'Sequential',

  'The start date cannot be later than the end date':
    'The start date cannot be later than the end date',
  'Repeat every year': 'Repeat every year',
  'Punctual day': 'Punctual day',
  To_hasta: 'To',
  'Create and enable': 'Create and enable',
  'New WhatspApp Account': 'New WhatspApp Account',
  'Edit WhatspApp Account': 'Edit WhatspApp Account',
  'Use this dialog window to create new WhatspApp Account':
    'Use this dialog window to create new WhatspApp Account',
  'Use this dialog window to edit WhatspApp Account':
    'Use this dialog window to edit WhatspApp Account',
  'Account details': 'Account details',
  'Provider details': 'Provider details',
  'Name of Username': 'Name of Username',
  'The username field is required': 'The username field is required',
  JOIN: 'Join',
  'Use this dialog window to mange the META templates':
    'Use this dialog window to mange the META templates',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (@, !, #, %, &, *)':
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (@, !, #, %, &, *)',
  'Password cannot contain username': 'Password cannot contain username',
  'Show password': 'Show password',
  'Use this dialog window to mange the templates':
    'Use this dialog window to mange the templates',
  'Templates from': 'Templates from',
  'Search template here...': 'Search template here...',
  'Show disabled': 'Show disabled',
  'WhatsApp State': 'WhatsApp State',
  'New webchat style': 'New webchat style',
  'Use this dialog window to create new webchat style':
    'Use this dialog window to create new webchat style',
  'Template Name': 'Template Name',
  Category: 'Category',
  Message: 'Message',
  Marketing: 'Marketing',
  Utility: 'Utility',
  'Custom Message': 'Custom Message',
  'Send promo offers, product offers more to increase awareness and engagement.':
    'Send promo offers, product offers more to increase awareness and engagement.',
  'Send account updates, order updates, alerts and more to share important information.':
    'Send account updates, order updates, alerts and more to share important information.',
  'Send promotional offers, announcements and more to increase awareness and engagement.':
    'Send promotional offers, announcements and more to increase awareness and engagement.',
  'Header (Optional)': 'Header (Optional)',
  'Header text': 'Header text',
  'Add a 60 character title to your message. Only one variable is supported in the header.':
    'Add a 60 character title to your message. Only one variable is supported in the header.',
  'Define sample variable value for header:':
    'Define sample variable value for header:',
  Body: 'Body',
  'Enter the text for your message in the language you have selected.':
    'Enter the text for your message in the language you have selected.',
  'Define sample variable value for body:':
    'Define sample variable value for body:',
  'Footer text (Optional)': 'Footer text (Optional)',
  'Hide sample templates': 'Hide sample templates',
  'Join Videocall': 'Join Videocall',
  'Add variable': 'Add variable',
  'Conversation preview:': 'Conversation preview:',
  'WhatsApp with': 'WhatsApp with',
  'The keyword already exists': 'The keyword already exists',
  'The keyword group was created succesfully':
    'The keyword group was created succesfully',
  'The keyword group was updated succesfully':
    'The keyword group was updated succesfully',
  'Update Keywords group': 'Update Keywords group',
  'Create a keywords group': 'Create a keywords group',
  'The role is in use:': 'The role is in use:',
  INBOX_LOADED: 'Loaded',
  'The App number is required': 'The App number is required',
  'The API key is required': 'The API key is required',
  'Displayed extension': 'Displayed extension',
  'Create extensions from range': 'Create extensions from range',
  'Status Name': 'Status Name',
  'Status Type': 'Status Type',
  'Status Color': 'Status Color',
  'Add new status': 'Add new status',
  AdministrativeTime: 'Administrative Time',
  'The company was successfully created':
    'The company was successfully created',
  'The extensions could not be created': 'The extensions could not be created',
  'The company could not be created': 'The company could not be created',
  'The company was deleted:': 'The company was deleted:',
  'The company was successfully updated':
    'The company was successfully updated',
  'The company could not be updated': 'The company could not be updated',
  'This field is required': 'This field is required',
  'This title is required': 'This title is required',
  'updated successfully': 'updated successfully',
  'created successfully': 'created successfully',
  'The company was disabled:': 'The company was disabled:',
  'The WhatsApp Account was sucessfully created':
    'The WhatsApp Account was sucessfully created',
  'The WhatsApp Account was sucessfully updated':
    'The WhatsApp Account was sucessfully updated',
  'The WhatsApp Account was deleted:': 'The WhatsApp Account was deleted:',
  'Use this dialog window to add a new': 'Use this dialog window to add a new',
  'Failed upload': 'Failed upload',
  'External Action': 'External Action',
  None: 'None',
  'The extension cannot have only white spaces':
    'The extension cannot have only white spaces',
  'Add extensions to selected groups': 'Add extensions to selected groups',
  'The campaign was sucessfully deleted':
    'The campaign was sucessfully deleted',
  'You must select at least one day': 'You must select at least one day',
  'Campaign created succesfully': 'Campaign created succesfully',
  'Service updated succesfully': 'Service updated succesfully',
  'Campaign updated succesfully': 'Campaign updated succesfully',
  'Videoroom could not be created': 'Videoroom could not be created',
  'Videoroom created successfully': 'Videoroom created successfully',
  'Enable one company': 'Enable one company',
  'Disable one company': 'Disable one company',
  'Enable one or several companies': 'Enable one or several companies',
  'Disable one or several companies': 'Disable one or several companies',
  'The company was enabled successfully':
    'The company was enabled successfully',
  'The company was disabled successfully':
    'The company was disabled successfully',
  'You must select at least one company':
    'You must select at least one company',
  'The company is already enabled': 'The company is already enabled',
  'The company is already disabled': 'The company is already disabled',
  'You cannot delete an active company': 'You cannot delete an active company',
  'Edit company': 'Edit company',
  'Search external action name here...': 'Search external action name here...',
  'Delete External Actions': 'Delete External Actions',
  'Copy External Actions': 'Copy External Actions',
  'Delete External Action': 'Delete External Action',
  'Edit External Action': 'Edit External Action',
  'Copy External Action': 'Copy External Action',
  Type: 'Type',
  'Create new External Action': 'Create new External Action',
  'Use this dialog window to add a new external action':
    'Use this dialog window to add a new external action',
  'External Action name is required': 'External Action name is required',
  'Contact Group is requiered to create External Action':
    'Contact Group is requiered to create External Action',
  'External Action type is required': 'External Action type is required',
  'Update External Action': 'Update External Action',
  'Use this dialog window to update a external action':
    'Use this dialog window to update a external action',
  'Write here the extrenal action name': 'Write here the extrenal action name',
  'Contact Group': 'Contact Group',
  'Write here the URL for the external action':
    'Write here the URL for the external action',
  'Url name variables': 'Url name variables',
  'Type $ to see the available variables.':
    'Type $ to see the available variables.',
  'External Actions': 'External Actions',
  'All aspects related to external actions can be managed from this page':
    'All aspects related to external actions can be managed from this page',
  'Add external action': 'Add external action',
  'The external action was successfully ':
    'The external action was successfully ',
  ' updated': ' updated',
  ' created': ' created',
  'The external action could not be': 'The external action could not be',
  'The external action': 'The external action',
  ' could not be deleted': ' could not be deleted',
  ' was successfully deleted': ' was successfully deleted',
  'The External Action ': 'The External Action ',
  ' was successfully cloned': ' was successfully cloned',
  ' could not be cloned': ' could not be cloned',
  'Are you sure to remove ': 'Are you sure to remove ',
  ' items?': ' items?',
  VIDEOCALLS_TALKING: 'In call',
  'You don"t have announcements permissions':
    'You don"t have announcements permissions',
  'Scan QR code': 'Scan QR code',
  'Enter the 6-digit authentication code.':
    'Enter the 6-digit authentication code.',
  'Create External Action': 'Create External Action',
  'Enter authenticator code': 'Enter authenticator code',
  'Configure Two-Factor Authentication': 'Configure Two-Factor Authentication',
  'Two-Factor Authentication has been successfully configured.':
    'Two-Factor Authentication has been successfully configured.',
  'Videocall has finished or it does not exist':
    'Videocall has finished or it does not exist',
  "The videoroom is full, you can't join":
    "The videoroom is full, you can't join",
  'Contact not added': 'Contact not added',
  'Add group': 'Add group',
  'Upload contacts': 'Upload contacts',
  'Create new tag': 'Create new tag',
  'Create new field': 'Create new field',
  'La petición se realizará desde la IP ':
    'The request will be made from the IP ',
  'recuerde permitirla en su firewall. Sólo se permite realizar peticiones a los puertos 80 o 443':
    'remember to allow it in your firewall. Only requests to ports 80 or 443 are allowed',
  'No Email account selected': 'No Email account selected',
  'Configure two-factor authentication (2FA) on next login':
    'Configure two-factor authentication (2FA) on next login',
  'Force the use of 2FA for all users': 'Force the use of 2FA for all users',
  'The selected email account is already':
    'The selected email account is already',
  'Authentication status': 'Authentication status',
  Protocol: 'Protocol',
  'Conversation tickets': 'Conversation tickets',
  'Status enable': 'Status enable',
  'Delete account': 'Delete account',
  'Edit Account': 'Edit Account',
  'Disable one or several email accounts':
    'Disable one or several email accounts',
  'Enable one or several email accounts':
    'Enable one or several email accounts',
  'Update email signature': 'Update email signature',
  'Create new email signature': 'Create new email signature',
  'Use this dialog window to update a email signature':
    'Use this dialog window to update a email signature',
  'Use this dialog window to add a new email signature':
    'Use this dialog window to add a new email signature',
  'Email signature name is required': 'Email signature name is required',
  'Contact Group is requiered to create email signature':
    'Contact Group is requiered to create email signature',
  'Write here the email signature name': 'Write here the email signature name',
  'Write here the email signature in ': 'Write here the email signature in ',
  'Create email signature': 'Create email signature',
  TEXT: 'TEXT',
  'Delete one o several email accounts': 'Delete one o several email accounts',
  'Activation Status': 'Activation Status',
  'User name': 'User name',
  'Verify account': 'Verify account',
  'Enable one or several telegram accounts':
    'Enable one or several telegram accounts',
  'Disable one or several telegram accounts':
    'Disable one or several telegram accounts',
  'No Telegram account selected': 'No Telegram account selected',
  'The selected telegram account is already':
    'The selected telegram account is already',
  'Delete one o several telegram accounts':
    'Delete one o several telegram accounts',
  'Ticket tags must have a name': 'Ticket tags must have a name',
  'Service ticket tags': 'Service ticket tags',
  'Click to add a new tag': 'Click to add a new tag',
  'Add tag': 'Add tag',
  'Add tags': 'Add tags',
  'Tag name': 'Tag name',
  'Ticket tags configuration': 'Ticket tags configuration',
  'The template was created successfully':
    'The template was created successfully',
  'Conversation History': 'Conversation History',
  TYPE_DOLLAR_TO_VARIABLES: 'Type $ to see the available variables.',
  'All aspects related to the email can be managed here':
    'All aspects related to the email can be managed here',
  'Email Account': 'Email Account',
  'Email Signature': 'Email Signature',
  'Add Email Account': 'Add Email Account',
  'Add Email Signature': 'Add Email Signature',
  'The mesage template was successfully sent':
    'The mesage template was successfully sent',
  'Refresh view': 'Refresh view',
  French: 'French',
  Germany: 'Germany',
  English: 'English',
  Spanish: 'Spanish (Spain)',
  MexicanSpanish: 'Spanish (Mexico)',
  ColombianSpanish: 'Spanish (Colombia)',
  PeruvianSpanish: 'Spanish (Peru)',
  ChileanSpanish: 'Spanish (Chile)',
  'Unknown name': 'Unknown name',
  'The report': 'El reporte',
  'Start a new conversation from this ticket!':
    'Start a new conversation from this ticket!',
  'Revoke 2FA': 'Revoke 2FA 2FA',
  'Temporal ranges': 'Temporal ranges',
  'Ticket creation date': 'Ticket creation date',
  'Ticket modification date': 'Ticket creation date',
  'Ticket id': 'Ticket id',
  'Add a temporal range': 'Add a temporal range',
  'has been generated succesfully!': 'has been generated succesfully!',
  'could not be generated!': 'could not be generated!',
  'The is no approved templates': 'The is no approved templates',
  'These are the extensions': 'These are the extensions',
  'These are the extensions groups': 'These are the extensions groups',
  'Extensions Groups': 'Extensions Groups',
  'Create extensions groups': 'Create extensions groups',
  Approve: 'Approve',
  'The WhatsApp templates was successfully updated':
    'The WhatsApp templates was successfully updated',
  'The message template was successfully sent':
    'The message template was successfully sent',
  'remember to allow it in your firewall. Only requests to ports 80 or 443 are allowed':
    'remember to allow it in your firewall. Only requests to ports 80 or 443 are allowed',
  'Thank you for attending!': 'Thank you for attending!',
  'You have been rejected...': 'You have been rejected...',
  'You have been kicked out of the video call':
    'You have been kicked out of the video call',
  'Add new time range': 'Add new time range',
  'The videoroom is full': 'The videoroom is full',
  'The video call has already ended or does not exist':
    'The video call has already ended or does not exist',
  'Could not connect with videocalls': 'Could not connect with videocalls',
  'The templates could not be loaded': 'The templates could not be loaded',
  Downloads: 'Downloads',
  'All aspects related to the downloads can be managed here':
    'All aspects related to the downloads can be managed here',
  'Add account': 'Add account',
  'Add style': 'Add style',
  'Add pre written message': 'Add pre written message',
  'Delete Field': 'Delete Field',
  'Add button': 'Add button',
  'The text is required': 'The text is required',
  'Use this dialog window to create new whatsapp template':
    'Use this dialog window to create new whatsapp template',
  'You must add at least one button': 'You must add at least one button',
  Services: 'Services',
  'Use this dialog window to create a new service':
    'Use this dialog window to create a new service',
  buttons: 'buttons',
  'Here are the roles and permissions': 'Here are the roles and permissions',
  'Add custom role': 'Add custom role',
  'Create new custom role': 'Create new custom role',
  'Edit role': 'Edit role',
  'Use this dialog window to edit a role':
    'Use this dialog window to edit a role',
  'Use this dialog window to add a new role':
    'Use this dialog window to add a new role',
  'All aspects related to the tickets can be managed here':
    'All aspects related to the tickets can be managed here',
  'Add typologies': 'Add typologies',
  'Add ticket': 'Add ticket',
  'Add fields': 'Add fields',
  Typologies: 'Typologies',
  Fields: 'Fields',
  Clear: 'Clear',
  'Write here the time group description...':
    'Write here the time group description...',
  'Stop sharing screen': 'Stop sharing screen',
  'Share screen': 'Share screen',
  'Route cloned succesfully': 'Route cloned succesfully',
  'The Route was not deleted': 'The Route was not deleted',
  'The Route was not disabled.': 'The Route was not disabled.',
  'Text cannot be just blank spaces': 'Text cannot be just blank spaces',
  'Move Down': 'Move Down',
  'Move Up': 'Move Up',
  'Last email date': 'Last email date',
  'Email account address': 'Email account address',
  'Last email sender': 'Last email sender',
  'Last email subject': 'Last email subject',
  'Role name': 'Role name',
  'Create new contact': 'Create new contact',
  'Empty if visible by anyone': 'Empty if visible by anyone',
  'Select a valid a channel': 'Select a valid a channel',
  'Start a new conversation': 'Start a new conversation',
  'Create new account': 'Create new account'
};

export default enJSON;
