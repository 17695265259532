export interface EmailSignature {
  companyId: string;
  contactGroupId: string;
  content: string;
  id?: string;
  name: string;
}

export interface EmailSignatureParams {
  companyId?: string;
  contactGroupId?: string;
  id?: string;
  name?: string;
  page?: number;
  size?: number;
}

export type EmailSignatureVariable = {
  id: string;
  description: string;
  name: string;
};

export const emailAcdVariables = [
  'lastEmailTimestamp',
  'emailAccountAddress',
  'lastEmailFrom',
  'lastEmailSubject'
];

export const emailSignaturesMock = [
  {
    id: 'O_agentName',
    name: 'Nombre del usuario',
    description: 'Nombre del usuario'
  },
  {
    id: 'O_campaignId',
    name: 'Campaña',
    description: 'Campaña'
  },
  {
    id: 'O_serviceId',
    name: 'Servicio',
    description: 'Servicio'
  }
] as EmailSignatureVariable[];
