import { Box, Button, CircularProgress, Divider, Stack } from '@mui/material';
import { t } from 'i18next';
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import TicketForm from './tickets/form/TicketForm';
import HelperAccordion from '../../../../../../../Dashboard/views/Inbox/HelperPanel/components/HelperAccordion';
import TicketConversations from './tickets/conversations/TicketConversations';
import TicketHistory from './tickets/changeHistory/TicketHistory';
import TicketComments from './tickets/comments/TicketComments';
import { Conversation } from 'src/models/conversations/conversations';
import { useAlert } from 'src/hooks/useAlert';
import { logger } from 'src/utils/logger';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { TicketsDomain } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/TicketsDomain';
import TicketData from './TicketData';
import {
  Ticket,
  TicketAttachment,
  TicketAttachmentFile,
  TicketSubResolution,
  TicketType
} from '../../models/tickets';
import TicketAttachments from './tickets/attachments/TicketAttachments';
import { User } from 'src/models/users';
import UsersDomain from 'src/services/users/domain/UserDomain';
import { getTicketInvolvedUserIds } from '../utils/getTicketInvolvedUserIds';
import { TicketContactLimitReached } from './TicketContactLimitReached';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreateTicketPermissions,
  checkDeleteTicketPermissions,
  checkUpdateTicketPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import ShowFlowTicketViewer from './showFlow';
import ShowflowWithverticalAccordion from './showFlow/components/ShowflowWithVerticalAccordion';
import { queryClient } from 'src/utils/queryClient';
import ModalView from 'src/components/Modal';
import ScheduleCalendarForm from 'src/modules/Dashboard/views/Inbox/HelperPanel/ScheduleCalendarPanel/ScheduleCalendarForm';
import Scrollbar from 'src/components/Scrollbar';
import AuthManager from 'src/services/authentication/manager';
import { useServiceByIdQuery } from 'src/services/users/application/query/getUsersDataQueries';
import { DaServiceModifyClosedTypes } from '../../../DaServices/models/daService';
import { checkIsOpenTicket } from '../../domain/checkIsOpenTicket';

interface Props {
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
  ticket?: Ticket;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
  onSubmit?: (params: {
    ticket: Ticket;
    firstRescheduleTimestamp?: string;
  }) => Promise<Ticket>;
  onDelete?: (ticket: Ticket) => Promise<void>;
  conversation?: Conversation;
}

const TicketDataView = (props: Props) => {
  const {
    onClose,
    ticket: ticketProps,
    setTicket: setTicketProps,
    onSubmit,
    onDelete,
    conversation
  } = props;
  const { hasAccess } = useContext(PermissionsContext);

  const [isScheduleOpen, setIsScheduleOpen] = useState<boolean>(false);
  const [isScheduleDone, setIsScheduleDone] = useState(false);
  const [isFirstManualReschedule, setIsFirstManualReschedule] = useState(false);
  const isRescheduled =
    (isFirstManualReschedule && isScheduleDone) || !isFirstManualReschedule;
  const isAllowedToSubmitTicket =
    ((ticketProps?.id && hasAccess(checkUpdateTicketPermissions)) ||
      (!ticketProps?.id && hasAccess(checkCreateTicketPermissions))) &&
    isRescheduled;
  const isAllowedToDeleteTicket = hasAccess(checkDeleteTicketPermissions);

  // If setTicket prop, setTicket is assigned to a new setTicket constant
  // If no setTicket prop, a new state is created and assigned to the new setTicket constant,
  const [ticket, setTicket] = setTicketProps
    ? [ticketProps, setTicketProps]
    : useState<Ticket>(ticketProps);

  const [allInvolvedUsers, setAllInvolvedUsers] = useState<User[]>([]);

  const [ticketFiles, setTicketFiles] = useState<TicketAttachmentFile[]>([]);

  const companyId = ticket?.companyId
    ? conversation?.companyId
    : AuthManager.getLoggedUserCompanyId();
  const serviceId = ticket?.serviceId ?? conversation?.serviceId;
  const service = useServiceByIdQuery(serviceId).data;
  const conversationContactIds =
    conversation?.involvedContacts
      .map((contact) => contact.contactId)
      .filter((contactId) => !!contactId) ?? [];

  const isClosedTicket = !checkIsOpenTicket(ticket);
  const isNotAllowedToModifyClosed =
    ticket?.id &&
    isClosedTicket &&
    service?.modifyClosed === DaServiceModifyClosedTypes.NO;

  /**
   * When a ticket is created from the Helper Panel, the ticket is added to the conversation automatically (the user does not add the ticket).
   * So, if there is no ticket (is creating a ticket) and there is conversation (the ticket will be added to the conversation after being created),
   * initially isAllowedToCreateEditTicket is set to false, otherwise to true.
   * If isAllowedToCreateEditTicket, the form will be rendered, otherwise a fallback component.
   */
  const [isAllowedToCreateEditTicket, setIsAllowedToCreateEditTicket] =
    useState(() => {
      return !ticketProps && conversation && conversationContactIds.length > 0
        ? false
        : true;
    });
  const [isLoadingContactsLimit, setIsLoadingContactsLimit] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  /** isValidForm is used to disable the submit button */
  const [isValidForm, setIsValidForm] = useState(false);

  const { showAlert } = useAlert();

  const previousTicketRef = useRef(ticketProps);

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  //resize observer
  useEffect(() => {
    const observerCallback: ResizeObserverCallback = (
      entries: ResizeObserverEntry[]
    ) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        for (let entry of entries) {
          setWidth(entry.contentRect.width);
        }
      });
    };
    const resizeObserver = new ResizeObserver(observerCallback);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  const direction = width > 900 ? 'row' : 'column';

  const onSubmitTicketComment = async (comment: string) => {
    const ticketComment = await TicketsDomain.addTicketComment(
      comment,
      ticketProps
    );
    return ticketComment;
  };

  const onChangeTicketSubResolution = (
    _subResolution: TicketSubResolution,
    isFirstManualReschedule: boolean
  ) => {
    if (isFirstManualReschedule) {
      setIsFirstManualReschedule(true);
      setIsScheduleOpen(true);
    }
  };

  const updateTicketAttachments = async () => {
    if (
      previousTicketRef.current?.id &&
      (ticketFiles.length > 0 ||
        previousTicketRef.current.attachments.length > 0)
    ) {
      let filesToAdd: TicketAttachmentFile[] = [];
      let filesToDelete: TicketAttachment[] = [];

      // ticketFiles that are not still saved does not have an id
      // So, they are pushed to the filesToAdd array
      ticketFiles.forEach((file) => {
        if (!file.fileId) {
          filesToAdd.push(file);
        }
      });

      // attachments from the ticket before being edited (previous ticket) which are not include in ticketFiles are pushed to filesToDelete array
      previousTicketRef.current?.attachments.forEach((attachment) => {
        if (
          !ticketFiles
            .map((ticketFile) => ticketFile.fileId)
            .includes(attachment.id)
        ) {
          filesToDelete.push(attachment);
        }
      });

      const filesToAddPromises = filesToAdd.map((ticketFile) => {
        return TicketsDomain.attachTicketFile(ticketFile.file, ticket?.id);
      });

      const filesToDeletePromises = filesToDelete.map((attachment) => {
        return TicketsDomain.removeTicketAttachment({
          ticketId: ticket?.id,
          ticketAttachment: attachment
        });
      });

      const attachmentPromises = [
        ...filesToAddPromises,
        ...filesToDeletePromises
      ];

      await Promise.all(attachmentPromises);
    }
  };

  const submitTicket = async (firstRescheduleTimestamp?: string) => {
    setIsSubmitting(true);
    try {
      // Add the current conversation id to the ticket if creating a new one and there is current conversation
      const conversationIds =
        conversation && !previousTicketRef.current
          ? [conversation?.id]
          : ticket?.conversationIds;
      const newTicket: Ticket = {
        ...ticket,
        companyId,
        conversationIds,
        serviceId
      };
      if (newTicket.companyId === undefined) {
        newTicket.companyId = AuthManager.getLoggedUserCompanyId();
      }

      queryClient.invalidateQueries({
        queryKey: ['reportingTicket']
      });
      await onSubmit({
        ticket: newTicket,
        firstRescheduleTimestamp
      });
      // tableRef.current?.refetchData();
      await updateTicketAttachments();

      showAlert(
        t(`The ticket was successfully ${ticketProps ? 'updated' : 'created'}`),
        'success',
        2000
      );
      setIsSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (err) {
      logger.error(err);
      showAlert(
        `${t(
          `The ticket could not be ${ticketProps ? 'updated' : 'created'}`
        )}. ${getErrorMessage(err).errorMessage}`,
        'error',
        2000
      );
      setIsSubmitting(false);
      onClose();
    }
  };

  // Check if it is allowed to create a new ticket in order to display or not the form. If initialy true, no need to check again
  useEffect(() => {
    if (!!serviceId && !isAllowedToCreateEditTicket) {
      TicketsDomain.checkTicketContactLimit({
        contactIds: conversationContactIds,
        serviceId: serviceId
      }).then((resp) => {
        setIsLoadingContactsLimit(false);
        setIsAllowedToCreateEditTicket(resp);
      });
    }
  }, []);

  // Set all ticket data
  useEffect(() => {
    if (ticketProps && isAllowedToCreateEditTicket) {
      // If the ticket is not full, the full ticket is requested and the ticket involved users are set
      if (
        !previousTicketRef.current?.assignedUserIds ||
        !previousTicketRef.current?.changeHistory ||
        !previousTicketRef.current?.conversationSummaries ||
        !previousTicketRef.current?.involvedContacts
      ) {
        TicketsDomain.getTicketById(ticketProps?.id).then((resp) => {
          setTicket(resp);
          // Save the ticket before editing to be able to compare attributes such as ticketUserIds
          previousTicketRef.current = resp;

          // The objective is to avoid fetching the involved users from each of the children components
          const allUserIds = getTicketInvolvedUserIds(
            previousTicketRef?.current
          );

          if (allUserIds.length > 0) {
            UsersDomain.getUsersList(allUserIds).then((resp) =>
              setAllInvolvedUsers(resp)
            );
          }
        });
      } else {
        // The objective is to avoid fetching the involved users from each of the children components
        const allUserIds = getTicketInvolvedUserIds(previousTicketRef?.current);

        if (allUserIds.length > 0) {
          UsersDomain.getUsersList(allUserIds).then((resp) =>
            setAllInvolvedUsers(resp)
          );
        }
      }
    }
  }, [isAllowedToCreateEditTicket]);

  const TicketFormComponent = ({
    enableShowFlow
  }: {
    enableShowFlow: boolean;
  }) => (
    <TicketForm
      key={ticket?.id}
      ticket={ticket}
      setTicket={setTicket}
      conversation={conversation}
      setIsValidForm={setIsValidForm}
      enableShowFlow={enableShowFlow}
      direction={direction}
      onChangeTicketSubResolution={onChangeTicketSubResolution}
    />
  );

  if (isLoadingContactsLimit && !isAllowedToCreateEditTicket) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (!isAllowedToCreateEditTicket && !isLoadingContactsLimit) {
    return <TicketContactLimitReached />;
  }

  return (
    <>
      {isScheduleOpen && (
        <ModalView
          setShowModal={setIsScheduleOpen}
          title={'Call Schedule'}
          heightSize={80}
        >
          <ScheduleCalendarForm
            conversation={conversation}
            setIsScheduleOpen={setIsScheduleOpen}
            setIsScheduleDone={setIsScheduleDone}
          />
        </ModalView>
      )}
      <Stack height="100%" justifyContent="space-between" sx={{ flex: 2 }}>
        <Scrollbar>
          <Stack
            ref={ref}
            direction={direction}
            spacing={direction === 'row' ? 1 : 0}
            mx={1}
            py={1}
          >
            {!!previousTicketRef.current ? (
              <>
                {direction === 'row' ? (
                  <ShowflowWithverticalAccordion ticket={ticket} />
                ) : (
                  <>
                    <Box width={'100%'} py={1}>
                      <HelperAccordion title={t('Script')}>
                        <Box padding={1}>
                          <ShowFlowTicketViewer ticket={ticket} />
                        </Box>
                      </HelperAccordion>
                    </Box>
                  </>
                )}
                <Stack flex={1} rowGap={1}>
                  <HelperAccordion title={t('General')}>
                    <Box pt={2}>
                      {TicketFormComponent({
                        enableShowFlow: false
                      })}
                      <Box px={1.5} mb={1}>
                        <TicketAttachments
                          ticket={ticket}
                          ticketFiles={ticketFiles}
                          setTicketFiles={setTicketFiles}
                        />
                      </Box>
                    </Box>
                  </HelperAccordion>
                  <HelperAccordion title={t('Conversations')}>
                    <Box padding={1}>
                      <TicketConversations
                        key={`${conversation}-${ticket?.id}`}
                        currentConversation={conversation}
                        conversations={ticket?.conversationSummaries}
                        ticket={ticket}
                        setTicket={setTicket}
                        involvedUsers={allInvolvedUsers}
                      />
                    </Box>
                  </HelperAccordion>
                  <HelperAccordion title={t('History')}>
                    <Box padding={1}>
                      <TicketHistory
                        key={ticket?.id}
                        ticketHistory={ticket?.changeHistory}
                        involvedUsers={allInvolvedUsers}
                      />
                    </Box>
                  </HelperAccordion>
                  <HelperAccordion title={t('Comments')}>
                    <Box padding={1}>
                      <TicketComments
                        key={ticket?.id}
                        setTicket={setTicket}
                        ticketComments={ticket?.comments}
                        onSubmitTicketComment={onSubmitTicketComment}
                        involvedUsers={allInvolvedUsers}
                      />
                    </Box>
                  </HelperAccordion>
                  <TicketData ticketMetadata={ticketProps?.metadata} />
                </Stack>
              </>
            ) : (
              <Box padding={1} flex={1}>
                {TicketFormComponent({
                  enableShowFlow: true
                })}
              </Box>
            )}
          </Stack>
        </Scrollbar>
        {/* BUTTONS */}
        <Divider />
        <Stack
          px={1.5}
          py={1.5}
          width="100%"
          direction={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          {/* CANCEL BUTTON */}
          <Button
            id="cancel-ticket-btn"
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          {/* DELETE BUTTON */}
          {!!previousTicketRef.current &&
            onDelete &&
            isAllowedToDeleteTicket && (
              <Button
                id="cancel-ticket-btn"
                fullWidth
                variant="outlined"
                color="error"
                disabled={isSubmitting || !isAllowedToDeleteTicket}
                onClick={async () => {
                  setIsSubmitting(true);
                  try {
                    await onDelete(ticket);
                    setIsSubmitting(false);
                    showAlert(
                      t(`The ticket was successfully deleted`),
                      'success',
                      2000
                    );
                    onClose();
                  } catch (err) {
                    logger.error(err);
                    showAlert(
                      `${t(
                        `The ticket could not be ${
                          ticketProps ? 'updated' : 'created'
                        }`
                      )}. ${getErrorMessage(err).errorMessage}`,
                      'error',
                      2000
                    );
                    setIsSubmitting(false);
                    onClose();
                  }
                }}
              >
                {t('Delete')}
              </Button>
            )}
          {/* SUBMIT BUTTON */}
          <PermissionsTooltip hasAccess={isAllowedToSubmitTicket}>
            <Button
              id="submit-ticket-btn"
              fullWidth
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={
                isSubmitting ||
                !isValidForm ||
                !isAllowedToSubmitTicket ||
                isNotAllowedToModifyClosed
              }
              variant="contained"
              color="secondary"
              onClick={async (e) => {
                await submitTicket();
              }}
            >
              {!!previousTicketRef.current ? t('Edit') : t('Create')}
            </Button>
          </PermissionsTooltip>
        </Stack>
      </Stack>
    </>
  );
};

export default TicketDataView;
