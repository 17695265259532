import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';

interface VideoStreamContextProps {
  videoRef: MutableRefObject<HTMLVideoElement>;
  selectedCamera: MediaDeviceInfo;
  handleCameraChange: (device: MediaDeviceInfo) => void;
  isCameraOn: boolean;
  toggleCamera: () => void;
  setSelectedCamera: Dispatch<SetStateAction<MediaDeviceInfo>>;
}

export const VideoStreamContext = createContext<VideoStreamContextProps>(
  {} as VideoStreamContextProps
);

export const VideoStreamProvider: React.FC = ({ children }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo>();
  const [isCameraOn, setIsCameraOn] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);

  const handleCameraChange = async (device: MediaDeviceInfo) => {
    setSelectedCamera(device);
    try {
      const constraints: MediaStreamConstraints = {
        video: { deviceId: { exact: device.deviceId } }
      };
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(newStream);
      setIsCameraOn(true);
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const toggleCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
      setIsCameraOn(false);
    } else {
      if (selectedCamera) {
        handleCameraChange(selectedCamera);
      }
    }
  };

  return (
    <VideoStreamContext.Provider
      value={{
        videoRef,
        selectedCamera,
        handleCameraChange,
        isCameraOn,
        toggleCamera,
        setSelectedCamera
      }}
    >
      {children}
    </VideoStreamContext.Provider>
  );
};
