import axios from 'axios';
import WebRTCService, { getCoolPhone } from '../../webrtc/webrtc.service';

import wsManager from 'src/services/websocket/manager';
import { logger } from 'src/utils/logger';
import AuthManager from '../manager';
import { AUTHENTICATION_LOGOUT } from 'src/utils/apiRoutes/msApiGateway';
import { emitCustomEvent } from 'react-custom-events';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';

// extension temporal

export const logout = async () => {
  const path = AUTHENTICATION_LOGOUT;
  const token = AuthManager.getToken();

  try {
    if (!token) {
      throw new Error(
        'Token not found on cookies. Not authorized to request logout'
      );
    }
    const resp = await axios.post(path, null);

    return resp.data;
  } catch (error) {
    logger.error(error);
    // localStorage.removeItem('extensionData');
    wsManager.closeWebSocket(wsManager.getWSClient());
    const webRtcSocket = WebRTCService.getWebRtcSocket();
    //The user may not be connected to WebRTC
    if (webRtcSocket) {
      WebRTCService.closeWebSocket(webRtcSocket);
    }
  } finally {
    wsManager.closeWebSocket(wsManager.getWSClient());
    const webRtcSocket = WebRTCService.getWebRtcSocket();
    //The user may not be connected to WebRTC
    if (webRtcSocket) {
      const coolPhone = getCoolPhone();
      coolPhone?.stop();
      WebRTCService.closeWebSocket(webRtcSocket);
    }
    AuthManager.removeCompanyId();
    AuthManager.removeExpiration();
    AuthManager.removeToken();
    AuthManager.removeUserExtension();
    AuthManager.removeUserId();
    AuthManager.removeUsername();

    emitCustomEvent(CustomEventNames.LOGOUT);
  }
};
