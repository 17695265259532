import { Variable } from '../../models/models';
import { CreateUpdateVariableModal } from './CreateUpdateVariableModal';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { DialTable } from 'src/components/DialTable';
import { useVariables } from '../hooks/useVariables';
import AuthManager from 'src/services/authentication/manager';

export const VariablesTable = () => {
  const companyId = AuthManager.getLoggedUserCompanyId();
  const {
    isUpdateModalOpened,
    setIsUpdateModalOpened,
    variableToEdit,
    isDeleteModalOpened,
    variablesToDelete,
    handleDelete,
    getColumns,
    getRowActions,
    getMultiActions,
    getMultiFilters,
    getDataFn,
    setVariablesToDelete
  } = useVariables(companyId);

  return (
    <>
      <CreateUpdateVariableModal
        isOpen={isUpdateModalOpened}
        setIsOpen={setIsUpdateModalOpened}
        variable={variableToEdit}
        key={isUpdateModalOpened ? 'opened' : 'closed'}
        companyId={companyId}
      />
      <ConfirmationModal<Variable, keyof Variable>
        dataKey={'name'}
        isOpen={isDeleteModalOpened}
        data={variablesToDelete}
        onConfirm={handleDelete}
        mode={ConfirmationModalModes.DELETE}
        closeFn={() => {
          setVariablesToDelete([]);
        }}
      />
      <DialTable<Variable>
        getColumnsFn={getColumns}
        getRowActionsFn={getRowActions}
        getDataFn={getDataFn}
        getAvailableFilters={getMultiFilters}
        getBulkActionsFn={getMultiActions}
        onlyEqualsOperator
      />
    </>
  );
};
