import { DragHandle } from '@mui/icons-material';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { Column } from '@tanstack/react-table';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

interface Props<T> {
  columns: Column<T>[];
  getIsAllColumnsVisible: () => boolean;
  getToggleAllColumnsVisibilityHandler: () => (event: unknown) => void;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

export const DialTableColumnActions = <T extends Object>({
  columns,
  onDragEnd,
  getIsAllColumnsVisible,
  getToggleAllColumnsVisibilityHandler
}: Props<T>) => {
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  const getColumnLabel = (column: Column<T>) =>
    typeof column.columnDef.header === 'string'
      ? column.columnDef.header
      : column.columnDef.id;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ width: '360px' }}
          >
            <ListItem>
              <Tooltip arrow title={t('Toggle all')}>
                <Stack direction="row" width="100%">
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          maxWidth: '200px',
                          fontWeight: 'bold'
                        }}
                      >
                        {getIsAllColumnsVisible?.()
                          ? t('Disable all')
                          : t('Enable all')}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={getIsAllColumnsVisible?.()}
                        onChange={getToggleAllColumnsVisibilityHandler?.()}
                      />
                    }
                  />
                </Stack>
              </Tooltip>
            </ListItem>
            <Divider />
            {columns.map((column, index) => (
              <Draggable key={column.id} draggableId={column.id} index={index}>
                {(provided, snapshot) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                      borderRadius: 0.25,
                      display: 'flex',
                      justifyContent: 'space-between',
                      transition: theme.transitions.create(['box-shadow']),
                      boxShadow: snapshot.isDragging
                        ? `0 2rem 8rem 0 ${alpha(
                            theme.colors.alpha.black[100],
                            0.05
                          )}, 
                        0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
                        0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`
                        : undefined
                    }}
                    dense
                  >
                    <Tooltip arrow title={getColumnLabel(column)}>
                      <Stack direction="row" width="100%">
                        <FormControlLabel
                          label={
                            <Typography
                              sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: '200px'
                              }}
                            >
                              {getColumnLabel(column)}
                            </Typography>
                          }
                          control={
                            <Checkbox
                              color="secondary"
                              size="small"
                              checked={column.getIsVisible()}
                              disabled={!column.getCanHide()}
                              onChange={column.getToggleVisibilityHandler()}
                            />
                          }
                        />
                      </Stack>
                    </Tooltip>
                    <DragHandle />
                  </ListItem>
                )}
              </Draggable>
            ))}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
