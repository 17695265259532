import { LexicalComposer } from '@lexical/react/LexicalComposer';
import Editor from './components/Editor';
import { EmailItemState } from '../EmailMessageItem';
import { Stack } from '@mui/material';
import { useState } from 'react';
import useEmailEditor from './hooks/useEmailEditor';
import EmailTopbar from './components/EmailTopbar';
import './style.css';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { ImageNode } from './components/Editor/plugins/ImagePlugin/ImageNode';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $nodesOfType, LexicalEditor, SerializedEditor } from 'lexical';
import { EmailEditorMode, EmailSessionData } from './models/models';
import useEmailParser from '../../hooks/useEmailParser';
import { Conversation } from 'src/models/conversations/conversations';
import { useAlert } from 'src/hooks/useAlert';
import { t } from 'i18next';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ReplyingToNode } from './components/Editor/nodes/ReplyingToNode';
import { emitCustomEvent } from 'react-custom-events';
import setAutoselectNextConversationDomain from 'src/services/socialmedia/domain/conversation/setAutoselectNextConversationDomain';
import { APISendEmailFileByCampaignParams } from 'src/services/email/infrastructure/message/sendEmailApi';
import { sendEmail } from 'src/services/email/domain/message/sendEmailMessage';
import { getCampaignByIdQuery } from 'src/services/users/application/query/getUsersDataQueries';
import { SocialmediaOutboundCampaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { SignatureNode } from './components/Editor/nodes/SignatureNode';
import useEmailSignature from './hooks/useEmailSignature';

interface Props {
  config: EmailItemState;
  setConfig: React.Dispatch<React.SetStateAction<EmailItemState>>;
  initialEmailTo?: string[];
  conversation?: Conversation;
  onClose?: () => void;
}

function EmailEditor({
  config,
  setConfig,
  conversation,
  initialEmailTo,
  onClose
}: Props) {
  const { editorInfo } = config;
  const { messageToReply } = editorInfo;
  const { htmlMessage: parsedMessageToReply } = useEmailParser(messageToReply);

  const currentConversation = conversation ?? getCurrentConversation(false);

  const { message, setMessage } = useEmailEditor({
    config: config.editorInfo,
    conversation: currentConversation,
    initialEmailTo
  });

  const [campaignId, setCampaignId] = useState<string | undefined>(undefined);
  const { parseSignatureFn, parsedSignature } = useEmailSignature({
    campaignId,
    conversation
  });

  const { showAlert } = useAlert();

  function handleClose() {
    setConfig((state) => {
      return {
        ...state,
        isEditorOpen: false
      };
    });
    onClose?.();
  }

  const onExternalButtonClick = (serializedEditor: SerializedEditor) => {
    const url = '/send-email';

    const dataToStore: EmailSessionData = {
      conversation: currentConversation,
      messageToReply,
      message,
      campaignId,
      mode: editorInfo.mode,
      serializedEditor
    };
    sessionStorage.setItem('emailEditorInfo', JSON.stringify(dataToStore));
    window.open(
      url,
      '_blank',
      'status=no,toolbar=no,menubar=no,resizable=yes,width=1000,height=700,directories=no,left=500,top=200'
    );
  };

  async function handleSend(params: {
    editor: LexicalEditor;
    files: File[];
    ticketId?: string;
  }) {
    const { editor, files, ticketId } = params;
    editor.update(async () => {
      let htmlString = $generateHtmlFromNodes(editor);

      if (htmlString.includes('$SIGNATURE_NODE$')) {
        htmlString = htmlString.replace('$SIGNATURE_NODE$', parseSignatureFn());
      }

      const imageNodes = $nodesOfType(ImageNode);
      let inlineFiles = imageNodes.map((node) => {
        return node.getFile();
      });

      if (editorInfo.mode === EmailEditorMode.New) {
        const params: APISendEmailFileByCampaignParams = {
          campaignId,
          message: {
            ...message,
            message: htmlString
          },
          files,
          inlineFiles,
          ticketId
        };

        return sendEmail(params)
          .then(() => {
            setAutoselectNextConversationDomain(true);
            showAlert(t('Email sent successfully'), 'success', 3000);
            handleClose();
          })
          .catch((e) => {
            showAlert(getErrorMessage(e)?.errorMessage, 'error', 3000);
            emitCustomEvent('email-send-error');
          });
      }

      // -- Reply mode --
      const replyToNodes = $nodesOfType(ReplyingToNode);
      if (replyToNodes.length > 0) {
        const replyToNode = replyToNodes[0];
        const replyInlineFiles = replyToNode.getInlineFiles();

        inlineFiles = [...inlineFiles, ...replyInlineFiles];
      }

      const campaign = await getCampaignByIdQuery(campaignId);
      const accountId = (campaign as SocialmediaOutboundCampaign)?.accountId;

      const params: APISendEmailFileByCampaignParams = {
        accountId,
        conversationId: currentConversation.id,
        message: {
          ...message,
          message: htmlString
        },
        files,
        inlineFiles,
        ticketId
      };

      sendEmail(params)
        .then(() => {
          handleClose();
        })
        .catch((e) => {
          showAlert(getErrorMessage(e)?.errorMessage, 'error', 3000);
          emitCustomEvent('email-send-error');
        });
    });
  }

  return (
    <Stack>
      <LexicalComposer
        initialConfig={{
          namespace: `email-${messageToReply?.messageId}`,

          onError: (error) => {
            console.error(error);
          },
          theme: {
            text: {
              underline: 'editor-underline',
              strikethrough: 'editor-strikethrough',
              bold: 'editor-bold',
              italic: 'editor-italic'
            }
          },
          nodes: [
            ImageNode,
            HeadingNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            HorizontalRuleNode,
            ListItemNode,
            ListNode,
            MarkNode,
            TableCellNode,
            TableNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            ReplyingToNode,
            SignatureNode
          ]
        }}
      >
        <EmailTopbar
          handleSend={handleSend}
          onClose={handleClose}
          message={message}
          setMessage={setMessage}
          mode={editorInfo.mode}
          setCampaignId={setCampaignId}
          campaignId={campaignId}
          onExternalButtonClick={onExternalButtonClick}
        />
        <Editor
          serializedEditor={editorInfo.serializedEditor}
          messageToReply={messageToReply}
          messageToReplyHtml={parsedMessageToReply}
          parsedSignature={parsedSignature}
        />
      </LexicalComposer>
    </Stack>
  );
}

export default EmailEditor;
