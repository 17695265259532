import { Fragment, MutableRefObject } from 'react';
import {
  DataCategory,
  DateOperator,
  DialTableFilter,
  FieldDataType,
  FieldType
} from '../models/filters';
import { Option } from '../models/option';
import {
  Box,
  Checkbox,
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { DateTimeCalendarPicker } from 'src/components/DateTimeCalendarPicker';
import { PaginatedElements } from 'src/models/pagination';
import { useDialTableFilterValueOptions } from '../hooks/useDialTableFilterValueOptions';
import { DialTableNoResultsFilterItem } from './DialTableNoResultsFilterItem';

interface Props<T> {
  filterToEdit: DialTableFilter<T>;
  onChangeFilterValues: (
    newValues: string[],
    newFilter: DialTableFilter<T>,
    selectedValueOptions?: Option[]
  ) => void;
  getCustomFilterValueOptionsFn?: (
    dataCategory: DataCategory,
    companyId: string,
    input?: string
  ) => Promise<PaginatedElements<Option>>;
  input?: string;
  selectedIndex?: number;
  itemRefs?: MutableRefObject<any[]>;
}

export const DialTableFilterValueOptions = <T extends Object>({
  filterToEdit: filterToEditProps,
  onChangeFilterValues: onChangeFilterValuesProps,
  getCustomFilterValueOptionsFn: getCustomFilterValueOptionsFnProps,
  input,
  selectedIndex,
  itemRefs
}: Props<T>) => {
  const {
    ref,
    filterToEdit,
    infiniteResultQuery,
    calendarValue,
    onChangeCalendarValue,
    booleanValueOptions,
    onChangeFilterValues
  } = useDialTableFilterValueOptions({
    filterToEdit: filterToEditProps,
    onChangeFilterValues: onChangeFilterValuesProps,
    getCustomFilterValueOptionsFn: getCustomFilterValueOptionsFnProps,
    input
  });
  const { data, isInitialLoading, isFetchingNextPage } = infiniteResultQuery;
  const IntersactionObserver = <Box ref={ref} />;

  if (filterToEdit?.fieldDataType === FieldDataType.DATE)
    return (
      <DateTimeCalendarPicker
        value={calendarValue}
        onChange={onChangeCalendarValue}
        selectRange={filterToEdit.operator === DateOperator.BETWEEN}
      />
    );

  if (filterToEdit?.fieldDataType === FieldDataType.BOOLEAN)
    return (
      <>
        {booleanValueOptions.length === 0 && <DialTableNoResultsFilterItem />}
        {booleanValueOptions.map((option, index) => (
          <ListItemButton
            key={String(option.value)}
            ref={(listItem) => {
              if (!!itemRefs) return (itemRefs.current[index] = listItem);
            }}
            onClick={() => {
              onChangeFilterValues(
                [option.value],
                {
                  ...filterToEdit,
                  values: [option.value]
                },
                [option]
              );
            }}
            selected={
              option.value === filterToEdit.values?.[0] ||
              index === selectedIndex
            }
          >
            <ListItemText primary={option.label} />
          </ListItemButton>
        ))}
      </>
    );

  if (filterToEdit?.fieldType === FieldType.SELECTOR)
    return (
      <>
        {data?.pages?.map((page) => (
          <Fragment key={`page-${page?.currentPage}`}>
            {page.elements.length === 0 && <DialTableNoResultsFilterItem />}
            {page?.elements.map((option, index) => (
              <ListItemButton
                key={String(option.value)}
                ref={(listItem) => {
                  if (!!itemRefs) return (itemRefs.current[index] = listItem);
                }}
                onClick={() => {
                  onChangeFilterValues(
                    [option.value],
                    {
                      ...filterToEdit,
                      values: [option.value]
                    },
                    [option]
                  );
                }}
                selected={
                  option.value === filterToEdit.values?.[0] ||
                  index === selectedIndex
                }
              >
                <ListItemText primary={option.label} />
              </ListItemButton>
            ))}
          </Fragment>
        ))}
        {IntersactionObserver}
        {(isInitialLoading || isFetchingNextPage) && (
          <Box display="flex" justifyContent="center" minWidth="150px">
            <CircularProgress size="1rem" />
          </Box>
        )}
      </>
    );

  if (filterToEdit?.fieldType === FieldType.MULTI_SELECTOR)
    return (
      <>
        {filterToEdit?.fieldType === FieldType.MULTI_SELECTOR &&
          data?.pages?.map((page) => (
            <Fragment key={`page-${page?.currentPage}`}>
              {page?.elements?.length === 0 && <DialTableNoResultsFilterItem />}
              {page?.elements.map((option, index) => (
                <ListItemButton
                  key={String(option.value)}
                  ref={(listItem) => {
                    if (!!itemRefs) return (itemRefs.current[index] = listItem);
                  }}
                  dense
                  selected={index === selectedIndex}
                  onClick={() => {
                    const currentIndex = filterToEdit.values.indexOf(
                      option.value
                    );
                    const newValues = [...filterToEdit.values];
                    if (currentIndex === -1) {
                      newValues.push(option.value);
                    } else {
                      newValues.splice(currentIndex, 1);
                    }
                    const newFilter = {
                      ...filterToEdit,
                      values: newValues
                    };
                    const selectedValueOptions = page?.elements.filter(
                      (option) => newFilter.values.includes(option.value)
                    );
                    onChangeFilterValues(
                      newValues,
                      newFilter,
                      selectedValueOptions
                    );
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      size="small"
                      color="secondary"
                      edge="start"
                      checked={
                        filterToEdit?.values.indexOf(option.value) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.label} />
                </ListItemButton>
              ))}
            </Fragment>
          ))}
        {IntersactionObserver}
        {(isInitialLoading || isFetchingNextPage) && (
          <Box display="flex" justifyContent="center" minWidth="150px">
            <CircularProgress size="1rem" />
          </Box>
        )}
      </>
    );
  return <></>;
};
