import axios from 'axios';
import { Variable } from '../models/models';
import { replaceURLParams } from 'src/services/utils';
import { VARIABLE_ID } from 'src/utils/apiRoutes/msAcd';

export const updateVariableApi = async (variable: Variable) => {
  const path = replaceURLParams(VARIABLE_ID, { '{id}': variable.id });
  const response = await axios.put(path, { ...variable });
  return response.data as Variable;
};
