import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  DialTableFilter,
  FieldDataType,
  FieldType,
  IntegerOperator,
  Operator,
  StringOperator
} from '../models/filters';
import { useSteps } from '../../../hooks/useSteps';
import {
  FillFilterSteps,
  fillFilterStepsOptions
} from '../models/fillFilterSteps';
import { Option } from '../models/option';
import { getFilteredAvailableFilterOptions } from '../utils/getAvailableFilterOptions';
import { getOperators } from '../utils/getOperatorOptions';
import { sortSelectedFilters } from '../utils/sortSelectedFilters';
import { DateOperator } from 'src/components/TableReporting/models/filtersRequest';
import { filterOptionsCallback } from '../utils/filterOptionsCallback';
import { useTranslation } from 'react-i18next';
import { getOptions } from '../utils/getOptionValues';
import { getOperatorLabel } from '../utils/getOperatorLabel';
import { getOperatorDescription } from '../utils/getOperatorDescription';
import { useSelectedIndex } from 'src/hooks/useSelectedIndex';

interface Params<T extends Object> {
  selectedFilters: DialTableFilter<T>[];
  availableFilters: DialTableFilter<T>[];
  onChange: (value: DialTableFilter<T>[]) => void;
  onEnterKeyDown?: (
    value: DialTableFilter<T>[],
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onlyEqualsOperator?: boolean;
}

export const useDialTableFilterInput = <T extends Object>(
  params: Params<T>
) => {
  const {
    availableFilters,
    selectedFilters,
    onChange,
    onlyEqualsOperator,
    onEnterKeyDown
  } = params;
  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState(false);
  // The new filter to edit. The DialTableFilterInput is in charge of displaying the options from that filter.
  const [filterToEdit, setFilterToEdit] = useState<DialTableFilter<T>>(null);
  const [input, setInput] = useState('');

  const filtersBoxRef = useRef<HTMLDivElement | null>(null);
  const filtersInputRef = useRef(null);
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  // isAllowedToRemoveSelectedFiltersRef.current should set to false when the user types text in the input and it should set to true when the 'Backspace' key up event occurs
  // This constant is useful to prevent the user from removing text and deleting a selected filter. So, the user has to stop deleting text before deltting a filter
  const isAllowedToRemoveSelectedFiltersRef = useRef(true);

  // options sent to DialTableFilterItem to display the label of the option using the values of the option and avoid requesting the option from API
  const [optionsMap, setOptionsMap] = useState<{
    [fieldName: string]: Option[];
  }>({});

  const { currentStep, nextStep, resetStep } = useSteps({
    steps: fillFilterStepsOptions
  });
  const { t } = useTranslation();

  // The dropdown is not displayed when the user is filling the value of a filter of type TEXT
  const isOpenDropdown =
    (isOpenDropdownMenu && filterToEdit?.fieldType !== FieldType?.TEXT) ||
    (filterToEdit?.fieldType === FieldType?.TEXT &&
      currentStep !== FillFilterSteps.VALUES);

  // Set a new filter to edit
  const handleAddNewSelectedFilter = (
    selectedAvailableFilter: DialTableFilter<T>
  ) => {
    setInput('');
    // Ensure that the operator and the values are empty
    const newFilter: DialTableFilter<T> = {
      ...selectedAvailableFilter,
      operator: undefined,
      values: []
    };
    setFilterToEdit(newFilter);
    filtersInputRef.current.focus();
    nextStep();
    // setSelectedFilters((prev) => [...prev, newFilter]);
  };

  const handleAddOperator = (operator: Operator) => {
    setInput('');
    setFilterToEdit((prev) => ({ ...prev, operator }));
    filtersInputRef.current.focus();
    nextStep();
  };

  /**
   *
   * @param _newValues
   * @param newFilter
   * @param selectedValueOptions
   */
  const onChangeFilterValues = (
    _newValues: string[],
    newFilter: DialTableFilter<T>,
    selectedValueOptions: Option[]
  ) => {
    setInput('');
    setFilterToEdit(newFilter);
    // Focus the input after the focus from the DateTimeCalendarPicker.
    setTimeout(() => {
      filtersInputRef.current.focus();
    }, 10);
    // SET OPTIONSMAP
    const newOptionsMap = { ...optionsMap };
    const previousOptions =
      newOptionsMap?.[newFilter.fieldName as string] ?? [];
    const newOptions = selectedValueOptions.filter(
      (newOption) =>
        !previousOptions.some((option) => option.value === newOption.value)
    );
    newOptionsMap[newFilter.fieldName as string] = [
      ...previousOptions,
      ...newOptions
    ];
    setOptionsMap(newOptionsMap);

    // onChange is only called if the filter field type is selector because when adding options from the multiselector,
    // the popper is scrolled and is incovenient for the user
    if (newFilter?.fieldType === FieldType.SELECTOR) {
      onChange([...selectedFilters, newFilter]);
      //   setSelectedFilters((prev) => [...prev, newFilter]);
      setFilterToEdit(null);
      filtersInputRef.current.focus();
      nextStep();
    }
  };

  const availableFilterOptions = useMemo(() => {
    const filters = [...selectedFilters, filterToEdit];
    const filteredAvailableFilters = getFilteredAvailableFilterOptions(
      availableFilters,
      filters,
      input
    );
    return filteredAvailableFilters;
  }, [availableFilters, selectedFilters, filterToEdit, input]);

  const { handleKeyDown, selectedIndex, resetIndex, itemRefs } =
    useSelectedIndex();

  const operatorList = onlyEqualsOperator
    ? [IntegerOperator.EQUALS]
    : getOperators(filterToEdit);

  const options = getOptions({
    options: operatorList,
    labelExtractor: (operator) => t(getOperatorLabel(operator)),
    valueExtractor: (operator) => operator,
    descriptionExtractor: (operator) => t(getOperatorDescription(operator))
  });

  const operatorOptions = options.filter((option) => {
    const filterOptions = filterOptionsCallback(input);
    return filterOptions(option.label) || filterOptions(option?.description);
  });

  const onChangeSelectedFilter = (
    newFilter: DialTableFilter<T>,
    previousFilter: DialTableFilter<T>,
    selectedValueOptions?: Option[]
  ) => {
    const selectedFilterIndex = selectedFilters.findIndex(
      (filter) => filter.fieldName === newFilter.fieldName
    );
    const newSelectedFilters = [...selectedFilters];
    const newOptionsMap = { ...optionsMap };
    const previousOptions =
      newOptionsMap?.[newFilter.fieldName as string] ?? [];
    const newOptions =
      selectedValueOptions?.filter(
        (newOption) =>
          !previousOptions.some((option) => option.value === newOption.value)
      ) ?? [];
    newOptionsMap[newFilter.fieldName as string] = [
      ...previousOptions,
      ...newOptions
    ];
    setOptionsMap(newOptionsMap);
    // If the field data type of the previous and the current filter is equals to 'DATE'
    // and the operator changes to 'BETWEEN' (the only operator that has  two values),
    // add the 'from' date and the 'to' date (see https://github.com/wojtekmaj/react-calendar )
    if (
      previousFilter.fieldDataType === FieldDataType.DATE &&
      newFilter.fieldDataType === FieldDataType.DATE &&
      previousFilter.operator !== DateOperator.BETWEEN &&
      newFilter.operator === DateOperator.BETWEEN
    ) {
      const date = newFilter.values?.[0] ?? new Date(Date.now()).toISOString();
      newFilter.values = [date, date];
    }
    // If the field data type of the previous and the current filter is equals to 'DATE'
    // and the operator changes from 'BETWEEN' to any other operator, sets the values array to a single date array
    if (
      previousFilter.fieldDataType === FieldDataType.DATE &&
      newFilter.fieldDataType === FieldDataType.DATE &&
      previousFilter.operator === DateOperator.BETWEEN &&
      newFilter.operator !== DateOperator.BETWEEN
    ) {
      const date = newFilter.values?.[0] ?? new Date(Date.now()).toISOString();
      newFilter.values = [date];
    }
    newSelectedFilters.splice(selectedFilterIndex, 1, newFilter);
    // setSelectedFilters(newSelectedFilters);
    onChange(newSelectedFilters);
  };

  const onClickInput = (e: MouseEvent<HTMLDivElement>) => {
    filtersInputRef.current.focus();
    if (!isOpenDropdown) {
      setIsOpenDropdownMenu(true);
    }
  };

  const onChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputValue = e.target.value;
    if (
      isAllowedToRemoveSelectedFiltersRef.current &&
      newInputValue.length > 0
    ) {
      isAllowedToRemoveSelectedFiltersRef.current = false;
    }
    setInput(newInputValue);
  };

  // Allow to remove selectedFilters
  const onKeyUpInput = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key !== 'Backspace') return;
    if ((selectedFilters.length === 0 && !filterToEdit) || input.length > 0)
      return;
    isAllowedToRemoveSelectedFiltersRef.current = true;
  };

  const onKeyDownInput = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // ARROW DOWN
    if (event.key === 'ArrowDown') {
      filtersInputRef.current.blur();
      dropdownMenuRef.current.focus();
    }
    // ENTER
    if (event.key === 'Enter') {
      // If there is no filter to edit and there is no text on the input, submit the selected filters
      if (!filterToEdit) {
        setIsOpenDropdownMenu(false);
        filtersInputRef.current.blur();
        onEnterKeyDown?.(selectedFilters, event);
      }
      let newFilter = filterToEdit;
      // If the field type of the filter to edit is equal to text, add the input to the values
      if (filterToEdit && filterToEdit.fieldType === FieldType.TEXT) {
        newFilter = { ...newFilter, values: [input] };
      }
      setInput('');
      if (!newFilter?.values.some((value) => value.trim().length > 0)) {
        return;
      }
      //   setSelectedFilters((prev) => [...prev, newFilter]);
      onChange([...selectedFilters, newFilter]);
      setFilterToEdit(null);
      nextStep();
    }
    // BACKSPACE
    if (
      event.key === 'Backspace' &&
      isAllowedToRemoveSelectedFiltersRef.current
    ) {
      if (!!filterToEdit) {
        removeFilterToEdit();
        return;
      }
      const newSelectedFilters = [...selectedFilters];
      const deletedFilter = newSelectedFilters.pop();
      // Avoid removing required filters
      if (deletedFilter?.required) return;
      onChange(newSelectedFilters);
      // setSelectedFilters(newSelectedFilters);
    }
  };

  const removeFilterToEdit = () => {
    setFilterToEdit(null);
    resetStep();
  };

  const onRemoveSelectedFilter = (filter: DialTableFilter<T>) => {
    const newFilters = selectedFilters.filter(
      (selectedFilter) => selectedFilter.fieldName !== filter.fieldName
    );
    onChange(newFilters);
    // setSelectedFilters(newFilters);
  };

  /**
   * Allows to remove the filter to edit while adding it only when it is not a text filter
   */
  const onRemoveFilterFilterToEdit =
    filterToEdit?.fieldType === FieldType.TEXT
      ? undefined
      : () => {
          removeFilterToEdit();
        };

  const onChangeFilterToEdit = (newFilter: DialTableFilter<T>) => {
    setFilterToEdit(newFilter);
  };

  // Show the values of the filter to edit when there are any values and when the field type is neither 'MULTI_SELECTOR' nor the field data type is 'DATE
  const showFilterToEditValuesFn = (filterToEdit: DialTableFilter<T>) =>
    filterToEdit.values.length > 0 &&
    filterToEdit.fieldType !== FieldType.MULTI_SELECTOR &&
    filterToEdit.fieldDataType !== FieldDataType.DATE;

  const sortedSelectedFilters = useMemo(
    () => sortSelectedFilters(selectedFilters),
    [selectedFilters]
  );

  // Modifiers to add additional logic to the positioning operations provided by default by Popper (see https://popper.js.org/docs/v2/modifiers/ ).
  // It allows that the filter options scrolls as the selected filters are added.
  const modifiers = [
    {
      name: 'offset',
      options: {
        offset: () => {
          const filtersInputPosition =
            filtersInputRef.current.getBoundingClientRect().left;
          const filtersBoxPosition =
            filtersBoxRef.current.getBoundingClientRect().left;
          const horizontalPosition = filtersInputPosition - filtersBoxPosition;
          return [horizontalPosition, 0];
        }
      }
    }
  ];

  const handleClickOutside = (event) => {
    if (
      filtersBoxRef.current &&
      !filtersBoxRef.current.contains(event.target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target)
    ) {
      setIsOpenDropdownMenu(false);
      if (!!filterToEdit?.operator && filterToEdit?.values?.length > 0) {
        // setSelectedFilters((prev) => [...prev, filterToEdit]);
        onChange([...selectedFilters, filterToEdit]);
        setFilterToEdit(null);
        resetStep();
        setIsOpenDropdownMenu(true);
      }
    }
  };

  const clearFilters = () => {
    removeFilterToEdit();
    onChange([]);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterToEdit]);

  useEffect(() => {
    // If onlyEqualsOperator equals to true, add the EQUALS operator automatically
    if (currentStep === FillFilterSteps.OPERATOR && onlyEqualsOperator) {
      handleAddOperator(StringOperator.EQUALS);
    }
  }, [currentStep]);

  useEffect(() => {
    itemRefs.current = [];
    resetIndex();
  }, [currentStep, selectedFilters]);

  return {
    availableFilterOptions,
    clearFilters,
    currentStep,
    dropdownMenuRef,
    filtersBoxRef,
    filtersInputRef,
    filterToEdit,
    handleAddNewSelectedFilter,
    handleAddOperator,
    handleKeyDown,
    input,
    isOpenDropdown,
    isOpenDropdownMenu,
    itemRefs,
    modifiers,
    onChangeFilterToEdit,
    onChangeFilterValues,
    onChangeInput,
    onChangeSelectedFilter,
    onClickInput,
    onKeyDownInput,
    onKeyUpInput,
    onRemoveFilterFilterToEdit,
    onRemoveSelectedFilter,
    operatorOptions,
    optionsMap,
    resetIndex,
    selectedIndex,
    setFilterToEdit,
    setIsOpenDropdownMenu,
    showFilterToEditValuesFn,
    sortedSelectedFilters
  };
};
