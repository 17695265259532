import {
  DOMConversionMap,
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey
} from 'lexical';
import { ReactNode } from 'react';
import VariableNodeComponent from './renderer/VariableNodeRenderer';
import { Variable } from 'src/modules/Admin/modules/Acd/modules/Variables/models/models';

export class VariableNode extends DecoratorNode<ReactNode> {
  __id: string;
  companyId: string;
  variables: Variable[];

  static getType(): string {
    return 'VariableNode';
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(
      node.__id,
      node.companyId,
      node.variables,
      node.__key
    );
  }

  constructor(
    id: string,
    companyId: string,
    variables: Variable[],
    key?: NodeKey
  ) {
    super(key);
    const variableId = id === '' ? undefined : id;
    this.__id = variableId;
    this.companyId = companyId;
    this.variables = variables;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  static importDOM(): DOMConversionMap | null {
    return {
      code: (element) => {
        const innerText = element.innerText;

        const id = innerText.replaceAll('$', '');

        return {
          conversion: (node) => {
            return {
              node: $createVariableNode(id, '', [])
            };
          },
          priority: 1
        };
      }
    };
  }

  updateDOM(): false {
    return false;
  }

  exportDOM(): DOMExportOutput {
    const span = document.createElement('span');

    span.innerText = `$${this.getVariableId()}$`;
    return {
      element: span
    };
  }

  decorate(): ReactNode {
    return <VariableNodeComponent node={this} />;
  }

  getVariableId(): string {
    return this.__id;
  }

  getVariables(): Variable[] {
    return this.variables;
  }

  getCompanyId(): string {
    return this.companyId;
  }

  setVariables(variables: Variable[]): void {
    this.variables = variables;
  }

  setCompanyId(companyId: string): void {
    this.companyId = companyId;
  }
}

export function $createVariableNode(
  id: string,
  companyId: string,
  variables: Variable[]
): VariableNode {
  return new VariableNode(id, companyId, variables);
}

export function $isVariableNode(
  node: LexicalNode | null | undefined
): node is VariableNode {
  return node instanceof VariableNode;
}
