import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  detectDelimiterFromColumnValue,
  getCSVHeadersAndDelimiter
} from './LinkFieldsWithCSV/utils/getCSVHeaders';
import { MenuItem, Stack, Switch, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { UploadCsvParameters } from '../models/models';

interface Props {
  uploadCsvParameters: UploadCsvParameters;
  setUploadCsvParameters: Dispatch<SetStateAction<UploadCsvParameters>>;
}

export const LinkTagsWithCSV = ({
  uploadCsvParameters,
  setUploadCsvParameters
}: Props) => {
  const { file, tagsColumn, tagsDelimiter } = uploadCsvParameters;

  const [csvHeaders, setCsvHeaders] = useState([]);

  useEffect(() => {
    if (!tagsColumn) return;
    detectDelimiterFromColumnValue(
      file,
      uploadCsvParameters.csvDelimiter,
      tagsColumn
    ).then((tagsDelimiter) => {
      setUploadCsvParameters({
        ...uploadCsvParameters,
        tagsDelimiter: tagsDelimiter
      });
    });
  }, [tagsColumn]);

  useEffect(() => {
    getCSVHeadersAndDelimiter(file, uploadCsvParameters.csvDelimiter).then(
      (response) => {
        setCsvHeaders(response.headers);
      }
    );
  }, [file]);

  return (
    <Stack py={1} spacing={2}>
      <TextField
        value={tagsColumn}
        label={t('Tags column')}
        select
        fullWidth
        onChange={(v) => {
          setUploadCsvParameters({
            ...uploadCsvParameters,
            tagsColumn: v.target.value
          });
        }}
      >
        {csvHeaders.map((header, index) => {
          return (
            <MenuItem key={index} value={header}>
              {header}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        key={tagsDelimiter}
        value={tagsDelimiter}
        label={t('Delimiter character')}
        fullWidth
        onChange={(v) => {
          setUploadCsvParameters({
            ...uploadCsvParameters,
            tagsDelimiter: v.target.value
          });
        }}
      ></TextField>
      <Stack direction={'row'} alignItems={'center'}>
        <Switch
          checked={uploadCsvParameters.createTagWhenNotFound}
          onChange={(e) => {
            setUploadCsvParameters({
              ...uploadCsvParameters,
              createTagWhenNotFound: e.target.checked
            });
          }}
        />
        <Typography>{t('Create tag when not found')}</Typography>
      </Stack>
    </Stack>
  );
};
