import { BASE_URL } from './base';

export const DEV_COMPANY_SHORTCODE = 'manhattan';

// API GATEWAY MICROSERVICE

// API KEY Controller
const API_KEY_CONTROLLER = `${BASE_URL}/apikey`;
export const API_KEY = API_KEY_CONTROLLER;
export const API_KEY_ID = `${API_KEY_CONTROLLER}/{id}`;

// Authentication
const AUTHENTICATION_CONTROLLER = `${BASE_URL}/authentication`;
export const AUTHENTICATION_LOGIN = `${AUTHENTICATION_CONTROLLER}/login`;
export const AUTHENTICATION_LOGOUT = `${AUTHENTICATION_CONTROLLER}/logout`;
export const AUTHENTICATION_USER_DATA = `${AUTHENTICATION_CONTROLLER}/info`;
export const AUTHENTICATION_REFRESH_TOKEN = `${AUTHENTICATION_CONTROLLER}/refresh_token`;
export const AUTHENTICATION_DOUBLE_FACTOR = `${AUTHENTICATION_CONTROLLER}/double_factor`;

// Permissions Groups Microservice
export const GROUPS_MS = 'groups';
export const GROUPS_BY_ID = `${GROUPS_MS}/{id}`;
export const GROUPS_STRUCTURE = `${GROUPS_MS}/structure`;
export const GROUPS_PERMISSIONS_USER_ID = `${GROUPS_MS}/permissions/{userId}`;
