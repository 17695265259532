import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { $createVariableNode, VariableNode } from '../VariableNode';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, KEY_DOWN_COMMAND } from 'lexical';
import Scrollbar from 'src/components/Scrollbar';
import { useEffect, useRef, useState } from 'react';
import {
  KeyboardArrowDownTwoTone,
  KeyboardArrowUp,
  KeyboardArrowUpTwoTone,
  KeyboardReturn
} from '@mui/icons-material';
import { t } from 'i18next';
import { Variable } from 'src/modules/Admin/modules/Acd/modules/Variables/models/models';

interface Props {
  node: VariableNode;
  parentRef: React.RefObject<HTMLSpanElement>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

function VariableModalSelector({ node, parentRef, isOpen, setIsOpen }: Props) {
  const [editor] = useLexicalComposerContext();

  const variables = node.getVariables();

  const companyId = node.getCompanyId();
  const [focusedNumber, setFocusedVariableNumber] = useState(0);
  const dataItemRefs = useRef([]);

  const [textFilter, setTextFilter] = useState('');
  const filteredVariables = variables.filter((variable) => {
    return variable.name?.toLowerCase().includes(textFilter.toLowerCase());
  });

  function onSelect(variable) {
    editor.update(() => {
      node.selectNext(0, 0);
      node.replace($createVariableNode(variable.id, companyId, variables));
    });
  }

  useEffect(() => {
    setFocusedVariableNumber(0);
  }, [textFilter]);

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSelect(filteredVariables[focusedNumber]);
      setFocusedVariableNumber(0);
    }

    // detect up arrow key
    if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
      event.preventDefault();
      setFocusedVariableNumber((prev) => {
        const length = variables.length > 0 ? variables.length : 4;
        const nextIndex = (prev - 1 + length) % length;

        dataItemRefs.current[nextIndex]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });

        return nextIndex;
      });
    } else if (event.key === 'ArrowDown' || event.key === 'Tab') {
      event.preventDefault();
      setFocusedVariableNumber((prev) => {
        const length = variables.length > 0 ? variables.length : 4;
        const nextIndex = (prev + 1) % length;
        dataItemRefs.current[nextIndex]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
        return nextIndex;
      });
    }
  }

  useEffect(() => {
    if (!isOpen) return;
    editor.update(() => {
      //select the node
    });
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [variables, isOpen, focusedNumber, textFilter]);

  return (
    <Popover
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      open={isOpen}
      anchorEl={parentRef.current}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={(e) => {
        // select the next position after the node
        editor.update(() => {
          node.selectNext(0, 0);
          setIsOpen(false);
        });
      }}
    >
      <Card
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.05)',
          height: 450,
          width: 350,

          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stack width="100%" p={0.5}>
          <TextField
            autoFocus
            size="small"
            fullWidth
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            placeholder={t('Filter variables') + '...'}
            InputProps={{
              sx: {
                borderRadius: '6.5px'
              }
            }}
          ></TextField>
        </Stack>
        <Scrollbar>
          <List
            sx={{
              px: 0.5
            }}
          >
            {filteredVariables.map((variable, index) => {
              return (
                <ListItemButton
                  key={variable.id}
                  ref={(dataItem) => (dataItemRefs.current[index] = dataItem)}
                  selected={focusedNumber === index}
                  onClick={() => {
                    onSelect(variable);
                  }}
                >
                  {variable.name}
                </ListItemButton>
              );
            })}
          </List>
        </Scrollbar>
        <Divider orientation="horizontal" />
        <Stack
          p={0.5}
          py={1}
          direction="row"
          alignItems="center"
          justifyContent={'space-around'}
        >
          <Stack direction="row" alignItems="center" columnGap={1}>
            <Box
              sx={{
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <KeyboardArrowUpTwoTone />
            </Box>
            <Box
              sx={{
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <KeyboardArrowDownTwoTone />
            </Box>
            <Typography>{t('to navigate')}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" columnGap={1}>
            <Box
              sx={{
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <KeyboardReturn />
            </Box>

            <Typography>{t('to select')}</Typography>
          </Stack>
        </Stack>
      </Card>
    </Popover>
  );
}

export default VariableModalSelector;
