import { IconButton, Tooltip, alpha, useTheme } from '@mui/material';
import { useContext } from 'react';
import {
  FinishReason,
  VideocallPhase,
  VideocallRoomContext
} from '../../../context/VideocallRoomContext';
import { VideoCallsDomain } from '../../../domain';
import { CallEnd } from '@mui/icons-material';
import { t } from 'i18next';
import stopStream from 'src/modules/VideoCall/utils/stopStream';

interface Props {}

export const VideocallsHangupButton = (props: Props) => {
  const videoCallsContext = useContext(VideocallRoomContext);
  const { currentRTCSession, setCurrentRTCSession } =
    videoCallsContext.rtcSession;
  const { setVideocallPhase, setFinishedReason } = videoCallsContext.phase;
  const { room, setRoom } = videoCallsContext.videoRoom;

  const theme = useTheme();

  async function handleHangup() {
    if (!currentRTCSession) {
      alert('No hay RTC session');
    } else if (currentRTCSession.status === 8) {
      alert('La llamada ya ha sido colgada');
    } else {
      currentRTCSession.terminate();
      VideoCallsDomain.hangUp(
        videoCallsContext.videoRoom.me.id,
        room.id,
        !videoCallsContext.isLoggedIn,
        videoCallsContext.connection.connectionId
      ).then((room) => {
        setRoom(room);
      });
    }

    const localStream = videoCallsContext.streams.local;
    stopStream(localStream);

    setCurrentRTCSession(undefined);
    setVideocallPhase(VideocallPhase.FINISHED);
    setFinishedReason(FinishReason.NORMAL);
  }

  return (
    <Tooltip title={t('Exit')}>
      <IconButton
        sx={{
          border: `1px solid ${theme.colors.error.main}`,
          color: `${theme.colors.error.main}`,

          '&:hover': {
            border: `1px solid ${theme.colors.error.main}`,
            background: alpha(theme.colors.error.main, 0.1),
            color: `${theme.colors.error.main}`
          }
        }}
        onClick={handleHangup}
      >
        <CallEnd />
      </IconButton>
    </Tooltip>
  );
};
