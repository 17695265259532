import { ExtensionsGroup } from 'src/modules/Admin/modules/Configurations/modules/Extensions/models/ExtensionsGroup';
import { PaginatedElements } from 'src/models/pagination';
import ExtensionsGroupForForm from 'src/modules/Admin/modules/Configurations/modules/Extensions/models/ExtensionsGroupForForm';
import {
  deleteExtensionsGroupById,
  ExtensionsGroupsRequestParams,
  getExtensionsGroupsByParams,
  postExtensionsGroup,
  putExtensionsGroup
} from '../infrastructure/api/extensionsGroups';
import { ExtensionsGroupDto } from '../models/ExtensionsGroupDto';

/**
 *
 * @param params
 * @returns
 */
export const getExtensionsGroups = (
  params: ExtensionsGroupsRequestParams
): Promise<PaginatedElements<ExtensionsGroup>> => {
  return getExtensionsGroupsByParams(params);
};

/**
 *
 * @param extensionsGroupId
 * @returns
 */
export const deleteExtensionsGroup = async (
  extensionsGroupId: string
): Promise<boolean> => {
  const result = await deleteExtensionsGroupById(extensionsGroupId);
  return result;
};

/**
 *
 * @param extensionsGroupForForm
 * @param companyId
 * @returns
 */
export const createExtensionsGroupFromForm = async (
  extensionsGroupForForm: ExtensionsGroupForForm,
  companyId: string
): Promise<ExtensionsGroup> => {
  const extensionsGroupDto = {
    companyId: companyId,
    description: extensionsGroupForForm.description,
    name: extensionsGroupForForm.name
  };

  const result = await postExtensionsGroup(extensionsGroupDto);
  return result;
};

export const createExtensionGroups = async (
  extensions: ExtensionsGroup[],
  companyId: string
) => {
  const result = await Promise.all(
    extensions.map(async (extension) => {
      const extensionsGroupDto = {
        companyId: companyId,
        description: extension.description ?? '',
        name: extension.name
      } as ExtensionsGroupDto;
      return postExtensionsGroup(extensionsGroupDto);
    })
  );
  return result;
};

export const updateExtensionsGroupFromForm = async (
  extensionsGroupForForm: ExtensionsGroupForForm,
  extensionsGroupId: string
): Promise<ExtensionsGroup> => {
  const extensionsGroupDto = {
    id: extensionsGroupId,
    name: extensionsGroupForForm.name,
    description: extensionsGroupForForm.description
  };

  const result = await putExtensionsGroup(extensionsGroupDto);
  return result;
};
