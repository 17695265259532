import { t } from 'i18next';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { getRTCSessions } from '../domain/getRTCSessions';
import {
  getCallerIdDataFromSession,
  getDisplayNameFromSession
} from '../domain/utils';
import WebRTCCallManagement from '../webrtc-call-management';
import { getAnswerOnClickStore } from '../infrastructure/store/getAnswerOnClickStore';
import { logger } from 'src/utils/logger';
import { getCampaignById } from 'src/services/users/domain/getCampaigns';

export const playConversationAssignedSound = () => {
  const ringer = document.getElementById(
    'conversation-notification'
  ) as HTMLAudioElement;
  ringer.volume = 0.3;
  ringer.play();
};

let beep = document.getElementById('webrtc-beep') as HTMLAudioElement;
export const startBeepSound = () => {
  beep.volume = 0.3;
  beep.play();
};

setInterval(() => {
  const talkingSessions = getRTCSessions(false).filter((s) => {
    return s.status === 'Talking';
  });
  const ringingSessions = getRTCSessions(false).filter((s) => {
    return s.status === 'Ringing';
  });
  // beep ringtone is played when there is a talking call and one or more ringing calls
  if (talkingSessions.length > 0 && ringingSessions.length > 0) {
    beep.volume = 0.3;
    beep.play();
  }
}, 10000);

const resetVolume = () => {
  const ringer = document.getElementById('webrtc-ring') as HTMLAudioElement;

  if (localStorage.getItem('ringVolume')) {
    ringer.volume = Number(localStorage.getItem('ringVolume')) / 100;
  }
};

const openCallAlert = async (session: WebRTCSession) => {
  const callerIdData = getCallerIdDataFromSession(session);
  const answerOnClick = getAnswerOnClickStore(false);

  let title = t('Call: $contact$ ($number$)');
  let body = t('Contact') + ': $contact$ \n';
  body += t('Number') + ': $number$ \n';

  if (callerIdData.campaignId) {
    const campaign = await getCampaignById(callerIdData.campaignId);
    if (campaign) {
      body += t('Campaign') + ': ' + campaign.name + ' \n\n';
    }
  }

  /* if (callerIdData.queueId) {
    const queue = getRTQueue({
      shouldUseHook: false,
      id: callerIdData.queueId
    });
    if (queue) body += t('Queue') + ': ' + queue.name + ' \n\n';
  } */

  if (answerOnClick) body += t('Click to answer the call');

  title = title.replace('$number$', callerIdData.phoneNumber);
  body = body.replace('$number$', callerIdData.phoneNumber);

  getDisplayNameFromSession(session)
    .then((displayName) => {
      if (callerIdData.internal)
        displayName = displayName.replace(/\w+ - /g, ' ');

      title = title.replace('$contact$', displayName);
      body = body.replace('$contact$', displayName);
    })
    .catch((error) => {
      title = title.replace('$contact$', '');
      body = body.replace('$contact$', '');
      logger.error(error);
    })
    .finally(() => {
      let notification = new Notification(title, { body: body });

      notification.onclick = (): void => {
        window.focus();

        if (answerOnClick) WebRTCCallManagement.attendCall(session);
      };
    });
};

const answerCallAlert: Function = () => {
  const ringer = document.getElementById('webrtc-answer') as HTMLAudioElement;
  ringer.play();
};
const hangUpCallAlert: Function = () => {
  const hangup = document.getElementById('webrtc-hangup') as HTMLAudioElement;
  hangup.play();
};

const startRinger = () => {
  const ringer = document.getElementById('webrtc-ring') as HTMLAudioElement;

  // ALWAYS PLAY RING UNTIL BACK FIXES THE BUG
  // if (talkingSessions.length === 0) {
  resetVolume();

  ringer.currentTime = 0;
  ringer.loop = true;
  ringer.play();
  // }
};
const stopRinger = () => {
  const ringer = document.getElementById('webrtc-ring') as HTMLAudioElement;
  ringer.pause();
  ringer.currentTime = 0;
};

const closeCallAlertIfNecessary: Function = () => {
  const sessions = getRTCSessions(false).filter((s) => {
    return s.status === 'Ringing';
  });

  const ringer = document.getElementById('webrtc-ring') as HTMLAudioElement;
  if (sessions.length <= 1) {
    ringer.pause();
  }

  /*if (localStorage.getItem('ringVolume')) {
    ringer.volume = Number(localStorage.getItem('ringVolume')) / 100;
  } else {
    ringer.volume = 0.8;
  }*/
};

const WebRTCNotifications = {
  openCallAlert,
  closeCallAlertIfNecessary,
  answerCallAlert,
  hangUpCallAlert,
  startRinger,
  stopRinger
};

export default WebRTCNotifications;
