import axios from 'axios';
import { Variable } from '../models/models';
import { replaceURLParams } from 'src/services/utils';
import { VARIABLE_ID } from 'src/utils/apiRoutes/msAcd';

export const deleteVariableApi = async (variableId: string) => {
  const path = replaceURLParams(VARIABLE_ID, { '{id}': variableId });
  const response = await axios.delete(path);
  return response.data as Variable;
};
