import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { checkReadContactsAndContactGroupsPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// example import component
const Contacts = Loader(lazy(() => import('../../../../Contacts/views')));

const contactsRoutes: RouteObject[] = [
  {
    path: 'view-contacts',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  },
  {
    path: 'tags',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  },
  {
    path: 'contact-fields',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  },
  {
    path: 'contact-groups',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadContactsAndContactGroupsPermissions}
      >
        <Contacts />
      </Restricted>
    )
  }
];

export default contactsRoutes;
