import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Conversation } from 'src/models/conversations/conversations';
import {
  getConversationSubtitle,
  getConversationTitle
} from '../../domain/conversation/getConversationInfo';
import { queryClient } from 'src/utils/queryClient';
import { EmailMessage } from 'src/models/conversations/messages';

export const useGetConversationTitleQuery = ({
  conversation,
  enabled
}: {
  conversation: Conversation;
  enabled: boolean;
}): UseQueryResult<string> => {
  return useQuery<string, Error>(
    [
      'conversationTitle',
      conversation?.id,
      conversation?.messages,
      (conversation?.lastMessage as EmailMessage)?.subject,
      ...(conversation?.involvedContacts?.map((c) => c.contactId) ?? [])
    ],
    () => getConversationTitle(conversation),
    {
      enabled,
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchInterval: 120000
    }
  );
};

export const useGetConversationSubTitleQuery = (
  conv: Conversation
): UseQueryResult<string> => {
  return useQuery<string, Error>(
    [
      'conversationSubTitle',
      conv?.id,
      conv?.metadata?.modificationDate,
      conv?.lastMessage,
      conv?.lastMessage?.messageId,
      conv?.messages?.length
    ],
    () => getConversationSubtitle(conv),
    {
      staleTime: 0
    }
  );
};

export const getConversationTitleQueryNoHook = (
  conv: Conversation
): Promise<string> => {
  return queryClient.fetchQuery(
    ['conversationTitle', conv],
    () => getConversationTitle(conv),
    { staleTime: 0 }
  );
};

export const getConversationSubTitleQueryNoHook = (
  conv: Conversation
): Promise<string> => {
  return queryClient.fetchQuery(
    ['conversationSubTitle', conv],
    () => getConversationSubtitle(conv),
    { staleTime: 0 }
  );
};
