import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { CreateWebChatAccount } from './CreateWebChatAccount';
import { WebChatAccount } from '../../../models/webChatAccount';
import EmbeddedForm from './EmbeddedForm';
import useWebChatAccountActionButtons from '../../utils/useWebChatAccountActionButtons';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { DialTable, DialTableImperativeHandle } from 'src/components/DialTable';
import { getContactGroups } from 'src/modules/Contacts/domain/getContactGroups';
import { ContactGroup } from 'src/modules/Contacts/models/contacts';

export const AccountsTab = () => {
  const {
    companyId,
    showEmbedChat,
    setShowEmbedChat,
    selected,
    showCreateAccount,
    setShowCreateAccount,
    setIsOpenDeleteModal,
    isOpenDeleteModal,
    webChatAccountsToDelete,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    deleteAccounts,
    setContactGroupsData,
    getData,
    sorting,
    setSorting
  } = useWebChatAccountActionButtons();

  const tableRef = useRef<DialTableImperativeHandle<WebChatAccount>>(null);

  useEffect(() => {
    getContactGroups({ companyId }).then((resp) => {
      setContactGroupsData(resp?.elements);
    });
  }, []);

  useEffect(() => {
    tableRef.current?.refetchData();
  }, [sorting]);

  return (
    <>
      <ConfirmationModal<WebChatAccount, keyof WebChatAccount>
        dataKey={'name'}
        data={webChatAccountsToDelete}
        isOpen={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        onConfirm={deleteAccounts}
        mode={ConfirmationModalModes.DELETE}
      />
      <CreateWebChatAccount
        show={showCreateAccount}
        setShow={setShowCreateAccount}
        webChatAccount={selected}
      />
      <EmbeddedForm
        open={showEmbedChat}
        setShow={setShowEmbedChat}
        webChatAccount={selected}
      />
      <Box width={'100%'} px={5} py={2}>
        <DialTable<WebChatAccount>
          queryKey={['webChatAccount']}
          getColumnsFn={getColumns}
          getRowActionsFn={getRowActions}
          getBulkActionsFn={getBulkActions}
          getAvailableFilters={getAvailableFilters}
          getDataFn={getData}
          state={{ sorting }}
          onSortingChange={setSorting}
          ref={tableRef}
        />
      </Box>
    </>
  );
};
