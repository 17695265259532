import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import WebRTCDevices from '../../../services/webrtc/webrtc-devices';
import {
  getAutoanswerStorage,
  setAutoanswerStorage
} from 'src/services/webrtc/infrastructure/store/getAutoanswerStore';
import {
  getAnswerOnClickStorage,
  setAnswerOnClickStorage
} from 'src/services/webrtc/infrastructure/store/getAnswerOnClickStore';
import { t } from 'i18next';

// Define a type for the slice state

export type MicrophoneState = 'granted' | 'denied' | 'prompt' | 'not-enabled';

interface ConfigVolumeState {
  audioVolume: number;
  audioDevice: MediaDeviceInfo;
  voiceVolume: number;
  voiceDevice: MediaDeviceInfo;
  ringVolume: number;
  ringDevice: MediaDeviceInfo;
  cameraDevice: MediaDeviceInfo;
  autoanswer: boolean;
  answerOnClickNotification: boolean;
  isOpen: boolean;
  isMuted: boolean;
  socialMediaVolume: number;
  devices: MediaDeviceInfo[];
  microphoneState: MicrophoneState;
}

// Define the initial state using that type
const initialState: ConfigVolumeState = {
  devices: WebRTCDevices.getMediaDevices(),
  audioVolume: 50,
  audioDevice: {
    deviceId: '',
    groupId: '',
    kind: 'audioinput',
    label: t('Default'),
    toJSON: () => {}
  },
  voiceVolume: 50,
  voiceDevice: {
    deviceId: '',
    groupId: '',
    kind: 'audiooutput',
    label: 'Default',
    toJSON: () => {}
  },
  ringVolume: 50,
  ringDevice: {
    deviceId: '',
    groupId: '',
    kind: 'audioinput',
    label: 'Default',
    toJSON: () => {}
  },
  cameraDevice: {
    deviceId: '',
    groupId: '',
    kind: 'videoinput',
    label: 'Default',
    toJSON: () => {}
  },
  autoanswer: getAutoanswerStorage(),
  answerOnClickNotification: getAnswerOnClickStorage(),
  isOpen: false,
  isMuted: false,
  socialMediaVolume: 0.5,
  microphoneState: 'not-enabled'
};

export const configVolumeSlice = createSlice({
  name: 'configVolume',
  initialState,
  reducers: {
    setMicrophoneState: (state, action: PayloadAction<MicrophoneState>) => {
      state.microphoneState = action.payload;
    },
    setDevices: (state, action: PayloadAction<MediaDeviceInfo[]>) => {
      state.devices = action.payload;
    },
    setAudioVolume: (state, action: PayloadAction<number>) => {
      state.audioVolume = action.payload;
    },
    setAudioDevice: (state, action: PayloadAction<MediaDeviceInfo>) => {
      state.audioDevice = action.payload;
    },
    setVoiceVolume: (state, action: PayloadAction<number>) => {
      state.voiceVolume = action.payload;
    },
    setVoiceDevice: (state, action: PayloadAction<MediaDeviceInfo>) => {
      state.voiceDevice = action.payload;
    },
    setRingVolume: (state, action: PayloadAction<number>) => {
      state.ringVolume = action.payload;
    },
    setRingDevice: (state, action: PayloadAction<MediaDeviceInfo>) => {
      state.ringDevice = action.payload;
    },
    setAutoAnswer: (state, action: PayloadAction<boolean>) => {
      state.autoanswer = action.payload;
      setAutoanswerStorage(action.payload);
    },
    setCameraDevice: (state, action: PayloadAction<MediaDeviceInfo>) => {
      state.cameraDevice = action.payload;
    },
    toggleAutoanswer: (state) => {
      state.autoanswer = !state.autoanswer;
      setAutoanswerStorage(state.autoanswer);
    },
    toggleAnswerOnClickNotification: (state) => {
      state.answerOnClickNotification = !state.answerOnClickNotification;
      setAnswerOnClickStorage(state.answerOnClickNotification);
    },
    toggleOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    toggleMuted: (state) => {
      state.isMuted = !state.isMuted;
    },
    setSocialMediaVolume: (state, action: PayloadAction<number>) => {
      state.socialMediaVolume = action.payload;
    }
  }
});

export const {
  setAudioVolume,
  setAudioDevice,
  setVoiceVolume,
  setVoiceDevice,
  setRingVolume,
  setRingDevice,
  setAutoAnswer,
  setCameraDevice,
  setDevices,
  toggleAutoanswer,
  toggleAnswerOnClickNotification,
  toggleOpen,
  toggleMuted,
  setSocialMediaVolume,
  setMicrophoneState
} = configVolumeSlice.actions;

export default configVolumeSlice.reducer;
