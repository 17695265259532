const frJSON = {
  NONDISPLAYEDNAME: '',
  Blueprints: 'Plans',
  'Extended Sidebar': 'Barre latérale étendue',
  'Accent Sidebar': "Barre latérale d'accentuation",
  'Accent Header': 'En-tête accentué',
  'Boxed Sidebar': 'Barre latérale en boîte',
  'Collapsed Sidebar': 'Barre latérale réduite',
  'Bottom Navigation': 'Navigation du bas',
  'Top Navigation': 'Navigation supérieure',
  'Lead Developer': 'Développeur principal',
  'Mega menu': 'Méga menu',
  Doctors: 'Médecins',
  Reports: 'Rapports',
  'Custom dashboard built using the included components':
    "Tableau de bord personnalisé construit à l'aide des composants inclus",
  Hospital: 'Hôpital',
  Export: 'Exportation',
  'Last year': "L'année dernière",
  Expenses: 'Dépenses',
  Products: 'Des produits',
  Statistics: 'Statistiques',
  General: 'Général',
  Automation: 'Automatisation',
  'Data Display': 'Affichage des données',
  Calendar: 'Calendrier',
  Mailbox: 'Boites aux lettres',
  Dashboards: 'Tableaux de bord',
  Analytics: 'Analytique',
  Banking: 'Bancaire',
  Commerce: 'Commerce',
  Crypto: 'Crypto',
  Finance: 'La finance',
  Fitness: 'Aptitude',
  Healthcare: 'Soins de santé',
  'Doctors Page': 'Page des médecins',
  'Hospital Overview': "Aperçu de l'hôpital",
  Helpdesk: "Bureau d'aide",
  Learning: 'Apprentissage',
  Monitoring: 'Surveillance',
  Tasks: 'Tâches',
  Applications: 'Applications',
  'File Manager': 'Gestionnaire de fichiers',
  'Jobs Platform': "Plateforme d'emploi",
  Messenger: 'Messager',
  'Projects Board': 'Conseil des projets',
  Management: 'La gestion',
  Users: 'Utilisateurs',
  'List All': 'Tout lister',
  'User Profile': "Profil de l'utilisateur",
  Projects: 'Projets',
  Shop: 'Magasin',
  'Products List': 'Liste de produits',
  'View Product': 'Voir le produit',
  'Create Product': 'Créer un produit',
  Invoices: 'Les factures',
  'View Single': 'Voir Single',
  'Auth Pages': "Pages d'authentification",
  Login: 'Connexion',
  Basic: 'De base',
  Cover: 'Couvrir',
  Register: "S'inscrire",
  Wizard: 'Magicien',
  'Recover Password': 'Récupérer mot de passe',
  Status: 'Statut',
  Foundation: 'Fondation',
  'Extra Pages': 'Pages supplémentaires',
  'Error 404': 'Erreur 404',
  'Error 500': 'Erreur 500',
  'Coming Soon': 'À venir',
  Maintenance: 'Maintenance',
  Overview: 'Aperçu',
  'Layout Starters': 'Démarreurs de mise en page',
  'Starter Kit 1': 'Kit de démarrage 1',
  'Starter Kit 2': 'Kit de démarrage 2',
  Documentation: 'Documentation',
  Welcome: 'Bienvenue',
  Help: 'Aider',
  'Features tour': 'Visite des fonctionnalités',
  'Getting started guide': 'Guide de Démarrage',
  'Contact support': 'Contactez le support',
  Version: 'Version',
  Search: 'Chercher',
  Notifications: 'Notifications',
  Settings: 'Paramètres',
  'Language Switcher': 'Sélecteur de langue',
  'Sign out': 'Déconnexion',
  'Change Theme': 'Change le thème',
  'View all notifications': 'Afficher toutes les notifications',
  'Tokyo NextJS Typescript Admin Dashboard':
    "Tableau de bord d'administration de Tokyo React",
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    "Modèle React haute performance construit avec de nombreux composants Material-UI puissants dans plusieurs niches de produits pour des processus de développement d'applications rapides et parfaits",
  'Browse Live Preview': "Parcourir l'aperçu en direct",
  'Live Preview': 'Aperçu en direct',
  'Key Features': 'Principales caractéristiques',
  dashboards: 'tableaux de bord',
  applications: 'applications',
  'management sections': 'sections de gestion',
  'dark/light themes': 'thèmes sombres / clairs',
  components: 'composants',
  'Some of the features that make Tokyo one of the best admin templates available today':
    "Certaines des fonctionnalités qui font de Tokyo l'un des meilleurs modèles d'administration disponibles aujourd'hui",
  'Design Source Files': 'Fichiers source de conception',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    "Commencez à travailler sur votre projet directement à partir de l'un des kits de démarrage inclus ou utilisez les fichiers Figma / Sketch pour créer d'abord un prototype",
  'We only translated a small part of the template, for demonstration purposes':
    "Nous n'avons traduit qu'une petite partie du modèle, à des fins de démonstration",
  Asigned: 'Attribué',
  Queue: "File d'attente",
  Calls: 'Appels',
  Attend: 'Assister à',
  Conversations: 'Conversations',
  Transfer: 'Transférer',
  Spy: 'Espionner',
  'Choose an extension for transfering':
    'Choisissez une extension pour le transfert',
  'No extensions available': 'Aucune extension disponible',
  'Choose a mode for spying': 'Choisissez un mode pour espionner',
  Loading: 'Chargement',
  'Start Dialerbot': 'Démarrer Dialerbot',
  'The dialerBot was updated': 'Le dialerBot a été mis à jour avec succès',
  'Update a dialerbot campaign': 'Mettre à jour une campagne de dialerbot',
  'Use this dialog window to update a dialerbot':
    'Utilisez cette fenêtre de dialogue pour mettre à jour un dialerbot',
  'The document was deleted successfully':
    'Le document a été supprimé avec succès',
  'The dialerBot was paused successfully':
    'Le dialerBot a été mis en pause avec succès',
  'The report was generated successfully':
    'Le rapport a été généré avec succès',
  'The dialerBot was started successfully':
    'Le dialerBot a été démarré avec succès',
  'A connection error occurred, contact the administrator':
    'Une erreur de connexion s`est produite, contactez l`administrateur',
  'The email was sent successfully': 'L`e-mail a été envoyé avec succès',
  'You must enter the destination email':
    'Vous devez entrer l`email de destination',
  'Type your message here': 'Tapez votre message ici',
  'Forwarded message': 'Message transféré',
  'The file you are trying to upload is already uploaded.':
    'Le fichier que vous essayez de télécharger est déjà téléchargé.',
  'View attachments': 'Afficher les pièces jointes',
  'Error launching the call': "Erreur lors du lancement de l'appel",
  'New conversation': 'Nouvelle conversation',
  Recents: 'Récents',
  'Type your': 'Tapez votre',
  phone: 'téléphone',
  Call: 'Appeler',
  'Call transfer success!': "Transfert d'appel réussi!",
  'Transfer in progress': 'Transfert en cours',
  'Transfer failed': 'Le transfert a échoué',
  'Select an agent:': 'Sélectionnez un agent :',
  'A call cannot be transfered to itself or to the same extension that it is already talking with.':
    'Un appel ne peut pas être transféré vers lui-même ou vers le même poste avec lequel il est déjà en communication.',
  'The call ended successfully': 'L`appel s`est terminé avec succès',
  'An error occurred in the connection':
    'Une erreur s`est produite dans la connexion',
  'The call was successfully transferred':
    'L`appel a été transféré avec succès',
  'The call was successfully unparked': 'L`appel a été récupéré avec succès',
  'The call was successfully parked': 'L`appel a été parqué avec succès',
  'The call was successfully captured': 'L`appel a été capturé avec succès',
  'The conversation was closed successfully':
    'La conversation a été fermée avec succès',
  'The call is being spied on successfully': 'L`appel est espionné avec succès',
  'Do not disturb': 'Ne pas déranger',
  'Reset calls': 'Actualiser les appels',
  'Devices configuration': 'Configuración de sonido',
  'Maximize bar': 'Maximiser',
  'Compress bar': 'Compresse',
  'Your session is about to expire': 'Votre session est sur le point d`expirer',
  "I'm still here": 'Je suis toujours là',
  'The user could not be deleted': 'L`utilisateur n`a pas pu être supprimé',
  'The user could not be created': 'L`utilisateur n`a pas pu être créé',
  'Update User': 'Mettre à jour l`utilisateur',
  'The user could not be updated': 'L`utilisateur n`a pas pu être mis à jour',
  'The user was successfully created': 'L`utilisateur a été créé avec succès',
  'The user was successfully updated':
    'L`utilisateur a été mis à jour avec succès',
  Username: 'Nom d`utilisateur',
  Description: 'La description',
  Extension: 'Extension',
  'The user was successfully cloned': 'L`utilisateur a été cloné avec succès',
  'The user could not be cloned': 'L`utilisateur n`a pas pu être cloné',
  Account: 'Compte',
  Templates: 'Modèles',
  'Create new user': 'Créer un nouvel utilisateur',
  'There are the users for company':
    'Il y a les utilisateurs pour l`entreprise',
  'All Users': 'Tous les utilisateurs',
  'Users Group': 'Groupe d`utilisateurs',
  'Add user': 'Ajouter un utilisateur',
  'Add users CSV': 'Ajouter des utilisateurs CSV',
  'Create user group': 'Créer un groupe d`utilisateurs',
  'Add users group CSV': 'Ajouter un groupe d`utilisateurs CSV',
  'WhatspApp Accounts': 'Comptes WhatsApp',
  'All aspects related to WhatspApp can be managed from this page':
    'Tous les aspects liés à WhatspApp peuvent être gérés depuis cette page',
  'Add WhatspApp account': 'Ajouter un compte WhatsApp',
  Accounts: 'Comptes',
  'Meta templates': 'Modèles de méta',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character':
    'Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial',
  'The name is required': 'Le nom est requis',
  'The password is required': 'Le mot de passe est requis',
  'The email is required': 'L`email est obligatoire',
  'The description is required': 'La description est obligatoire',
  'The company is required': 'La société est requise',
  'The Asign extension is required': 'L`extension Asign est requise',
  'The extension is required': 'L`extension est requise',
  'The email format is invalid': 'Le format de l`e-mail n`est pas valide',
  'Please retype your password': 'Veuillez retaper votre mot de passe',
  'Your passwords do not match': 'Vos mots de passe ne correspondent pas',
  'The password can not be longer than 16 characters':
    'Le mot de passe ne peut pas dépasser 16 caractères',
  Preview: 'Aperçu',
  'No User selected': 'Aucun utilisateur sélectionné',
  'Delete one or several users': 'Supprimer un ou plusieurs utilisateurs',
  'Disable one or several users': 'Désactiver un ou plusieurs utilisateurs',
  'Enable one or several users': 'Activer un ou plusieurs utilisateurs',
  'The user was enabled sucessfully': 'L`utilisateur a été activé avec succès',
  'The user was disabled sucessfully':
    'L`utilisateur a été désactivé avec succès',
  'Create new WhatspApp Account': 'Créer un nouveau compte WhatspApp',
  'Use this dialog window to add a new WhatspApp Account':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau compte WhatsApp',
  'Create account': 'Créer un compte',
  'Edit/Update WhatspApp Account': 'Modifier/Mettre à jour le compte WhatsApp',
  'Use this dialog window to edit/update WhatspApp Account':
    'Utilisez cette fenêtre de dialogue pour modifier/mettre à jour le compte WhatsApp',
  'Update account': 'Compte mis à jour',
  'The key is required': 'La clé est obligatoire',
  'The phone number is required': 'Le numéro de téléphone est obligatoire',
  'The provider is required': 'Le fournisseur est requis',
  'The WhatsApp account was successfully created':
    'Le compte WhatsApp a été créé avec succès',
  'The WhatsApp account was successfully updated':
    'Le compte WhatsApp a été mis à jour avec succès',
  'The WhatsApp account could not be created':
    'Le compte WhatsApp n`a pas pu être créé',
  'The WhatsApp account could not be updated':
    'Le compte WhatsApp n`a pas pu être mis à jour',
  'No WhatsApp account selected': 'Aucun compte WhatsApp sélectionné',
  'Delete one or several WhatsApp account':
    'Supprimer un ou plusieurs compte WhatsApp',
  'Disable one or several WhatsApp account':
    'Désactiver un ou plusieurs comptes WhatsApp',
  'Enable one or several WhatsApp account':
    'Activer un ou plusieurs comptes WhatsApp',
  'The WhatsApp account was enable sucessfully':
    'Le compte WhatsApp a été activé avec succès',
  'The WhatsApp account was disable sucessfully':
    'Le compte WhatsApp a été désactivé avec succès',
  'No Routes selected': 'Aucun itinéraire sélectionné',
  'The selected route is already enabled':
    'L`itinéraire sélectionné est déjà activé',
  'The selected route is already disabled':
    'L`itinéraire sélectionné est déjà désactivé',
  'The route was enable sucessfully': 'L`itinéraire a été activé avec succès',
  'The route was disable sucessfully':
    'L`itinéraire a été désactivé avec succès',
  'The selected user is already disabled':
    'L`utilisateur sélectionné est déjà désactivé',
  'The selected user is already enabled':
    'L`utilisateur sélectionné est déjà activé',
  'The selected WhatsApp account is already enabled':
    'Le compte WhatsApp sélectionné est déjà activé',
  'The selected WhatsApp account is already disabled':
    'Le compte WhatsApp sélectionné est déjà désactivé',
  'Time groups': 'Groupes horaires',
  'These are the time groups': 'Ce sont les groupes horaires',
  'Create extensions': 'Créer des extensions',
  'Create new extension': 'Créer une nouvelle extension',
  'Use this dialog window to add a new time group':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau groupe horaire',
  'Create time group': 'Créer un groupe horaire',
  'The time group was successfully created':
    'Le groupe horaire a été créé avec succès',
  'The time group could not be created':
    'Le groupe horaire n`a pas pu être créé',
  'Create Extension': 'Créer une extension',
  'Write here the time group name...':
    'Écrivez ici le nom du groupe horaire...',
  'The time group was successfully deleted':
    'Le groupe horaire a été supprimé avec succès',
  'The time group could not be deleted':
    'Le groupe horaire n`a pas pu être supprimé',
  'Copy time group': 'Copier le groupe horaire',
  'Edit time group': 'Modifier le groupe horaire',
  'Delete time group': 'Supprimer le groupe horaire',
  'No time groups selected': 'Aucun groupe horaire sélectionné',
  'Clone one or several time groups': 'Cloner un ou plusieurs groupes horaires',
  'Delete one or several time group':
    'Supprimer un ou plusieurs groupes horaires',
  'The temporal range was successfully deleted':
    'La plage temporelle a été supprimée avec succès',
  'The temporal range could not be deleted':
    'La plage temporelle n`a pas pu être supprimée',
  'The temporal range was successfully updated':
    'La plage temporelle a été mise à jour avec succès',
  'The temporal range could not be updated':
    'La plage temporelle n`a pas pu être mise à jour',
  'The time group was successfully copied':
    'Le groupe horaire a été copié avec succès',
  'The time group could not be copied':
    'Le groupe horaire n`a pas pu être copié',
  'Update time group': 'Mettre à jour le groupe horaire',
  'Use this dialog window to update a time group':
    'Utilisez cette fenêtre de dialogue pour mettre à jour un groupe horaire',
  'The temporal range was successfully copied':
    'La plage temporelle a été copiée avec succès',
  'The temporal range could not be copied':
    'La plage temporelle n`a pas pu être copiée',
  'Copy temporal range': 'Copier la plage temporelle',
  'Edit temporal range': 'Modifier la plage temporelle',
  'Delete temporal range': 'Supprimer la plage temporelle',
  'Create new time range': 'Créer une nouvelle plage horaire',
  'Update a time range': 'Mettre à jour une plage horairea',
  'Use this dialog window to add a new time range':
    'Utilisez cette fenêtre de dialogue pour ajouter une nouvelle plage de temps',
  'Use this dialog window to update a time range':
    'Utilisez cette fenêtre de dialogue pour mettre à jour une plage de temps',
  'Create temporal range': 'Créer une plage temporelle',
  'Update temporal range': 'Mettre à jour la plage temporelle',
  'The final month cannot be less than the initial month':
    'Le dernier mois ne peut pas être inférieur au mois initial',
  'Start Month': 'Mois de début',
  'End Month': 'Mois de fin',
  'Start Week Day': 'Jour de la semaine de début',
  'End Week Day': 'Jour de fin de semaine',
  'Start Month Day': 'Début Mois Jour',
  'End Month Day': 'Fin Mois Jour',
  'Initial time': 'Temps initial',
  'End time': 'Heure de fin',
  'The end time is required': 'L`heure de fin est requise',
  'The start time is required': 'L`heure de début est requise',
  'The start month is required': 'Le mois de début est requis',
  'The end month is required': 'Le mois de fin est obligatoire',
  'The start week day is required': 'Le jour de la semaine de début est requis',
  'The start month day is required': 'Le jour du mois de début est requis',
  'The time range was successfully created':
    'La plage horaire a été créée avec succès',
  'The time range could not be created':
    'La plage horaire n`a pas pu être créée',
  'The time range was successfully updated':
    'La plage horaire a été mise à jour avec succès',
  'The time range could not be updated':
    'La plage horaire n`a pas pu être mise à jour',
  'Repite Password': 'Répéter le mot de passe',
  'Asign extension': 'Attribuer un poste',
  Available: 'Disponible',
  Busy: 'Occupé',
  Unavailable: 'Indisponible',
  'Sign in': 'S`identifier',
  User: 'Utilisateur',
  Password: 'Mot de passe',
  'Without extension': 'Sans rallonge',
  'Have a problem ?': 'Avoir un problème ?',
  'Click here': 'Cliquez ici',
  'Something went wrong: Check your user or password':
    'Une erreur s`est produite: vérifiez votre nom d`utilisateur ou votre mot de passe',
  'Fill in the fields below to sign into your account.':
    'Remplissez les champs ci-dessous pour vous connecter à votre compte.',
  Queues: 'Files d`attente',
  Routes: 'Itinéraires',
  Interactions: 'Interactions',
  Election: 'Élection',
  Comprobations: 'Combinaisons',
  Method: 'Méthode',
  Operations: 'Opérations',
  Integrations: 'Intégrations',
  'Contact Center': 'Centre d`appels',
  Quality: 'Qualité',
  Contacts: 'Contacts',
  'In Firefox you can not change output':
    'Dans Firefox, vous ne pouvez pas modifier la sortie',
  'General Settings': 'réglages généraux',
  Actions: 'Actions',
  Companies: 'Entreprises',
  Assigned: 'Attribué',
  Create: 'Créer',
  Add: 'Ajouter',
  Update: 'Mise à jour',
  'No Options': 'Aucune option',
  'Internal Chat': 'Conversation interne',
  'Configure Web Client': 'Configurer le client Web',
  'Events Panel': 'Panneau des événements',
  'FTP Servers': 'Serveurs FTP',
  'Web Events': 'Événements Web',
  'Create service': 'Créer un service',
  'View services': 'Voir les prestations',
  'Create showflow': 'Créer un flux de présentation',
  'View ShowFlows': 'Voir les ShowFlows',
  'View showflows': 'Afficher les flux de diffusion',
  Tipology: 'Typologie',
  Comments: 'commentaires',
  'API Methods': 'Méthodes API',
  'Api methods': 'Méthodes API',
  Webservices: 'Services Web',
  'API Tokens': 'Jetons d`API',
  'Api tokens': 'Jetons d`API',
  'Create User': 'Créer un utilisateur',
  'View Users': 'Afficher les utilisateurs',
  Skills: 'Compétences',
  'Roles and Permissions': 'Rôles et autorisations',
  'Agent groups': 'Groupes d`agents',
  'Agent states': 'États des agents',
  Patterns: 'Motifs',
  'Schedule groups': 'Horaire des groupes',
  'Voice messages': 'Messages vocaux',
  Task: 'Tâche',
  'View Contacts': 'Afficher les contacts',
  'Import CSV': 'Importer CSV',
  Blacklist: 'Liste noire',
  'Creating user': 'Création d`un utilisateur',
  'No Calls': 'Pas d`appels',
  'Contact Data': 'Données de contact',
  'Conversations History': 'Historique des conversations',
  'Call Schedule': 'Horaire d`appel',
  Notes: 'Remarques',
  'New call incoming': 'Nouvel appel entrant',
  Typology: 'Typologie',
  'New Telegram Conversation': 'Nouvelle conversation par télégramme',
  'New Email Conversation': 'Nouvelle conversation par e-mail',
  'Your message  here...': 'Votre message ici...',
  Send: 'Envoyer',
  To: 'Pour',
  Subject: 'Sujet',
  'Text here': 'Texte ici',
  Agent: 'Agent',
  'Error changing status': 'Erreur lors du changement d`état',
  'Disabled while in call': 'Désactivé pendant un appel',
  'Attended transfer': 'Transfert assisté',
  'Dialerbot Campaigns': 'Campagnes Dialerbot',
  'These are your dialertbots': 'Ce sont vos dialertbots',
  'Create new dialerbot': 'Créer un nouveau dialerbot',
  'Search by campaign, channel or status...':
    'Rechercher par campagne, canal ou statut...',
  'In call': 'En appel',
  'Parked call': 'Appel parqué',
  'The title field is required': 'Le champ titre est obligatoire',
  'The timeout field is required': 'Le champ timeout est obligatoire',
  Title: 'Titre',
  'The Max calls per second field is required':
    'Le champ Max appels par seconde est obligatoire',
  'The Max simultaneus calls field is required':
    'Le champ Max appels simultanés est obligatoire',
  'The Amd Detection field is required':
    'Le champ Amd Detection est obligatoire',
  'The Pause Holidays field is required':
    'Le champ Suspendre les vacances est obligatoire',
  'Select Company': 'Sélectionnez l`entreprise',
  'Select Service': 'Sélectionnez le service',
  'DialerBot configuration': 'Configuration de DialerBot',
  Dates: 'Rendez-vous',
  'Select Queue Percentage': 'Sélectionner le pourcentage de file d`attente',
  'Select Outbound Route': 'Sélectionnez l`itinéraire sortant',
  'Select Inbound Route': 'Sélectionnez la route entrante',
  'AMD detection': 'Détection de DMLA',
  'Launch date': 'Date de lancement',
  'Pause on holidays': 'Pause pendant les vacances',
  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
  Sunday: 'Dimanche',
  'All Days': 'Tous les jours',
  'Monday to Friday': 'lundi à vendredi',
  'Create a new dialerbot campaign': 'Créer une nouvelle campagne de dialerbot',
  'Use this dialog window to Add Dialerbot':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau dialerbot',
  Cancel: 'Annuler',
  'Upload CSV': 'Importer un fichier CSV',
  'Keep as draft': 'Conserver comme brouillon',
  'Max calls per second': 'Appels maximum par secondeo',
  'Max simultaneus calls': 'Max appels simultanés',
  CAMPAIGN: 'CAMPAGNE',
  CHANNEL: 'CANALISER',
  'LAUNCH DATE': 'DATE DE LANCEMENT',
  PROGRESS: 'PROGRÈS',
  STATUS: 'STATUT',
  ACTIONS: 'ACTIONS',
  'Rows per page': 'Lignes par page',
  Active: 'Actif',
  Finished: 'Fini',
  Unstarted: 'Non commencé',
  Progressive: 'Progressif',
  Predictive: 'Prédictif',
  'Predictive configuration': 'Prédictif configuration',
  'Progressive Configuration': 'Progressif Configuration',
  'Abandoned rate': "Taux d'abandon",
  'Force autoanswer': "Forcer l'auto-réponse",
  'Recordings configuration': 'Configuration des enregistrements',
  'Recordings percentage': "Taux d'enregistrement",
  'Start date': 'Date de début',
  'Are you sure to delete this dialerbot?':
    'Êtes-vous sûr de vouloir supprimer ce dialerbot?',
  'Are you sure to delete several dialerbots?':
    'Êtes-vous sûr de vouloir supprimer plusieurs dialerbots?',
  Delete: 'Supprimer',
  'The delimiter field is required': 'Le champ délimiteur est obligatoire',
  Delimiter: 'Délimiteur',
  'The phonenumber field is required':
    'Le champ numéro de téléphone est obligatoire',
  'Name of PhoneColumn': 'Nom de la colonne de téléphone',
  'Select Holiday': 'Sélectionnez Vacances',
  Holiday: 'Vacances',
  'drag and drop the files here': 'faites glisser et déposez les fichiers ici',
  Upload: 'Télécharger',
  'Create Dialerbot': 'Créer un Dialerbot',
  'No documents uploaded': 'Aucun document téléchargé',
  'Dialerbot finished': 'Dialerbot terminé',
  'Add new CSV': 'Ajouter un nouveau CSV',
  Done: 'Fait',
  'Export Result CSV': 'Exporter le résultat CSV',
  'Stop Dialerbot': 'Arrêter Dialerbot',
  "We couldn't find any users matching your search criteria":
    'Nous n`avons trouvé aucun utilisateur correspondant à vos critères de recherche',
  Undefined: 'Indéfini',
  Showing: 'Montrant',
  'Dialerbot execution not found': 'Exécution de Dialerbot introuvable',
  Until: 'Jusqu`à',
  'Select the hours of weeks to be executed:':
    'Sélectionnez les heures des semaines à exécuter:',
  'Cannot start a dialerbot that is paused by time of day configuration':
    'Impossible de démarrer un dialerbot qui est mis en pause par la configuration de l`heure du jour',
  Company: 'Entreprise',
  Channel: 'Canaliser',
  'Edit the hours of weeks to be executed:':
    'Modifiez les heures des semaines à exécuter:',
  'Dates configuration': 'Paramétrage des dates',
  'Start time must be before end time.':
    'L`heure de début doit être antérieure à l`heure de fin.',
  'Dialerbot Name': 'Nom du robot de numérotation',
  'The dialerbot name field is required':
    'Le champ du nom du dialerbot est obligatoire',
  'Refresh table': 'Actualiser le tableau',
  'Delete one or several dialerbots': 'Supprimer un ou plusieurs dialerbots',
  'Pause one or several dialerbots':
    'Mettre en pause un ou plusieurs dialerbots',
  'Start one or several dialerbots': 'Démarrer un ou plusieurs dialerbots',
  'Update Dialerbot': 'Mettre à jour le dialerbot',
  'Delete dialerbot': 'Supprimer le dialerbot',
  'Close conversation': 'Fermer la conversation',
  'Transfer call': 'Transférer l`appel',
  'Hangup call': 'Raccrocher l`appel',
  'Park Call': 'Parquer un appel',
  'Unpark call': 'Reprendre l`appel',
  "We're working on implementing new awesomes features!":
    'Nous travaillons sur la mise en œuvre de nouvelles fonctionnalités géniales!',
  'Do you have any problem?': 'Avez-vous le moindre problème?',
  'Telegram message has been sent!': 'Le télégramme a été envoyé!',
  'Error sending the message': 'Erreur lors de l`envoi du message',
  'Call launched!': 'Appel lancé!',
  'Are you sure to remove the following DialerBots?':
    'Êtes-vous sûr de supprimer les DialerBots suivants?',
  schedules: 'des horaires',
  'Here you can edit,add or delete your schedules':
    'Ici, vous pouvez modifier, ajouter ou supprimer vos horaires',
  NAME: 'NOM',
  'Open SGI': 'SGI ouvert',
  'Open Fichaje': 'Ouvrir le fichier',
  'Disable do not disturb mode to launch calls':
    'Désactiver le mode Ne pas déranger pour lancer des appels',
  'Do not disturb enabled': 'Ne pas déranger activé',
  'Conversation history': 'Historique des conversations',
  Outbound: 'Sortant',
  Inbound: 'Entrant',
  Opened: 'Ouvert',
  Closed: 'Fermé',
  'Save the contact to see the history':
    'Enregistrez le contact pour voir l`historique',
  'Queue Percentage': 'Pourcentage de file d`attente',
  'OutBound Route': 'Route sortante',
  'Inbound Route': 'Itinéraire entrant',
  'Max calls simultaneous': 'Max appels simultanés',
  'AMD Detection': 'Détection DMLA',
  Service: 'Service',
  'General Configuration': 'Paramétrage général',
  'Select Start Date': 'Sélectionnez la date de début',
  'New WhatsApp Conversation': 'Nouvelle conversation WhatsApp',
  Number: 'Nombre',
  Select: 'Sélectionner',
  Selected: 'Choisi',
  'Unlock bar': 'Déverrouiller ba',
  'Lock bar': 'Barre de verrouillage',
  'The files were uploaded!': 'Les fichiers ont été téléchargés!',
  'The file was deleted!': 'Le fichier a été supprimé!',
  'The Dialerbot execution has started!':
    'L`exécution de Dialerbot a commencé!',
  'Execution is already paused.': 'L`exécution est déjà en pause.',
  'Download Report': 'Télécharger le rapport',
  'Generate report': 'Générer un rapport',
  'Report name': 'Nom du rapport',
  'Duplicate fields found in the CSV file.':
    'Champs en double trouvés dans le fichier CSV.',
  'That date is already selected': 'Cette date est déjà sélectionnée',
  'Create a new': 'Créer un nouveau',
  ' dialerbot campaign': ' campagne dialerbot',
  'Update a': 'Mettre à jour un',
  'Use this dialog window to ': 'Utilisez cette fenêtre de dialogue pour ',
  'Add Dialerbot': 'ajouter un nouveau dialerbot',
  'update a dialerbot': 'mettre à jour un dialerbot',
  Name: 'Nom',
  'Please create a dialerbot first!!': 'Veuillez d`abord créer un dialerbot!!',
  'No dialerbot selected': 'Aucun dialerbot sélectionné',
  'Droping the files here ...': 'Déposer les fichiers ici...',
  Field: 'Champ',
  'not found in the file': 'introuvable dans le dossier',
  'Download report': 'Télécharger le rapport',
  'Ignore wrong lines': 'Ignorer les lignes erronées',
  'There is elements without edge': 'Il y a des éléments sans bord',
  'Full Route was updated': 'L`itinéraire complet a été mis à jour',
  Nodes: 'Nœuds',
  Save: 'Sauvegarder',
  'Publish now': 'Publier maintenant',
  'Write Route title here': 'Écrivez le titre de l`itinéraire ici',
  'Route title': 'Titre de l`itinéraire',
  'Route History': 'Historique des itinéraires',
  'Search node...': 'Noeud de recherche...',
  'Inbound Routes': 'Itinéraires entrants',
  'Create new Route': 'Créer un nouvel itinéraire',
  'Disable one or several routes': 'Désactiver une ou plusieurs routes',
  'Enable one or several routes': 'Activer une ou plusieurs routes',
  'Delete one or several routes': 'Supprimer un ou plusieurs itinéraires',
  'Edit route': 'Modifier l`itinéraire',
  'Delete route': 'Supprimer l`itinéraire',
  'Create new inbound route': 'Créer une nouvelle route entrante',
  'Create a new route': 'Créer un nouvel itinéraire',
  'Create Route': 'Créer un itinéraire',
  Close: 'Fermer',
  'No elements': 'Aucun élément',
  'Time condition': 'Conditions temporelles',
  'Send email': 'Envoyer un e-mail',
  HangUp: 'Raccrocher',
  Hangup: 'Raccrocher',
  PlayBack: 'Relecture',
  'Select existing ': 'Sélectionnez existant ',
  Interaction: 'Interaction',
  Choice: 'Choix',
  Verification: 'Vérification',
  Registration: 'Inscription',
  Finalize: 'Finaliser',
  'Phone number': 'Numéro de téléphone',
  Or: 'Ou',
  'Number to call': 'Numéro à appeler',
  'Use the original caller id':
    'Utiliser l`identifiant de l`appelant d`origine',
  'Call as anonymous': 'Appel en tant qu`anonyme',
  'Create Node': 'Créer un nœud',
  'Update Node': 'Mettre à jour le nœud',
  'The Dialerbot execution depends on configuration!':
    'L`exécution de Dialerbot dépend de la configuration!',
  'The download has started!': 'Le téléchargement a commencé!',
  'The download has finished successfully!':
    'Le téléchargement s`est terminé avec succès!',
  'Something went wrong please contact your administrator!':
    'Quelque chose s`est mal passé, veuillez contacter votre administrateur!',
  'The number must be less or equal to 200':
    'Le nombre doit être inférieur ou égal à 200',
  'Delete report': 'Supprimer le rapport',
  'Create new service': 'Créer un nouveau service',
  'Must select company first': 'Doit d`abord sélectionner l`entreprise',
  You: 'Toi',
  Announcement: 'Annonce',
  Announcements: 'Annonces',
  'Time Conditions': 'Conditions de temps',
  'Send Email': 'Envoyer un e-mail',
  'Send SMS': 'Envoyer un SMS',
  Redirects: 'Redirections',
  'Call Forwardings': 'Renvois d`appels',
  Publish: 'Publier',
  Back: 'Dos',
  Hangups: 'raccroche',
  SendEmail: 'EnvoyerEmail',
  SendSMS: 'Envoyer un SMS',
  TimeCondition: 'Condition de temps',
  AriQueue: 'AriQueue',
  'Announcement configuration': 'Paramétrage des annonces',
  'Ivr configuration': 'Configuration SVI',
  'Crossroad configuration': 'Configuration carrefour',
  'TimeCondition configuration': 'Configuration de TimeCondition',
  'SendEmail configuration': 'Configuration de SendEmail',
  'SendSMS configuration': 'Configuration de SendSMS',
  'AriQueue configuration': 'Configuration d`AriQueue',
  'Hangup configuration': 'Raccrocher la configuration',
  'Redirect configuration': 'Configuration de la redirection',
  'Call Forwarding configuration': 'Configuration du renvoi d`appel',
  CallForwarding: 'Renvoi d`appel',
  on: 'sur',
  at: 'à',
  'Edit/Update user': 'Modifier/Mettre à jour l`utilisateur',
  'Use this dialog window to edit/update a user':
    'Utilisez cette fenêtre de dialogue pour modifier/mettre à jour un utilisateur',
  'Use this dialog window to add a new user':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouvel utilisateur',
  'Load Phones': 'Charger les téléphones',
  'In Progress': 'En cours',
  Desactivate: 'Désactiver',
  Pendings: 'En attente',
  completed: 'complété',
  'No answer': 'Pas de réponse',
  'New schedule': 'Nouveau programme',
  Robinson: 'Robinson',
  Rejected: 'Rejeté',
  Answerphone: 'Répondeur',
  Congestion: 'Congestion',
  Invalid: 'Invalide',
  'Hide details': 'Cacher les détails',
  'Show details': 'Afficher les détails',
  'Dont call': 'N`appelez pas',
  Reschedule: 'Reprogrammer',
  'Field deleted': 'Champ supprimé',
  'Could not delete field': 'Impossible de supprimer le champ',
  'Configure reschedules': 'Configurer les replanifications',
  'Dialerbot reschedules configuration':
    'Dialerbot reprogramme la configuration',
  'Select the number of call retries':
    'Sélectionnez le nombre de tentatives d`appel:',
  'Select the percentage reserved of relaunches:':
    'Sélectionnez le pourcentage réservé de relances:',
  'Select the call results for reschedules and the delay for each:':
    'Sélectionnez les résultats d`appel pour les reprogrammations et le délai pour chacun:',
  Attempts: 'Tentatives',
  relaunches: 'relance',
  'delay(min)': 'retard(min)',
  Attended: 'A participé',
  Unanswered: 'Sans réponse',
  Answeringmachine: 'Répondeur automatique',
  'Next step': 'L`étape suivante',
  'The number must be  equal to larger than to 1':
    'Le nombre doit être égal à supérieur à 1',
  'The field is required': 'Ce champ est requis',
  'Are you sure to remove the following?':
    '¿Voulez-vous vraiment supprimer les éléments suivants?',
  'The final week day cannot be less than the initial week day':
    'Le dernier jour de la semaine ne peut pas être inférieur au premier jour de la semaine',
  'The final day cannot be less than the initial day':
    'Le dernier jour ne peut pas être inférieur au premier jour',
  'The final hour cannot be less than the initial hour':
    'L`heure finale ne peut pas être inférieure à l`heure initiale',
  'The initial hour cannot be less than the final hour':
    'L`heure initiale ne peut pas être inférieure à l`heure finale',
  'The file you are trying to upload is already uploaded':
    'Le fichier que vous essayez de télécharger est déjà téléchargé',
  'Select a time range': 'Sélectionnez une plage horaire',
  'Add existing time range': 'Ajouter une plage horaire existante',
  'Add temporal range': 'Ajouter une plage temporelle',
  'You have to select one temporal range':
    'Vous devez sélectionner une plage temporelle',
  'The temporal range already exists:': 'La plage temporelle existe déjà: ',
  'Select roles...': 'Sélectionnez les rôles',
  'Outbound Routes': 'Routes sortantes',
  'Add number restrictions': 'Ajouter des restrictions de nombre',
  'Create new outbound route': 'Créer une nouvelle route sortante',
  'Add number restriction': 'Ajouter une restriction de nombre',
  'Create new number restriction': 'Créer une nouvelle restriction de numéro',
  Length: 'Longueur',
  'Start with': 'Commencer avec',
  'Length must be a number': 'La longueur doit être un nombre',
  'The length is required': 'La longueur est requis',
  'The start with is required': 'Le début par est obligatoire',
  'The number restriction was successfully added':
    'La restriction de nombre a été ajoutée avec succès',
  'Update number restriction': 'Mettre à jour la restriction du nombre',
  'The number restriction was successfully updated':
    'La restriction de nombre a été mise à jour avec succès',
  'The ddi is required': 'Le ddi est obligatoire',
  'The ddi must be a number': 'Le ddi doit être un nombre',
  'The route was successfully created': 'L`itinéraire a été créé avec succès',
  'The route was sucessfully enabled': 'La route a été activée avec succès',
  'The route was sucessfully disabled': 'La route a été désactivée avec succès',
  'The route was successfully updated':
    'L`itinéraire a été mis à jour avec succès',
  'At least one of the fields is required':
    'Au moins un des champs est obligatoire',
  'Add caller id': 'Ajouter l`identifiant de l`appelant',
  'Select caller id': 'Sélectionnez l`identification de l`appelant',
  Prefix: 'Préfixe',
  'The prefix must be numeric': 'Le préfixe doit être numérique',
  'The caller id is already added':
    'L`identifiant de l`appelant est déjà ajouté',
  'Sort ascending': 'Trier par ordre croissant',
  'Sort descending': 'Trier par ordre décroissant',
  'Search email': 'Chercher un email',
  'Copy user': 'Copier l`utilisateur',
  'Edit user': 'Modifier l`utilisateur',
  'Delete user': 'Supprimer l`utilisateur',
  username: 'nom d`utilisateur',
  date: 'date',
  firstLogin: 'première connexion',
  lastLogin: 'Dernière connexion',
  firstLogout: 'première déconnexion',
  lastLogout: 'dernière déconnexion',
  formattedDuration: 'Durée formatée',
  Filters: 'Filtres',
  'Select existing contacts': 'Sélectionnez les contacts existants',
  'Create new file': 'Créer un nouveau fichier',
  'Use this dialog window to Add Dialerbot file':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau fichier Dialerbot',
  'Create File': 'Créer un fichier',
  'Call until contact': 'Appeler jusqu`au contact',
  'Call all PhoneNumbers': 'Appeler tous les numéros de téléphone',
  'Tag field is required': 'Le champ de balise est obligatoire',
  'Select contact tags...': 'Sélectionnez les balises de contact...',
  'Contact tags': 'Balises de contact',
  'Add contact tags': 'Ajouter des balises de contact',
  'Add contact fields': 'Ajouter des champs de contact',
  'Contact field is required': 'Le champ de contact est obligatoire',
  'Select phone contact fields...':
    'Sélectionnez les champs de contact téléphonique...',
  'Phone contact fields': 'Champs de contact téléphonique',
  'Add or update contacts': 'Ajouter ou mettre à jour des contacts',
  'Server connection error': 'Erreur de connexion au serveur',
  'Add numbers': 'Ajouter des numéros',
  'Dialerbot type': 'Type de robot de numérotation',
  'The Inbound Route field is required':
    'Le champ Route entrante est obligatoire',
  'The min value is 1': 'La valeur minimale est 1',
  'The max value is 100': 'La valeur maximale est de 100',
  'The value must be numeric': 'La valeur doit être numérique',
  'The min values is 5': 'Les valeurs minimales sont 5',
  'Default time zone': 'Fuseau horaire par défaut',
  'Time zone': 'Fuseau horaire',
  'Change time zone': 'Changer de fuseau horaire',
  'Record percentage': 'Pourcentage record',
  'AI Models': 'Modèles d`IA',
  'Here you can find the models created to be used in the NLU node':
    'Vous trouverez ici les modèles créés pour être utilisés dans le nœud NLU',
  'Create AI MODEL': 'Créer un modèle d`IA',
  'Create new AI MODEL': 'Créer un nouveau MODÈLE IA',
  'Use this dialog window to add new AI MODEL':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau MODÈLE AI',
  'Write here the AI model name': 'Écrivez ici le nom du modèle d`IA',
  'Write here a description...': 'Écrivez ici une description...',
  Modify: 'Modifier',
  Schedule: 'Calendrier',
  'Copy AI MODEL': 'Copier le modèle IA',
  'Edit AI MODEL': 'Modifier le modèle IA',
  'Delete AI MODEL': 'Supprimer le modèle IA',
  'Edit one or several AI MODEL': 'Modifier un ou plusieurs MODÈLES AI',
  'Delete one or several AI MODELS': 'Supprimer un ou plusieurs MODÈLES IA',
  'You must enter at least one model': 'Vous devez saisir au moins un modèle',
  'The AI MODEL was successfully registered':
    'Le MODÈLE AI a été enregistré avec succès',
  'No AI MODEL selected': 'Aucun MODÈLE AI sélectionné',
  'Write the intention name here...': 'Écrivez le nom de l`intention ici...',
  'Answering machine detection': 'Détection du répondeur',
  'Add label': 'Ajouter une étiquette',
  Label: 'Étiquette',
  'Update AI MODEL': 'Mettre à jour le modèle d`IA',
  'Type intention name': 'Tapez le nom de l`intention',
  'First edit the intention opened': 'Modifiez d`abord l`intention ouverte',
  'First save the intention name': 'Enregistrez d`abord le nom de l`intention',
  'You have to write one intention': 'Vous devez écrire une intention',
  'You have to write one tag in every intention':
    'Vous devez écrire une balise dans chaque intention',
  'The priority was updated successfully':
    'La priorité a été mise à jour avec succès',
  'Priority Hight': 'Haute priorité',
  'Priority Medium': 'Médium prioritaire',
  'Priority Low': 'Priorité Baja',
  Language: 'Langue',
  Example: 'Exemple',
  'Error, please contact the administrator.':
    'Erreur, veuillez contacter l´administrateur.',
  'Could not detect delimiter automatically.':
    'Impossible de détecter automatiquement le délimiteur.',
  'You have selected': 'Vous avez sélectionné',
  file: 'déposer',
  'Drag & drop files here': 'Glissez et déposez les fichiers ici',
  'Progressive configuration': 'Configuration progressive',
  'Use this dialog window to add a new Predictive file':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau fichier prédictif',
  'Use this dialog window to add a new Progressive file':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau fichier progressif',
  'Use this dialog window to add a new DialerBot file':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau fichier Dialerbot',
  'The Predictive execution depends on configuration!':
    'L`exécution prédictive dépend de la configuration !',
  'The Progressive execution depends on configuration!':
    'L`exécution progressive dépend de la configuration !',
  CREATE_NEW_COMPANY: 'Créer une nouvelle entreprise',
  'Update a company': 'Mettre à jour une entreprise',
  'Use this dialog window to update a company':
    'Utiliser cette fenêtre de dialogue pour mettre à jour une entreprise',
  'Update company': 'Mettre à jour l`entreprise',
  'Create company': 'Créer une entreprise',
  Licenses: 'Licences',
  'Number of licenses': 'Nombre de licences',
  'Use this dialog window to create a new company':
    'Utilisez cette fenêtre de dialogue pour créer une nouvelle entreprise',
  Extensions: 'Rallonges',
  'Displayed Extensions': 'Extensions affichées',
  'Typology for maximum rescheduling achieved':
    'Typologie pour un rééchelonnement maximum réalisé',
  'Ticket sub-typologies': 'Sous-typologies de billets',
  'Add ticket sub-typologies to configure a relaunch':
    'Ajouter des sous-typologies de tickets pour configurer une relance',
  'Sub-typologies': 'Sous-typologies',
  'Create campaign': 'Créer une campagne',
  'Update campaign': 'Campagne de mise à jour',
  'Use this dialog window to create a campaign':
    'Utilisez cette fenêtre de dialogue pour créer une campagne',
  'Use this dialog window to update a campaign':
    'Utiliser cette fenêtre de dialogue pour mettre à jour une campagne',
  'End date': 'Date de fin',
  MOH_TITLE: 'Musique en attente',
  MOH_SUBTITLE: `Gérer la musique d'attente`,
  MOH_CREATE_BUTTON: `Créer de la musique d'attente`,
  MOH_CREATE_BUTTON_NEW: 'Create new queue Music On Hold',
  MOH_CREATE_TITLE: `Créer une nouvelle musique d'attente`,
  MOH_CREATE_SUBTITLE: `Utilisez cette fenêtre de dialogue pour ajouter une musique d'attente`,

  MOH_UPDATE_TITLE: `Mettre à jour la nouvelle musique d'attente`,
  MOH_UPDATE_SUBTITLE: `Utilisez cette fenêtre de dialogue pour mettre à jour une musique d'attente`,

  MOH_COMPANY: 'Entreprise',
  MOH_NAME: 'Nom',
  MOH_ORDER: 'Commande',
  MOH_AUDIOS: 'Audios',
  MOH_ADD_NEW: `Ajouter une nouvelle annonce`,
  MOH_CANCEL_BUTTON: 'Annuler',
  MOH_UPDATE_BUTTON: 'Mise à jour',
  MOH_SUCCESS_CREATE_MESSAGE: `Musique d'attente créée avec succès`,
  MOH_SUCCESS_UPDATE_MESSAGE: `Musique d'attente mise à jour avec succès`,
  MOH_DELETE_BUTTON: `Supprimer la musique d'attente`,
  MOH_COMPANY_REQUIRED: 'Le champ Société est obligatoire',
  MOH_NAME_REQUIRED: 'le champ npbre est obligatoire',
  MOH_ORDER_REQUIRED: 'Le champ Commande est obligatoire',
  MOH_AUDIOS_REQUIRED: 'Le champ Audios est obligatoire',
  Random: 'Aléatoire',
  Sequential: 'Séquentiel',

  'The start date cannot be later than the end date':
    'La date de début ne peut pas être postérieure à la date de fin',
  'Repeat every year': 'Répéter chaque année',
  'Punctual day': 'Journée ponctuelle',
  To_hasta: 'À',
  'Create and enable': 'Créer et activer',
  'New WhatspApp Account': 'Nouveau compte WhatsApp',
  'Edit WhatspApp Account': 'Modifier le compte WhatsApp',
  'Use this dialog window to create new WhatspApp Account':
    'Utilisez cette fenêtre de dialogue pour créer un nouveau compte WhatsApp',
  'Use this dialog window to edit WhatspApp Account':
    'Utilisez cette fenêtre de dialogue pour modifier le compte WhatsApp',
  'Account details': 'Détails du compte',
  'Provider details': 'Détails du fournisseur',
  'Name of Username': 'Nom du nom d`utilisateur',
  'The username field is required':
    'Le champ nom d`utilisateur est obligatoire',
  JOIN: 'Rejoindre',
  'Show password': 'Afficher le mot de passe',
  'Use this dialog window to mange the META templates':
    'Utilisez cette fenêtre de dialogue pour gérer les modèles META',
  'Use this dialog window to mange the templates':
    'Utilisez cette fenêtre de dialogue pour gérer les modèles',
  'Templates from': 'Modèles de',
  'Search template here...': 'Modèle de recherche ici...',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (@, !, #, %, &, *)':
    'Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et une casse spéciale (@, !, #, %, &, *)',
  'Password cannot contain username':
    'Le mot de passe ne peut pas contenir le nom d`utilisateur',
  'Show disabled': 'Afficher désactivé',
  'WhatsApp State': 'Statut WhatsApp',
  'New webchat style': 'Nouveau style de chat en ligne',
  'Use this dialog window to create new webchat style':
    'Utilisez cette fenêtre de dialogue pour créer un nouveau style de chat Web',
  'Template Name': 'Nom du modèle',
  Category: 'Catégorie',
  Message: 'Message',
  Marketing: 'Commercialisation',
  Utility: 'Utilitaire',
  'Custom Message': 'Message personnalisé',
  'Send promo offers, product offers more to increase awareness and engagement.':
    'Envoyez davantage d`offres promotionnelles et d`offres de produits pour accroître la notoriété et l`engagement.',
  'Send account updates, order updates, alerts and more to share important information.':
    'Envoyez des mises à jour de compte, des mises à jour de commandes, des alertes et bien plus encore pour partager des informations importantes.',
  'Send promotional offers, announcements and more to increase awareness and engagement.':
    'Envoyez des offres promotionnelles, des annonces et bien plus encore pour accroître la notoriété et l`engagement.',
  'Header (Optional)': 'En-tête (facultatif)',
  'Header text': 'En-tête',
  'Add a 60 character title to your message. Only one variable is supported in the header.':
    'Ajoutez un titre de 60 caractères à votre message. Une seule variable est prise en charge dans l`en-tête.',
  'Define sample variable value for header:':
    'Définissez un exemple de valeur de variable pour l`en-tête:',
  Body: 'Corps',
  'Enter the text for your message in the language you have selected.':
    'Saisissez le texte de votre message dans la langue que vous avez sélectionnée.',
  'Define sample variable value for body:':
    'Définissez un exemple de valeur de variable pour le corps:',
  'Footer text (Optional)': 'Texte de pied de page (facultatif)',
  'Hide sample templates': 'Masquer les exemples de modèles',
  'Join Videocall': "Rejoindre l'appel vidéo",
  'Add variable': 'Ajouter une variable',
  'Conversation preview:': 'Aperçu de la conversation:',
  'WhatsApp with': 'WhatsApp avec',
  'The keyword already exists': 'Le mot-clé existe déjà',
  'The keyword group was created succesfully':
    'Le groupe de mots clés a été créé avec succès',
  'The keyword group was updated succesfully':
    'Le groupe de mots clés a été mis à jour avec succès',
  'Update Keywords group': 'Groupe Mettre à jour les mots clés',
  'Create a keywords group': 'Créer un groupe de mots-clés',
  'The role is in use:': 'Le rôle est en cours d`utilisation:',
  'The App number is required': 'Le numéro d`application est requis',
  'The API key is required': 'La clé API est requise',
  'Displayed extension': 'Poste affiché',
  'Create extensions from range': 'Créer des extensions à partir de la plage',
  'Status Name': 'Nom du statut',
  'Status Type': 'Type de statut',
  'Status Color': 'Couleur d`état',
  'Add new status': 'Ajouter un nouveau statut',
  AdministrativeTime: 'Temps administratif',
  'The company was successfully created': 'La société a été créée avec succès',
  'The extensions could not be created':
    'Les extensions n`ont pas pu être créées',
  'The company could not be created': 'La société n`a pas pu être créée',
  'The company was deleted:': 'La société a été supprimée:',
  'The company was successfully updated':
    'La société a été mise à jour avec succès',
  'The company could not be updated': 'La société n`a pas pu être mise à jour',
  'This field is required': 'Ce champ est obligatoire',
  'This title is required': 'Ce titre est obligatoire',
  'updated successfully': 'Mis à jour avec succés',
  'created successfully': 'créé avec succès',
  'The WhatsApp Account was sucessfully created':
    'Le compte WhatsApp a été créé avec succès',
  'The WhatsApp Account was sucessfully updated':
    'Le compte WhatsApp a été mis à jour avec succès',
  'The WhatsApp Account was deleted:': 'Le compte WhatsApp a été supprimé:',
  'Use this dialog window to add a new':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau',
  'Failed upload': 'Échec du téléchargement',
  'The extension cannot have only white spaces':
    'L`extension ne peut pas avoir uniquement des espaces blancs',
  'Add extensions to selected groups':
    'Ajouter des extensions aux groupes sélectionnés',
  'The campaign was sucessfully delete':
    'La campagne a été supprimée avec succèsd',
  'You must select at least one day':
    'Vous devez sélectionner au moins un jour',
  'Campaign created succesfully': 'Campagne créée avec succès',
  'Service updated succesfully': 'Service mis à jour avec succès',
  'Campaign updated succesfully': 'Campagne mise à jour avec succès',
  'Videoroom could not be created': "La salle vidéo n'a pas pu être créée",
  'Videoroom created successfully': 'Salle vidéo créée avec succès',
  'Enable one company': 'Activer une entreprise',
  'Disable one company': 'Désactiver une entreprise',
  'Enable one or several companies': 'Activer une ou plusieurs entreprises',
  'Disable one or several companies': 'Désactiver une ou plusieurs entreprises',
  'The company was enabled successfully':
    'L`entreprise a été activée avec succès',
  'The company was disabled successfully':
    'L`entreprise a été désactivée avec succès',
  'You must select at least one company':
    'Vous devez sélectionner au moins une entreprise',
  'The company is already enabled': 'L`entreprise est déjà activée',
  'The company is already disabled': 'L`entreprise est déjà désactivée',
  'You cannot delete an active company':
    'Vous ne pouvez pas supprimer une entreprise active',
  'Edit company': 'Modifier l`entreprise',
  'You don"t have announcements permissions':
    'Vous n`avez pas d`autorisations d`annonces',
  'Videocall has finished or it does not exist':
    "L'appel vidéo est terminé ou n'existe pas",
  "The videoroom is full, you can't join":
    'La salle de vidéoconférence est pleine, vous ne pouvez pas rejoindre.',
  'Add group': 'Ajouter un groupe',
  'Upload contacts': 'Télécharger des contacts',
  'Create new tag': 'Créer une nouvelle balise',
  'Create new field': 'Créer un nouveau champ',
  'Thank you for attending!': "Merci d'avoir assisté!",
  'You have been rejected...': 'Vous avez été rejeté...',
  'You have been kicked out of the video call':
    "Vous avez été expulsé de l'appel vidéo",
  'The videoroom is full': 'La salle de vidéoconférence est pleine',
  'The video call has already ended or does not exist':
    "L'appel vidéo est déjà terminé ou n'existe pas",
  'The template was created successfully': 'Le modèle a été créé avec succès',
  'The mesage template was successfully sent':
    'Le modèle de message a été envoyé avec succès',
  'The is no approved templates': 'Il n`y a aucun modèle approuvé',
  'These are the extensions': 'Ce sont les extensions',
  'These are the extensions groups': 'Ce sont les groupes d`extensions',
  'Extensions Groups': 'Groupes d`extensions',
  'Create extensions groups': 'Créer des groupes d`extensions',
  Approve: 'Approuver',
  'The WhatsApp templates was successfully updated':
    'Les modèles WhatsApp ont été mis à jour avec succès',
  'The templates could not be loaded': 'Les modèles n`ont pas pu être chargés',
  Downloads: 'Téléchargements',
  'All aspects related to the downloads can be managed here':
    'Tous les aspects liés aux téléchargements peuvent être gérés ici',
  'Add account': 'Ajouter un compte',
  'Add style': 'Ajouter du style',
  'Add pre written message': 'Ajouter un message pré-écrit',
  'Delete Field': 'Supprimer le champ',
  'Add button': 'Bouton Ajouter',
  'The text is required': 'Le texte est obligatoire',
  'Use this dialog window to create new whatsapp template':
    'Utilisez cette fenêtre de dialogue pour créer un nouveau modèle WhatsApp',
  'You must add at least one button': 'Vous devez ajouter au moins un bouton',
  Services: 'Prestations de service',
  'Use this dialog window to create a new service':
    'Utilisez cette fenêtre de dialogue pour créer un nouveau service',
  buttons: 'boutons',
  'Here are the roles and permissions': 'Voici les rôles et autorisations',
  'Add custom role': 'Ajouter un rôle personnalisé',
  'Create new custom role': 'Créer un nouveau rôle personnalisé',
  'Edit role': 'Modifier le rôle',
  'Use this dialog window to edit a role':
    'Utilisez cette fenêtre de dialogue pour modifier un rôle',
  'Use this dialog window to add a new role':
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau rôle',
  'All aspects related to the tickets can be managed here':
    'Tous les aspects liés aux billets peuvent être gérés ici',
  'Add typologies': 'Ajouter des typologies',
  'Add ticket': 'Ajouter un billet',
  'Add fields': 'Ajouter des champs',
  Typologies: 'Typologies',
  Fields: 'Des champs',
  'Stop sharing screen': "Arreter le partage de l'écran",
  'Share screen': "Partager l'écran",
  'Route cloned succesfully': 'Route clonée avec succès',
  'The Route was not deleted': 'L`itinéraire n`a pas été supprimé',
  'The Route was not disabled.': 'L`itinéraire n`a pas été désactivé.',
  'Text cannot be just blank spaces':
    'Le texte ne peut pas être constitué uniquement d`espaces vides',
  'What the contact will see:': 'Ce que le contact verra :',
  'Add Contact Field': 'Ajouter un champ de contact',
  'Remove Contact Field': 'Supprimer un champ de contact',
  'Select Contact Field': 'Sélectionner un champ de contact',
  'Change Contact Field Label': "Changer l'étiquette du champ de contact",
  'Move Down': 'Descendre',
  'Move Up': 'Monter',
  'Last email date': 'Date du dernier e-mail',
  'Email account address': 'Adresse du compte e-mail',
  'Last email sender': 'Dernier expéditeur du courrier électronique',
  'Last email subject': 'Objet du dernier email',
  'Role name': 'Nom du rôle',
  'Create new contact': 'Créer un nouveau contact',
  'Create new account': 'Créer un nouveau compte'
};

export default frJSON;
