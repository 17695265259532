import moment from 'moment';
import { DataCategory } from 'src/components/TableReporting/models/availableFilters';
import { getServicesQuery } from 'src/modules/Admin/modules/Operations/modules/DaServices/views/hooks/daServiceQueries';
import { getTicketTagsQuery } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/tags';
import { getTicketSubResolutionsQuery } from 'src/modules/Admin/modules/Operations/modules/Tickets/views/utils/getTicketSubResolutionsQuery';
import { getContactFieldValueFromType } from 'src/modules/Contacts/domain/getContactInfo';
import { getContactsListQuery } from 'src/modules/Contacts/views/utils/getContactsListQuery';
import { getDialerBotSchedulesQuery } from 'src/modules/DialerBot/views/utils/getDialerBotSchedulesQuery';
import {
  getCampaignsQuery,
  getCompaniesQuery,
  getUsersListQuery
} from 'src/services/users/application/query/getUsersDataQueries';
import i18n from 'src/utils/react-libreria-html/src/i18n';

export const getFiltersValuesFromDataCategory = async (
  filterKeys: string[],
  dataCategory: DataCategory,
  companyId?: string
) => {
  let arrayKeys = filterKeys;
  switch (dataCategory) {
    case 'AGENTID':
      const users = await getUsersListQuery(filterKeys);
      arrayKeys = users.map((user) => user.username);
      break;
    case 'CAMPAIGNID':
      const campaigns = (await getCampaignsQuery({ companyId }))?.elements;
      arrayKeys = campaigns
        .filter((campaign) => filterKeys.includes(campaign.id))
        .map((campaign) => campaign.name);
      break;
    case 'COMPANYID':
      const companies = await getCompaniesQuery();
      arrayKeys = companies
        .filter((company) => filterKeys.includes(company.id))
        .map((company) => company.name);
      break;
    case 'CONTACTID':
      const contacts = await getContactsListQuery(filterKeys);
      const contactNamesPromises = contacts.map((contact) =>
        getContactFieldValueFromType(contact, 'Name')
      );
      arrayKeys = await Promise.all(contactNamesPromises);
      break;
    case 'DATABASEID':
      const paginatedSchedules = await getDialerBotSchedulesQuery({
        scheduleDatabaseId: filterKeys[0]
      });
      arrayKeys = [paginatedSchedules.elements?.[0]?.filename];
      break;
    case 'DATE':
      const formattedDates = filterKeys.map((date) => {
        const momentObject = moment(date);
        momentObject.locale(i18n.language);
        const formattedDate = momentObject.format('LLLL');
        return formattedDate;
      });
      filterKeys = formattedDates;
      break;
    case 'SERVICEID':
      const services = await getServicesQuery({ ids: filterKeys });
      filterKeys = services.elements.map((service) => service.name);
      break;
    case 'SUBRESOLUTIONID':
      const subResolutions = await getTicketSubResolutionsQuery({ companyId });
      filterKeys = subResolutions
        .filter((subResolution) => filterKeys.includes(subResolution.id))
        .map((subResolution) => subResolution.name);
      break;
    case 'TAGID':
      const tags = await getTicketTagsQuery({ companyId });
      filterKeys = tags
        .filter((tag) => filterKeys.includes(tag?.id))
        .map((tag) => tag.name);
      break;
  }
  return arrayKeys.join(', ');
};
