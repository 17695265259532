const deJSON = {
  NONDISPLAYEDNAME: '',
  Blueprints: 'Blaupausen',
  'Extended Sidebar': 'Erweiterte Seitenleiste',
  'Accent Sidebar': 'Akzent-Sidebar',
  'Accent Header': 'Akzentkopfzeile',
  'Boxed Sidebar': 'Verschachtelte Seitenleiste',
  'Collapsed Sidebar': 'Zugeklappte Seitenleiste',
  'Bottom Navigation': 'Untere Navigation',
  'Top Navigation': 'Top-Navigation',
  'Lead Developer': 'Leitender Entwickler',
  'Mega menu': 'Mega-Menü',
  Doctors: 'Ärzte',
  Reports: 'Berichte',
  'Custom dashboard built using the included components':
    'Benutzerdefiniertes Dashboard erstellt mit den enthaltenen Komponenten',
  Hospital: 'Krankenhaus',
  Export: 'Export',
  'Last year': 'Letztes Jahr',
  Expenses: 'Ausgaben',
  Products: 'Produkte',
  Statistics: 'Statistiken',
  General: 'Allgemein',
  Automation: 'Automatisierung',
  'Data Display': 'Datenanzeige',
  Calendar: 'Kalender',
  Mailbox: 'Briefkasten',
  Dashboards: 'Dashboards',
  Analytics: 'Analytik',
  Banking: 'Bankwesen',
  Commerce: 'Handel',
  Crypto: 'Krypto',
  Finance: 'Finanzen',
  Fitness: 'Fitness',
  Healthcare: 'Gesundheitspflege',
  'Doctors Page': 'Ärzte Seite',
  'Hospital Overview': 'Krankenhausübersicht',
  Helpdesk: 'Beratungsstelle',
  Learning: 'Lernen',
  Monitoring: 'Überwachung',
  Tasks: 'Aufgaben',
  Applications: 'Anwendungen',
  'File Manager': 'Dateimanager',
  'Jobs Platform': 'Jobs Plattform',
  Messenger: 'Bote',
  'Projects Board': 'Projektvorstand',
  Management: 'Management',
  Users: 'Benutzer',
  'List All': 'Liste alle auf',
  'User Profile': 'Benutzerprofil',
  Projects: 'Projekte',
  Shop: 'Geschäft',
  'Products List': 'Produktliste',
  'View Product': 'Produkt anzeigen',
  'Create Product': 'Produkt erstellen',
  Invoices: 'Rechnungen',
  'View Single': 'Single anzeigen',
  'Auth Pages': 'Auth-Seiten',
  Login: 'Anmeldung',
  Basic: 'Basic',
  Cover: 'Startseite',
  Register: 'Registrieren',
  Wizard: 'Magier',
  'Recover Password': 'Passwort wiederherstellen',
  Status: 'Status',
  Foundation: 'Stiftung',
  'Extra Pages': 'Zusätzliche Seiten',
  'Error 404': 'Fehler 404',
  'Error 500': 'Fehler 500',
  'Coming Soon': 'Kommt bald',
  Maintenance: 'Instandhaltung',
  Overview: 'Überblick',
  'Layout Starters': 'Layout-Starter',
  'Starter Kit 1': 'Starter Kit 1',
  'Starter Kit 2': 'Starter Kit 2',
  Documentation: 'Dokumentation',
  Welcome: 'Herzlich willkommen',
  Help: 'Hilfe',
  'Features tour': 'Features Tour',
  'Getting started guide': 'Erste Schritte Anleitung',
  'Contact support': 'Kontaktieren Sie Support',
  Version: 'Ausführung',
  Search: 'Suche',
  Notifications: 'Benachrichtigun',
  Settings: 'Einstellungen',
  'Language Switcher': 'Sprach Wechsler',
  'Sign out': 'Ausloggen',
  'Change Theme': 'Thema ändern',
  'View all notifications': 'Alle Benachrichtigungen anzeigen',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Tokyo NextJS Typescript Admin Dashboard',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Hochleistungs-React-Vorlage mit vielen leistungsstarken Material-UI-Komponenten in mehreren Produktnischen für schnelle und perfekte Entwicklungsprozesse für Apps',
  'Browse Live Preview': 'Durchsuchen Sie die Live-Vorschau',
  'Live Preview': 'Live Vorschau',
  'Key Features': 'Hauptmerkmale',
  dashboards: 'dashboards',
  applications: 'anwendungen',
  'management sections': 'management-bereiche',
  'dark/light themes': 'dunkle / helle themen',
  components: 'komponenten',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Einige der Funktionen, die Tokio zu einer der besten heute verfügbaren Admin-Vorlagen machen',
  'Design Source Files': 'Quelldateien entwerfen',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Beginnen Sie direkt mit einem der mitgelieferten Starter-Kits mit der Arbeit an Ihrem Projekt oder verwenden Sie zuerst die Figma / Sketch-Dateien, um einen Prototyp zu erstellen',
  'We only translated a small part of the template, for demonstration purposes':
    'Wir haben nur einen kleinen Teil der Vorlage zu Demonstrationszwecken übersetzt',
  Asigned: 'Zugewiesen',
  Queue: 'Warteschlange',
  Calls: 'Anrufe',
  Attend: 'Teilnehmen',
  Conversations: 'Gespräche',
  Transfer: 'Transfer',
  Spy: 'Spion',
  'Choose an extension for transfering':
    'Wählen Sie eine Erweiterung für die Übertragung aus',
  'No extensions available': 'Keine Erweiterungen verfügbar',
  'Choose a mode for spying': 'Wählen Sie einen Spionagemodus',
  Loading: 'Wird geladen',
  'Start Dialerbot': 'Dialerbot starten',
  'The dialerBot was updated': 'Der dialerBot wurde erfolgreich aktualisiert',
  'Update a dialerbot campaign': 'Aktualisieren Sie eine Dialerbot-Kampagne',
  'Use this dialog window to update a dialerbot':
    'Verwenden Sie dieses Dialogfenster, um einen Dialerbot zu aktualisieren',
  'The document was deleted successfully':
    'Das Dokument wurde erfolgreich gelöscht',
  'The dialerBot was paused successfully':
    'Der dialerBot wurde erfolgreich angehalten',
  'The report was generated successfully':
    'Der Bericht wurde erfolgreich erstellt',
  'The dialerBot was started successfully':
    'Der dialerBot wurde erfolgreich gestartet',
  'A connection error occurred, contact the administrator':
    'Ein Verbindungsfehler ist aufgetreten, wenden Sie sich an den Administrator',
  'The email was sent successfully': 'Die E-Mail wurde erfolgreich gesendet',
  'You must enter the destination email': 'Sie müssen die Ziel-E-Mail eingeben',
  'Type your message here': 'Schreiben Sie ihre Nachricht hier',
  'Forwarded message': 'Weitergeleitete Nachricht',
  'The file you are trying to upload is already uploaded.':
    'Die Datei, die Sie hochladen möchten, wurde bereits hochgeladen.',
  'View attachments': 'Anhänge ansehen',
  'Error launching the call': 'Fehler beim Starten des Anrufs',
  'New conversation': 'Neue Unterhaltung',
  Recents: 'Kürzlich',
  'Type your': 'Geben Sie Ihre',
  phone: 'Telefon',
  Call: 'Anruf',
  'Call transfer success!': 'Anrufübertragung erfolgreich!',
  'Transfer in progress': 'Übertragung in Bearbeitung',
  'Transfer failed': 'Übertragung fehlgeschlagen',
  'Select an agent:': 'Wählen Sie einen Agenten aus:',
  'A call cannot be transfered to itself or to the same extension that it is already talking with.':
    'Ein Anruf kann nicht an sich selbst oder an dieselbe Nebenstelle weitergeleitet werden, mit der er bereits spricht.',
  'The call ended successfully': 'Der Anruf wurde erfolgreich beendet',
  'An error occurred in the connection':
    'Bei der Verbindung ist ein Fehler aufgetreten',
  'The call was successfully transferred':
    'Der Anruf wurde erfolgreich weitergeleitet',
  'The call was successfully unparked': 'Der Anruf wurde erfolgreich entparkt',
  'The call was successfully parked': 'Der Anruf wurde erfolgreich geparkt',
  'The call was successfully captured': 'Der Anruf wurde erfolgreich erfasst',
  'The conversation was closed successfully':
    'Die Konversation wurde erfolgreich geschlossen',
  'The call is being spied on successfully':
    'Der Anruf wird erfolgreich ausspioniert',
  'Do not disturb': 'Nicht stören',
  'Reset calls': 'Anrufe aktualisieren',
  'Maximize bar': 'Maximieren',
  'Compress bar': 'Kompresse',
  'Your session is about to expire': 'Ihre Sitzung läuft bald ab',
  "I'm still here": 'Ich bin noch hier',
  'The user could not be deleted': 'Der Benutzer konnte nicht gelöscht werden',
  'The user could not be created': 'Der Benutzer konnte nicht erstellt werden',
  'Update User': 'Benutzer aktualisieren',
  'The user could not be updated':
    'Der Benutzer konnte nicht aktualisiert werden',
  'The user was successfully created':
    'Der Benutzer wurde erfolgreich erstellt',
  'The user was successfully updated':
    'Der Benutzer wurde erfolgreich aktualisiert',
  Username: 'Nutzername',
  Description: 'Beschreibung',
  Extension: 'Verlängerung',
  'The user was successfully cloned': 'Der Benutzer wurde erfolgreich geklont',
  'The user could not be cloned': 'Der Benutzer konnte nicht geklont werden',
  Account: 'Konto',
  Templates: 'Vorlagen',
  'Create new user': 'Neuen Benutzer anlegen',
  'There are the users for company': 'Es gibt die Benutzer für Unternehmen',
  'All Users': 'Alle Nutzer',
  'Users Group': 'Benutzergruppe',
  'Add user': 'Benutzer hinzufügen',
  'Add users CSV': 'Benutzer-CSV hinzufügen',
  'Create user group': 'Benutzergruppe erstellen',
  'Add users group CSV': 'Benutzergruppen-CSV hinzufügen',
  'WhatspApp Accounts': 'WhatsApp-Konten',
  'All aspects related to WhatspApp can be managed from this page':
    'Alle Aspekte im Zusammenhang mit WhatsApp können von dieser Seite aus verwaltet werden',
  'Add WhatspApp account': 'WhatsApp-Konto hinzufügen',
  Accounts: 'Konten',
  'Meta templates': 'Meta-Vorlagen',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character':
    'Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen',
  'The name is required': 'Der Name ist erforderlich',
  'The password is required': 'Das Passwort ist erforderlich',
  'The email is required': 'Die E-Mail ist erforderlich',
  'The description is required': 'Die Beschreibung ist erforderlich',
  'The company is required': 'Die Firma ist erforderlich',
  'The Asign extension is required':
    'Die Zuweisungserweiterung ist erforderlich',
  'The extension is required': 'Die Erweiterung ist erforderlich',
  'The email format is invalid': 'Das E-Mail-Format ist ungültig',
  'Please retype your password': 'Bitte geben Sie ihr Passwort erneut ein',
  'Your passwords do not match': 'Ihre Passwörter stimmen nicht überein',
  'The password can not be longer than 16 characters':
    'Das Passwort darf nicht länger als 16 Zeichen sein',
  Preview: 'Vorschau',
  'No User selected': 'Kein Benutzer ausgewählt',
  'Delete one or several users': 'Löschen Sie einen oder mehrere Benutzer',
  'Disable one or several users':
    'Deaktivieren Sie einen oder mehrere Benutzer',
  'Enable one or several users': 'Aktivieren Sie einen oder mehrere Benutzer',
  'The user was enabled sucessfully':
    'Der Benutzer wurde erfolgreich aktiviert',
  'The user was disabled sucessfully':
    'Der Benutzer wurde erfolgreich deaktiviert',
  'Create new WhatspApp Account': 'Erstellen Sie ein neues WhatsApp-Konto',
  'Use this dialog window to add a new WhatspApp Account':
    'Verwenden Sie dieses Dialogfenster, um ein neues WhatsApp-Konto hinzuzufügen',
  'Create account': 'Benutzerkonto erstellen',
  'Edit/Update WhatspApp Account': 'WhatsApp-Konto bearbeiten/aktualisieren',
  'Use this dialog window to edit/update WhatspApp Account':
    'Verwenden Sie dieses Dialogfenster, um das WhatsApp-Konto zu bearbeiten/aktualisieren',
  'Update account': 'Account aktualisieren',
  'The key is required': 'Der Schlüssel ist erforderlich',
  'The phone number is required': 'Die Telefonnummer ist erforderlich',
  'The provider is required': 'Der Anbieter ist erforderlich',
  'The WhatsApp account was successfully created':
    'Das WhatsApp-Konto wurde erfolgreich erstellt',
  'The WhatsApp account was successfully updated':
    'Das WhatsApp-Konto wurde erfolgreich aktualisiert',
  'The WhatsApp account could not be created':
    'Das WhatsApp-Konto konnte nicht erstellt werden',
  'The WhatsApp account could not be updated':
    'Das WhatsApp-Konto konnte nicht aktualisiert werden',
  'No WhatsApp account selected': 'Kein WhatsApp-Konto ausgewählt',
  'Delete one or several WhatsApp account':
    'Ein oder mehrere WhatsApp-Konten löschen',
  'Disable one or several WhatsApp account':
    'Ein oder mehrere WhatsApp-Konten deaktivieren',
  'Enable one or several WhatsApp account':
    'Ein oder mehrere WhatsApp-Konten aktivieren',
  'The WhatsApp account was enable sucessfully':
    'Das WhatsApp-Konto wurde erfolgreich aktiviert',
  'The WhatsApp account was disable sucessfully':
    'Das WhatsApp-Konto wurde erfolgreich deaktiviert',
  'No Routes selected': 'Keine Routen ausgewählt',
  'The selected route is already enabled':
    'Die ausgewählte Route ist bereits aktiviert',
  'The selected route is already disabled':
    'Die ausgewählte Route ist bereits deaktiviert',
  'The route was enable sucessfully': 'Die Route wurde erfolgreich aktiviert',
  'The route was disable sucessfully':
    'Die Route wurde erfolgreich deaktiviert',
  'The selected user is already disabled':
    'Der ausgewählte Benutzer ist bereits deaktiviert',
  'The selected user is already enabled':
    'Der ausgewählte Benutzer ist bereits aktiviert',
  'The selected WhatsApp account is already enabled':
    'Das ausgewählte WhatsApp-Konto ist bereits aktiviert',
  'The selected WhatsApp account is already disabled':
    'Das ausgewählte WhatsApp-Konto ist bereits deaktiviert',
  'Time groups': 'Zeitgruppen',
  'These are the time groups': 'Dies sind die Zeitgruppen',
  'Create extensions': 'Erweiterungen erstellen',
  'Create new extension': 'Neue Erweiterung erstellen',
  'Use this dialog window to add a new time group':
    'Verwenden Sie dieses Dialogfenster, um eine neue Zeitgruppe hinzuzufügen',
  'Create time group': 'Zeitgruppe erstellen',
  'The time group was successfully created':
    'Die Zeitgruppe wurde erfolgreich erstellt',
  'The time group could not be created':
    'Die Zeitgruppe konnte nicht erstellt werden',
  'Create Extension': 'Erweiterung erstellen',
  'Write here the time group name...':
    'Schreiben Sie hier den Zeitgruppennamen...',
  'The time group was successfully deleted':
    'Die Zeitgruppe wurde erfolgreich gelöscht',
  'The time group could not be deleted':
    'Die Zeitgruppe konnte nicht gelöscht werden',
  'Copy time group': 'Zeitgruppe kopieren',
  'Edit time group': 'Zeitgruppe bearbeiten',
  'Delete time group': 'Zeitgruppe löschen',
  'No time groups selected': 'Keine Zeitgruppen ausgewählt',
  'Clone one or several time groups':
    'Klonen Sie eine oder mehrere Zeitgruppen',
  'Delete one or several time group':
    'Löschen Sie eine oder mehrere Zeitgruppen',
  'The temporal range was successfully deleted':
    'Der Zeitbereich wurde erfolgreich gelöscht',
  'The temporal range could not be deleted':
    'Der Zeitbereich konnte nicht gelöscht werden',
  'The temporal range was successfully updated':
    'Der Zeitbereich wurde erfolgreich aktualisiert',
  'The temporal range could not be updated':
    'Der Zeitbereich konnte nicht aktualisiert werden',
  'The time group was successfully copied':
    'Die Zeitgruppe wurde erfolgreich kopiert',
  'The time group could not be copied':
    'Die Zeitgruppe konnte nicht kopiert werden',
  'Update time group': 'Zeitgruppe aktualisieren',
  'Use this dialog window to update a time group':
    'Verwenden Sie dieses Dialogfenster, um eine Zeitgruppe zu aktualisieren',
  'The temporal range was successfully copied':
    'Der Zeitbereich wurde erfolgreich kopiert',
  'The temporal range could not be copied':
    'Der Zeitbereich konnte nicht kopiert werden',
  'Copy temporal range': 'Zeitbereich kopieren',
  'Edit temporal range': 'Zeitbereich bearbeiten',
  'Delete temporal range': 'Zeitbereich löschen',
  'Create new time range': 'Neuen Zeitbereich erstellen',
  'Update a time range': 'Aktualisieren Sie einen Zeitraum',
  'Use this dialog window to add a new time range':
    'Verwenden Sie dieses Dialogfenster, um einen neuen Zeitbereich hinzuzufügen',
  'Use this dialog window to update a time range':
    'Verwenden Sie dieses Dialogfenster, um einen Zeitbereich zu aktualisieren',
  'Create temporal range': 'Zeitbereich erstellen',
  'Update temporal range': 'Zeitbereich aktualisieren',
  'The final month cannot be less than the initial month':
    'Der letzte Monat darf nicht kleiner als der Anfangsmonat sein',
  'Start Month': 'Monat starten',
  'End Month': 'Monat beenden',
  'Start Week Day': 'Wochentag beginnen',
  'End Week Day': 'Wochentag beenden',
  'Start Month Day': 'Start Monat Tag',
  'End Month Day': 'Tag des Monatsendes',
  'Initial time': 'Anfangszeit',
  'End time': 'Endzeit',
  'The end time is required': 'Die Endzeit ist erforderlich',
  'The start time is required': 'Die Startzeit ist erforderlich',
  'The start month is required': 'Der Startmonat ist erforderlich',
  'The end month is required': 'Der Endmonat ist erforderlich',
  'The start week day is required': 'Der Startwochentag ist erforderlich',
  'The start month day is required': 'Der Tag des Startmonats ist erforderlich',
  'The time range was successfully created':
    'Der Zeitraum wurde erfolgreich erstellt',
  'The time range could not be created':
    'Der Zeitraum konnte nicht erstellt werden',
  'The time range was successfully updated':
    'Der Zeitraum wurde erfolgreich aktualisiert',
  'The time range could not be updated':
    'Der Zeitraum konnte nicht aktualisiert werden',
  'Repite Password': 'Passwort wiederholen',
  'Asign extension': 'Nebenstelle zuweisen',
  Available: 'Verfügbar',
  Busy: 'Beschäftigt',
  Unavailable: 'Unavailable',
  'The final week day cannot be less than the initial week day':
    'Der letzte Wochentag darf nicht kleiner sein als der anfängliche Wochentag',
  'The final day cannot be less than the initial day':
    'Der letzte Tag darf nicht kürzer als der Anfangstag sein',
  'The final hour cannot be less than the initial hour':
    'Die letzte Stunde darf nicht kleiner sein als die erste Stunde',
  'The initial hour cannot be less than the final hour':
    'Die Anfangsstunde darf nicht kleiner sein als die Endstunde',
  'The file you are trying to upload is already uploaded':
    'Die Datei, die Sie hochladen möchten, wurde bereits hochgeladen',
  'Select a time range': 'Wählen Sie einen Zeitraum aus',
  'Add existing time range': 'Vorhandenen Zeitraum hinzufügen',
  'Add temporal range': 'Zeitbereich hinzufügen',
  'You have to select one temporal range':
    'Sie müssen einen zeitlichen Bereich auswählen',
  'The temporal range already exists: ': 'Der Zeitbereich existiert bereits: ',
  'Select roles...': 'Wählen Sie Rollen aus',
  'Outbound Routes': 'Ausgehende Routen',
  'Add number restrictions': 'Fügen Sie Nummernbeschränkungen hinzu',
  'Create new outbound route': 'Erstellen Sie eine neue ausgehende Route',
  'Add number restriction': 'Nummernbeschränkung hinzufügen',
  'Create new number restriction': 'Neue Nummernbeschränkung erstellen',
  Length: 'Länge',
  'Start with': 'Beginnen mit',
  'Length must be a number': 'Länge muss eine Zahl sein',
  'The length is required': 'Die Länge ist erforderlich',
  'The start with is required': 'Der Anfang mit ist erforderlich',
  'The number restriction was successfully added':
    'Die Nummernbeschränkung wurde erfolgreich hinzugefügt',
  'Update number restriction': 'Nummernbeschränkung aktualisieren',
  'The number restriction was successfully updated':
    'Die Nummernbeschränkung wurde erfolgreich aktualisiert',
  'The ddi is required': 'Das DDI ist erforderlich',
  'The ddi must be a number': 'Der ddi muss eine Zahl sein',
  'The route was successfully created': 'Die Route wurde erfolgreich erstellt',
  'The route was sucessfully enabled': 'Die Route wurde erfolgreich aktiviert',
  'The route was sucessfully disabled':
    'Die Route wurde erfolgreich deaktiviert',
  'The route was successfully updated':
    'Die Route wurde erfolgreich aktualisiert',
  'At least one of the fields is required':
    'Mindestens eines der Felder ist erforderlich',
  'Add caller id': 'Anrufer-ID hinzufügen',
  'Select caller id': 'Wählen Sie die Anrufer-ID aus',
  Prefix: 'Präfix',
  'The prefix must be numeric': 'Das Präfix muss numerisch sein',
  'The caller id is already added': 'Die Anrufer-ID ist bereits hinzugefügt',
  'Sort ascending': 'Aufsteigend sortieren',
  'Sort descending': 'Absteigend sortieren',
  'Search email': 'Suche Email',
  'Copy user': 'Benutzer kopieren',
  'Edit user': 'Benutzer bearbeiten',
  'Delete user': 'Benutzer löschen',
  username: 'Nutzername',
  date: 'Datum',
  firstLogin: 'erste Anmeldung',
  lastLogin: 'letzte Anmeldung',
  firstLogout: 'erste Abmeldung',
  lastLogout: 'letzte Abmeldung',
  formattedDuration: 'formatierte Dauer',
  Filters: 'Filter',
  'Select existing contacts': 'Wählen Sie vorhandene Kontakte aus',
  'Create new file': 'Neue Datei erstellen',
  'Use this dialog window to add dialerbot file':
    'Verwenden Sie dieses Dialogfenster, um eine neue Dialerbot-Datei hinzuzufügen',
  'Create File': 'Crea un archivo',
  'Call until contact': 'Bis zur Kontaktaufnahme anrufen',
  'Call all PhoneNumbers': 'Rufen Sie alle Telefonnummern an',
  'Tag field is required': 'Das Tag-Feld ist erforderlich',
  'Select contact tags...': 'Kontakt-Tags auswählen...',
  'Contact tags': 'Kontakt-Tags',
  'Add contact tags': 'Fügen Sie Kontakt-Tags hinzu',
  'Add contact fields': 'Kontaktfelder hinzufügen',
  'Contact field is required': 'Das Kontaktfeld ist erforderlich',
  'Select phone contact fields...': 'Telefonkontaktfelder auswählen...',
  'Phone contact fields': 'Telefonkontaktfelder',
  'Add or update contacts': 'Kontakte hinzufügen oder aktualisieren',
  'Server connection error': 'Serververbindungsfehler',
  'Add numbers': 'Zahlen hinzufügen',
  'Dialerbot type': 'Dialerbot-Typ',
  'The Inbound Route field is required':
    'Das Feld „Eingehende Route“ ist erforderlich',
  'The min value is 1': 'Der Mindestwert ist 1',
  'The max value is 100': 'Der Maximalwert beträgt 100',
  'The value must be numeric': 'Der Wert muss numerisch sein',
  'The min values is 5': 'Der Mindestwert beträgt 5',
  'Default time zone': 'Standardzeitzone',
  'Time zone': 'Zeitzone',
  'Change time zone': 'Zeitzone ändern',
  'Record percentage': 'Rekordprozentsatz',
  'AI Models': 'KI-Modelle',
  'Here you can find the models created to be used in the NLU node':
    'Hier finden Sie die Modelle, die zur Verwendung im NLU-Knoten erstellt wurden',
  'Create AI MODEL': 'Erstellen Sie ein KI-MODELL',
  'Create new AI MODEL': 'Erstellen Sie ein neues KI-MODELL',
  'Use this dialog window to add new AI MODEL':
    'Verwenden Sie dieses Dialogfenster, um ein neues AI-MODELL hinzuzufügen',
  'Write here the AI model name': 'Geben Sie hier den Namen des KI-Modells ein',
  'Write here a description...': 'Schreiben Sie hier eine Beschreibung...',
  Modify: 'Ändern',
  Schedule: 'Zeitplan',
  'Copy AI MODEL': 'KI-MODELL kopieren',
  'Edit AI MODEL': 'KI-MODELL bearbeiten',
  'Delete AI MODEL': 'KI-MODELL löschen',
  'Edit one or several AI MODEL': 'Ein oder mehrere AI-MODELL bearbeiten',
  'Delete one or several AI MODELS': 'Ein oder mehrere KI-MODELLE löschen',
  'You must enter at least one model':
    'Sie müssen mindestens ein Modell eingeben',
  'The AI MODEL was successfully registered':
    'Das AI-MODELL wurde erfolgreich registriert',
  'No AI MODEL selected': 'Kein AI-MODELL ausgewählt',
  'Write the intention name here...':
    'Geben Sie hier den Namen der Absicht ein...',
  'Answering machine detection': 'Erkennung des Anrufbeantworters',
  'Add label': 'Etikett hinzufügen',
  Label: 'Etikett',
  'Update AI MODEL': 'KI-MODELL aktualisieren',
  'Type intention name': 'Geben Sie den Absichtsnamen ein',
  'First edit the intention opened':
    'Bearbeiten Sie zunächst die geöffnete Absicht',
  'First save the intention name': 'Speichern Sie zunächst den Absichtsnamen',
  'You have to write one intention': 'Sie müssen eine Absicht schreiben',
  'You have to write one tag in every intention':
    'Sie müssen in jede Absicht ein Tag schreiben',
  'The priority was updated successfully':
    'Die Priorität wurde erfolgreich aktualisiert',
  'Priority Hight': 'Prioritätshöhe',
  'Priority Medium': 'Prioritätsmedium',
  'Priority Low': 'Priorität Baja',
  Language: 'Sprache',
  Example: 'Beispiel',
  'Error, please contact the administrator.':
    'Fehler, bitte wenden Sie sich an den Administrator.',
  'Could not detect delimiter automatically.':
    'Trennzeichen konnte nicht automatisch erkannt werden.',
  'You have selected': 'Du hast ausgewählt',
  file: 'Datei',
  'Drag & drop files here': 'Ziehen Sie Dateien hier per Drag & Drop',
  'Predictive configuration': 'Vorausschauende Konfiguration',
  'Progressive configuration': 'Progressive Konfiguration',
  'Use this dialog window to add a new Predictive file':
    'Verwenden Sie dieses Dialogfenster, um eine neue Vorhersagedatei hinzuzufügen',
  'Use this dialog window to add a new Progressive file':
    'Verwenden Sie dieses Dialogfenster, um eine neue progressive Datei hinzuzufügen',
  'Use this dialog window to add a new DialerBot file':
    'Verwenden Sie dieses Dialogfenster, um eine neue Dialerbot-Datei hinzuzufügen',
  'The Predictive execution depends on configuration!':
    'Die prädiktive Ausführung hängt von der Konfiguration ab!',
  'The Progressive execution depends on configuration!':
    'Die progressive Ausführung hängt von der Konfiguration ab!',
  CREATE_NEW_COMPANY: 'Neues Unternehmen gründen',
  'Update a company': 'Aktualisieren Sie ein Unternehmen',
  'Use this dialog window to update a company':
    'Verwenden Sie dieses Dialogfenster, um eine Firma zu aktualisieren',
  'Update company': 'Firma aktualisieren',
  'Create company': 'Unternehmen gründen',
  Licenses: 'Lizenzen',
  'Number of licenses': 'Anzahl der Lizenzen',
  'Use this dialog window to create a new company':
    'In diesem Dialogfenster können Sie eine neue Firma anlegen',
  Extensions: 'Erweiterungen',
  'Displayed Extensions': 'Angezeigte Erweiterungen',
  Companies: 'Firmen',
  Name: 'Name',
  Actions: 'Aktionen',
  'Typology for maximum rescheduling achieved':
    'Typologie für die maximal erreichte Umplanung',
  'Ticket sub-typologies': 'Ticket-Subtypologien',
  'Add ticket sub-typologies to configure a relaunch':
    'Fügen Sie Ticket-Untertypologien hinzu, um einen Relaunch zu konfigurieren',
  'Sub-typologies': 'Subtypologien',
  'Create campaign': 'Kampagne erstellen',
  'Update campaign': 'Kampagne aktualisieren',
  'Use this dialog window to create a campaign':
    'Verwenden Sie dieses Dialogfenster, um eine Kampagne zu erstellen',
  'Use this dialog window to update a campaign':
    'Verwenden Sie dieses Dialogfenster, um eine Kampagne zu aktualisieren',
  'End date': 'Endtermin',
  MOH_TITLE: 'Musik in der Warteschleife',
  MOH_SUBTITLE: 'Wartemusik verwalten',
  MOH_CREATE_BUTTON: 'Erstellen Sie Wartemusik',
  MOH_CREATE_BUTTON_NEW: 'Create new queue Music On Hold',
  MOH_CREATE_TITLE: 'Erstellen Sie eine neue Wartemusik',
  MOH_CREATE_SUBTITLE:
    'Verwenden Sie dieses Dialogfenster, um eine Wartemusik hinzuzufügen',

  MOH_UPDATE_TITLE: 'Aktualisieren Sie die neue Wartemusik',
  MOH_UPDATE_SUBTITLE:
    'Verwenden Sie dieses Dialogfenster, um eine Wartemusik zu aktualisieren',

  MOH_COMPANY: 'Unternehmen',
  MOH_NAME: 'Name',
  MOH_ORDER: 'Befehl',
  MOH_AUDIOS: 'Audios',
  MOH_ADD_NEW: 'Neue Ankündigung hinzufügen',
  MOH_CANCEL_BUTTON: 'Stornieren',
  MOH_UPDATE_BUTTON: 'Aktualisieren',
  MOH_SUCCESS_CREATE_MESSAGE: 'Wartemusik erfolgreich erstellt',
  MOH_SUCCESS_UPDATE_MESSAGE: 'Music On Hold wurde erfolgreich aktualisiert',
  MOH_DELETE_BUTTON: 'Entfernen Sie die Wartemusik',
  MOH_COMPANY_REQUIRED: 'Das Feld „Firma“ ist erforderlich',
  MOH_NAME_REQUIRED: 'Das Feld „Name“ ist erforderlich',
  MOH_ORDER_REQUIRED: 'Das Feld „Bestellung“ ist erforderlich',
  MOH_AUDIOS_REQUIRED: 'Das Feld „Audios“ ist erforderlich',
  Random: 'Zufällig',
  Sequential: 'Sequentiell',

  'The start date cannot be later than the end date':
    'Das Startdatum darf nicht nach dem Enddatum liegen',
  'Repeat every year': 'Jedes Jahr wiederholen',
  'Punctual day': 'Pünktlicher Tag',
  To_hasta: 'Bis',
  'Create and enable': 'Erstellen und aktivieren',
  'New WhatspApp Account': 'Neues WhatsApp-Konto',
  'Edit WhatspApp Account': 'Bearbeiten Sie das WhatsApp-Konto',
  'Use this dialog window to create new WhatspApp Account':
    'Utilisez cette fenêtre de dialogue pour créer un nouveau compte WhatsApp',
  'Use this dialog window to edit WhatspApp Account':
    'Verwenden Sie dieses Dialogfenster, um das WhatspApp-Konto zu bearbeiten',
  'Account details': 'Kontodetails',
  'Provider details': 'Angaben zum Anbieter',
  'Name of Username': 'Name des Benutzernamens',
  'The username field is required': 'Das Feld „Benutzername“ ist erforderlich',
  JOIN: 'Verbinden',
  'Use this dialog window to mange the META templates':
    'Verwenden Sie dieses Dialogfenster, um die Vorlagen zu verwalten',
  'Templates from':'Vorlagen von',
  'Search template here...': 'Suchvorlage hier...',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (@, !, #, %, &, *)':
    'Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen (@, !, #, %, &, *)',
  'Password cannot contain username':
    'Das Passwort darf keinen Benutzernamen enthalten',
    'Show password':'Passwort anzeigen',
  'Show disabled': 'Deaktiviert anzeigen',
  'WhatsApp State': 'WhatsApp-Status',
  'New webchat style': 'Neuer Webchat-Stil',
  'Use this dialog window to create new webchat style':
    'Verwenden Sie dieses Dialogfenster, um einen neuen Webchat-Stil zu erstellen',
  'Template Name': 'Vorlagenname',
  Category: 'Kategorie',
  Message: 'Nachricht',
  Marketing: 'Marketing',
  Utility: 'Dienstprogramm',
  'Custom Message': 'Benutzerdefinierte Nachricht',
  'Send promo offers, product offers more to increase awareness and engagement.':
    'Senden Sie Werbeangebote und Produktangebote, um Bekanntheit und Engagement zu steigern.',
  'Send account updates, order updates, alerts and more to share important information.':
    'Senden Sie Kontoaktualisierungen, Bestellaktualisierungen, Benachrichtigungen und mehr, um wichtige Informationen auszutauschen.',
  'Send promotional offers, announcements and more to increase awareness and engagement.':
    'Versenden Sie Werbeangebote, Ankündigungen und mehr, um Bekanntheit und Engagement zu steigern.',
  'Header (Optional)': 'Kopfzeile (optional)',
  'Header text': 'Überschrift',
  'Add a 60 character title to your message. Only one variable is supported in the header.':
    'Fügen Sie Ihrer Nachricht einen 60-stelligen Titel hinzu. Im Header wird nur eine Variable unterstützt.',
  'Define sample variable value for header:':
    'Definieren Sie den Beispielvariablenwert für den Header:',
  Body: 'Körper',
  'Enter the text for your message in the language you have selected.':
    'Geben Sie den Text für Ihre Nachricht in der von Ihnen ausgewählten Sprache ein.',
  'Define sample variable value for body:':
    'Definieren Sie den Beispielvariablenwert für den Körper:',
  'Footer text (Optional)': 'Fußzeilentext (optional)',
  'Hide sample templates': 'Beispielvorlagen ausblenden',
  'Join Videocall': 'An einem Videogespräch teilnehmen',
  'Add variable': 'Variable hinzufügen',
  'Conversation preview:': 'Konversationsvorschau:',
  'WhatsApp with': 'WhatsApp mit',
  'The keyword already exists': 'Das Schlüsselwort existiert bereits',
  'The keyword group was created succesfully':
    'Die Keyword-Gruppe wurde erfolgreich erstellt',
  'The keyword group was updated succesfully':
    'Die Keyword-Gruppe wurde erfolgreich aktualisiert',
  'Update Keywords group': 'Aktualisieren Sie die Gruppe „Keywords“.',
  'Create a keywords group': 'Erstellen Sie eine Schlüsselwortgruppe',
  'The role is in use:': 'Die Rolle ist im Einsatz:',
  'The App number is required': 'Die App-Nummer ist erforderlich',
  'The API key is required': 'Der API-Schlüssel ist erforderlich',
  'Displayed extension': 'Angezeigte Erweiterung',
  'Create extensions from range': 'Erstellen Sie Erweiterungen aus dem Bereich',
  'Status Name': 'Statusname',
  'Status Type': 'Statustyp',
  'Status Color': 'Statusfarbe',
  'Add new status': 'Neuen Status hinzufügen',
  AdministrativeTime: 'Verwaltungszeit',
  'The company was successfully created':
    'Das Unternehmen wurde erfolgreich gegründet',
  'The extensions could not be created':
    'Die Erweiterungen konnten nicht erstellt werden',
  'The company could not be created':
    'Das Unternehmen konnte nicht erstellt werden',
  'The company was deleted:': 'Das Unternehmen wurde gelöscht:',
  'The company was successfully updated':
    'Das Unternehmen wurde erfolgreich aktualisiert',
  'The company could not be updated':
    'Das Unternehmen konnte nicht aktualisiert werden',
  'This field is required': 'Dieses Feld ist erforderlich',
  'This title is required': 'Dieser Titel ist erforderlich',
  'updated successfully': 'Erfolgreich geupdated',
  'created successfully': 'erfolgreich erstellt',
  'The company was disabled:': 'Das Unternehmen wurde deaktiviert:',
  'The WhatsApp Account was sucessfully created':
    'Das WhatsApp-Konto wurde erfolgreich erstellt',
  'The WhatsApp Account was sucessfully updated':
    'Das WhatsApp-Konto wurde erfolgreich aktualisiert',
  'The WhatsApp Account was deleted:': 'Das WhatsApp-Konto wurde gelöscht:',
  'Use this dialog window to add a new':
    'Verwenden Sie dieses Dialogfenster, um ein neues hinzuzufügen',
  'Failed upload': 'Hochladen fehlgeschlagen',
  'Add extensions to selected groups':
    'Fügen Sie Erweiterungen zu ausgewählten Gruppen hinzu',
  'The extension cannot have only white spaces':
    'Die Erweiterung darf nicht nur Leerzeichen enthalten',
  'The campaign was sucessfully deleted':
    'Die Kampagne wurde erfolgreich gelöscht',
  'You must select at least one day':
    'Sie müssen mindestens einen Tag auswählen',
  'Campaign created succesfully': 'Kampagne erfolgreich erstellt',
  'Service updated succesfully': 'Der Dienst wurde erfolgreich aktualisiert',
  'Campaign updated succesfully': 'Kampagne erfolgreich aktualisiert',
  'Videoroom could not be created': 'Videoraum konnte nicht erstellt werden',
  'Videoroom created successfully': 'Videoraum erfolgreich erstellt',
  'Enable one company': 'Aktivieren Sie ein Unternehmen',
  'Disable one company': 'Deaktivieren Sie ein Unternehmen',
  'Enable one or several companies':
    'Aktivieren Sie ein oder mehrere Unternehmen',
  'Disable one or several companies':
    'Deaktivieren Sie ein oder mehrere Unternehmen',
  'The company was enabled successfully':
    'Das Unternehmen wurde erfolgreich aktiviert',
  'The company was disabled successfully':
    'Das Unternehmen wurde erfolgreich deaktiviert',
  'You must select at least one company':
    'Sie müssen mindestens ein Unternehmen auswählen',
  'The company is already enabled': 'Das Unternehmen ist bereits aktiviert',
  'The company is already disabled': 'Das Unternehmen ist bereits deaktiviert',
  'You cannot delete an active company':
    'Sie können ein aktives Unternehmen nicht löschen',
  'Edit company': 'Firma bearbeiten',
  'You don"t have announcements permissions':
    'Sie haben keine Ankündigungsberechtigungen',
  'Videocall has finished or it does not exist':
    'Der Videoanruf ist beendet oder existiert nicht',
  "The videoroom is full, you can't join":
    'Der Videoraum ist voll, du kannst nicht beitreten.',
  'Add group': 'Gruppe hinzufügen',
  'Upload contacts': 'Kontakte hochladen',
  'Create new tag': 'Neues Tag erstellen',
  'Create new field': 'Neues Feld erstellen',
  'The template was created successfully':
    'Die Vorlage wurde erfolgreich erstellt',
  'The mesage template was successfully sent':
    'Die Nachrichtenvorlage wurde erfolgreich gesendet',
  'The is no approved templates': 'Es gibt keine genehmigten Vorlagen',
  'These are the extensions': 'Das sind die Erweiterungen',
  'These are the extensions groups': 'Dies sind die Erweiterungsgruppen',
  'Extensions Groups': 'Erweiterungsgruppen',
  'Create extensions groups': 'Erstellen Sie Erweiterungsgruppen',
  Approve: 'Genehmigen',
  'The WhatsApp templates was successfully updated':
    'Die WhatsApp-Vorlagen wurden erfolgreich aktualisiert',
  'Thank you for attending!': 'Danke für Ihre Teilnahme!',
  'You have been rejected...': 'Du wurdest abgelehnt...',
  'You have been kicked out of the video call':
    'Du wurdest aus dem Videoanruf geworfen',
  'The videoroom is full': 'Der Videoanrufraum ist voll',
  'The video call has already ended or does not exist':
    'Der Videoanruf ist bereits beendet oder existiert nicht',
  'The templates could not be loaded':
    'Die Vorlagen konnten nicht geladen werden',
  Downloads: 'Downloads',
  'All aspects related to the downloads can be managed here':
    'Alle Aspekte rund um die Downloads können hier verwaltet werden',
  'Add account': 'Konto hinzufügen',
  'Add style': 'Stil hinzufügen',
  'Add pre written message':
    'Fügen Sie eine vorab geschriebene Nachricht hinzu',
  'Delete Field': 'Feld löschen',
  'Add button': 'Schaltfläche Hinzufügen',
  'The text is required': 'Der Text ist erforderlich',
  'Use this dialog window to create new whatsapp template':
    'Verwenden Sie dieses Dialogfenster, um eine neue WhatsApp-Vorlage zu erstellen',
  'You must add at least one button':
    'Sie müssen mindestens eine Schaltfläche hinzufügen',
  Services: 'Dienstleistungen',
  'Use this dialog window to create a new service':
    'Verwenden Sie dieses Dialogfenster, um einen neuen Dienst zu erstellen',
  buttons: 'Tasten',
  'Here are the roles and permissions':
    'Hier sind die Rollen und Berechtigungen',
  'Add custom role': 'Benutzerdefinierte Rolle hinzufügen',
  'Create new custom role': 'Erstellen Sie eine neue benutzerdefinierte Rolle',
  'Edit role': 'Rolle bearbeiten',
  'Use this dialog window to edit a role':
    'Verwenden Sie dieses Dialogfenster, um eine Rolle zu bearbeiten',
  'Use this dialog window to add a new role':
    'Verwenden Sie dieses Dialogfenster, um eine neue Rolle hinzuzufügen',
  'All aspects related to the tickets can be managed here':
    'Alle Aspekte rund um die Tickets können hier verwaltet werden',
  'Add typologies': 'Fügen Sie Typologien hinzu',
  'Add ticket': 'Ticket hinzufügen',
  'Add fields': 'Felder hinzufügen',
  Typologies: 'Typologien',
  Fields: 'Felder',
  'Stop sharing screen': 'Beenden Sie die Bildschirmfreigabe',
  'Share screen': 'Bildschirm teilen',
  'Route cloned succesfully': 'Route erfolgreich geklont',
  'The Route was not deleted': 'Die Route wurde nicht gelöscht',
  'The Route was not disabled.': 'Die Route wurde nicht deaktiviert.',
  'Text cannot be just blank spaces':
    'Text darf nicht nur aus Leerzeichen bestehen',
  'What the contact will see:': 'Was der Kontakt sehen wird:',
  'Add Contact Field': 'Fügen Sie ein Kontaktfeld hinzu',
  'Remove Contact Field': 'Entfernen Sie ein Kontaktfeld',
  'Select Contact Field': 'Wählen Sie ein Kontaktfeld aus',
  'Change Contact Field Label': 'Ändern Sie die Beschriftung des Kontaktfelds',
  'Move Down': 'Nach unten bewegen',
  'Move Up': 'Nach oben bewegen',
  'Last email date': 'Letztes E-Mail-Datum',
  'Email account address': 'E-Mail-Kontoadresse',
  'Last email sender': 'Letzter E-Mail-Absender',
  'Last email subject': 'Letzter E-Mail-Betreff',
  'Role name': 'Rollenname',
  'Create new contact': 'Neuen Kontakt erstellen',
  'Create new account': 'Neues Konto erstellen'
};

export default deJSON;
