import { MenuItem, Select, Stack, useTheme } from '@mui/material';
import { getCameraDeviceStore } from 'src/services/webrtc/infrastructure/store/devices/getCameraDeviceStore';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';
import { getVoiceDeviceStore } from 'src/services/webrtc/infrastructure/store/devices/getMicDeviceStore';
import WebRTCDevices, {
  requestUserDevices
} from 'src/services/webrtc/webrtc-devices';
import { DeviceStorageKey } from './utils/constants';
import { VideoCall, Videocam } from '@mui/icons-material';
import { useCamera } from './useCamera';
import { setCameraDeviceStore } from 'src/services/webrtc/infrastructure/store/devices/setCameraDeviceStore';
interface Props {
  showIcon?: boolean;
}

export function CameraSelect({ showIcon }: Props) {
  const cameraSelected = getCameraDeviceStore(true);

  const theme = useTheme();

  const mediaDevices = getDevicesStore(true).filter(
    (d) => d.kind === 'videoinput'
  );

  const onSelectChange = async (idSelected: string) => {
    const newDevice = mediaDevices.filter((device) => {
      return device.deviceId === idSelected;
    })[0];

    const audioDevice = getVoiceDeviceStore(false);

    await requestUserDevices({
      exactDevices: { video: newDevice, audio: audioDevice },
      enableVideo: true
    });
    setCameraDeviceStore(newDevice);
    localStorage.setItem(DeviceStorageKey.CAMERA, JSON.stringify(newDevice));
  };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={1} width="100%">
        <Videocam color="secondary" />

        {mediaDevices.length > 0 && (
          <Select
            fullWidth
            value={cameraSelected?.label}
            sx={{
              backgroundColor: theme.colors.secondary.main,
              color: 'white'
            }}
            rows={2}
          >
            <MenuItem sx={{ display: 'none' }} value={cameraSelected?.label}>
              {cameraSelected?.label}
            </MenuItem>
            {mediaDevices.map((device, idx) => {
              return (
                <MenuItem
                  key={idx}
                  onClick={(): void => {
                    onSelectChange(device.deviceId);
                  }}
                  value={device.label}
                >
                  {device.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </Stack>
    </>
  );
}
