import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import {
  Ticket,
  TicketField,
  TicketFieldValue
} from '../../../../models/tickets';
import { useEffect, useState } from 'react';
import { Variable } from 'src/modules/Admin/modules/Acd/modules/Variables/models/models';
import { getVariableByIdQuery } from 'src/modules/Admin/modules/Acd/modules/Variables/domain/getVariableByIdDomain';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getRTCallByCallIdApi } from 'src/modules/CTI/infrastructure/getCallsByCallIdApi';
import { getReportingCallSummaryByIdApi } from 'src/modules/Productivity/modules/Calls/infrastructure/api/getReportingCallSummaryApi';
import { getTicketFieldByIdQuery } from '../../../../domain/fields';
import { ChannelType } from 'src/models/conversations/conversations';
import { getContactFieldByIdQuery } from 'src/modules/Contacts/domain/getContactFields';
import { getCompanyVariablesQuery } from 'src/modules/Admin/modules/Acd/modules/Variables/application/getVariablesQuery';
import { getSelectedService } from 'src/services/daServices/domain/getSelectedService';

export const ShowflowVariableTypeConverter = {
  ca: 'Call',
  com: 'Company',
  ti: 'Ticket',
  con: 'Contact'
} as { [key: string]: ShowflowVariableType };

export type ShowflowVariableType = 'Call' | 'Company' | 'Ticket' | 'Contact';

interface Props {
  variableId: string;
  contact?: Contact;
  ticket?: Ticket;
}

interface ShowflowVariable {
  type: ShowflowVariableType;
  field: TicketField | ContactField | Variable;
  value: TicketFieldValue | string;
}

function useShowflowVariable({ variableId, contact, ticket }: Props) {
  async function getVariable(id: string) {
    if (id.includes('default')) {
      const service = getSelectedService();
      const companyId = service?.companyId;
      const variables = await getCompanyVariablesQuery({ companyId });
      const variable = variables.find((variable) => variable.id === id);
      return variable;
    } else {
      const variable = await getVariableByIdQuery(id);
      return variable;
    }
  }
  async function getVariableValue() {
    const [type, ...rest] = variableId.split('-');
    const id = rest.join('-');
    const variableType = ShowflowVariableTypeConverter[type];

    if (!variableType) return '';
    setType(variableType);

    if (variableType === 'Contact') {
      const field = await getContactFieldByIdQuery(id);
      const fieldValue = contact?.fields[id];
      setField(field);
      setValue(fieldValue);
    }

    if (variableType === 'Ticket') {
      const ticketField = await getTicketFieldByIdQuery(id);
      const ticketFieldValue = ticket?.fields[id]?.value;
      setField(ticketField);
      setValue(ticketFieldValue);
    }

    if (variableType === 'Company') {
      const variable = await getVariable(id);
      setField(variable);
      setValue(variable.value);
    }

    if (variableType === 'Call') {
      const variable = await getVariable(id);
      setField(variable);

      if (!contact && !ticket) return;

      const currentConversation = getCurrentConversation(false);
      if (currentConversation?.channel !== ChannelType.CALL) {
        setValue(undefined);
        return;
      }
      const activeCall = getActiveRTCall(getConnectedRTUser(false));
      const isAnActiveCall =
        activeCall?.callId === currentConversation.originAgent.callId;

      if (isAnActiveCall) {
        const instanceId = activeCall.instanceId;
        const rtCall = await getRTCallByCallIdApi(
          activeCall.callId,
          instanceId
        );
        const variable = rtCall?.variables[id];
        setValue(variable?.value);
      } else {
        const callId = currentConversation.originAgent.callId;
        const call = await getReportingCallSummaryByIdApi(callId);
        const value = call?.variables[variable.name];
        setValue(value);
      }
    }
  }
  const [type, setType] = useState('' as ShowflowVariableType);
  const [field, setField] = useState(
    {} as TicketField | ContactField | Variable
  );
  const [value, setValue] = useState<string | string[] | boolean>('');

  useEffect(() => {
    if (field?.id !== variableId) {
      getVariableValue();
    }
  }, [variableId, contact?.id, ticket?.id]);

  return {
    type,
    field,
    value
  } as ShowflowVariable;
}

export default useShowflowVariable;
