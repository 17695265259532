import axios, { AxiosError } from 'axios';
import { replaceURLParams } from 'src/services/utils/replaceURLParams';
import { logger } from 'src/utils/logger';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;
const BASE_MS_VIDEOCALLS = process.env.REACT_APP_BASE_VIDEOCALLS as string;
const VIDEOCALLS_URL = (BASE_URL + BASE_MS_VIDEOCALLS) as string;

const ACCEPT_PARTICIPANT = process.env
  .REACT_APP_VIDEOCALL_VIDEOROOM_ACCEPT as string;
const REJECT_PARTICIPANT = process.env
  .REACT_APP_VIDEOCALL_VIDEOROOM_REJECT as string;
const TOGGLE_MUTE = process.env.REACT_APP_VIDEOCALL_TOGGLE_MUTE as string;
const TOGGLE_DEAF = process.env.REACT_APP_VIDEOCALL_TOGGLE_DEAF as string;
const PARTICIPANT_READY = process.env
  .REACT_APP_VIDEOCALL_PARTICIPANT_READY as string;

export const acceptParticipant = async (
  roomId: string,
  participantId: string
) => {
  const url = replaceURLParams(VIDEOCALLS_URL + ACCEPT_PARTICIPANT, {
    '{id}': roomId,
    '{participantId}': participantId
  });

  try {
    const response = await axios.post(url);
    return true;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response.status == 404) {
        logger.warn(
          'When accepting a participant => The participant has exited or does not exist'
        );
        return true;
      }
    }
    return false;
  }
};

export const rejectParticipant = async (
  roomId: string,
  participantId: string
) => {
  const url = replaceURLParams(VIDEOCALLS_URL + REJECT_PARTICIPANT, {
    '{id}': roomId,
    '{participantId}': participantId
  });

  try {
    await axios.post(url);
    return true;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response.status == 404) {
        logger.warn(
          'When rejecting a participant => The participant has exited or does not exist'
        );
        return true;
      }
    }
    return false;
  }
};

export const participantReady = async (extension: string, roomId: string) => {
  const url = replaceURLParams(VIDEOCALLS_URL + PARTICIPANT_READY, {
    '{id}': roomId,
    '{extension}': extension
  });

  try {
    await axios.post(url);
    return true;
  } catch (error) {
    logger.error(error);
    return false;
  }
};

export const toggleMute = async (roomId: string, socketId: string) => {
  const url = replaceURLParams(VIDEOCALLS_URL + TOGGLE_MUTE, {
    '{id}': roomId
  });

  try {
    return (await axios.post(url, {}, { headers: { socketId } }))
      .data as boolean;
  } catch (error) {
    if (error instanceof AxiosError) {
      logger.error('Error toogling the mute on participant');
      return undefined;
    }
  }
};

export const toggleDeaf = async (roomId: string, socketId: string) => {
  const url = replaceURLParams(VIDEOCALLS_URL + TOGGLE_DEAF, {
    '{id}': roomId
  });

  try {
    return (await axios.post(url, {}, { headers: { socketId } }))
      .data as boolean;
  } catch (error) {
    if (error instanceof AxiosError) {
      logger.error('Error toogling the deaf on participant');
      return undefined;
    }
  }
};
