import { Delete, GppMaybe, VerifiedUser } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import {
  DialTableDataContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';
import { getSort } from 'src/components/DialTable/utils/getSort';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { useAlert } from 'src/hooks/useAlert';
import deleteInstagramAccountApi from 'src/services/instagram/infrastructure/account/deleteInstagramAccountApi';
import { getInstagramAccountsApi } from 'src/services/instagram/infrastructure/account/getInstagramAccountsApi';
import {
  InstagramAccount,
  InstagramAccountStatus
} from 'src/services/instagram/models/instagramAccounts';
import {
  checkDeleteInstagramAccountPermissions,
  checkUpdateInstagramAccountPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

interface Props {
  setAccountToVerify: (account: InstagramAccount) => void;
  setAccountsToDelete: (accounts: InstagramAccount[]) => void;
}

function useInstagramAccountsDialtable({
  setAccountToVerify,
  setAccountsToDelete
}: Props) {
  const { hasAccess } = useContext(PermissionsContext);
  const initialSorting = [{ id: 'metadata.modificationDate', desc: false }];
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const getData = async ({
    filtersObject,
    state,
    paginationValues
  }: DialTableDataContext<InstagramAccount>) => {
    const sort = getSort(state?.sorting);
    const companyId = filtersObject.companyId?.[0];
    const response = await getInstagramAccountsApi({
      companyId,
      sortBy: sort?.sort,
      sortDir: sort?.direction
    });

    const data = getPaginatedElements(response);

    return data;
  };

  const getColumnsFn = async (): Promise<ColumnDef<InstagramAccount>[]> => {
    return [
      {
        accessorKey: 'instagramUsername',
        id: 'instagramUsername',
        header: t('Username')
      },
      {
        accessorKey: 'status',
        id: 'status',
        header: t('Status'),
        cell: (cell) => {
          const account = cell.row.original;
          const status = account.status;

          return (
            <Stack direction="row" alignItems={'center'} columnGap={1}>
              {status === InstagramAccountStatus.AUTHENTICATED && (
                <>
                  <VerifiedUser color="success" />
                  <Typography>{t(status)}</Typography>
                </>
              )}
              {status === InstagramAccountStatus.PENDING && (
                <>
                  <GppMaybe color="error" />
                  <Typography>{t('Pending authentication')}</Typography>
                </>
              )}
              {status === InstagramAccountStatus.EXPIRED && (
                <>
                  <GppMaybe color="warning" />
                  <Typography>{t('Needs to be re-authenticated')}</Typography>
                </>
              )}
            </Stack>
          );
        }
      }
    ];
  };

  const getAvailableFilters = async () => {
    const filters: DialTableFilter<InstagramAccount>[] = [
      {
        fieldName: 'companyId',
        required: false,
        fieldDataType: FieldDataType.STRING,
        fieldType: FieldType.SELECTOR,
        dataCategory: DataCategory.COMPANY_ID,
        operator: StringOperator.EQUALS,
        values: []
      }
    ];
    return filters;
  };

  const getRowActions = ({
    rowData: account
  }: DialTableRowActionsContext<InstagramAccount>) => {
    return (
      <>
        {account.status !== InstagramAccountStatus.AUTHENTICATED && (
          <Tooltip title={t('Authenticate')} arrow>
            <IconButton
              key={'verify'}
              color="secondary"
              onClick={() => {
                setAccountToVerify(account);
              }}
              disabled={!hasAccess(checkUpdateInstagramAccountPermissions)}
            >
              <VerifiedUser />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t('Delete')} arrow>
          <IconButton
            key={'delete'}
            color="error"
            onClick={() => {
              setAccountsToDelete([account]);
            }}
            disabled={!hasAccess(checkDeleteInstagramAccountPermissions)}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const { showAlert } = useAlert();

  async function deleteAccounts(accounts: InstagramAccount[]) {
    try {
      for (const account of accounts) {
        await deleteInstagramAccountApi(account.id);
      }
      emitCustomEvent(REFETCH_DATA_EVENT_NAME);
      showAlert(t('Instagram account deleted successfully'), 'success', 3000);
    } catch (error) {
      showAlert(error.message, 'error', 3000);
    }
  }

  return {
    getColumnsFn,
    getData,
    getAvailableFilters,
    getRowActions,
    deleteAccounts,
    sorting,
    setSorting
  };
}

export default useInstagramAccountsDialtable;
