import AdminHeader from 'src/components/AdminHeader';
import { VariablesTable } from './components/VariablesTable';
import { useEffect, useState } from 'react';
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { CreateUpdateVariableModal } from './components/CreateUpdateVariableModal';
import { getCompaniesQuery } from 'src/services/users/application/query/getUsersDataQueries';
import { Company } from 'src/models/users';
import { checkCreateVariablePermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import AuthManager from 'src/services/authentication/manager';

export const VariablesPage = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [companies, setCompanies] = useState([] as Company[]);

  useEffect(() => {
    getCompaniesQuery().then((response) => {
      setCompanies(response);
    });
  }, []);

  return (
    <div>
      <AdminHeader
        heading="Variables"
        subheading="Manage all the variables"
        buttonName="Create variable"
        onClick={() => {
          setIsModalOpened(true);
        }}
        permissionCheckerFn={checkCreateVariablePermissions}
      />
      <Stack direction="column">
        <CreateUpdateVariableModal
          isOpen={isModalOpened}
          setIsOpen={setIsModalOpened}
          companyId={AuthManager.getLoggedUserCompanyId()}
        />
        <Box width={'100%'} p={2}>
          <VariablesTable />
        </Box>
      </Stack>
    </div>
  );
};
