import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ContactField, Contact } from 'src/modules/Contacts/models/contacts';
import ContactListItem from './ContactListItem';
import Scrollbar from 'src/components/Scrollbar';
import { useTranslation } from 'react-i18next';
import { Alert, Box, List } from '@mui/material';
import {
  getConversationInvolvedContacts,
  getMatchesWebchat
} from 'src/services/socialmedia/domain/conversation/utils';
import { Conversation } from 'src/models/conversations/conversations';

interface Props {
  conversation: Conversation;
  companyFields: ContactField[];
  setSelectedContact: Dispatch<SetStateAction<Contact>>;
  setIsAdding: Dispatch<SetStateAction<boolean>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  setEditingIndex: Dispatch<SetStateAction<number | undefined>>;
  setWebChatFieldsContact: Dispatch<SetStateAction<Contact | null>>;
}

const ContactDataListView = (props: Props) => {
  const {
    conversation,
    companyFields,
    setSelectedContact,
    setIsAdding,
    setIsEditing,
    setEditingIndex,
    setWebChatFieldsContact
  } = props;
  const { involvedContacts, channel } = conversation;
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [webChatMatchContacts, setWebChatMatchContacts] = useState<Contact[]>(
    []
  );

  const { t } = useTranslation();

  const [socialmediaAgentContact, setSocialmediaAgentContact] = useState<
    Contact[]
  >([]);

  useEffect(() => {
    if (channel === 'WebChat') {
      // find out if there are more than one match for the initial form
      getMatchesWebchat(conversation).then((webchat) => {
        if (webchat.matches.length > 0) {
          //to set recommendations
          if (webchat.matches.length > 1) {
            setWebChatMatchContacts(webchat.matches);
          }
          getConversationInvolvedContacts(conversation).then((contacts) => {
            setContacts(contacts);
            setWebChatFieldsContact(webchat.contact);
            setSocialmediaAgentContact([webchat.contact]);
          });
        } else {
          setContacts([webchat.contact]);
          setSocialmediaAgentContact([webchat.contact]);
        }
      });
    } else {
      getConversationInvolvedContacts(conversation).then((contacts) => {
        getConversationInvolvedContacts(conversation).then((contacts) => {
          setContacts(contacts);
        });
      });
    }

    const withNoContactIds = {
      ...conversation,
      involvedContacts: involvedContacts.map((c) => {
        return {
          ...c,
          contactId: null
        };
      })
    } as Conversation;

    getConversationInvolvedContacts(withNoContactIds).then((contacts) => {
      setSocialmediaAgentContact(contacts);
    });
  }, [conversation]);

  return (
    <>
      <Box height="100%">
        <Box
          sx={{
            width: '100%',
            height: '500px'
          }}
        >
          {webChatMatchContacts.length > 0 && (
            <Alert severity="info" sx={{ margin: '15px' }}>
              {t(
                `There is more than one matching contact for this conversation.`
              )}
            </Alert>
          )}
          <Scrollbar>
            <List disablePadding>
              {contacts?.length > 0 &&
                contacts.map((contact, index) => {
                  return (
                    <ContactListItem
                      webChatMatchContacts={webChatMatchContacts}
                      key={conversation?.id + contact?.id + index}
                      index={index}
                      companyFields={companyFields}
                      contact={contact}
                      socialmediaAgent={socialmediaAgentContact[index]}
                      conversation={conversation}
                      setSelectedContact={setSelectedContact}
                      setIsAdding={setIsAdding}
                      setIsEditing={setIsEditing}
                      setEditingIndex={setEditingIndex}
                    />
                  );
                })}
            </List>
          </Scrollbar>
        </Box>
      </Box>
    </>
  );
};

export default ContactDataListView;
