import { Dispatch, useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  lighten,
  useTheme
} from '@mui/material';
import { PreviewAttachmentFile } from './PreviewAttachmentFile';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Clear } from '@mui/icons-material';
import { formatBytes, isImageFile } from '../utils/utils';

interface Props {
  file: File;
  setFile: Dispatch<React.SetStateAction<File>>;
  onRemove?: (file: File) => void;
  onClose?: () => void;
}
const AttachmentFileContainer: React.FC<Props> = (props) => {
  const { file, setFile, onClose, onRemove } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState([]);

  const theme = useTheme();
  const totalWidth = '160px';
  const fileHeight = '90px';

  const onCloseAttachmentFile = () => {
    if (onClose) {
      onClose();
    }
    if (onRemove) {
      onRemove(file);
    }
    setFile(null);
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const badgeContent = (
    <Tooltip arrow placement="top" title={t('Unattachment file')}>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onCloseAttachmentFile();
        }}
        sx={{
          color: 'white',
          backgroundColor: '#b7becb',
          borderRadius: 2,
          ':hover': {
            backgroundColor: '#b7becb'
          }
        }}
      >
        <Clear
          //@ts-ignore
          fontSize="8px"
        />
      </IconButton>
    </Tooltip>
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (file?.type.split('/')[0] === 'video') {
        generateVideoThumbnails(file, 1, file.type).then((thumbs) => {
          setThumbnail(thumbs);
        });
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <PreviewAttachmentFile
        file={file}
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
      />
      <Tooltip title={file.name}>
        <Box
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
          onClick={handleOpenPreview}
          sx={{
            cursor: 'pointer',
            borderRadius: '5px',
            boxShadow:
              'var(--ds-shadow-raised, 0 1px 1px rgba(9, 30, 66, 0.31), 0 0 1px 0 rgba(9, 30, 66, 0.31))',
            width: 'fit-content'
          }}
        >
          <Badge badgeContent={badgeContent} invisible={!isHovered}>
            <Box
              position="absolute"
              sx={{
                width: 'fit-content',
                height: fileHeight,
                borderTopRightRadius: '5px',
                borderTopLeftRadius: '5px',
                ':hover': {
                  backgroundColor: alpha(theme.colors.primary.main, 0.2)
                }
              }}
            />
            <Box
              sx={{
                backgroundColor: lighten(theme.colors.primary.lighter, 0.5),

                textAlign: 'center'
              }}
            >
              {isImageFile(file) ? (
                <img
                  src={URL.createObjectURL(file)}
                  style={{
                    width: totalWidth,
                    height: fileHeight,
                    borderTopRightRadius: '5px',
                    borderTopLeftRadius: '5px'
                  }}
                  alt={file.name}
                />
              ) : file.type.split('/')[0] === 'video' ? (
                <img
                  src={thumbnail[0]}
                  style={{
                    width: totalWidth,
                    height: fileHeight,
                    borderTopRightRadius: '5px',
                    borderTopLeftRadius: '5px'
                  }}
                  alt={file.name}
                />
              ) : (
                <Box
                  sx={{
                    background: '#F6F6F6',
                    color: 'primary',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: totalWidth,
                    height: fileHeight,
                    borderTopRightRadius: '5px',
                    borderTopLeftRadius: '5px',
                    // Ñapita
                    mb: '0.36rem'
                  }}
                >
                  <InsertDriveFileIcon fontSize="large" />
                </Box>
              )}
              <Box px={1}>
                <Typography
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  maxWidth="140px"
                  overflow="hidden"
                  fontSize="0.75rem"
                  fontWeight="bold"
                >
                  {file.name}
                </Typography>
                <Typography
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  maxWidth="140px"
                  overflow="hidden"
                  fontSize="0.75rem"
                >
                  {formatBytes({ bytes: file.size })}
                </Typography>
              </Box>
            </Box>
          </Badge>
        </Box>
      </Tooltip>
    </>
  );
};

export default AttachmentFileContainer;
