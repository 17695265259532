import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useRef,
  useState
} from 'react';
import { Option } from '../models/option';
import { getOperators } from '../utils/getOperatorOptions';
import { getOperatorLabel } from '../utils/getOperatorLabel';
import { getFilterValues } from '../utils/getFilterValues';
import {
  CustomFilterValueOptions,
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  IntegerOperator
} from '../models/filters';
import { useQuery } from '@tanstack/react-query';
import { getOptions } from '../utils/getOptionValues';
import { getOperatorDescription } from '../utils/getOperatorDescription';
import { useTranslation } from 'react-i18next';

interface Params<T> {
  filter: DialTableFilter<T>;
  onChangeFilter?: (
    newFilter: DialTableFilter<T>,
    previousFilter: DialTableFilter<T>,
    selectedValueOptions?: Option[]
  ) => void;
  onKeyDownFilter?: (event: KeyboardEvent<HTMLUListElement>) => void;
  onRemoveFilter?: (filter: DialTableFilter<T>) => void;
  /**
   * Function to determine whether the operator box is displayed or not.
   * If not passed, the operator box is displayed when the operator is other than undefined or null.
   */
  showOperatorFn?: (filter: DialTableFilter<T>) => boolean;
  /**
   * Function to determine whether the values box is displayed or not.
   * If not passed, the values box is displayed when the length of the values array is greater than 0.
   */
  showValuesFn?: (filter: DialTableFilter<T>) => boolean;
  /**
   * Object to map the filter fieldName to an Option array.
   * It is in charge of displaying the label of the option using the values of the filter whose fieldName matches with the key.
   * If the label option cannot be found in the optionsMap object, the option is requested to backend (using the function getOptionValue).
   * */
  optionsMap?: { [fieldName: string]: Option[] };
  /** Object with callbacks called to return custom filter options and selected values. The custom options and selcted values will not overwrite the default options.
   *
   * This callback can be used to consider other DataCategory that are not already considered by the table.
   */
  customFilterValueOptions?: CustomFilterValueOptions;
  onlyEqualsOperator?: boolean;
}

export const useDialTableFilterItem = <T extends Object>(params: Params<T>) => {
  const {
    filter,
    onChangeFilter,
    showOperatorFn,
    showValuesFn,
    optionsMap,
    customFilterValueOptions,
    onlyEqualsOperator
  } = params;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isSelectedOperatorItem, setIsSelectedOperatorItem] = useState(false);
  const [isSelectedTextValueItem, setIsSelectedTextValueItem] = useState(false);
  const [input, setInput] = useState('');
  const initialTextFilterInput = filter?.values?.[0];
  const [textFilterInput, setTextFilterInput] = useState(
    initialTextFilterInput
  );
  const isOperatorVisible = showOperatorFn?.(filter) ?? !!filter?.operator;
  const isValuesVisible = showValuesFn?.(filter) ?? filter?.values?.length > 0;
  const placeHolderDataRef = useRef<Option[]>([]);
  const { t } = useTranslation();

  const isTextFilter =
    filter.fieldType === FieldType.TEXT &&
    (filter.fieldDataType !== FieldDataType.DATE ||
      filter.dataCategory !== DataCategory.DATE);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // If onlyEqualsOperator, do not handle the click
  const handleClickOperator = onlyEqualsOperator
    ? undefined
    : (event: MouseEvent<HTMLDivElement>) => {
        // Display the operator options and hide value options
        setIsSelectedOperatorItem(true);
        handleClick(event);
      };

  const handleClickValues = (event: MouseEvent<HTMLDivElement>) => {
    if (isTextFilter) {
      // Display the input text
      setIsSelectedTextValueItem(true);
      return;
    }
    // Hide the operator options and display the value options
    setIsSelectedOperatorItem(false);
    handleClick(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onTextFilterInputChange = ({
    target
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTextFilterInput(target.value);
  };

  const confirmTextFilterValue = () => {
    setIsSelectedTextValueItem(false);
    const newFilter = {
      ...filter,
      values: [textFilterInput?.trim()]
    };
    onChangeFilter?.(newFilter, filter);
  };

  const onInputKeyDown = (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && isSelectedTextValueItem) {
      confirmTextFilterValue();
    }
  };

  const onInputBlur = () => {
    confirmTextFilterValue();
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInput(target.value);
  };

  const openDropdownMenu =
    !!onChangeFilter &&
    ((!isTextFilter && (isOperatorVisible || isValuesVisible)) ||
      (isTextFilter && isOperatorVisible)) &&
    Boolean(anchorEl);

  const operatorList = onlyEqualsOperator
    ? [IntegerOperator.EQUALS]
    : getOperators(filter);

  const options = getOptions({
    options: operatorList,
    labelExtractor: (operator) => t(getOperatorLabel(operator)),
    valueExtractor: (operator) => operator,
    descriptionExtractor: (operator) => t(getOperatorDescription(operator))
  });

  const operatorOptions = options.filter(
    (operator) => operator.value !== filter.operator
  );

  const operatorLabel = getOperatorLabel(filter.operator);

  const getCustomFilterValueOptionFn =
    customFilterValueOptions?.getValueOptionFn;

  const getCustomFilterValueOptionListFn =
    customFilterValueOptions?.getValueOptionListFn;

  const queryResult = useQuery({
    queryKey: [
      'optionValue',
      // if no dataCategory, the fieldName is used as queryKey
      !filter?.dataCategory ? filter?.fieldName : undefined,
      filter?.dataCategory,
      filter.values
    ],
    queryFn: async () => {
      const resp = await getFilterValues(
        filter,
        getCustomFilterValueOptionFn,
        optionsMap
      );
      placeHolderDataRef.current = resp;
      return resp;
    },
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: placeHolderDataRef.current
  });

  const filterValues = queryResult?.data
    ?.map((option) => option?.label ?? option)
    .join(', ');

  return {
    anchorEl,
    filter,
    filterValues,
    getCustomFilterValueOptionListFn,
    handleClickOperator,
    handleClickValues,
    handleClose,
    handleInputChange,
    input,
    isOperatorVisible,
    isSelectedOperatorItem,
    isSelectedTextValueItem,
    isValuesVisible,
    onInputBlur,
    onInputKeyDown,
    onTextFilterInputChange,
    openDropdownMenu,
    operatorLabel,
    operatorOptions,
    setIsSelectedTextValueItem,
    textFilterInput
  };
};
