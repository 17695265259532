import { MouseEvent, ReactNode } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface Props {
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const DialTableFilterItemBox = (props: Props) => {
  const { children, onClick } = props;
  const isPointerCursor = !!onClick;
  const theme = useTheme();
  return (
    <Box
      borderRadius={0.25}
      px={0.75}
      py={0.5}
      sx={{
        backgroundColor: theme.colors.alpha.black[10],
        cursor: isPointerCursor ? 'pointer' : 'default'
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
