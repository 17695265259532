import axios, { AxiosResponse } from 'axios';
import { PaginatedElements } from 'src/models/pagination';
import {
  CallResultCount,
  PrioritySchedule,
  ScheduleContactsDialerbot,
  Schedules,
  ScheduleDatabaseFiles,
  UploadedSchedulesProps
} from 'src/modules/DialerBot/models/dialerbot';
import { replaceURLParams } from 'src/services/utils';
import {
  SCHEDULE_DATABASE,
  SCHEDULE_DATABASE_ID,
  SCHEDULE_DATABASE_PRIORITY,
  SCHEDULE_DATABASE_UPLOAD_CONTACTS,
  SCHEDULE_DATABASE_UPLOAD_FILES,
  SCHEDULE_DIALERBOT_CALL_RESULT_COUNT
} from 'src/utils/apiRoutes/msCallSchedule';

export const getSchedulesDialerBot = async (
  campaignId?: string,
  scheduleDatabaseId?: string,
  page?: number,
  size?: number
) => {
  const params = { page, size, scheduleDatabaseId, campaignId };

  const resp = await axios.get<PaginatedElements<Schedules>>(
    SCHEDULE_DATABASE,
    {
      params
    }
  );
  return resp.data;
};

export const uploadFiles: Function = async (
  schedule: ScheduleDatabaseFiles
): Promise<ScheduleDatabaseFiles> => {
  const params: Omit<ScheduleDatabaseFiles, 'files'> = {
    campaignId: schedule.campaignId,
    delimiter: schedule.delimiter,
    phoneColumn: schedule.phoneColumn,
    priority: schedule.priority,
    defaultPrefix: schedule.defaultPrefix
  };
  if (schedule.usernameColumn) {
    params.usernameColumn = schedule.usernameColumn;
  }

  const formData = new FormData();
  schedule.files.map((file) => formData.append('files', file));

  const response: AxiosResponse<ScheduleDatabaseFiles> = await axios.post(
    SCHEDULE_DATABASE_UPLOAD_FILES,
    formData,
    {
      params
    }
  );
  return response.data;
};

export const getUploadedSchedules: Function = (
  campaignId: string,
  currentPage?: number
) => {
  const params = {
    campaignId,
    page: currentPage ?? 0,
    size: 5
  };

  return axios
    .get(SCHEDULE_DATABASE, { params })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const deleteUploadedSchedules: Function = (id: string) => {
  const path = replaceURLParams(SCHEDULE_DATABASE_ID, {
    '{id}': id
  });

  return axios
    .delete(path)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getCallResultsCount = async (
  campaignId: string,
  scheduleDatabaseId?: string
): Promise<CallResultCount> => {
  const params = {
    campaignId
  };

  if (scheduleDatabaseId) params['scheduleDatabaseId'] = scheduleDatabaseId;
  const response: AxiosResponse<CallResultCount> = await axios.get(
    SCHEDULE_DIALERBOT_CALL_RESULT_COUNT,
    {
      params
    }
  );
  return response.data;
};

export const uploadContacts: Function = async (
  urlParams: string
): Promise<AxiosResponse<ScheduleContactsDialerbot>> => {
  const UPLOAD_FILE = SCHEDULE_DATABASE_UPLOAD_CONTACTS + urlParams;

  return axios
    .post(UPLOAD_FILE)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const updateSchedulePrority: Function = (
  id: string,
  priority: PrioritySchedule
): Promise<AxiosResponse<UploadedSchedulesProps>> => {
  const path = replaceURLParams(SCHEDULE_DATABASE_PRIORITY, {
    '{id}': id
  });

  const params = {
    priority
  };

  return axios
    .put(path, null, { params })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
