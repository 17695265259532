import { Variable } from '../models/models';
import { updateVariableApi } from '../infrastructure/updateVariableApi';
import { deleteVariableApi } from '../infrastructure/deleteVariableApi';

export const deleteVariableDomain = async (id: string) => {
  try {
    return await deleteVariableApi(id);
  } catch (error: unknown) {
    throw error;
  }
};
