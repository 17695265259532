import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Extensions from '../../Configurations/modules/Extensions/views';
import { InboundRoutesProvider } from 'src/contexts/InboundRoutesContext';
import { VariablesPage } from '../modules/Variables/views';
import { IntentionsModelsProvider } from '../modules/AIModels/views/context/AiModelsContext';
import { MusicOnHold } from '../modules/MOH/views';
import { MusicOnHoldProvider } from '../modules/MOH/views/context';
import { VideoStream } from 'src/modules/VideoStream';
import { VideoStreamProvider } from 'src/modules/VideoStream/context';
import Restricted from 'src/components/Restricted';
import {
  checkReadAnnouncementPermissions,
  checkReadInboundRoutePermissions,
  checkReadMusicOnHoldPermissions,
  checkReadOutboundCampaignPermissions,
  checkReadOutboundRoutePermissions,
  checkReadTimeConditionPermissions,
  checkReadVariablePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { KnowledgeModels } from '../modules/KnowledgeModels';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Announcements = Loader(
  lazy(() => import('../modules/AnnouncementsView'))
);
const CERoute = Loader(
  lazy(
    () =>
      import(
        'src/modules/Admin/modules/Acd/modules/InboundRoutes/views/components/InboundRoutesCreateEdit'
      )
  )
);
const OutboundRoutes = Loader(
  lazy(() => import('../modules/OutboundRoutes/views'))
);
const Routes = Loader(lazy(() => import('../modules/InboundRoutes/views')));
const TimerGroups = Loader(
  lazy(() => import('../modules/TimeGroupsViews/views'))
);
const AIModels = Loader(lazy(() => import('../modules/AIModels/views')));

const acdRoutes: RouteObject[] = [
  {
    path: 'routes',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadInboundRoutePermissions}
      >
        <Routes />
      </Restricted>
    ),
    children: [
      {
        path: ':id',
        element: (
          <ReactFlowProvider>
            <InboundRoutesProvider>
              <CERoute />
            </InboundRoutesProvider>
          </ReactFlowProvider>
        )
      },
      {
        path: 'new-route',
        element: (
          <ReactFlowProvider>
            <InboundRoutesProvider>
              <CERoute />
            </InboundRoutesProvider>
          </ReactFlowProvider>
        )
      }
    ]
  },
  {
    path: 'outbound-routes',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadOutboundRoutePermissions}
      >
        <OutboundRoutes />
      </Restricted>
    ),
    children: [
      // {
      //   path: ':id',
      //   element: (
      //     <ReactFlowProvider>
      //       <OutboundRoutesProvider>
      //         <CERoute />
      //       </OutboundRoutesProvider>
      //     </ReactFlowProvider>
      //   )
      // },
      // {
      //   path: 'new-route',
      //   element: (
      //     <ReactFlowProvider>
      //       <OutboundRoutesProvider>
      //         <CERoute />
      //       </OutboundRoutesProvider>
      //     </ReactFlowProvider>
      //   )
      // }
    ]
  },

  {
    path: 'ai-models',
    element: (
      <IntentionsModelsProvider>
        <AIModels />
      </IntentionsModelsProvider>
    )
  },
  {
    path: 'knowledge-models',
    element: <KnowledgeModels />
  },
  {
    path: 'announcements',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadAnnouncementPermissions}
      >
        <Announcements />
      </Restricted>
    )
  },
  {
    path: 'schedule-groups',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadTimeConditionPermissions}
      >
        <TimerGroups />
      </Restricted>
    )
  },
  {
    path: 'variables',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadVariablePermissions}>
        <VariablesPage />
      </Restricted>
    )
  },
  {
    path: 'moh',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadMusicOnHoldPermissions}
      >
        <MusicOnHoldProvider>
          <MusicOnHold />
        </MusicOnHoldProvider>
      </Restricted>
    )
  },
  {
    path: 'video-call',
    element: (
      <VideoStreamProvider>
        <VideoStream />
      </VideoStreamProvider>
    )
  }
];

export default acdRoutes;
