import { EmailMessage } from 'src/models/conversations/messages';
import {
  formatAttachmentUrl,
  getBlobUrl
} from 'src/modules/Shared/utils/attachments';

export const getEmailTextWithParsedInlineAttachments = async (
  messageData: EmailMessage
) => {
  const attachments = messageData?.attachedFiles ?? [];
  let parsedHtml = messageData.message;

  for (const attachment of attachments) {
    if (attachment.type === 'Inline') {
      const cid = attachment.contentId.replace('<', '').replace('>', '');
      const url = formatAttachmentUrl(
        attachment.uploadedFile,
        messageData.channel
      );
      const urlBlob = await getBlobUrl(url);
      parsedHtml = parsedHtml?.replaceAll?.(`cid:${cid}`, urlBlob);
    }
  }

  return parsedHtml;
};
