import { AxiosResponse } from 'axios';
import wsManager from 'src/services/websocket/manager';
import AuthManager from '../manager';

/** REFRESH SESSION TOKEN
 *
 */
export const refreshRequestDomain = (websocketId: string) => {
  const token = AuthManager.getToken();
  AuthManager.refreshRequest(token, websocketId);
};
