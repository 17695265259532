import { Dispatch, SetStateAction } from 'react';
import { Card, IconButton, Stack } from '@mui/material';
import { CustomFilterValueOptions, DialTableFilter } from '../models/filters';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import { DialTableFilterInput } from './DialTableFilterInput';

interface Props<T> {
  availableFilters: DialTableFilter<T>[];
  selectedFilters: DialTableFilter<T>[];
  setSelectedFilters: Dispatch<SetStateAction<DialTableFilter<T>[]>>;
  onApplyFilters: (selectedFilters: DialTableFilter<T>[]) => void;
  customFilterValueOptions?: CustomFilterValueOptions;
  onlyEqualsOperator?: boolean;
  disabled?: boolean;
}

export const DialTableFilters = <T extends Object>(props: Props<T>) => {
  const {
    availableFilters,
    selectedFilters,
    setSelectedFilters,
    onApplyFilters: onClickApplyFiltersProps,
    customFilterValueOptions,
    onlyEqualsOperator,
    disabled
  } = props;

  const onApplyFilters = () => {
    onClickApplyFiltersProps?.(selectedFilters);
  };

  const { t }: { t: any } = useTranslation();

  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Stack width="100%" direction="row" spacing={0.75}>
        <DialTableFilterInput
          availableFilters={availableFilters}
          selectedFilters={selectedFilters}
          onChange={(value) => {
            setSelectedFilters(value);
          }}
          onEnterKeyDown={onClickApplyFiltersProps}
          customFilterValueOptions={customFilterValueOptions}
          onlyEqualsOperator={onlyEqualsOperator}
        />
        <Stack py={0.5} justifyContent="end" spacing={1}>
          <IconButton
            size="small"
            color="secondary"
            title={t('Apply filters')}
            disabled={disabled}
            onClick={onApplyFilters}
          >
            <Search />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};
