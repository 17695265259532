import { Box, Avatar, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Conversation } from 'src/models/conversations/conversations';
import { ChatMessage } from 'src/models/conversations/messages';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { getMessageContactName } from 'src/services/socialmedia/application/utils';

import ChatMessageWrapperCard from './ChatMessageWrapperCard';

interface Props {
  conversation: Conversation;
  message: ChatMessage;
}

const ChatMessageCard = (props: Props) => {
  const { message, conversation } = props;
  const { orientation } = message;
  const [contactName, setContactName] = useState<string>('');
  const theme = useTheme();

  const loggedUserName = getConnectedRTUser(true)?.username;

  useEffect(() => {
    if (orientation === 'Incoming') {
      let isMounted = true;
      getMessageContactName(conversation, message).then((name) => {
        if (isMounted) {
          setContactName(name);
        }
      });
      return () => {
        isMounted = false;
      };
    }
  }, [conversation, message]);

  const contactInitial = contactName?.charAt(0).toLocaleUpperCase();
  const loggeduserInitial = loggedUserName?.charAt(0).toLocaleUpperCase();

  return (
    <Box p={0.25}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent={orientation === 'Incoming' ? 'flex-start' : 'flex-end'}
      >
        {orientation === 'Incoming' && (
          <Avatar
            variant="circular"
            sx={{
              width: 42,
              height: 42
            }}
            alt={contactName}
            // src="/static/images/avatars/1.jpg"
          >
            <Typography variant="h3" color="inherit">
              {contactInitial}
            </Typography>
          </Avatar>
        )}
        <Box
          display="flex"
          alignItems={orientation === 'Incoming' ? 'flex-start' : 'flex-end'}
          flexDirection="column"
          justifyContent={
            orientation === 'Incoming' ? 'flex-start' : 'flex-end'
          }
          mx={2}
          sx={{ width: '100%' }}
          zIndex={30}
        >
          <ChatMessageWrapperCard
            conversation={conversation}
            message={message}
            contactName={contactName}
          />
        </Box>
        {orientation === 'Outgoing' && (
          <Avatar
            variant="circular"
            sx={{
              width: 42,
              height: 42,
              backgroundColor: theme.colors.secondary.light
            }}
            alt={loggedUserName}
            // src="/static/images/avatars/2.jpg"
          >
            <Typography variant="h3" color="inherit">
              {loggeduserInitial}
            </Typography>
          </Avatar>
        )}
      </Box>
    </Box>
  );
};

export default ChatMessageCard;
