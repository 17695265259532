import { FC, useState, createContext, Dispatch, SetStateAction } from 'react';
import { UserRole } from 'src/models/permissionGroups';
import { User } from 'src/models/users';
import { ActionType, FormTypes } from 'src/models/users';
import { ExtensionProps } from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/models/acdRoutes';

type UserContext = {
  userRoleOptions: UserRole[];
  setUserRoleOptions: Dispatch<SetStateAction<UserRole[]>>;
  listExtensions: ExtensionProps[];
  setListExtensions: Dispatch<SetStateAction<ExtensionProps[]>>;
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  actionType: ActionType;
  visibleExtension: boolean;
  setVisibleExtension: Dispatch<SetStateAction<boolean>>;
  formType: FormTypes;
  setFormType: Dispatch<SetStateAction<FormTypes>>;
  currentTab: FormTypes;
  setCurrentTab: Dispatch<SetStateAction<FormTypes>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserContext = createContext<UserContext>({} as UserContext);

export const UserProvider: FC = ({ children }) => {
  const [userRoleOptions, setUserRoleOptions] = useState<UserRole[]>([]);
  const [listExtensions, setListExtensions] = useState<ExtensionProps[]>([]);
  const [user, setUser] = useState<User>();
  const actionType: ActionType = user?.id ? 'update' : 'create';
  const [visibleExtension, setVisibleExtension] = useState<boolean>();
  const [formType, setFormType] = useState<FormTypes>('All Users');
  const [currentTab, setCurrentTab] = useState<FormTypes>('All Users');

  return (
    <UserContext.Provider
      value={{
        userRoleOptions,
        setUserRoleOptions,
        listExtensions,
        setListExtensions,
        user,
        setUser,
        actionType,
        visibleExtension,
        setVisibleExtension,
        formType,
        setFormType,
        currentTab,
        setCurrentTab
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
