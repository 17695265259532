import { WhatspAppAccount } from 'src/services/whatsapp/models/WhatsAppAccount';
import {
  ChannelType,
  Conversation,
  ConversationStatus
} from 'src/models/conversations/conversations';

export const dummyConversation: Conversation = {
  id: 'hotline',
  campaignId: null,
  companyId: '645e2b9ce339381ed1b65400',
  serviceId: '63220530b8efa2107e38607e',
  involvedContacts: [
    {
      contactId: '',
      socialmediaAgent: {
        channel: ChannelType.CALL,
        endpoint: 'SGI HOME',
        orientation: 'Inbound',
        callId: '0@e843de69-0cbb-4a1f-9539-e94cea7fbe39'
      }
    }
  ],
  messages: [],
  accountId: null,
  channel: ChannelType.CALL,
  status: ConversationStatus.ASSIGNED,
  originAgent: {
    channel: ChannelType.CALL,
    endpoint: 'c-63220530b8efa2107e38607e-e-104',
    orientation: 'Inbound',
    callId: '0@e843de69-0cbb-4a1f-9539-e94cea7fbe39'
  },
  assignedUserIds: [
    '6322062db8efa2107e386080',
    '63230ef1b8efa2107e386088',
    '63230ec6b8efa2107e386086',
    '63230e8f73a0500df0f8b08c',
    '6322df5273a0500df0f8b089',
    '6322d117b8efa2107e386081',
    '6334b28c830b272d29d51e89'
  ],
  metadata: {
    creationDate: '2023-01-16T13:22:37.149Z',
    modificationDate: '2023-01-16T13:27:06.165Z'
  },
  unreadIncomingMessages: true,
  lastInteractionTime: '2023-01-16T13:27:06.165Z',
  lastMessage: null,
  variables: {}
};

export const dummyConversationFichaje: Conversation = {
  id: 'fichaje',
  campaignId: null,
  companyId: '63220530b8efa2107e38607e',
  serviceId: '63220530b8efa2107e38607e',
  involvedContacts: [
    {
      contactId: '',
      socialmediaAgent: {
        channel: ChannelType.CALL,
        endpoint: 'FICHAJE HOME',
        orientation: 'Inbound',
        callId: '0@e843de69-0cbb-4a1f-9539-e94cea7fbe39'
      }
    }
  ],
  messages: [],
  accountId: null,
  channel: ChannelType.CALL,
  status: ConversationStatus.ASSIGNED,
  originAgent: {
    channel: ChannelType.CALL,
    endpoint: 'c-63220530b8efa2107e38607e-e-104',
    orientation: 'Inbound',
    callId: '0@e843de69-0cbb-4a1f-9539-e94cea7fbe39'
  },
  lastInteractionTime: '2023-01-16T13:27:06.165Z',
  lastMessage: null,
  assignedUserIds: [
    '6322062db8efa2107e386080',
    '63230ef1b8efa2107e386088',
    '63230ec6b8efa2107e386086',
    '63230e8f73a0500df0f8b08c',
    '6322df5273a0500df0f8b089',
    '6322d117b8efa2107e386081',
    '6334b28c830b272d29d51e89'
  ],
  metadata: {
    creationDate: '2023-01-16T13:22:37.149Z',
    modificationDate: '2023-01-16T13:27:06.165Z'
  },
  unreadIncomingMessages: true,
  variables: {}
};

export const dummyWhatspAppAccount: WhatspAppAccount[] = [
  {
    companyId: 'BORRAR',
    enabled: true,
    phoneNumber: '310384722',
    id: '1',
    name: 'prueba 1'
  },
  {
    companyId: 'DELICIOSA',
    enabled: true,
    phoneNumber: '61429374',
    id: '2',
    name: 'prueba 2'
  }
];
