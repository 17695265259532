import {
  Box,
  Stack,
  Switch,
  IconButton,
  Button,
  Tooltip,
  Divider,
  TextField,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import { Report, SyncTwoTone, Tune } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { AudioSlider } from 'src/components/WebRTCDevices/AudioSlider';
import { AudioSelect } from 'src/components/WebRTCDevices/AudioSelect';
import { VoiceSlider } from 'src/components/WebRTCDevices/VoiceSlider';
import { VoiceSelect } from 'src/components/WebRTCDevices/VoiceSelect';
import { RingSlider } from 'src/components/WebRTCDevices/RingSlider';
import { RingSelect } from 'src/components/WebRTCDevices/RingSelect';
import { useDispatch } from 'src/store/store';
import {
  toggleAnswerOnClickNotification,
  toggleAutoanswer
} from 'src/store/slices/users/configVolumeSlice';
import { CtiPopover } from '../CtiPopover';
import { useTranslation } from 'react-i18next';
import { refreshUserCalls } from 'src/services/authentication/domain/refreshUserCalls';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import VolumeMeter from 'src/components/VolumeMeter';
import { ChannelType } from 'src/models/conversations/conversations';
import { getAutoanswerStore } from 'src/services/webrtc/infrastructure/store/getAutoanswerStore';
import { getAnswerOnClickStore } from 'src/services/webrtc/infrastructure/store/getAnswerOnClickStore';
import { SpeakerTestProps } from './hooks/useSpeakerTest';
import {
  ringtoneSoundPathKey,
  telegramSoundPathKey,
  whatsappSoundPathKey,
  emailSoundPathKey,
  webchatSoundPathKey
} from 'src/services/webrtc/webrtc-devices';
import { logger } from 'src/utils/logger';
import { CTIPopoverNames } from 'src/contexts/CTIBarContext';
import { t } from 'i18next';

interface DevicesMenuProps {
  speakersTest: SpeakerTestProps;
}

const SoundsOptions = [
  { name: 'Messenger', path: '/sounds/messenger.mp3' },
  { name: 'iPhone', path: '/sounds/iphone_notification.mp3' },
  { name: 'Old Samsung', path: '/sounds/old_samsung_notification.mp3' },
  { name: 'WindowsXP', path: '/sounds/windows_xp.mp3' }
];

const RingtonesOptions = [
  { name: t('Default'), path: '/sounds/ringtones/message-ringtone-21467.mp3' },
  { name: 'Ring 1', path: '/sounds/ringtones/rtc_ring_1.mp3' },
  { name: 'Ring 2', path: '/sounds/ringtones/rtc_ring_2.mp3' },
  { name: 'Ring 3', path: '/sounds/ringtones/rtc_ring_3.mp3' },
  { name: 'Ring 4', path: '/sounds/ringtones/rtc_ring_4.mp3' },
  { name: 'Ring 5', path: '/sounds/ringtones/rtc_ring_5.mp3' },
  { name: 'Ring 6', path: '/sounds/ringtones/rtc_ring_6.mp3' },
  { name: 'Ring 7', path: '/sounds/ringtones/rtc_ring_7.mp3' },
  { name: 'Ring 8', path: '/sounds/ringtones/rtc_ring_8.mp3' },
  { name: 'Ring 9', path: '/sounds/ringtones/rtc_ring_9.mp3' },
  { name: 'Ring 10', path: '/sounds/ringtones/rtc_ring_10.mp3' },
  { name: 'Ring 11', path: '/sounds/ringtones/rtc_ring_11.mp3' },
  { name: 'Ring 13', path: '/sounds/ringtones/rtc_ring_13.mp3' },
  { name: 'Ring 14', path: '/sounds/ringtones/rtc_ring_14.mp3' }
];

export const DevicesMenu: React.FC<DevicesMenuProps> = ({ speakersTest }) => {
  const autoanswer = getAutoanswerStore(true);
  const answerOnClickNotification = getAnswerOnClickStore(true);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Microphone test States
  const [isTestMicrophoneOn, setIsTestMicrophoneOn] = useState(false);
  const [microphoneValue, setMicrophoneValue] = useState(0);
  const [stream, setStream] = useState<MediaStream | null>(null);
  // ring selectors states
  const [ringtonePath, setRingtonePath] = useState(
    localStorage.getItem(ringtoneSoundPathKey)
  );
  const [telegramPath, setTelegramPath] = useState(
    localStorage.getItem(telegramSoundPathKey)
  );
  const [whatsappPath, setWhatsappPath] = useState(
    localStorage.getItem(whatsappSoundPathKey)
  );
  const [emailPath, setEmailPath] = useState(
    localStorage.getItem(emailSoundPathKey)
  );
  const [webchatPath, setWebchatPath] = useState(
    localStorage.getItem(webchatSoundPathKey)
  );
  // Ring test States
  const [isTestRingOpen, setIsTestRingOpen] = useState(false);
  const [ringPopupAnchorEl, setRingPopupAnchorEl] =
    useState<null | HTMLElement>(null);

  useEffect(() => {
    let intervalId;
    if (isTestMicrophoneOn) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream: MediaStream) => {
          speakersTest.audioContext.current.resume();
          setStream(stream);
          const analyser = speakersTest.audioContext.current.createAnalyser();
          const microphone =
            speakersTest.audioContext.current.createMediaStreamSource(stream);
          const javascriptNode =
            speakersTest.audioContext.current.createScriptProcessor(2048, 1, 1);

          analyser.smoothingTimeConstant = 0.8;
          analyser.fftSize = 128;

          microphone.connect(analyser);
          analyser.connect(javascriptNode);
          microphone.connect(speakersTest.audioContext.current.destination);
          javascriptNode.connect(speakersTest.audioContext.current.destination);

          intervalId = setInterval(() => {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            let values = 0;

            const length = array.length;
            for (let i = 0; i < length; i++) {
              values += array[i];
            }

            const average = values / length;
            setMicrophoneValue(average);
          }, 100);
        })
        .catch((err) => logger.error(err));
    } else {
      // If isTestMicrophoneOn is false and stream exists, stop all tracks.
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        setStream(null);
      }
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isTestMicrophoneOn]);

  const setSocialmediaAudioElementSrc = (
    channel: ChannelType,
    path: string
  ) => {
    const socialMediaRing = document.getElementById(
      'socialmedia-message-notification-' + channel
    ) as HTMLMediaElement;
    socialMediaRing.src = path;
  };

  const setRingAudioElementSrc = (path: string) => {
    const ringtone = document.getElementById('webrtc-ring') as HTMLMediaElement;
    ringtone.src = path;
  };

  const changeSocialmediaSound = (
    channel: ChannelType,
    value: string,
    storageKey: string,
    setSelectValue: (path: string) => void
  ) => {
    if (channel === 'Call') {
      setRingtonePath(value);
      setRingAudioElementSrc(value);
    } else {
      setSocialmediaAudioElementSrc(channel, value);
    }
    setSelectValue(value);
    localStorage.setItem(storageKey, value);
  };
  return (
    <CtiPopover
      title={CTIPopoverNames.DEVICES}
      icon={
        <Tooltip title={t('Devices configuration')}>
          <IconButton id="devices-button">
            <Tune fontSize="medium" />
          </IconButton>
        </Tooltip>
      }
    >
      <Stack direction={'row'}>
        <Box p={1}>
          {navigator.userAgent.includes('Firefox') && (
            <Stack
              direction="row"
              alignItems={'center'}
              justifyContent="center"
              spacing={1}
            >
              <Report color="error" fontSize="small" />
              <Typography>
                {t("In Firefox you can't change the speaker")}
              </Typography>
            </Stack>
          )}
          <Stack
            direction={'column'}
            justifyContent="center"
            spacing={6}
            paddingTop={1}
            paddingLeft={2}
          >
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <AudioSlider />

              <AudioSelect />
              <Button
                variant="contained"
                color={speakersTest.isCallTestPlaying ? 'primary' : 'secondary'}
                onClick={speakersTest.handlePlayPauseTestCall}
                sx={{ height: '35px' }}
              >
                {t('Test')}
              </Button>
            </Stack>
            <Stack direction={'row'} alignItems="center" spacing={2}>
              {isTestMicrophoneOn ? (
                <VolumeMeter input={microphoneValue} />
              ) : (
                <VoiceSlider />
              )}
              <VoiceSelect />
              <Button
                variant="contained"
                color={isTestMicrophoneOn ? 'primary' : 'secondary'}
                onClick={() => setIsTestMicrophoneOn((prev) => !prev)}
                sx={{ height: '35px' }}
              >
                {t('Test')}
              </Button>
            </Stack>
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <RingSlider />

              <RingSelect />
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  speakersTest.isRingTestPlaying ||
                  speakersTest.isRingtoneTestPlaying
                }
                onClick={(e) => {
                  setRingPopupAnchorEl(e.currentTarget);
                  setIsTestRingOpen(true);
                }}
                sx={{ height: '35px' }}
              >
                {t('Test')}
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ my: 3 }} />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center">
              <Switch
                checked={autoanswer}
                onChange={(): void => {
                  dispatch(toggleAutoanswer());
                }}
                color="success"
                id="switchAuto-DevicesMenu"
              />
              <Typography marginRight={2}>{t('Autoanswer')}</Typography>

              <Switch
                checked={answerOnClickNotification}
                onChange={(): void => {
                  dispatch(toggleAnswerOnClickNotification());
                }}
                color="success"
                id="switchAuto-DevicesMenu"
              />
              <Typography marginRight={2}>
                {t('Attend calls when clicking on the browser notification')}
              </Typography>

              <Tooltip title={t('Reset calls')}>
                <Button onClick={refreshUserCalls} id="btnSync-DevicesMenu">
                  <SyncTwoTone />
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
        </Box>
        <Divider orientation="vertical" />
        <Stack direction="column" spacing={2} margin={2}>
          <Typography>{t('Select ringtones and sounds')}</Typography>
          <TextField
            select
            label={t('Select ringtone')}
            value={ringtonePath}
            onChange={(e) => {
              changeSocialmediaSound(
                ChannelType.CALL,
                e.target.value,
                ringtoneSoundPathKey,
                setRingAudioElementSrc
              );
            }}
          >
            {RingtonesOptions.map((ringtoneOption) => (
              <MenuItem
                value={ringtoneOption.path}
                key={ringtoneOption.name}
                id={`ring-${ringtoneOption.path}`}
              >
                {ringtoneOption.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label={t('Select telegram sound')}
            value={telegramPath}
            onChange={(e) => {
              changeSocialmediaSound(
                ChannelType.TELEGRAM,
                e.target.value,
                telegramSoundPathKey,
                setTelegramPath
              );
            }}
          >
            {SoundsOptions.map((soundOption) => (
              <MenuItem
                value={soundOption.path}
                key={soundOption.name}
                id={`sound-${soundOption.path}`}
              >
                {soundOption.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label={t('Select whatsapp sound')}
            value={whatsappPath}
            onChange={(e) => {
              changeSocialmediaSound(
                ChannelType.WHATSAPP,
                e.target.value,
                whatsappSoundPathKey,
                setWhatsappPath
              );
            }}
          >
            {SoundsOptions.map((soundOption) => (
              <MenuItem
                value={soundOption.path}
                key={soundOption.name}
                id={`sound-${soundOption.path}`}
              >
                {soundOption.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label={t('Select email sound')}
            value={emailPath}
            onChange={(e) => {
              changeSocialmediaSound(
                ChannelType.EMAIL,
                e.target.value,
                emailSoundPathKey,
                setEmailPath
              );
            }}
          >
            {SoundsOptions.map((soundOption) => (
              <MenuItem
                value={soundOption.path}
                key={soundOption.name}
                id={`sound-${soundOption.path}`}
              >
                {soundOption.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label={t('Select chat sound')}
            value={webchatPath}
            onChange={(e) => {
              changeSocialmediaSound(
                ChannelType.WEBCHAT,
                e.target.value,
                webchatSoundPathKey,
                setWebchatPath
              );
            }}
          >
            {SoundsOptions.map((soundOption) => (
              <MenuItem
                value={soundOption.path}
                key={soundOption.name}
                id={`sound-${soundOption.path}`}
              >
                {soundOption.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Stack>
      <Popover
        open={
          isTestRingOpen &&
          !speakersTest.isRingTestPlaying &&
          !speakersTest.isRingtoneTestPlaying
        }
        onClose={() => {
          setIsTestRingOpen(false);
          setRingPopupAnchorEl(null);
        }}
        sx={{
          backdropFilter: 'blur(0px)'
        }}
        anchorEl={ringPopupAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack direction="column">
          <IconButton
            onClick={() =>
              speakersTest.handlePlayNotificationSound(ChannelType.TELEGRAM)
            }
            sx={{ justifyContent: 'start' }}
          >
            <MusicNoteIcon sx={{ height: '20px' }} />
            <Typography>{t('Telegram')}</Typography>
          </IconButton>
          <IconButton
            onClick={() =>
              speakersTest.handlePlayNotificationSound(ChannelType.WHATSAPP)
            }
            sx={{ justifyContent: 'start' }}
          >
            <MusicNoteIcon sx={{ height: '20px' }} />
            <Typography>{t('WhatsApp')}</Typography>
          </IconButton>
          <IconButton
            onClick={() =>
              speakersTest.handlePlayNotificationSound(ChannelType.EMAIL)
            }
            sx={{ justifyContent: 'start' }}
          >
            <MusicNoteIcon sx={{ height: '20px' }} />
            <Typography>{t('Email')}</Typography>
          </IconButton>
          <IconButton
            onClick={() =>
              speakersTest.handlePlayNotificationSound(ChannelType.WEBCHAT)
            }
            sx={{ justifyContent: 'start' }}
          >
            <MusicNoteIcon sx={{ height: '20px' }} />
            <Typography>{t('WebChat')}</Typography>
          </IconButton>
          <IconButton
            onClick={() =>
              speakersTest.handlePlayNotificationSound(ChannelType.CALL)
            }
            sx={{ justifyContent: 'start' }}
          >
            <MusicNoteIcon sx={{ height: '20px' }} />
            <Typography>{t('Ringtone')}</Typography>
          </IconButton>
        </Stack>
      </Popover>
    </CtiPopover>
  );
};
