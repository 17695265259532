import { useContext } from 'react';
import { VideoStreamContext } from '../../context';

export const Video = () => {
  const { videoRef } = useContext(VideoStreamContext);

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      style={{
        width: 640,
        height: 'auto',
        backgroundColor: 'black',
        aspectRatio: '16 / 9'
      }}
    />
  );
};
