import { SerializedEditor } from 'lexical';
import { Conversation } from 'src/models/conversations/conversations';
import { EmailMessage } from 'src/models/conversations/messages';
import { SendEmailMessageData } from '../hooks/useEmailEditor';

export interface EmailEditorConfig {
  mode: EmailEditorMode;
  messageToReply: EmailMessage;
  message?: SendEmailMessageData;
  serializedEditor: SerializedEditor;
}

export enum EmailEditorMode {
  Reply = 'reply',
  ReplyAll = 'replyAll',
  Forward = 'forward',
  New = 'new'
}

export interface EmailSessionData {
  conversation: Conversation;
  messageToReply: EmailMessage;
  message: SendEmailMessageData;
  campaignId: string;
  mode: EmailEditorMode;
  serializedEditor: SerializedEditor;
}
