import { QueryKey } from '@tanstack/react-query';
import { DialTableQueryKeys } from '../models/queryKey';

/**
 * Returns an object of type DialTableQueryKeys containing the keys availableFilters, data, and initialFilters.
 * If queryKey is not provided or partially provided, the function assigns a randomly generated UUID to any missing keys
 * @param queryKey
 * @returns
 */
export const getDialTableQueryKeys = (
  queryKey: QueryKey | Partial<DialTableQueryKeys>
) => {
  const randomKey = [crypto.randomUUID()];
  if (queryKey && Array.isArray(queryKey)) {
    const keys: DialTableQueryKeys = {
      availableFilters: queryKey,
      data: queryKey,
      initialFilters: queryKey
    };
    return keys;
  } else {
    const keys: DialTableQueryKeys = {
      availableFilters:
        (queryKey as DialTableQueryKeys)?.availableFilters ?? randomKey,
      data: (queryKey as DialTableQueryKeys)?.data ?? randomKey,
      initialFilters:
        (queryKey as DialTableQueryKeys)?.initialFilters ?? randomKey
    };
    return keys;
  }
};
