import { Card, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ConversationContact } from 'src/models/conversations/conversations';
import {
  EmailMessage as EmailMessageModel,
  MessageStatus
} from 'src/models/conversations/messages';
import EmailItemHeader from './components/EmailItemHeader';
import EmailMessageRenderer from './components/EmailMessage';
import {
  EmailEditorConfig,
  EmailEditorMode
} from '../EmailEditor/models/models';
import EmailEditor from '../EmailEditor';
import Attachments from './components/Attachments';
import { readMessages } from 'src/services/socialmedia/domain/messages/readMessages';
import { getCurrentConversation } from 'src/services/socialmedia/domain/conversation/getCurrentConversation';
import { setConversationStore } from 'src/services/socialmedia/infraestructure/store/setConversationStore';

interface Props {
  message: EmailMessageModel;
  involvedContacts: ConversationContact[];
  conversationId: string;
  defaultMaximized?: boolean;
}

export interface EmailItemState {
  isMaximized: boolean;
  isEditorOpen: boolean;
  editorInfo: EmailEditorConfig;
}

function EmailItem({ message, involvedContacts, defaultMaximized }: Props) {
  const [itemState, setItemState] = useState({
    isMaximized: defaultMaximized ?? false,
    isEditorOpen: false,
    editorInfo: {
      mode: EmailEditorMode.Reply,
      messageToReply: message
    }
  } as EmailItemState);

  async function readEmail() {
    const conversation = getCurrentConversation(false);
    await readMessages(conversation);
    const lastMessage = conversation.lastMessage
      ? conversation.lastMessage
      : message;
    setConversationStore({
      ...conversation,
      lastMessage: {
        ...lastMessage,
        status: MessageStatus.READ
      },
      unreadIncomingMessages: false
    });
  }

  useEffect(() => {
    if (itemState.isMaximized && message.status === 'Received') {
      readEmail();
    }
  }, [message?.status, itemState.isMaximized]);

  return (
    <>
      {itemState.isEditorOpen && (
        <Card
          sx={{
            borderRadius: '4px',
            width: '100%'
          }}
        >
          <EmailEditor
            key={itemState.editorInfo.mode}
            config={itemState}
            setConfig={setItemState}
            conversation={getCurrentConversation(false)}
          />
        </Card>
      )}
      <Card
        sx={{
          borderRadius: '4px',
          width: '100%'
        }}
      >
        <EmailItemHeader
          involvedContacts={involvedContacts}
          message={message}
          itemState={itemState}
          setItemState={setItemState}
        />
        {itemState.isMaximized && (
          <Attachments attachments={message.attachedFiles} />
        )}
        <Stack pr={2} pt={0} pl={'67px'}>
          <EmailMessageRenderer
            message={message}
            isMaximized={itemState.isMaximized}
          />
        </Stack>
      </Card>
    </>
  );
}

export default EmailItem;
