import { BASE_URL } from './base';

export const ACD_MS = `${BASE_URL}/acd`;

// Routes
const ROUTES_CONTROLLER = `${ACD_MS}/routes`;
export const ROUTES = ROUTES_CONTROLLER;

// Elements
const ELEMENTS_CONTROLLER = `${ACD_MS}/elements`;
export const ELEMENTS = ELEMENTS_CONTROLLER;

// Nodes
const NODES_CONTROLLER = `${ACD_MS}/nodes`;
export const NODES = NODES_CONTROLLER;

// Inbound
const INBOUND_CONTROLLER = `${ROUTES}/inbound`;
export const INBOUND = INBOUND_CONTROLLER;
export const INBOUND_ID = `${INBOUND}/{id}`;
export const INBOUND_ID_CLONE = `${INBOUND_ID}/clone`;
export const INBOUND_BY_ACCOUNT = `${INBOUND}/by_account`;
export const INBOUND_FIND_ELEMENT_BY_ID = `${INBOUND}/findByElementId`;
export const INBOUND_FULL_ACCOUNT = `${INBOUND}/full_account/{accountId}`;
export const INBOUND_FULL_ID_ID = `${INBOUND}/full_id/{id}`;
export const INBOUND_FULL_DDI = `${INBOUND}/full/{ddi}`;
export const INBOUND_USED_ACCOUNTS = `${INBOUND}/used_accounts`;

// Outbound
const OUTBOUND_CONTROLLER = `${ROUTES}/outbound`;
export const OUTBOUND = OUTBOUND_CONTROLLER;
export const OUTBOUND_ID = `${OUTBOUND}/{id}`;
export const OUTBOUND_IS_CALL_ALLOWED = `${OUTBOUND_ID}/isCallAllowed`;

// Temporal ranges
const TEMPORAL_RANGES_CONTROLLER = `${ACD_MS}/temporal-ranges`;
export const TEMPORAL_RANGES = TEMPORAL_RANGES_CONTROLLER;
export const TEMPORAL_RANGES_ID = `${TEMPORAL_RANGES}/{id}`;

// Time condition
const TIME_CONDITIONS_CONTROLLER = `${ELEMENTS_CONTROLLER}/time-condition`;
export const TIME_CONDITIONS = TIME_CONDITIONS_CONTROLLER;
export const TIME_CONDITIONS_ID = `${TIME_CONDITIONS_CONTROLLER}/{id}`;

// Music on hold
const MUSIC_ON_HOLD_CONTROLLER = `${ACD_MS}/moh`;
export const MUSIC_ON_HOLD = MUSIC_ON_HOLD_CONTROLLER;
export const MUSIC_ON_HOLD_ID = `${MUSIC_ON_HOLD_CONTROLLER}/{id}`;

// IVR node
const IVR_CONTROLLER = `${NODES_CONTROLLER}/ivr`;
export const IVR_NODE = IVR_CONTROLLER;
export const IVR_ID = `${IVR_CONTROLLER}/{id}`;

// Hangup node
const HANGUP_CONTROLLER = `${NODES_CONTROLLER}/hangup`;
export const HANGUP_NODE = HANGUP_CONTROLLER;
export const HANGUP_ID = `${HANGUP_CONTROLLER}/{id}`;

// Announcement
const ANNOUNCEMENT_ELEMENT_CONTROLLER = `${ELEMENTS_CONTROLLER}/announcement`;
export const ANNOUNCEMENT_ELEMENT = ANNOUNCEMENT_ELEMENT_CONTROLLER;
export const ANNOUNCEMENT_ELEMENT_ID = `${ANNOUNCEMENT_ELEMENT_CONTROLLER}/{id}`;
export const ANNOUNCEMENT_ELEMENT_AUDIO = `${ANNOUNCEMENT_ELEMENT_CONTROLLER}/audio`;
export const ANNOUNCEMENT_ELEMENT_AUDIO_ID = `${ANNOUNCEMENT_ELEMENT_AUDIO}/{id}`;
export const ANNOUNCEMENT_ELEMENT_TEXT = `${ANNOUNCEMENT_ELEMENT_CONTROLLER}/text`;
export const ANNOUNCEMENT_ELEMENT_TEXT_ID = `${ANNOUNCEMENT_ELEMENT_TEXT}/{id}`;
export const ANNOUNCEMENT_ELEMENT_TEXT_PREVIEW = `${ANNOUNCEMENT_ELEMENT_TEXT}/preview`;

// Announcement node
const ANNOUNCEMENT_NODE_CONTROLLER = `${NODES_CONTROLLER}/announcement`;
export const ANNOUNCEMENT_NODE = ANNOUNCEMENT_NODE_CONTROLLER;
export const ANNOUNCEMENT_NODE_ID = `${ANNOUNCEMENT_NODE_CONTROLLER}/{id}`;

// Send mail node
const SEND_EMAIL_NODE_CONTROLLER = `${NODES_CONTROLLER}/send-email`;
export const SEND_EMAIL_NODE = SEND_EMAIL_NODE_CONTROLLER;
export const SEND_EMAIL_NODE_ID = `${SEND_EMAIL_NODE_CONTROLLER}/{id}`;

// Send SMS
const SEND_SMS_ELEMENT_CONTROLLER = `${ELEMENTS_CONTROLLER}/send-sms`;
export const SEND_SMS_ELEMENT = SEND_SMS_ELEMENT_CONTROLLER;
export const SEND_SMS_ID = `${SEND_SMS_ELEMENT_CONTROLLER}/{id}`;

// Send SMS node
const SEND_SMS_NODE_CONTROLLER = `${NODES_CONTROLLER}/send-sms`;
export const SEND_SMS_NODE = SEND_SMS_NODE_CONTROLLER;
export const SEND_SMS_NODE_ID = `${SEND_SMS_NODE_CONTROLLER}/{id}`;

// Time conditions element
const TIME_CONDITION_ELEMENT_CONTROLLER = `${ELEMENTS_CONTROLLER}/time-condition`;
export const TIME_CONDITION_ELEMENT = TIME_CONDITION_ELEMENT_CONTROLLER;
export const TIME_CONDITION_ELEMENT_ID = `${TIME_CONDITION_ELEMENT_CONTROLLER}/{id}`;

// Time conditions node
const TIME_CONDITION_NODE_CONTROLLER = `${NODES_CONTROLLER}/time-condition`;
export const TIME_CONDITION_NODE = TIME_CONDITION_NODE_CONTROLLER;
export const TIME_CONDITION_NODE_ID = `${TIME_CONDITION_NODE_CONTROLLER}/{id}`;

// Crossroad node
const CROSSROAD_NODE_CONTROLLER = `${NODES_CONTROLLER}/crossroad`;
export const CROSSROAD_NODE = CROSSROAD_NODE_CONTROLLER;
export const CROSSROAD_NODE_ID = `${CROSSROAD_NODE_CONTROLLER}/{id}`;

// Redirect node
const REDIRECT_NODE_CONTROLLER = `${NODES_CONTROLLER}/redirect`;
export const REDIRECT_NODE = REDIRECT_NODE_CONTROLLER;
export const REDIRECT_NODEE_ID = `${REDIRECT_NODE_CONTROLLER}/{id}`;

// Extensions
const EXTENSIONS_CONTROLLER = `${ACD_MS}/extensions`;
export const EXTENSIONS = EXTENSIONS_CONTROLLER;
export const EXTENSIONS_ID = `${EXTENSIONS_CONTROLLER}/{id}`;
export const EXTENSIONS_RANGES = `${EXTENSIONS_CONTROLLER}/ranges`;
export const EXTENSIONS_ASSIGN = `${EXTENSIONS_CONTROLLER}/assign`;

// Extensions groups
const EXTENSIONS_GROUPS_CONTROLLER = `${ACD_MS}/extension-group`;
export const EXTENSIONS_GROUPS = EXTENSIONS_GROUPS_CONTROLLER;
export const EXTENSIONS_GROUPS_ID = `${EXTENSIONS_GROUPS_CONTROLLER}/{id}`;

// Call forwarding node
const CALL_FORWARDING_NODE_CONTROLLER = `${NODES_CONTROLLER}/call-forwarding`;
export const CALL_FORWARDING_NODE = CALL_FORWARDING_NODE_CONTROLLER;
export const CALL_FORWARDING_NODE_ID = `${CALL_FORWARDING_NODE_CONTROLLER}/{id}`;

// Variables
const VARIABLE_CONTROLLER = `${ACD_MS}/variable`;
export const VARIABLE = VARIABLE_CONTROLLER;
export const VARIABLE_ID = `${VARIABLE_CONTROLLER}/{id}`;

// Data input node
const DATA_INPUT_NODE_CONTROLLER = `${NODES_CONTROLLER}/data-input`;
export const DATA_INPUT_NODE = DATA_INPUT_NODE_CONTROLLER;
export const DATA_INPUT_NODE_ID = `${DATA_INPUT_NODE_CONTROLLER}/{id}`;

// Condition node
const CONDITION_NODE_CONTROLLER = `${NODES_CONTROLLER}/condition`;
export const CONDITION_NODE = CONDITION_NODE_CONTROLLER;
export const CONDITION_NODE_ID = `${CONDITION_NODE_CONTROLLER}/{id}`;

// Operation node
const OPERATION_NODE_CONTROLLER = `${NODES_CONTROLLER}/operation`;
export const OPERATION_NODE = OPERATION_NODE_CONTROLLER;
export const OPERATION_NODE_ID = `${OPERATION_NODE_CONTROLLER}/{id}`;

// NLU node
const NLU_NODE_CONTROLLER = `${NODES_CONTROLLER}/nlu`;
export const NLU_NODE = NLU_NODE_CONTROLLER;
export const NLU_NODE_ID = `${NLU_NODE_CONTROLLER}/{id}`;

// Recording node
const RECORDING_NODE_CONTROLLER = `${NODES_CONTROLLER}/recording`;
export const RECORDING_NODE = RECORDING_NODE_CONTROLLER;
export const RECORDING_NODE_ID = `${RECORDING_NODE_CONTROLLER}/{id}`;

// Request node
const API_REQUEST_NODE_CONTROLLER = `${NODES_CONTROLLER}/api-request`;
export const API_REQUEST_NODE = API_REQUEST_NODE_CONTROLLER;
export const API_REQUEST_NODE_ID = `${API_REQUEST_NODE_CONTROLLER}/{id}`;

// Send template node
const SEND_TEMPLATE_NODE_CONTROLLER = `${NODES_CONTROLLER}/send-template`;
export const SEND_TEMPLATE_NODE = SEND_TEMPLATE_NODE_CONTROLLER;
export const SEND_TEMPLATE_NODE_ID = `${SEND_TEMPLATE_NODE_CONTROLLER}/{id}`;

// Knowledge model node
const KNOWLEDGE_MODEL_NODE_CONTROLLER = `${NODES_CONTROLLER}/knowledge-model`;
export const KNOWLEDGE_MODEL_NODE = KNOWLEDGE_MODEL_NODE_CONTROLLER;
export const KNOWLEDGE_MODEL_NODE_ID = `${KNOWLEDGE_MODEL_NODE_CONTROLLER}/{id}`;

// Function node
const FUNCTION_NODE_CONTROLLER = `${NODES_CONTROLLER}/function`;
export const FUNCTION_NODE = FUNCTION_NODE_CONTROLLER;
export const FUNCTION_NODE_ID = `${FUNCTION_NODE_CONTROLLER}/{id}`;
