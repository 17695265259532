import { ChannelType } from 'src/models/conversations/conversations';
import { SocialmediaAttachment } from 'src/models/conversations/messages';
import { replaceURLParams } from './apiFunctions';
import AuthManager from 'src/services/authentication/manager';
import axios from 'axios';
import { logger } from 'src/utils/logger';
import { queryClient } from 'src/utils/queryClient';
import { EMAIL_ATTACHMENT_ID } from 'src/utils/apiRoutes/msEmail';
import { WHATSAPP_ATTACHMENT_ID } from 'src/utils/apiRoutes/msWhatsapp';
import { WEBCHAT_ATTACHMENT_ID } from 'src/utils/apiRoutes/msWebChat';
import { TELEGRAM_ATTACHMENT_ID } from 'src/utils/apiRoutes/msTelegram';

/** This method takes an uploaded file and returns the correct URL
 * to download it from the backend.
 */
export const formatAttachmentUrl = (
  file: SocialmediaAttachment,
  channel: ChannelType
) => {
  if (channel === 'Email') {
    return replaceURLParams(EMAIL_ATTACHMENT_ID, { '{id}': file?.id });
  }
  if (channel === 'Telegram') {
    return replaceURLParams(TELEGRAM_ATTACHMENT_ID, { '{id}': file?.id });
  }
  if (channel === 'WhatsApp') {
    return replaceURLParams(WHATSAPP_ATTACHMENT_ID, { '{id}': file?.id });
  }
  if (channel === 'WebChat') {
    return replaceURLParams(WEBCHAT_ATTACHMENT_ID, { '{id}': file?.id });
  }
  //for now all channel share attachments
  if (channel === 'Instagram') {
    return replaceURLParams(TELEGRAM_ATTACHMENT_ID, { '{id}': file?.id });
  }
};

/*
 * This method takes an url, downloads the file and returns a local
 * blob URL that can be used to display the image or download the file.
 */
export const getBlobUrl = async (url: string) => {
  const imageBlob = await getBlobQuery(url);
  return URL.createObjectURL(imageBlob);
};

function getBlobQuery(url: string) {
  const headers = {
    Authorization: 'Bearer ' + AuthManager.getToken()
  };
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: headers,
    credentials: 'omit'
  };

  return queryClient.fetchQuery({
    queryKey: [url],
    queryFn: () => fetch(url, requestOptions).then((res) => res.blob()),
    staleTime: 1000 * 60 * 5
  });
}

export const getStickerFileApi = async (url: string): Promise<string> => {
  const resp = await axios.get(url);
  return resp.data;
};

/* This method downloads a SocialmediaUploadedFile from the backend. */
export const downloadAttachment = async (
  uploadedFile: SocialmediaAttachment,
  channel: ChannelType
) => {
  const url = formatAttachmentUrl(uploadedFile, channel);
  const blobUrl = await getBlobUrl(url);

  const a: HTMLAnchorElement = document.createElement('a');
  a.setAttribute('style', 'display:none;');
  a.download = uploadedFile.name;
  a.href = blobUrl;

  a.click();

  a.remove();
};

export interface ParsedInlineMessage {
  message: string;
  inlineFiles: File[];
}
export const parseMailAttachmentsBeforeSend = async (
  message: string
): Promise<ParsedInlineMessage> => {
  // Procesamiento de adjuntos in-line
  let inlineFiles: File[] = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(message, 'text/html');
  const blobImgElements = doc.querySelectorAll('img[src^="blob:"]');
  // Replace the src attribute of each blob img element with the new string
  let i = 0;
  for (const imgElement of blobImgElements) {
    const src = imgElement.getAttribute('src');
    try {
      const response = await fetch(src);
      const blob = await response.blob();
      const file = new File([blob], `manhattan_att_` + i);
      message = message.replace(src, `cid:manhattan_att_` + i);
      inlineFiles[i] = file;
      i = i + 1;
    } catch (error) {
      logger.error(error);
      inlineFiles[i] = null;
    }
  }

  return { message, inlineFiles };
};
