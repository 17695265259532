import { ApexOptions } from 'apexcharts';
import {
  CallMode,
  Campaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { FormTabs } from 'src/modules/Shared/models/forms';
import {
  Dashboard,
  KpiConfig,
  KpiIcon,
  LegendPosition,
  Panel,
  PanelsConstant,
  PanelsGroup,
  PanelsGroupsConstans
} from '../../models/Dashboards';
import SpeedIcon from '@mui/icons-material/Speed';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import StorageIcon from '@mui/icons-material/Storage';
import { getIdentifiersArray } from './apex/apexLogic';
import CustomZoomIcon from '../components/apex/toolbarIcons/CustomZoomIcon';
import CustomLeftArrowIcon from '../components/apex/toolbarIcons/CustomLeftArrowIcon';
import CustomRightArrowIcon from '../components/apex/toolbarIcons/CustomRightArrowIcon';
import CustomToolTip from '../components/apex/CustomToolTip';
import CustomResetIcon from '../components/apex/toolbarIcons/CustomResetIcon';
import AppsIcon from '@mui/icons-material/Apps';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { MenuButton } from 'src/models/menus';
import { ChannelType } from 'src/models/conversations/conversations';
import { Queue } from 'src/models/ariQueues';

//-------------------APEX LINE CHART CONSTANTS-------------------------------------

//KPI CONSTANTS

export const ICONS: KpiIcon[] = [
  { name: 'Speed', icon: SpeedIcon, type: 'error' },
  { name: 'Hour', icon: HourglassEmptyIcon, type: 'warning' },
  { name: 'Phone Link Erase', icon: PhonelinkEraseIcon, type: 'primary' },
  { name: 'Storage', icon: StorageIcon, type: 'success' }
];

export const hasNullValues: Record<string, boolean> = {
  All_campaigns: true,
  All_services: true
};

//Chart Constants

// Chart availables colors

export const chartColors = [
  //TEMPORAL SHOULD BE CHANGED TO SELECT USERS COLORS
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
  '#393b79',
  '#637939',
  '#8ca252',
  '#b5cf6b',
  '#cedb9c',
  '#8c6d31',
  '#bd9e39',
  '#e7ba52',
  '#e7cb94',
  '#843c39',
  '#ad494a',
  '#d6616b',
  '#e7969c',
  '#7b4173',
  '#a55194',
  '#ce6dbd',
  '#de9ed6',
  '#3182bd',
  '#6baed6',
  '#9ecae1',
  '#c6dbef',
  '#e6550d',
  '#fd8d3c',
  '#fdae6b',
  '#fdd0a2',
  '#31a354',
  '#74c476',
  '#a1d99b',
  '#756bb1',
  '#9e9ac8',
  '#bcbddc',
  '#dadaeb',
  '#636363',
  '#969696',
  '#bdbdbd',
  '#737373',
  '#f46d43',
  '#4575b4',
  '#91bfdb',
  '#313695',
  '#4575b4',
  '#91bfdb',
  '#313695',
  '#4575b4',
  '#91bfdb'
];

export enum DASHBOARD_PANEL_FORM_TYPES {
  CREATE = 'create',
  UPDATE = 'update'
}
//Base line chartLine Object
export const BasicChartOptions: ApexOptions = {
  stroke: {
    curve: 'smooth',
    width: 3
  },
  colors: chartColors,
  chart: {
    background: 'transparent',
    zoom: {
      enabled: true,
      type: 'xy',
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.7,
          width: 1
        }
      }
    },
    toolbar: {
      show: true,
      autoSelected: 'zoom',
      tools: {
        download: false,
        selection: false,
        zoom: CustomZoomIcon(),
        zoomin: CustomLeftArrowIcon(),
        zoomout: CustomRightArrowIcon(),
        reset: CustomResetIcon(),
        pan: false,
        customIcons: []
      }
    },
    animations: {
      enabled: false,
      easing: 'linear',
      dynamicAnimation: {
        speed: 200
      }
    }
  },
  tooltip: {
    enabled: true,
    followCursor: true,
    items: {
      display: 'flex'
    },
    x: {
      show: true,
      format: 'dd MMM',
      formatter: undefined
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let dataPoint = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      let date = dataPoint.x;
      const identifiersArray = getIdentifiersArray(dataPoint, w);
      return CustomToolTip({ date, identifiersArray });
    },
    style: {
      fontSize: '12px'
    }
  },
  fill: {
    opacity: 1
  },
  labels: [],
  dataLabels: {
    enabled: false
  },
  grid: {
    strokeDashArray: 5
  },
  legend: {
    show: true
  },
  xaxis: {
    type: 'datetime'
  },
  yaxis: {
    show: true,
    min: 0
  }
};

//Campaign mockup
export const mockCampaign: Campaign = {
  accountId: '',
  callMode: CallMode.INBOUND,
  channel: ChannelType.CALL,
  companyId: '',
  conversationTimeoutMins: 0,
  description: 'Sin Campaña',
  enabled: true,
  id: 'All_campaigns',
  name: 'All Campaigns',
  recordPercentage: 0,
  serviceId: '',
  users: [''],
  adminTime: 0,
  autoTicketResolutionConfig: {
    onMaxCallRelaunches: ''
  },
  startDate: '',
  endDate: '',
  holidays: [],
  dayHours: {},
  allowCreatingManualReschedules: false,
  forceAutoanswer: false,
  ignoreExternalAction: false,
  queueConfig: {} as Queue,
  recordingNamePattern: '',
  relaunchResolutionConfig: {},
  autoCloseConvOnLastTicketEdited: false
};

export const basePanel: Panel = {
  accountIds: null,
  campaignId: '',
  companyId: '',
  calculation: 'Count',
  dashboardId: '',
  datatype: 'CallResultsPercentage',
  name: '',
  serviceId: '',
  paneltype: 'Group',
  panelAlert: {
    alerts: [
      {
        bound: 0,
        boundtype: 'Lower',
        message: {
          message: 'msg',
          msgtype: 'Email'
        },
        severity: 'Danger'
      }
    ]
  },
  panelParameters: {
    coordX: 0,
    coordY: 0,
    height: 15,
    width: 6,
    maxHeight: 32,
    maxWidth: 12,
    minHeight: 1,
    minWidth: 1,
    chartType: 'line'
  },
  kpiConfig: {
    color: 'green',
    kpiCalculation: 'Last',
    kpiIcon: 'Speed',
    selectedIdentifier: 'Abandoned'
  }
};

export const basePanelForm: Panel = {
  accountIds: { [ChannelType.EMAIL]: [] },
  campaignId: '',
  companyId: '',
  calculation: 'Count',
  dashboardId: '',
  datatype: 'CallResultsPercentage',
  name: '',
  serviceId: '',
  paneltype: 'Group',
  panelAlert: {
    alerts: [
      {
        bound: 0,
        boundtype: 'Lower',
        message: {
          message: 'msg',
          msgtype: 'Email'
        },
        severity: 'Danger'
      }
    ]
  },
  panelParameters: {
    coordX: 0,
    coordY: 0,
    height: 15,
    width: 6,
    maxHeight: 32,
    maxWidth: 12,
    minHeight: 1,
    minWidth: 1,
    chartType: 'line',
    legendPosition: LegendPosition.legend
  },
  kpiConfig: {
    color: 'green',
    kpiCalculation: 'Last',
    kpiIcon: 'Speed',
    selectedIdentifier: 'Abandoned'
  }
};

export const mockKpi: KpiConfig = {
  color: 'green',
  kpiCalculation: 'Last',
  kpiIcon: 'Speed',
  selectedIdentifier: 'Abandoned'
};

//CHART TYPES

export const chartsTypesBlockedInSelector = [
  'Coordinator',
  'KPI',
  'bar',
  'pie',
  'donut'
];
//For each value on seconds  show since what second is restringed
export const PanelSizesGroupIntervalRestriccions = [
  { panelSizeSeconds: 300, groupIntervalSecondsLimit: 1 },
  { panelSizeSeconds: 900, groupIntervalSecondsLimit: 1 },
  { panelSizeSeconds: 1800, groupIntervalSecondsLimit: 5 },
  { panelSizeSeconds: 3600, groupIntervalSecondsLimit: 10 },
  { panelSizeSeconds: 10800, groupIntervalSecondsLimit: 30 },
  { panelSizeSeconds: 21600, groupIntervalSecondsLimit: 60 },
  { panelSizeSeconds: 43200, groupIntervalSecondsLimit: 60 },
  { panelSizeSeconds: 86400, groupIntervalSecondsLimit: 300 },
  { panelSizeSeconds: 172800, groupIntervalSecondsLimit: 300 },
  { panelSizeSeconds: 432000, groupIntervalSecondsLimit: 1800 }
];

//MAIN CONSTANTS
export const PANELS: PanelsConstant = {
  KPI: {
    key: 'KPI',
    label: 'KPIs',
    panelDefaultSizes: {
      panelDefaultWidth: 2,
      panelDefaultYposition: 0,
      panelDefaultHeight: 7,
      minPanelHeight: 7,
      minPanelWidth: 2,
      maxPanelWidth: 12
    },
    dataTypeCalculation: {
      CallResults: 'Both',
      IncomingCallsStarted: 'Last',
      OutgoingCallsStarted: 'Last',
      SimultaneousCalls: 'Last',
      AgentStatus: 'Both',
      CallStatus: 'Both',
      CallResultsPercentage: 'Percentage',
      ClosedConversations: 'Last',
      ConversationStatus: 'Both',
      ConversationStatusByAccount: 'Both'
    },
    defaultInstance: '0',
    iconsArray: ICONS,
    maxNumberForNonAveraging: 10,
    missingDataText: 'No data',
    notApplicable: 'NaN'
  },
  Charts: {
    key: 'Charts',
    label: 'Charts',
    panelDefaultSizes: {
      panelDefaultWidth: 6,
      panelDefaultYposition: 0,
      panelDefaultHeight: 15,
      minPanelHeight: 10,
      minPanelWidth: 4,
      maxPanelWidth: 12
    },
    baseChartOptions: BasicChartOptions,
    defaultChart: 'line',
    missingDataText: 'No data'
  },
  Coordinator: {
    key: 'Coordinator',
    label: 'Coordinator',
    panelDefaultSizes: {
      panelDefaultWidth: 6,
      panelDefaultYposition: 0,
      panelDefaultHeight: 15,
      minPanelHeight: 10,
      minPanelWidth: 4,
      maxPanelWidth: 12
    },
    missingDataText: 'No users connected'
  }
};

export const PANELS_GROUPS: PanelsGroupsConstans = {
  defaults: {
    xPosition: 0,
    width: 12,
    height: 1,
    panelsGroupsPrefix: 'GP',
    noGroupPanelName: 'NO_GROUP'
  }
};

export enum PANEL_DATA_TYPE {
  QUEUES = 'Queues',
  USERS_AND_QUEUES = 'UsersAndQueues',
  USERS = 'Users'
}

export const DASHBOARD = {
  defaults: {
    timeIntervals: {
      groupInterval: 300,
      panelSize: 3600
    },
    groupIntervalsEnabled: [
      1, 5, 10, 30, 60, 300, 900, 1800, 3600, 7200, 21600, 86400
    ],
    panelSizesEnabled: [
      60, 300, 900, 1800, 3600, 10800, 21600, 43200, 86400, 172800, 432000
    ],
    panelSizesRestrictions: PanelSizesGroupIntervalRestriccions
  }
};

export const PanelFormTabs: FormTabs[] = [
  {
    label: PANELS.Charts.label,
    value: PANELS.Charts.key
  },
  {
    label: PANELS.KPI.label,
    value: PANELS.KPI.key
  },
  {
    label: PANELS.Coordinator.label,
    value: PANELS.Coordinator.key
  }
];

export const MOCK_PANELS: Panel[] = [
  {
    id: '65b26c7aba27eb638a651036',
    name: 'Estados de agentes',
    companyId: '649a7fc3a812b911a107936e',
    serviceId: null,
    campaignId: null,
    dashboardId: '65b26c54ba27eb638a651095',
    panelParameters: {
      coordX: 0,
      coordY: 18,
      width: 6,
      height: 15,
      minWidth: 1,
      maxWidth: 12,
      minHeight: 1,
      maxHeight: 32,
      chartType: 'line'
    },
    kpiConfig: null,
    datatype: 'AgentStatus',
    calculation: 'Count',
    paneltype: 'Realtime',
    accountIds: null
  },
  {
    id: '65b26c7aba27eb638a651096',
    name: 'Estados de agentes',
    companyId: '649a7fc3a812b911a107936e',
    serviceId: null,
    campaignId: null,
    dashboardId: '65b26c54ba27eb638a651095',
    panelParameters: {
      coordX: 0,
      coordY: 2,
      width: 6,
      height: 15,
      minWidth: 1,
      maxWidth: 12,
      minHeight: 1,
      maxHeight: 32,
      chartType: 'line'
    },
    kpiConfig: null,
    datatype: 'AgentStatus',
    calculation: 'Count',
    paneltype: 'Realtime',
    accountIds: null
  },
  {
    id: '65b26c7aba27eb638a651098',
    name: 'Estados de agentes',
    companyId: '649a7fc3a812b911a107936e',
    serviceId: null,
    campaignId: null,
    dashboardId: '65b26c54ba27eb638a651095',
    panelParameters: {
      coordX: 7,
      coordY: 2,
      width: 6,
      height: 15,
      minWidth: 1,
      maxWidth: 12,
      minHeight: 1,
      maxHeight: 32,
      chartType: 'line'
    },
    kpiConfig: null,
    datatype: 'AgentStatus',
    calculation: 'Count',
    paneltype: 'Realtime',
    accountIds: null
  }
];

export const MOCK_DASHBOARD: Dashboard = {
  id: '65b26c54ba27eb638a651095',
  name: 'Pruebas Mario',
  description: 'Para pruebas de back',
  companyId: '649a7fc3a812b911a107936e',
  serviceId: null,
  campaignIds: null,
  groupIntervalSeconds: 300,
  panelSizeSeconds: 3600,
  panelGroups: [
    {
      name: 'NO_GROUP',
      pos: 0,
      description: '',
      panelIds: ['65b26c7aba27eb638a651096', '65b26c7aba27eb638a651098'],
      collapsed: false
    },
    {
      name: 'grupo 1',
      pos: 16,
      description: '',
      panelIds: ['65b26c7aba27eb638a651036'],
      collapsed: false
    }
  ],
  hidden: false,
  presetFixedId: null
};

export const DUMMY_PANELS_GROUPS: PanelsGroup = {
  name: 'Dummy',
  pos: 0,
  description: '',
  panelIds: [],
  collapsed: false
};

export const GROUP_PANELS_PREFIX = 'GP';

export const NO_GROUP_PANEL = 'NO_GROUP';

export const GRIDLAYOUT_MAX_HEIGHT = 10000;
export const GRIDLAYOUT_MAX_ROW_SIZE = 30;
export const GRIDLAYOUT_MAX_WIDTH = 12;
export const GRIDLAYOUT_MIN_HEIGHT = 0;
export const GL_MIN_WIDHT_PARAM = 'minW';
export const GL_MIN_HEIGHT_PARAM = 'minH';
export const ALL_CAMPAIGNS = 'Todas las campañas';

export const GROUPED_BY = 'Grouped by ';
export const LAST = 'Last ';
export const PANEL_FORM_TYPE = {
  create: 'create',
  update: 'update'
};

export const AddItemsMenu: MenuButton = {
  create_panel: {
    name: 'Panel',
    onClick: () => {},
    icon: AssessmentIcon,
    visible: true
  },
  create_panels_group: {
    name: 'Panels group',
    onClick: () => {},
    icon: AppsIcon,
    visible: true
  }
};

export const DASHBOARD_PATH = '/dashboard/productivity/live';

export enum DASHBOARD_TEXTS {
  CREATE_NEW_DASHBOARD_TITLE = 'Create new dashboard',
  CREATE_NEW_DASHBOARD_SUBTITLE = 'Here you can create a new dashboard',
  REAL_TIME_DASHBOARD = 'Real time dashboards',
  HELMET_DASHBOARD = 'Dashboards',
  CREATE_NEW_PANEL = 'Create a new Panel',
  CREATE_NEW_PANEL_SUBTITLE = 'Here you can create a new panel',
  UPDATE_PANEL = 'Update Panel',
  UPDATE_PANEL_SUBTITLE = 'Here you can update a panel',
  EDIT_AND_CREATE_PANELS = 'Edit and create panels for you dashboard',
  NO_PANELS_YET = 'No panels yets',
  MOVE_BACK = 'Move Back',
  RESET_TO_BASE_STATE = 'Reset to base state',
  MOVE_FORWARD = 'Move Forward',
  ZOOM_ACTIVATED = 'Zoom is activated',
  TIMES_UPDATED = 'The times were successfully  updated!',
  ESCAPE_FULL_SCREEN = "Press 'ESC' key to exit full screen mode",
  FULL_SCREEM = 'Full Screen',
  UPDATE_TIMES = 'Update times',
  PANELS_GROUP_REQUIRED = 'Panels group name is required',
  PLACEHOLDER_PANELS_GROUP_INPUT = 'Write here the group Panels name...',
  FORM_NAME = 'Name',
  FORM_DESCRIPTION = 'Description',
  FORM_COMPANY = 'Company',
  FORM_SERVICE = 'Service',
  FORM_CHANNEL = 'Channel',
  FORM_ACCOUNT = 'Account',
  FORM_DATA_TYPE = 'Data type',
  FORM_CAMPAIGN = 'Campaign',
  FORM_SHOW_KPI_ICON = 'Show Icon',
  FORM_ICON = 'Icon',
  FORM_IDENTIFIER = 'Identifier',
  FORM_CALCULATIONS = 'Calculations',
  CHANNEL_BUTTON = 'Add channel',
  PLACEHOLDER_PANELS_GROUP_DESCRIPTION = 'Write here the group Panels description...',
  CREATE_GROUP_PANELS = 'Create group Panel',
  CANCEL_BUTTON = 'Cancel',
  DASHBOARD_WAS_CREATED = 'The dashboard was created!',
  CHARTS_CONFIGURATION = 'Charts Configuration',
  CHART_TYPE = 'Chart type',
  COORDINATOR_CONFIGURATION = 'Coordinator Configuration',
  ALL_SERVICES_FILTER = 'All_services',
  CHANNEL_SELECTOR = 'Select a channel',
  ALL_ACCOUNTS_FILTER = 'All accounts',
  GENERAL_CONFIGURATION = 'General Configuration',
  PLEASE_CONTACT_ADMINISTRATOR = ', please contact your administrator.',
  SPEED_ICON = 'Speed',
  KPI_CONFIGURATION = 'KPI Configuration',
  VISUALIZATION = 'Visualization',
  NO_USERS_CONNECTED = 'No users connected',
  PANEL_WAS_DELETED = 'The panel was deleted correctly',
  DELETE_PANEL_GROUP = 'Delete panel group',
  EDIT_PANEL_GROUP = 'Edit panel group'
}

export const DASHBOARD_FSEXIT_KEY = 'Escape';

export interface DashboardForTable extends Dashboard {
  campaignName: string;
}
