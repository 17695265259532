import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';

import { Helmet } from 'react-helmet-async';

import { Box, Button, styled, useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Contact,
  ContactField,
  ContactTag
} from 'src/modules/Contacts/models/contacts';

import ContactDataForm from '../ContactDataForm';
import { updateContact } from 'src/modules/Contacts/domain/updateContact';
import { deleteContact } from 'src/modules/Contacts/domain/deleteContact';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        height: 100%;
        display: flex;
    `
);

interface Props {
  contact: Contact;
  setContact: Dispatch<SetStateAction<Contact>>;
  tags: ContactTag[];
  companyFields: ContactField[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isSetContact?: boolean;
  onSave?: (contact: Contact) => void;
  saveButtonLabel?: string;
}

const UpdateContactDataView = (props: Props) => {
  const {
    contact,
    setContact,
    tags,
    companyFields,
    setIsOpen,
    isSetContact = true,
    saveButtonLabel
  } = props;

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Edit contact</title>
      </Helmet>
      <RootWrapper className="Mui-FixedWrapper">
        <Box
          sx={{
            minWidth: 300,
            width: '100%'
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="start">
            <Button
              color="primary"
              variant="text"
              size="small"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ marginTop: theme.spacing(0.75) }}
              onClick={() => {
                setIsOpen(false);
                setContact(null);
              }}
              id="btnBack-UpdateContactDataView"
            >
              {t('Back')}
            </Button>
          </Box>
          <Box height={'calc(100% - 40px)'}>
            <Scrollbar>
              <ContactDataForm
                contact={contact}
                setContact={setContact}
                onClose={(updatedContact: Contact) => {
                  if (props.onSave && updatedContact) {
                    props.onSave(updatedContact);
                  }
                  setIsOpen(false);
                }}
                onSubmitContact={async (newContact) => {
                  const updatedContact = await updateContact(newContact);

                  return updatedContact;
                }}
                onDeleteContact={deleteContact}
                isCreatingContact={false}
                isSetContact={isSetContact}
                saveButtonLabel={saveButtonLabel}
              />
            </Scrollbar>
          </Box>
        </Box>
      </RootWrapper>
    </>
  );
};

export default UpdateContactDataView;
