import { FC, useState, createContext, Dispatch, SetStateAction } from 'react';
import { AIModel } from '../../models/AIModels';

type IntentionsModelsContext = {
  currentAiModel: AIModel;
  setCurrentAiModel: Dispatch<SetStateAction<AIModel>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntentionsModelsContext = createContext<IntentionsModelsContext>(
  {} as IntentionsModelsContext
);

export const IntentionsModelsProvider: FC = ({ children }) => {
  const [currentAiModel, setCurrentAiModel] = useState<AIModel | undefined>();

  return (
    <IntentionsModelsContext.Provider
      value={{
        currentAiModel,
        setCurrentAiModel
      }}
    >
      {children}
    </IntentionsModelsContext.Provider>
  );
};
