import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import AdminHeader from 'src/components/AdminHeader';
import { DialTable, DialTableImperativeHandle } from 'src/components/DialTable';
import { InstagramAccount } from 'src/services/instagram/models/instagramAccounts';
import useInstagramAccountsDialtable from './hooks/useInstagramAccountsDialtable';
import { Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CreateInstagramAccountPopover from './components/CreateInstagramAccountPopover';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { AuthenticateInstagramAccountModal } from './components/VerifyInstagramAccountModal';
import { checkCreateInstagramAccountPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

function InstagramAccounts() {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [accountToVerify, setAccountToVerify] =
    useState<InstagramAccount | null>(null);

  const [accountsToDelete, setAccountsToDelete] = useState([]);

  const {
    getColumnsFn,
    getData,
    getAvailableFilters,
    getRowActions,
    deleteAccounts,
    sorting,
    setSorting
  } = useInstagramAccountsDialtable({
    setAccountToVerify,
    setAccountsToDelete
  });

  const tableRef = useRef<DialTableImperativeHandle<InstagramAccount>>(null);

  useEffect(() => {
    tableRef.current?.refetchData();
  }, [sorting]);

  return (
    <>
      <Helmet>
        <title>{t('Instagram accounts')}</title>
      </Helmet>
      <AdminHeader
        heading={t('Instagram accounts')}
        subheading={t(
          'All aspects related to Instagram can be managed from this page'
        )}
        buttonName={t('Add Instagram Account')}
        onClick={() => {
          setOpenCreateModal(true);
        }}
        permissionCheckerFn={checkCreateInstagramAccountPermissions}
      />
      <CreateInstagramAccountPopover
        isOpen={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
        }}
      />
      <AuthenticateInstagramAccountModal
        key={accountToVerify?.id}
        instagramAccount={accountToVerify}
        setAccount={(account) => {
          setAccountToVerify(account);
        }}
        onClose={() => {
          setAccountToVerify(null);
        }}
      />
      <ConfirmationModal<InstagramAccount, keyof InstagramAccount>
        data={accountsToDelete}
        dataKey="instagramUsername"
        onConfirm={deleteAccounts}
        mode={ConfirmationModalModes.DELETE}
        isOpen={accountsToDelete.length > 0}
        setOpen={() => setAccountsToDelete([])}
      />
      <Stack p={1} px={4}>
        <DialTable<InstagramAccount>
          queryKey={['instagramAccountList']}
          getColumnsFn={getColumnsFn}
          getDataFn={getData}
          getAvailableFilters={getAvailableFilters}
          getRowActionsFn={getRowActions}
          onlyEqualsOperator
          state={{ sorting }}
          onSortingChange={setSorting}
          ref={tableRef}
        />
      </Stack>
    </>
  );
}

export default InstagramAccounts;
