import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// example import component
// const Routes = Loader(lazy(() => import('../views/RoutesView')));

const ExternalActionsView = Loader(
  lazy(() => import('../modules/externalActions'))
);
const integrationsRoutes: RouteObject[] = [
  {
    path: 'externalActions',
    element: (
      <Restricted navigate permissionCheckerFn={() => true}>
        <ExternalActionsView></ExternalActionsView>
      </Restricted>
    )
  }
];

export default integrationsRoutes;
