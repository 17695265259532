import { DirectionType } from 'src/components/TableReporting/models/direction';
import { Metadata } from 'src/modules/Admin/modules/Acd/modules/MOH/model/MusicOnHold';

export type ContactFieldType =
  | 'Phone'
  | 'Name'
  | 'TelegramUsername'
  | 'TelegramChatId'
  | 'Email'
  | 'Custom'
  | 'InstagramUsername';

export const ContactFieldTypes = {
  CUSTOM: 'Custom',
  EMAIL: 'Email',
  NAME: 'Name',
  PHONE: 'Phone',
  TELEGRAM: 'TelegramUsername',
  WHATSAPP: 'WhatsAppUsername',
  INSTAGRAM: 'InstagramUsername'
};

export interface ContactFields {
  [name: string]: string;
}

export interface FakeContactFields extends ContactFields {
  Phone: string;
  Name: string;
  User: string;
  Email: string;
}

export interface Contact {
  companyId: string;
  fields: ContactFields;
  id?: string;
  label?: { name: string; value: string };
  tags?: string[];
  contactGroupId: string;
  metadata?: Metadata;
}

export interface ContactUI extends Contact {
  contactGroupName: string;
  displayedTags?: string;
  email: string;
  name: string;
  phone: string;
  telegram: string;
  instagram: string;
}

export interface ContactField {
  companyId: string;
  id: string;
  name: string;
  type: ContactFieldType;
  mainField: boolean;
  contactGroupId: string;
  visibleTo: string[];
  editableBy: string[];
  required: boolean;
  unique: boolean;
  autoGenerated: boolean;
  metadata?: Metadata;
  value?: string;
  pinned: boolean;
}

export type PhoneFieldType = 'Phone Number';
export type NameFieldType = 'Name';
export type TelegramFieldType = 'Telegram';
export type TelegramChatIdFieldType = 'Telegram chat id';
export type InstagramUsername = 'Instagram Username';
export type EmailFieldType = 'Email';
export type CustomFieldType = 'Others';

export const enum FieldTypesEnum {
  Phone,
  Name,
  TelegramUsername,
  Email,
  InstagramUsername,
  Custom
}

export const ContactFieldTypeNames: {
  Phone: PhoneFieldType;
  Name: NameFieldType;
  TelegramUsername: TelegramFieldType;
  TelegramChatId: TelegramChatIdFieldType;
  Email: EmailFieldType;
  InstagramUsername: InstagramUsername;
  Custom: CustomFieldType;
} = {
  ['Phone']: 'Phone Number',
  ['Name']: 'Name',
  ['TelegramUsername']: 'Telegram',
  ['TelegramChatId']: 'Telegram chat id',
  ['Email']: 'Email',
  ['InstagramUsername']: 'Instagram Username',
  ['Custom']: 'Others'
};

export interface ContactsRequestParams {
  id?: string;
  companyId?: string;
  exactMatch?: boolean;
  fieldType?: ContactFieldType;
  fieldValue?: string;
  includeDisabled?: boolean;
  page?: number;
  size?: number;
  tags?: string[];
  contactGroupId?: string;
  serviceId?: string;
  sortBy?: keyof Contact;
  sortDir?: DirectionType;
}

export interface ContactTag {
  companyId: string;
  id: string;
  name: string;
  contactGroupId: string;
  metadata?: Metadata;
}

export interface ContactGroup {
  companyId: string;
  contactFields: ContactField[];
  contactTags: ContactTag[];
  description: string;
  id: string;
  name: string;
  wasAssigned: string;
  hexColor: string;
  metadata?: Metadata;
}

export enum ContactGroupFieldType {
  NAME = 'Name',
  PHONE = 'Phone',
  EMAIL = 'Email',
  TELEGRAM = 'TelegramUsername',
  CUSTOM = 'Custom',
  INSTAGRAM = 'InstagramUsername'
}

export const contactGroupFieldTypeOptions = Object.values(
  ContactGroupFieldType
);
