import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';

const useStyles: any = makeStyles(() => ({
  indicator: {
    background: 'none',
    border: '1px solid #1870F5',
    borderRadius: '10px'
  },
  tabs: {
    '& .MuiTabs-indicator': {
      boder: '1px solid red !important'
    },

    '& button': {
      padding: 5
    },
    "& button[aria-selected='true']": {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -1
      },

      '& > *': { zIndex: 0 },
      '& > .MuiTab-wrapper': {
        background: '#fff',
        height: '100%'
      }
    }
  }
}));

export interface TabsTypes<T extends string> {
  value: T;
  label: string;
  disabled?: boolean;
}

interface CustomTabsPropTypes<T extends string> {
  tabs: TabsTypes<T>[];
  type?: string;
  currentTab: string;
  setCurrentTab?: Dispatch<SetStateAction<string>>;
  color?: 'primary' | 'secondary';
  onClick?: (tab: string) => void;
  variant?: 'fullWidth' | 'scrollable' | 'standard';
}

const CustomTabs = <T extends string>(props: CustomTabsPropTypes<T>) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    if (!props.type) {
      props.setCurrentTab(value);
      props.onClick?.(value);
    }
  };
  return (
    <Tabs
      onChange={handleTabsChange}
      value={props.currentTab}
      variant={props.variant ? props.variant : 'scrollable'}
      orientation={'horizontal'}
      scrollButtons={false}
      indicatorColor={props.color ? props.color : 'secondary'}
      className={classes.tabs}
      classes={{ indicator: classes.indicator }}
    >
      {props.tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={t(tab.label)}
          value={tab.value}
          disabled={tab.disabled ? tab.disabled : false}
        />
      ))}
    </Tabs>
  );
};
export default CustomTabs;
