import axios from 'axios';
import { setNextOutgoingCall } from 'src/services/rtusers/domain/rtcall/setNextOutgoingCall';
import { setCurrentConversation } from 'src/store/slices/calls/calls';
import store from 'src/store/store';
import { ASTERISK_MS, CALLS_LAUNCH } from 'src/utils/apiRoutes/msAsterisk';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;
const BASE_MS_VIDEOCALLS = process.env.REACT_APP as string;
const VIDEOCALLS_URL = (BASE_URL + BASE_MS_VIDEOCALLS) as string;
const API_URL_CALL_VIDEO = process.env.REACT_APP_API_URL_CALL_VIDEO as string;

export const launchVideocallApi = (
  campaignId: string,
  extension: string,
  userId: string
) => {
  const url = VIDEOCALLS_URL + API_URL_CALL_VIDEO;
  const params = {
    campaignId,
    extension
  };
  const headers = {
    userId
  };

  return axios
    .post(url, null, { params, headers })
    .then((response: any) => {
      if (response.status === 200) {
      }
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const launchCallApi = (
  campaignId: string,
  phone: string,
  srcExt: string,
  isInternal: boolean,
  ticketId?: string,
  isSpecial?: boolean
) => {
  const params = {
    campaignId,
    destExten: phone,
    record: false,
    srcExten: srcExt,
    isInternal: isInternal,
    isSpecial: isSpecial,
    codecs: 'g722',
    ticketId
  };
  // const activeCall = getActiveRTCall(getConnectedRTUser(false));
  return axios
    .post(CALLS_LAUNCH, null, { params })
    .then((response: any) => {
      if (response.status === 200) {
        // if (activeCall) {
        //   CallManagerDomain.parkCall(activeCall);
        // }
        store.dispatch(setCurrentConversation(response.data.id));
        //emitCustomEvent('Outgoing call launched', phone);
        setNextOutgoingCall(phone);
      }
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
