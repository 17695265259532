import axios from 'axios';
import { BASE_URL, BASE_MS } from '../constants';
import { replaceURLParams } from 'src/services/utils';
import { TicketField } from '../../models/tickets';
import {
  TICKET_FIELDS,
  TICKET_FIELDS_ID
} from 'src/utils/apiRoutes/msMessaging';

export interface TicketFieldsFilters {
  companyId: string;
  serviceId?: string;
}

export const getTicketFieldsApi = async (
  filters: TicketFieldsFilters
): Promise<TicketField[]> => {
  const params = filters;
  const resp = await axios.get(TICKET_FIELDS, { params });
  return resp.data;
};

export const getTicketFieldByIdApi = async (
  id: string
): Promise<TicketField> => {
  const path = replaceURLParams(TICKET_FIELDS_ID, {
    '{id}': id
  });
  const resp = await axios.get(path);
  return resp.data;
};
