import { CloseTwoTone } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { useAlert } from 'src/hooks/useAlert';
import { ColorPicker } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/views/components/Style/ColorPicker';
import { createContactGroupDomain } from 'src/modules/Contacts/domain/createContactGroup';
import { invalidateContactGroupsQuery } from 'src/modules/Contacts/domain/getContactGroups';
import { updateContactGroup } from 'src/modules/Contacts/domain/updateContactGroup';
import { ContactGroup } from 'src/modules/Contacts/models/contacts';
import AuthManager from 'src/services/authentication/manager';
import {
  checkCreateContactGroupPermissions,
  checkUpdateContactGroupPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { useGetCompaniesQuery } from 'src/services/users/application/query/getUsersDataQueries';

interface Props {
  onClose: (newContactGroup?: ContactGroup) => void;
  contactGroupToUpdate?: ContactGroup;
  setContactGroupToUpdate?: (group: ContactGroup) => void;
  returnNewContactGroup?: boolean;
  companyId?: string;
}

export const CreateUpdateContactGroupsPopover = ({
  onClose,
  contactGroupToUpdate,
  setContactGroupToUpdate,
  returnNewContactGroup = false,
  companyId
}: Props) => {
  const theme = useTheme();
  const companies = useGetCompaniesQuery()?.data;
  const defaultContactGroup = {
    name: '',
    description: '',
    companyId: companyId ?? AuthManager.getLoggedUserCompanyId(),
    hexColor: theme.colors.secondary.main
  } as ContactGroup;

  const mode = contactGroupToUpdate ? 'update' : 'create';
  const { hasAccess } = useContext(PermissionsContext);
  const hasContactGroupAccess =
    (mode === 'create' && hasAccess(checkCreateContactGroupPermissions)) ||
    (mode === 'update' && hasAccess(checkUpdateContactGroupPermissions));

  const [contactGroup, setContactGroup] = useState(
    mode === 'update' ? contactGroupToUpdate : defaultContactGroup
  );
  const { showAlert } = useAlert();

  const handleCreateOrUpdate = () => {
    // The hex color should be sent to the backend without '#'
    if (mode === 'update') {
      const newContactGroup = {
        description: contactGroup.description,
        hexColor: contactGroup.hexColor.replace('#', ''),
        name: contactGroup.name,
        id: contactGroup.id
      } as ContactGroup;
      updateContactGroup(newContactGroup)
        .then(() => {
          showAlert('Contact group update', 'success', 3000);
          invalidateContactGroupsQuery();
          emitCustomEvent(REFETCH_DATA_EVENT_NAME);
          handleClose();
        })
        .catch(() => {
          showAlert('Error updating contact group', 'error', 3000);
        });
      return;
    }

    createContactGroupDomain({
      ...contactGroup,
      hexColor: contactGroup.hexColor.replace('#', '')
    })
      .then((response) => {
        const newContactGroup = response.data as ContactGroup;
        showAlert('Contact group created', 'success', 3000);
        emitCustomEvent(REFETCH_DATA_EVENT_NAME);
        handleClose(newContactGroup);
      })
      .catch(() => {
        showAlert('Error creating contact group', 'error', 3000);
      });
  };

  const handleChange = (value: string, name: string) => {
    setContactGroup((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = (contactGroup?: ContactGroup) => {
    onClose(returnNewContactGroup ? contactGroup : undefined);
    setContactGroupToUpdate?.(undefined);
  };

  const getContactGroupHexColor = () => {
    // The hexColor from the backend does not include '#'
    if (!!contactGroup.hexColor) {
      return contactGroup?.hexColor.includes('#')
        ? contactGroup?.hexColor
        : `#${contactGroup.hexColor}`;
    } else return '#FFF';
  };

  return (
    <Card>
      <CardHeader
        title={
          mode === 'update'
            ? t('Update contact group')
            : t('Create new contact group')
        }
        subheader={
          mode === 'update'
            ? t('Use this view to update the contact group')
            : t('Use this view to create a new contact group')
        }
        action={
          <IconButton
            sx={{
              m: 1
            }}
            size="small"
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseTwoTone />
          </IconButton>
        }
      />
      <Divider />

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          p: 0,
          '&.MuiCardContent-root': {
            paddingBottom: 0
          }
        }}
      >
        <Stack py={2} px={2} spacing={2}>
          {/* <TextField
            label={t('Company')}
            select
            value={contactGroup.companyId}
            onChange={(e) => {
              handleChange(e.target.value, 'companyId');
            }}
            disabled={companyId !== undefined}
          >
            {companies?.map((company) => {
              return (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              );
            })}
          </TextField> */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              label={t('Name')}
              id={'contactGroup-name-input'}
              value={contactGroup.name}
              onChange={(e) => handleChange(e.target.value, 'name')}
            />

            <ColorPicker
              color={getContactGroupHexColor()}
              setColor={(c) => {
                handleChange(c, 'hexColor');
              }}
              title={t('Asociate color')}
            />
          </Stack>
          <TextField
            label={t('Description')}
            id={'contactGroup-description-input'}
            value={contactGroup.description}
            onChange={(e) => handleChange(e.target.value, 'description')}
          />
        </Stack>
      </CardContent>

      <Divider />
      <Stack
        px={1.5}
        py={1.5}
        width="100%"
        direction={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={'space-between'}
        spacing={1.5}
      >
        <Button
          variant="outlined"
          color="secondary"
          id={'contactGroup-cancel-button'}
          onClick={() => {
            handleClose();
          }}
        >
          {t('Cancel')}
        </Button>
        <PermissionsTooltip hasAccess={hasContactGroupAccess}>
          <Button
            variant="contained"
            color="secondary"
            id={'contactGroup-create-button'}
            onClick={() => {
              handleCreateOrUpdate();
            }}
            disabled={!hasContactGroupAccess}
          >
            {mode === 'update' ? t('Update') : t('Create')}
          </Button>
        </PermissionsTooltip>
      </Stack>
    </Card>
  );
};
