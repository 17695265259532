import { useEffect, useMemo, useState } from 'react';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  Campaign,
  CallMode,
  CallEngineCampaign
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import {
  useCampaignsQuery,
  useGetUsersQuery
} from 'src/services/users/application/query/getUsersDataQueries';
import {
  NEW_SCHEDULE_ID,
  PhonesAutocompleteOption
} from './ScheduleCalendarForm';
import { ScheduleCalendar } from './createScheduleCalendar';
import {
  createContactsFromInvolvedQuery,
  getContactFieldsQuery
} from 'src/services/socialmedia/application/query/getCompanyContactFieldsQuery';
import AuthManager from 'src/services/authentication/manager';

export const useSchedulesForm = (conversation: Conversation) => {
  const companyId = AuthManager.getLoggedUserCompanyId();

  // CAMPAIGNS
  const campaignsQuery = useCampaignsQuery({
    serviceId: conversation?.serviceId
  });
  const {
    campaigns,
    outboundCampaigns,
    allowedCampaigns,
    conversationCampaign
  } = useMemo(() => {
    const outbound: Campaign[] = [];
    const allowed: Campaign[] = [];
    let conversationCampaign: Campaign;

    campaignsQuery?.data?.elements.forEach?.((campaign) => {
      if (
        campaign.channel === ChannelType.CALL &&
        (campaign as CallEngineCampaign).allowRescheduling
      ) {
        if (campaign.callMode === CallMode.OUTBOUND) {
          outbound.push(campaign);
        }
        if (campaign.callMode !== CallMode.INBOUND) {
          allowed.push(campaign);
        }
        if (campaign.id === conversation?.campaignId) {
          conversationCampaign = campaign;
        }
      }
    });

    return {
      campaigns: campaignsQuery?.data,
      outboundCampaigns: outbound,
      allowedCampaigns: allowed,
      conversationCampaign
    };
  }, [campaignsQuery.isFetched]);

  const conversationCampaignIsOutbound =
    conversationCampaign?.channel === ChannelType.CALL &&
    conversationCampaign.callMode === CallMode.OUTBOUND;

  // -----------------  CONTACTS -----------------

  const contactsQuery = createContactsFromInvolvedQuery(
    conversation,
    conversation
  );
  const contacts = contactsQuery?.data;
  const [phoneNumbers, setPhoneNumbers] = useState<PhonesAutocompleteOption[]>(
    []
  );

  const contactIds = contacts?.map((c) => c.id).filter(Boolean);

  const getPhoneNumberOptions = async () => {
    let phoneAutocompleteOptions: PhonesAutocompleteOption[] = [];

    for (const contact of contacts) {
      if (!contact?.contactGroupId) {
        phoneAutocompleteOptions.push({
          contactId: '',
          name: 'Sin contacto',
          number: contact.fields['Phone']
        });
        continue;
      }

      const contactFields = (
        await getContactFieldsQuery({
          companyId: contact?.companyId,
          contactGroupId: contact?.contactGroupId
        })
      )?.elements;

      let name = contactFields
        ?.filter((field) => field.type === 'Name' && field.mainField)
        .map((field) => contact?.fields[field.id])
        .join();

      const phoneNumbers = contactFields
        ?.filter((field) => field.type === 'Phone')
        .flatMap((field) => contact?.fields[field.id] || []);

      phoneAutocompleteOptions.push(
        ...phoneNumbers.map((number) => ({
          contactId: contact?.id,
          name,
          number
        }))
      );
    }

    return phoneAutocompleteOptions;
  };

  useEffect(() => {
    if (contactsQuery.isFetched) {
      getPhoneNumberOptions().then((phoneNumbers) =>
        setPhoneNumbers(phoneNumbers)
      );
    }
  }, [conversation?.id, contactsQuery.dataUpdatedAt]);

  // -----------------  USERS  ----------------

  const usersQuery = useGetUsersQuery({
    companyId: companyId
  });
  const users = usersQuery?.data?.elements ?? [];

  // -----------------  FORM  -----------------
  const defaultSchedule = useMemo(() => {
    const socialmediaAgent = conversation.involvedContacts
      ? conversation.involvedContacts[0]?.socialmediaAgent
      : undefined;
    const destinationNumber = socialmediaAgent?.endpoint ?? '';
    return {
      title: '',
      description: '',
      userId: conversation?.assignedUserIds[0] ?? '',
      destinationNumber,
      campaignId: conversationCampaignIsOutbound
        ? conversationCampaign?.id
        : outboundCampaigns?.[0]?.id,
      scheduleTimestamp: new Date().toISOString(),
      id: NEW_SCHEDULE_ID,
      contactId: '',
      companyId: conversation?.companyId ?? companyId
    } as ScheduleCalendar;
  }, [conversation?.id, campaignsQuery.isFetched]);

  return {
    allowedCampaigns,
    defaultSchedule,
    phoneNumbers,
    users,
    contactIds
  };
};
