import { t } from 'i18next';
import { CallerIdData } from 'src/models/CallerIdData';
import { getContactName } from 'src/modules/Contacts/domain/getContactName';
import { getRTUser } from 'src/services/rtusers/domain/rtuser/getRTUser';
import { getContactQueryNoHook } from 'src/services/socialmedia/application/query/getContactsQuery';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { logger } from 'src/utils/logger';

export const getCallerIdDataFromSession = (
  session: WebRTCSession
): CallerIdData => {
  const callerIdDataString = session.RTCSession.remote_identity.display_name;
  return JSON.parse(callerIdDataString) as CallerIdData;
};

export const getDisplayNameFromSession = async (session: WebRTCSession) => {
  const callerIdData = getCallerIdDataFromSession(session);
  const displayName = await getDisplayNameFromCallerIdData(callerIdData);

  return displayName;
};

export const getNameFromCall = async (
  phoneNumber: string,
  serviceId: string
) => {
  const callerIdData = {
    phoneNumber,
    serviceId
  } as CallerIdData;

  return getDisplayNameFromCallerIdData(callerIdData);
};

export const getDisplayNameFromCallerIdData = async (
  callerIdData: CallerIdData
) => {
  let sessionNumber = callerIdData.phoneNumber;
  let display_name = sessionNumber;

  const rtUserInternal = getRTUser({
    complexExtension: callerIdData.phoneNumber,
    shouldUseHook: false
  });

  if (rtUserInternal) display_name = rtUserInternal.username;
  else {
    try {
      const contact = await getContactQueryNoHook({
        id: callerIdData.contactId
      });

      if (contact) display_name = await getContactName(contact);
    } catch (error) {
      logger.error(error);
    }
  }

  if (callerIdData.conference)
    display_name = '(' + t('Conference') + ') ' + display_name;
  if (callerIdData.outgoing)
    display_name = '(' + t('Outgoing') + ') ' + display_name;
  if (callerIdData.spy) display_name = '(' + t('Spy') + ') ' + display_name;
  if (callerIdData.transfer)
    display_name = '(' + t('Transfer') + ') ' + display_name;
  if (callerIdData.unpark)
    display_name = '(' + t('Unparking') + ') ' + display_name;
  // if (callerIdData.queueId)
  //   display_name = '(' + t('Incoming') + ') ' + display_name;
  if (callerIdData.internal)
    display_name = '(' + t('Internal') + ') ' + display_name;
  if (callerIdData.from) {
    const rtUserFrom = getRTUser({
      complexExtension: callerIdData.from,
      shouldUseHook: false
    });

    const fromDisplayName = rtUserFrom
      ? rtUserFrom.username
      : callerIdData.from;

    display_name =
      '(' + t('From') + ' ' + fromDisplayName + ')  ' + display_name;
  }

  return display_name;
};
