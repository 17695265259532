import { Navigate, RouteObject } from 'react-router';

import AppLayout from 'src/layouts/AppLayout';
// import Authenticated from 'src/components/Authenticated';
// import BaseLayout from 'src/layouts/BaseLayout';
// import Guest from 'src/components/Guest';
// import Login from 'src/modules/Auth/views/Login/Basic';

import authRoutes from 'src/modules/Auth/routes/authRoutes';
// import baseRoutes from './baseRoutes';
import dashboardRoutes from 'src/modules/Dashboard/routes/dashboardRoutes';
// import accountRoutes from './account';

import Authenticated from 'src/components/Authenticated';
import { CTIBarProvider } from 'src/contexts/CTIBarContext';
import { ErrorBoundary } from 'react-error-boundary';
import UnknownPage from 'src/content/pages/Status/UnknowError';
import { logger } from 'src/utils/logger';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import EmailExternalView from 'src/modules/Dashboard/views/Inbox/EmailPanel/components/EmailExternalView';
import { VideocallRoomContextProvider } from 'src/modules/VideoCall/context/VideocallRoomContext';
import { InboxPanelsProvider } from 'src/contexts/InboxPanelsContext';
import { ScreenProvider } from 'src/contexts/ScreenContext';

const router: RouteObject[] = [
  {
    path: '',
    children: authRoutes
  },
  {
    path: 'dashboard',
    element: (
      <Authenticated>
        <ErrorBoundary
          fallbackRender={UnknownPage}
          onError={(e) => logger.error(e)}
        >
          <PermissionsProvider>
            <ScreenProvider>
              <CTIBarProvider>
                <VideocallRoomContextProvider>
                  <InboxPanelsProvider>
                    <AppLayout />
                  </InboxPanelsProvider>
                </VideocallRoomContextProvider>
              </CTIBarProvider>
            </ScreenProvider>
          </PermissionsProvider>
        </ErrorBoundary>
      </Authenticated>
    ),
    children: dashboardRoutes
  },
  {
    path: 'send-email',
    element: (
      <Authenticated>
        <ErrorBoundary
          fallbackRender={UnknownPage}
          onError={(e) => logger.error(e)}
        >
          <EmailExternalView />
        </ErrorBoundary>
      </Authenticated>
    )
  },
  {
    path: '*',
    element: <Navigate to="dashboard" replace />
  }
];

export default router;
