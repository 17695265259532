import { Call } from 'src/models/call';
import {
  Contact,
  ContactsRequestParams
} from 'src/modules/Contacts/models/contacts';
import { Conversation } from 'src/models/conversations/conversations';
import { getContactQueryNoHook } from 'src/services/socialmedia/application/query/getContactsQuery';
import {
  getContactApi,
  getContactsApi,
  getContactsListApi as getContactListApi
} from '../infraestructure/api/getContactsApi';
import { PaginatedElements } from 'src/models/pagination';
import { getContactsByFieldValueApi } from '../infraestructure/api/getContactsByFieldValue';

/** GET ONE CONTACT
 *
 * Retrieves the first contact found with the specified filter
 *
 * @param filter Parameters to filter contacts by
 * @returns Contact
 */
export const getContact = async (
  filter: ContactsRequestParams
): Promise<Contact> => {
  if (!filter.id) return Promise.resolve(null);

  const contact = await getContactApi(filter.id);
  return contact;
};

export const getContacts = async (
  params: ContactsRequestParams
): Promise<PaginatedElements<Contact>> => {
  const paginatedContacts = await getContactsApi(params);
  return paginatedContacts;
};

export const getContactList = async (contactIds: string[]) => {
  const resp = await getContactListApi(contactIds);
  return resp;
};

export const getContactByFieldValue = async (
  params: ContactsRequestParams
): Promise<Contact> => {
  let contactResult = await getContactsByFieldValueApi(
    params.serviceId,
    params.fieldType,
    params.fieldValue
  );
  return contactResult;
};

/** GET MANY CONTACTS
 *
 * It's a way to fetch many contacts with one promise.
 * Calls getContact internally.
 *
 * @param filters  Array of filters for each search.
 * @returns  Array of contacts, one for each filter.
 */
export const getManyContacts = async (
  filters: ContactsRequestParams[]
): Promise<Contact[]> => {
  const contacts = [];

  for (const filter of filters) {
    const contact = await getContactQueryNoHook(filter);
    contacts.push(contact);
  }

  return Promise.resolve(contacts);
};

/** GET CONTACTS FROM CONVERSATION
 *
 * @param conversation
 * @returns Listof contacts involved in the conversation
 */
export const getContactsFromConversation = async (
  conversation: Conversation
): Promise<Contact[]> => {
  const filters: ContactsRequestParams[] = conversation.involvedContacts.map(
    (invC) => {
      return { id: invC.contactId } as ContactsRequestParams;
    }
  );

  const filtersWithId = filters.filter((f) => f.id);
  return getManyContacts(filtersWithId);
};

export const getContactsFromCall = async (call: Call): Promise<Contact[]> => {
  const filters: ContactsRequestParams[] = call.involvedContacts.map((invC) => {
    return { id: invC.contactId } as ContactsRequestParams;
  });

  const filtersWithId = filters.filter((f) => f.id);
  return getManyContacts(filtersWithId);
};
