import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Restricted from 'src/components/Restricted';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {
  checkReadCallerIdsPermissions,
  checkReadExtensionPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Companies = Loader(lazy(() => import('../modules/Companies/views')));
const Extensions = Loader(lazy(() => import('../modules/Extensions/views')));
const CallerIds = Loader(lazy(() => import('../modules/CallerIds/views')));

const configurationRoutes: RouteObject[] = [
  {
    path: 'companies',
    element: <Companies />
  },
  {
    path: 'extensions',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadExtensionPermissions}>
        <Extensions />
      </Restricted>
    )
  },
  {
    path: 'caller-ids',
    element: (
      <Restricted navigate permissionCheckerFn={checkReadCallerIdsPermissions}>
        <CallerIds />
      </Restricted>
    )
  }
];

export default configurationRoutes;
