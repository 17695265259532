import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ArticleIcon from '@mui/icons-material/Article';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FlakyIcon from '@mui/icons-material/Flaky';
import PersonIcon from '@mui/icons-material/Person';
import { Item, Menu } from 'src/models/menus';
import {
  checkReadAnnouncementPermissions,
  checkReadCallerIdsPermissions,
  checkReadContactGroupPermissions,
  checkReadContactPermissions,
  checkReadEmailAccountPermissions,
  checkReadExtensionPermissions,
  checkReadInboundRoutePermissions,
  checkReadTelegramAccountPermissions,
  checkReadTimeConditionPermissions,
  checkReadUserPermissions,
  checkReadUserRolePermissions,
  checkReadVariablePermissions,
  checkReadWebChatAccountPermissions,
  checkReadWhatsAppAccountPermissions,
  checkReadDialerbotCampaignPermissions,
  checkReadTicketViewPermissions,
  checkReadMusicOnHoldPermissions,
  checkReadOutboundRoutePermissions,
  checkReadExternalActionsPermissions,
  checkReadInstagramAccountPermissions,
  checkReadKeyWordsGroupsPermissions,
  checkReadAnalyzePermissions,
  checkReadServicePermissions,
  checkReadQuestionGroupsPermissions,
  checkReadApiKeysPermissions,
  checkReadContactTagsPermissions,
  checkReadContactsAndContactGroupsPermissions,
  checkReadRobinsonListsPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { Permissions } from 'src/models/permissionGroups';

export const adminItems: Menu[] = [
  {
    option: 'General Settings',
    color: '#EBF9FF',
    iconColor: '#0AB6FF',
    icon: SettingsApplicationsIcon,
    items: [
      {
        name: 'Companies',
        link: '/dashboard/admin/settings/companies',
        onlySuperAdmin: true
      },
      {
        name: 'Extensions',
        link: '/dashboard/admin/settings/extensions',
        permissionsCheckerFn: checkReadExtensionPermissions
      },
      {
        name: 'Caller Ids',
        link: '/dashboard/admin/settings/caller-ids',
        permissionsCheckerFn: checkReadCallerIdsPermissions,
        onlySuperAdmin: true
      }
    ] as Item[]
  },
  {
    option: 'Operations',
    color: '#ECFBE6',
    iconColor: '#3BB900	',
    icon: ArticleIcon,
    items: [
      {
        name: 'Services',
        link: '/dashboard/admin/operations/services',
        permissionsCheckerFn: checkReadServicePermissions
      },
      {
        name: 'Tickets',
        link: '/dashboard/admin/operations/tickets',
        permissionsCheckerFn: checkReadTicketViewPermissions
      },
      {
        name: 'Motores de marcacion',
        link: '/dashboard/admin/operations/dialerbot',
        permissionsCheckerFn: checkReadDialerbotCampaignPermissions
      },
      {
        name: 'Robinson List',
        link: '/dashboard/admin/operations/robinson-list',
        permissionCheckerFn: checkReadRobinsonListsPermissions
      },
      {
        name: 'Massive SMS',
        link: '/dashboard/admin/operations/massive-sms',
        permissionsCheckerFn: () => true
      }
    ] as Item[]
  },
  {
    option: 'Integrations',
    color: '#FFE8EC',
    iconColor: '#F4002C',
    icon: IntegrationInstructionsIcon,
    items: [
      {
        name: 'External Actions',
        link: '/dashboard/admin/integrations/externalActions',
        permissionsCheckerFn: checkReadExternalActionsPermissions
      }
    ]
  },
  {
    option: 'Contact center',
    color: '#FFF6E8',
    iconColor: '#F49200',
    icon: PublicIcon,
    items: [
      {
        name: 'Chat',
        link: '/dashboard/admin/contact-center/chat',
        permissionsCheckerFn: checkReadWebChatAccountPermissions
      },
      {
        name: 'Email',
        link: '/dashboard/admin/contact-center/email',
        permissionsCheckerFn: checkReadEmailAccountPermissions
      },
      {
        name: 'Instagram',
        link: '/dashboard/admin/contact-center/instagram',
        permissionsCheckerFn: checkReadInstagramAccountPermissions
      },
      {
        name: 'Telegram',
        link: '/dashboard/admin/contact-center/telegram',
        permissionsCheckerFn: checkReadTelegramAccountPermissions
      },
      {
        name: 'WhatsApp',
        link: '/dashboard/admin/contact-center/whatsapp',
        permissionsCheckerFn: checkReadWhatsAppAccountPermissions,
        onlySuperAdmin: true
      }
    ] as Item[]
  },
  {
    option: 'Management',
    color: '#F5C9EB',
    iconColor: '#E161D5',
    icon: GroupsIcon,
    items: [
      {
        name: 'Users',
        link: '/dashboard/admin/management/view-users',
        permissionsCheckerFn: checkReadUserPermissions
      },
      {
        name: 'Roles and Permissions',
        link: '/dashboard/admin/management/roles-and-permissions',
        permissionsCheckerFn: checkReadUserRolePermissions
      },
      {
        name: 'API Keys',
        link: '/dashboard/admin/management/api-keys',
        permissionsCheckerFn: checkReadApiKeysPermissions
      }
    ] as Item[]
  },
  {
    option: 'Acd',
    color: '#8AAEF6',
    iconColor: '#1975FF',
    icon: AccountTreeIcon,
    items: [
      {
        name: 'Music On Hold',
        link: '/dashboard/admin/acd/moh',
        permissionsCheckerFn: checkReadMusicOnHoldPermissions
      },
      {
        name: 'Inbound Routes',
        link: '/dashboard/admin/acd/routes',
        permissionsCheckerFn: checkReadInboundRoutePermissions
      },
      {
        name: 'Variables',
        link: '/dashboard/admin/acd/variables',
        permissionsCheckerFn: checkReadVariablePermissions
      },
      {
        name: 'Intention Models',
        link: '/dashboard/admin/acd/ai-models'
      },
      {
        name: 'Knowledge models',
        link: '/dashboard/admin/acd/knowledge-models'
      },
      {
        name: 'Temporal conditions',
        link: '/dashboard/admin/acd/schedule-groups',
        permissionsCheckerFn: checkReadTimeConditionPermissions
      },
      {
        name: 'Announcements',
        link: '/dashboard/admin/acd/announcements',
        permissionsCheckerFn: checkReadAnnouncementPermissions
      },

      {
        name: 'Outbound Routes',
        link: '/dashboard/admin/acd/outbound-routes',
        permissionsCheckerFn: checkReadOutboundRoutePermissions
      }
    ] as Item[]
  },
  {
    option: 'Quality',
    color: '#FFCFA4',
    iconColor: '#FE9B95',
    icon: FlakyIcon,
    items: [
      {
        name: 'Keyword Groups',
        link: '/dashboard/admin/quality/keyword-groups',
        permissionsCheckerFn: checkReadKeyWordsGroupsPermissions
      },
      {
        name: 'Analyzed Recordings',
        link: '/dashboard/admin/quality/analyzed-recordings',
        permissionsCheckerFn: checkReadAnalyzePermissions
      },
      {
        name: 'Questions Groups',
        link: '/dashboard/admin/quality/questions-groups',
        permissionsCheckerFn: checkReadQuestionGroupsPermissions
      }
    ] as Item[]
  },
  {
    option: 'Contacts',
    color: '#DEF6F0',
    iconColor: '#00B98D',
    icon: PersonIcon,
    items: [
      {
        name: 'Contacts',
        link: '/dashboard/admin/contacts/view-contacts',
        permissionsCheckerFn: checkReadContactPermissions
      },
      {
        name: 'Tags',
        link: '/dashboard/admin/contacts/tags',
        permissionCheckerFn: (permissions: Permissions) =>
          checkReadContactTagsPermissions(permissions) &&
          checkReadContactsAndContactGroupsPermissions(permissions)
      },
      {
        name: 'Contacts fields',
        link: '/dashboard/admin/contacts/contact-fields',
        permissionCheckerFn: (permissions: Permissions) =>
          checkReadContactTagsPermissions(permissions) &&
          checkReadContactsAndContactGroupsPermissions(permissions)
      },
      {
        name: 'Contacts groups',
        link: '/dashboard/admin/contacts/contact-groups',
        permissionsCheckerFn: checkReadContactGroupPermissions
      }
    ] as Item[]
  }
];
