import { emitCustomEvent } from 'react-custom-events';
import { logger } from 'src/utils/logger';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

const WS = process.env.REACT_APP_WS as string;

export const initWebSocket: Function = (
  token: string,
  subscriptionType: string
) => {
  logger.log('Starting websocket', WS);

  const client = new W3CWebSocket(WS);
  const authMsg = {
    type: 'Auth',
    subtype: 'Login',
    token: token,
    timestamp: new Date().toJSON()
  };
  client.onopen = (): void => {
    logger.log('Websocket connection opened');
    client.send(JSON.stringify(authMsg));
    emitCustomEvent('realtime-connection-changed', true);
  };
  client.onclose = (): void => {
    logger.log('Websocket connection closed');

    emitCustomEvent('realtime-connection-changed', false);
  };
  client.onerror = (error: any): void => {
    logger.log('Websocket error: ', error);
    emitCustomEvent('realtime-connection-changed', false);
  };

  return client;
};
