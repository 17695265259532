import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { ContactGroup, ContactTag } from 'src/modules/Contacts/models/contacts';
import { UpdateTagModal } from './UpdateTagModal';
import { Box } from '@mui/material';
import { DialTable } from 'src/components/DialTable';
import { useTags } from '../../hooks/useTags';
import { useEffect } from 'react';
import { getContactGroups } from 'src/modules/Contacts/domain/getContactGroups';

function ContactTagsTable() {
  const {
    data,
    tagToEdit,
    setTagToEdit,
    tagsToDelete,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    onDeleteTags,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    companyId,
    setContactGroupsData,

    applyFilters
  } = useTags();

  useEffect(() => {
    getContactGroups({ companyId }).then((resp) => {
      setContactGroupsData(resp?.elements);
    });
  }, []);

  return (
    <>
      <UpdateTagModal
        key={tagToEdit?.id}
        tag={tagToEdit}
        onClose={() => {
          setTagToEdit(undefined);
        }}
      />

      <ConfirmationModal<ContactTag, keyof ContactTag>
        dataKey={'name'}
        data={tagsToDelete}
        isOpen={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        onConfirm={onDeleteTags}
        mode={ConfirmationModalModes.DELETE}
      />

      <Box width={'100%'} p={2}>
        <DialTable<ContactTag>
          queryKey={['contactFields']}
          getColumnsFn={getColumns}
          getRowActionsFn={getRowActions}
          data={data}
          getBulkActionsFn={getBulkActions}
          getAvailableFilters={getAvailableFilters}
          onlyEqualsOperator
          onApplyFilters={applyFilters}
        />
      </Box>
    </>
  );
}

export default ContactTagsTable;
