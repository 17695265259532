import {
  Box,
  Modal,
  Typography,
  Button,
  CardHeader,
  Divider,
  Stack,
  CircularProgress,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { emitCustomEvent } from 'react-custom-events';
import { t } from 'i18next';
import withId from '../../models/withId';
import { useState } from 'react';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';

interface DeleteModalPropTypes<T extends withId, K extends keyof T> {
  data: Array<T>;
  dataKey: K;
  isOpen: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: (obj: T[]) => Promise<any>;
  isCompaniesForm?: boolean; // Nueva prop para indicar si se está utilizando en CompaniesForm
  informationText?: string;
  closeFn?: () => void;
  mode: ConfirmationModalModes;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit',
  height: 'fit',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export enum ConfirmationModalModes {
  DELETE = 'delete',
  INFO = 'info'
}

export const ConfirmationModal = <T extends withId, K extends keyof T>({
  data,
  isOpen,
  setOpen,
  dataKey,
  onConfirm,
  isCompaniesForm = false, // Valor por defecto: false
  informationText,
  closeFn,
  mode
}: DeleteModalPropTypes<T, K>) => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalTitle =
    mode === ConfirmationModalModes.DELETE
      ? isCompaniesForm
        ? t(
            'Are you sure to remove the following? You have to delete it twice to delete it permanently'
          )
        : t('Are you sure to remove the following?')
      : t('Confirm action');

  function handleClose() {
    if (closeFn) {
      closeFn();
    } else if (setOpen) {
      setOpen(!open);
    }
  }

  const buttonColor =
    mode === ConfirmationModalModes.DELETE ? 'error' : 'secondary';

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} display="flex" flexDirection={'column'}>
        {data.length === 1 ? (
          <>
            <CardHeader
              title={modalTitle}
              subheader={
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: '7.5px'
                  }}
                >
                  {data[0]?.[dataKey]}
                </div>
              }
              sx={{ px: 2, pt: 2, pb: 1 }}
            />
          </>
        ) : (
          <>
            <CardHeader
              title={
                (mode === ConfirmationModalModes.DELETE
                  ? t('Are you sure to remove ')
                  : t('Confirm action will affect')) +
                data.length +
                t(' items?')
              }
              sx={{ px: 2, pt: 2, pb: 1 }}
            />
          </>
        )}
        {informationText && (
          <Box justifyContent={'center'} sx={{ display: 'flex', mb: 1, ml: 2 }}>
            <InfoOutlinedIcon color="secondary" sx={{ pr: 1 }} />
            <Typography
              id="modal-modal-info"
              variant="subtitle1"
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                maxWidth: '275px'
              }}
            >
              {informationText}
            </Typography>
          </Box>
        )}
        <Divider sx={{ width: '100%' }} />
        <Stack
          p={1.5}
          width="100%"
          direction={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          <Button
            fullWidth
            // size="small"
            color="secondary"
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
            id="btnCancel-ConfirmationModal"
          >
            {t('Cancel')}
          </Button>

          <Button
            fullWidth
            // size="small"
            color={buttonColor}
            variant="contained"
            onClick={async () => {
              setIsSubmitting(true);
              await onConfirm(data);
              emitCustomEvent(REFETCH_DATA_EVENT_NAME);
              handleClose();
              setIsSubmitting(false);
            }}
            startIcon={
              isSubmitting ? (
                <CircularProgress color="inherit" size="1rem" />
              ) : null
            }
            id="btnConfirm-ConfirmationModal"
            disabled={isSubmitting}
          >
            {mode === ConfirmationModalModes.DELETE
              ? t('Delete')
              : t('Confirm')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
