import {
  FC,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { Edge, Node } from 'reactflow';
import { AriElement } from 'src/modules/Shared/models/ariModels';
import {
  InitialElementData,
  RFdata,
  RFedges
} from 'src/modules/Admin/modules/Acd/modules/InboundRoutes/models/routesNodes';
type InboundRoutesContext = {
  nodes: Node<RFdata>[];
  setNodes: Dispatch<SetStateAction<Node<RFdata>[]>>;
  originalNodes: Node<RFdata>[];
  setOriginalNodes: Dispatch<SetStateAction<Node<RFdata>[]>>;
  edges: Edge<RFedges>[];
  setEdges: Dispatch<SetStateAction<Edge<RFedges>[]>>;
  originalEdges: Edge<RFedges>[];
  setOriginalEdges: Dispatch<SetStateAction<Edge<RFedges>[]>>;
  currentDdi: string;
  setCurrentDdi: Dispatch<SetStateAction<string>>;
  isConfigModalOpened: boolean;
  setIsConfigModalOpened: Dispatch<SetStateAction<boolean>>;
  data: null | InitialElementData;
  setData: Dispatch<SetStateAction<null | InitialElementData>>;
  isNewNode: boolean;
  currentElement: AriElement;
  setCurrentElement: Dispatch<SetStateAction<AriElement>>;
  isRootModalOpened: boolean;
  setIsRootModalOpened: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundRoutesContext = createContext<InboundRoutesContext>(
  {} as InboundRoutesContext
);

export const InboundRoutesProvider: FC = ({ children }) => {
  const [nodes, setNodes] = useState<Node<RFdata>[]>([]);
  // nodes before editing the route
  const [originalNodes, setOriginalNodes] = useState<Node<RFdata>[]>([]);
  const [edges, setEdges] = useState<Edge<RFedges>[]>([]);
  // edges before editing the route
  const [originalEdges, setOriginalEdges] = useState<Edge<RFedges>[]>([]);
  const [currentDdi, setCurrentDdi] = useState<string>('');
  const [data, setData] = useState<null | InitialElementData>(null);
  const [isConfigModalOpened, setIsConfigModalOpened] =
    useState<boolean>(false);
  const [isRootModalOpened, setIsRootModalOpened] = useState<boolean>(false);
  const isNewNode = data?.clicked === undefined;
  const [currentElement, setCurrentElement] = useState<AriElement>();

  useEffect(() => {
    if (!isConfigModalOpened) {
      emitCustomEvent('deleteSuspense');
    }
  }, [isConfigModalOpened]);

  return (
    <InboundRoutesContext.Provider
      value={{
        nodes,
        setNodes,
        originalNodes,
        setOriginalNodes,
        edges,
        setEdges,
        originalEdges,
        setOriginalEdges,
        currentDdi,
        setCurrentDdi,
        isConfigModalOpened,
        setIsConfigModalOpened,
        data,
        setData,
        isNewNode,
        currentElement,
        setCurrentElement,
        isRootModalOpened,
        setIsRootModalOpened
      }}
    >
      {children}
    </InboundRoutesContext.Provider>
  );
};
