import { BASE_URL } from './base';

const CALL_SCHEDULE_MS = `${BASE_URL}/call-scheduling`;

// Executions controller
const EXECUTION_CONTROLLER = `${CALL_SCHEDULE_MS}/executions`;
export const EXECUTION = EXECUTION_CONTROLLER;
export const EXECUTION_LIST = `${EXECUTION_CONTROLLER}/list`;
export const EXECUTION_START = `${EXECUTION_CONTROLLER}/start`;
export const EXECUTION_STOP = `${EXECUTION_CONTROLLER}/stop`;
export const EXECUTION_KILL = `${EXECUTION_CONTROLLER}/kill`;
export const EXECUTION_PROGRESS = `${EXECUTION_CONTROLLER}/progress`;

// Schedule database controller
const SCHEDULE_DATABASE_CONTROLLER = `${CALL_SCHEDULE_MS}/scheduleDatabase`;
export const SCHEDULE_DATABASE = SCHEDULE_DATABASE_CONTROLLER;
export const SCHEDULE_DATABASE_ID = `${SCHEDULE_DATABASE_CONTROLLER}/{id}`;
export const SCHEDULE_DATABASE_PRIORITY = `${SCHEDULE_DATABASE_CONTROLLER}/{id}/priority`;
export const SCHEDULE_DATABASE_UPLOAD_FILES = `${SCHEDULE_DATABASE_CONTROLLER}/upload-csv`;
export const SCHEDULE_DATABASE_UPLOAD_CONTACTS = `${SCHEDULE_DATABASE_CONTROLLER}/upload-contacts`;

// Scheduler calendar controller
const SCHEDULE_CALENDAR_CONTROLLER = `${CALL_SCHEDULE_MS}/scheduleCalendar`;
export const SCHEDULE_CALENDAR = SCHEDULE_CALENDAR_CONTROLLER;
export const SCHEDULE_CALENDAR_ID = `${SCHEDULE_CALENDAR_CONTROLLER}/{callScheduleId}`;

// Schedule dialerbot controller

 const SCHEDULE_DIALERBOT_CONTROLLER = `${CALL_SCHEDULE_MS}/dialerbot`;
export const SCHEDULE_DIALERBOT = SCHEDULE_DIALERBOT_CONTROLLER;
export const SCHEDULE_DIALERBOT_REPORT = `${SCHEDULE_DIALERBOT_CONTROLLER}/report`;
export const SCHEDULE_DIALERBOT_CALL_RESULT_COUNT = `${SCHEDULE_DIALERBOT_CONTROLLER}/callResultCount`;
