import { emitCustomEvent } from 'react-custom-events';
import { Conversation } from 'src/models/conversations/conversations';
import { getRTCall } from 'src/services/rtcalls/domain/getRTCall';
import { refreshConversationMessages } from 'src/services/socialmedia/domain/conversation/refreshConversationMessages';
import { setCurrentConversation } from 'src/services/socialmedia/domain/conversation/setCurrentConversation';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';

export const onClickConversation = (
  conv: Conversation,
  hasAccess?: boolean
) => {
  // If the user does not have permissions to view the conversation, do nothing
  if (hasAccess !== undefined && !hasAccess) return;

  selectConversation(conv);
  //Unkpark call onclick
  if (conv.channel !== 'Call') return;
  const rtCall = getRTCall({
    callId: conv.originAgent.callId,
    shouldUseHook: false
  });
  if (rtCall?.status !== 'Parked') return;
  // CallManagerDomain.unparkCall(rtCall);
};

export const selectConversation = (conversation: Conversation) => {
  setCurrentConversation(conversation);
  refreshConversationMessages(conversation);
  emitCustomEvent(CustomEventNames.OPEN_HELPER_PANEL, conversation);
};
