import { darken, Grid, Stack, useTheme } from '@mui/material';
import { useContext } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useLocation, useNavigate } from 'react-router-dom';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';

export const TotalCalls: React.FC = () => {
  const theme = useTheme();
  const user = getConnectedRTUser();
  const { barState } = useContext(CTIBarContext);
  const { isBarCompressed, isDragging } = barState;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Grid
      item
      xs={6} // There are 12 columns and this component should occupy 50%
      sx={{
        cursor: 'pointer',
        backgroundColor: theme.colors.success.lighter,
        '&:hover': {
          backgroundColor: darken(theme.colors.success.lighter, 0.5)
        },
        height: isBarCompressed ? 23 : 'auto',
        width: isBarCompressed ? 23 : 'auto'
      }}
      onClick={() => {
        if (!isDragging) {
          if (location.pathname === '/dashboard/inbox') {
            // TODO: Add a type to this variable
            const tab = 'assigned';
            localStorage.setItem('conversationTab', tab);
            emitCustomEvent('Set active tab', tab);
          } else navigate('/dashboard/inbox');
        }
      }}
    >
      {
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            color: theme.colors.success.main,
            height: '100%',
            fontWeight: 'bold',
            fontSize: isBarCompressed ? '13px' : '21px'
          }}
        >
          {
            user?.calls.filter((call) => {
              return call.status !== 'Ringing';
            }).length
          }
        </Stack>
      }
    </Grid>
  );
};
