import axios, { AxiosResponse } from 'axios';
import { ScheduleCalendar } from '../createScheduleCalendar';
import qs from 'qs';
import { SCHEDULE_CALENDAR } from 'src/utils/apiRoutes/msCallSchedule';

export interface ScheduleCalendarFilter {
  contactIds?: string[];
  createdBy?: string;
  endTime?: string;
  launched?: boolean;
  startTime?: string;
  ticketId?: string;
  userId?: string;
}

export const getScheduleCalendarsApi = async (
  filter: ScheduleCalendarFilter
) => {
  const params = { ...filter };

  const paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };

  const response = await axios.get(SCHEDULE_CALENDAR, {
    params,
    paramsSerializer
  });
  return response.data as ScheduleCalendar[];
};

export const createScheduleCalendar = async (
  scheduleCalendar: ScheduleCalendar,
  conversationId: string
): Promise<ScheduleCalendar> => {
  const params = { conversationId };

  const response: AxiosResponse<ScheduleCalendar> = await axios.post(
    SCHEDULE_CALENDAR,
    scheduleCalendar,
    { params }
  );
  return response.data;
};
