import { CloseTwoTone } from '@mui/icons-material';
import {
  Button,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  Stack,
  TextField
} from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { useAlert } from 'src/hooks/useAlert';
import { IconButtonWrapper } from 'src/modules/Productivity/modules/Calls/views/styles';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import createInstagramAccount from 'src/services/instagram/domain/account/createInstagramAccount';
import createInstagramAccountApi from 'src/services/instagram/infrastructure/account/createInstagramAccoutnApi';
import { InstagramAccount } from 'src/services/instagram/models/instagramAccounts';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function CreateInstagramAccountPopover({ isOpen, onClose }: Props) {
  const title = t(`Create Instagram account`);
  const subheader = t(`Use this dialog window to add an Instagram account`);

  const [username, setUsername] = useState('');

  const { showAlert } = useAlert();

  async function handleSubmit() {
    try {
      await createInstagramAccount(username);
      emitCustomEvent(REFETCH_DATA_EVENT_NAME);
      showAlert(t('Instagram account created successfully'), 'success', 3000);
    } catch (error) {
      showAlert(getErrorMessage(error).errorMessage, 'error', 3000);
    }
    onClose();
    setUsername('');
  }

  return (
    <>
      <Popover
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Stack>
          <CardHeader
            title={title}
            subheader={subheader}
            action={
              <IconButton
                sx={{
                  m: 1
                }}
                size="small"
                color="secondary"
                onClick={onClose}
              >
                <CloseTwoTone />
              </IconButton>
            }
          />
          <Divider />
          <TextField
            value={username}
            onChange={(e) => {
              setUsername(e.target.value.replace(/\s/g, ''));
            }}
            label={t('Username')}
            sx={{
              margin: 2
            }}
            helperText={t('Needs to be the name of the Instagram account')}
          />
          <Divider />
          <Stack
            px={1.5}
            py={1.5}
            width="100%"
            direction={{ xs: 'column-reverse', sm: 'row' }}
            justifyContent={'space-between'}
            spacing={1.5}
          >
            <Button onClick={onClose} variant="outlined" color="secondary">
              {t('Cancel')}
            </Button>
            <Button
              disabled={username === ''}
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
            >
              {t('Create')}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}

export default CreateInstagramAccountPopover;
