import { queryClient } from 'src/utils/queryClient';
import { ContactsDomain } from '../../domain/ContactsDomain';

export const getContactsListQuery = async (contactIds: string[]) => {
  return queryClient.fetchQuery(
    ['contactsList', contactIds],
    () => ContactsDomain.getContactList(contactIds),
    { staleTime: 60 * 1000 }
  );
};
