import { Card, Grid, Stack } from '@mui/material';
import { JoinVideoStream } from '../JoinVideoStream';
import { Video } from '../Video';
import { CameraControl } from '../CameraControl';
import { AudioSlider } from 'src/components/WebRTCDevices/AudioSlider';
import { VoiceSlider } from 'src/components/WebRTCDevices/VoiceSlider';

export const CardVideoStream = () => {
  return (
    <Card>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Stack
            direction={'column'}
            justifyContent="center"
            spacing={1}
            paddingTop={2}
            paddingBottom={2}
            paddingLeft={2}
            paddingRight={2}
          >
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <Video />
            </Stack>

            <Stack direction={'row'} alignItems="center" spacing={2}>
              <AudioSlider />
            </Stack>

            <Stack direction={'row'} alignItems="center" spacing={2}>
              <VoiceSlider />
            </Stack>

            <Stack direction={'row'} alignItems="center" spacing={2}>
              <CameraControl />
            </Stack>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <JoinVideoStream />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
