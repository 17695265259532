import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { MFACode } from 'src/models/auth';
import { RTUser } from 'src/models/rtUser';

import AuthManager from 'src/services/authentication/manager';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { useSelector } from 'src/store/store';

interface AuthDataState {
  username: string;
  /** null after login */
  password: string | null;
  withExtension: boolean;
  /** null after login. When null, the username-password login view is displayed */
  mfaCode?: MFACode | null;
}

interface AuthState {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  authData: AuthDataState;
  setAuthData: Dispatch<SetStateAction<AuthDataState>>;
  isConfigureMfa: boolean;
  isEnterMfa: boolean;
  isMfa: boolean;
  setMfaCode: (updater: MFACode | ((previous: MFACode) => MFACode)) => void;
  user: RTUser | null;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const AuthContext = createContext<AuthState>({} as AuthState);

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
}

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const userId = AuthManager.getUserId();
  const token = AuthManager.getToken();

  const user = useSelector((state) => state.rtusers.rtUsers[userId]);

  const [isAuthenticated, setIsAuthenticated] = useState(!!token && !!userId);
  const [authData, setAuthData] = useState<AuthDataState>(null);

  const isConfigureMfa = authData?.mfaCode === MFACode.CONFIGURE_MFA;
  const isEnterMfa = authData?.mfaCode === MFACode.ENTER_MFA_CODE;
  const isMfa = isConfigureMfa || isEnterMfa;

  const setMfaCode = (updater: MFACode | ((previous: MFACode) => MFACode)) => {
    const mfaCode =
      typeof updater === 'function' ? updater?.(authData?.mfaCode) : updater;
    setAuthData((prev) => ({ ...prev, mfaCode }));
  };

  useCustomEventListener(CustomEventNames.LOGOUT, () => {
    setIsAuthenticated(false);
  });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        authData,
        setAuthData,
        isConfigureMfa,
        isEnterMfa,
        isMfa,
        setMfaCode,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
