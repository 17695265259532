import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const JoinVideoStream = () => {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      startIcon={<Add />}
      sx={{ px: 6 }}
    >
      {t('JOIN')}
    </Button>
  );
};
