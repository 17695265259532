import { RTCSession } from 'jssip/lib/RTCSession';
import { logger } from 'src/utils/logger';
import { emitCustomEvent } from 'react-custom-events';
import { setDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/setDevices';

/**
 * Request the user's media devices
 * @property exactDevices - The exact devices to request
 * @property enableVideo - Whether to enable video or not
 * @property constraints - Full constraints object to request
 */
interface RequestDevicesProps {
  enableVideo?: boolean;
  constraints?: MediaStreamConstraints;
  session?: RTCSession;
}

/**
 *
 * @param props - If constraints are provided, the rest of props are ignored
 * @returns
 */
const requestLocalStream = async (props?: RequestDevicesProps) => {
  const { enableVideo, constraints } = props ?? {
    enableVideo: false,
    constraints: undefined
  };

  try {
    const audioDeviceId =
      (constraints?.audio as MediaTrackConstraints)?.deviceId ??
      localStorage.getItem(videoMicrophoneDeviceKey);
    const videoDeviceId =
      (constraints?.video as MediaTrackConstraints)?.deviceId ??
      localStorage.getItem(videoCameraDeviceKey);

    const stream = await navigator.mediaDevices?.getUserMedia?.({
      audio: {
        ...(constraints?.audio as MediaTrackConstraints),
        deviceId: audioDeviceId
      },
      video: enableVideo
        ? {
            ...(constraints?.video as MediaTrackConstraints),
            deviceId: videoDeviceId
          }
        : false
    });
    if (stream && audioDeviceId) {
      localStorage.setItem(videoMicrophoneDeviceKey, audioDeviceId.toString());
    }
    if (stream && videoDeviceId) {
      localStorage.setItem(videoCameraDeviceKey, videoDeviceId.toString());
    }

    navigator.mediaDevices.enumerateDevices().then((devicesList) => {
      setDevicesStore(devicesList);
    });

    return stream;
  } catch (err) {
    logger.error('[WEBRTC] Error requesting user devices', err);
    if (err.name === 'OverconstrainedError') {
      return;
    }

    if (err.name !== 'NotAllowedError' && !props) {
      //If the device selected is not available, try again with the default device
      requestLocalStream();
    }
  }
};

async function replaceStreamOnRTCSession({
  stream,
  rtcSession,
  kind
}: {
  stream: MediaStream;
  rtcSession: RTCSession;
  kind: 'audio' | 'video';
}) {
  try {
    const senders = rtcSession.connection.getSenders();
    const videoSender = senders.find((sender) => sender.track?.kind === kind);
    const track = stream.getTracks().find((track) => track.kind === kind);

    if (videoSender && track) {
      videoSender.replaceTrack(track);
    } else {
      logger.error('Could not replace track ');
    }
  } catch (e) {
    logger.error(
      '[VIDEO.WEBRTC.DEVICES] Error replacing track on RTCSession',
      e
    );
    return false;
  }

  return true;
}

/**
 * Mute or unmute the audio of a stream
 * @param stream - The stream to mute
 * @param mute - Whether to mute or unmute the stream
 * @param rtcSession - If provided, the audio track will be replaced with the new one
 */
function muteAudioStream({
  stream,
  mute
}: {
  stream: MediaStream;
  mute: boolean;
  rtcSession?: RTCSession;
}) {
  const audioTracks = stream.getAudioTracks();
  audioTracks.forEach((track) => {
    track.enabled = !mute;
  });

  return stream;
}

function toggleDisableVideoStream(stream: MediaStream, disable: boolean) {
  const videoTracks = stream?.getVideoTracks();
  videoTracks.forEach((track) => {
    track.enabled = !disable;
  });

  return stream;
}

function toggleDisableAudioStream(stream: MediaStream, disable: boolean) {
  const videoTracks = stream.getAudioTracks();
  videoTracks.forEach((track) => {
    track.enabled = !disable;
  });

  return stream;
}

export const videoMicrophoneDeviceKey = 'videoMicrophoneDeviceKey';
export const videoCameraDeviceKey = 'videoCameraDeviceKey';
export const videoSpeakerDeviceKey = 'videoSpeakerDeviceKey';

const VideoWebRTCDevices = {
  requestLocalStream,
  muteStream: muteAudioStream,
  toggleDisableVideoStream,
  replaceStreamOnRTCSession,
  toggleDisableAudioStream
};

export default VideoWebRTCDevices;
