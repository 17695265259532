import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  getConversationFiltersStore,
  getExpandedUserStore,
  getExpandedUsersStore,
  getGroupConversationsByChannelFilterStore,
  getSelectedConversationChannelFilterStore,
  getShowClosedConversationsFilterStore,
  getTextFilterStore
} from '../../infraestructure/store/getConversationFiltersStore';
import { getConversation } from './getConversation';
import { getRTCallFromConversation } from './getConversationInfo';
import { sortConversationsByModificationDate } from '../messages/utils';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import AuthManager from 'src/services/authentication/manager';

export const getSelectedConversationChannelFilterDomain = (
  channel: ChannelType,
  shouldUseHook: boolean
) => {
  return getSelectedConversationChannelFilterStore(channel, shouldUseHook);
};

export const getShowClosedConversationsFilterDomain = (
  shouldUseHook: boolean
) => {
  return getShowClosedConversationsFilterStore(shouldUseHook);
};

export const getGroupConversationsByChannelFilterDomain = (
  shouldUseHook: boolean
) => {
  return getGroupConversationsByChannelFilterStore(shouldUseHook);
};

export const getTextFilterDomain = (shouldUseHook: boolean) => {
  return getTextFilterStore(shouldUseHook);
};

export const getExpandedUsersDomain = (shouldUseHook: boolean) => {
  return getExpandedUsersStore(shouldUseHook);
};

export const getExpandedUserDomain = (
  userId: string,
  shouldUseHook: boolean
) => {
  return getExpandedUserStore(userId, shouldUseHook);
};

export const getConversationFiltersDomain = (shouldUseHook: boolean) => {
  return getConversationFiltersStore(shouldUseHook);
};

export const sortAndFilterConversationIds = (
  conversationIds: string[],
  userId: string
) => {
  if (!conversationIds || conversationIds.length === 0) return [];
  return [...conversationIds]
    .filter((id) => {
      const conversation = getConversation({ id, shouldUseHook: false });
      if (!conversation) return false;

      const showConversation = checkConversationPassFilter(
        conversation,
        userId
      );

      return showConversation;
    })
    .sort(sortConversationIdsByDate);
};

export const sortConversationIdsByDate = (a: string, b: string) => {
  const conversationA = getConversation({ id: a, shouldUseHook: false });
  const conversationB = getConversation({ id: b, shouldUseHook: false });
  return sortConversationsByModificationDate(conversationA, conversationB);
};
/**
 *
 * @param conversations
 * @param userId
 * @param onlyAssigned if true, returns only conversations assigned to the user with the userId passed. Otherwise, the conversation assigned users are not checked.
 * @returns
 */
export const sortAndFilterConversations = (
  conversations: Conversation[],
  userId: string,
  onlyAssigned?: boolean
) => {
  if (!conversations || conversations.length === 0) return [];
  return [...conversations]
    .filter((conversation) => {
      if (!conversation) return false;

      const showConversation = checkConversationPassFilter(
        conversation,
        userId,
        onlyAssigned
      );

      return showConversation;
    })
    .sort((conversationA, conversationB) => {
      return sortConversationsByModificationDate(conversationA, conversationB);
    });
};

/**
 *  A function to see if a conversation should be shown or not
 * @param conversation
 * @param userId
 * @param onlyAssigned if true, returns only conversations assigned to the user with the userId passed. Otherwise, the users assigned to the conversation are not checked.
 * @returns
 */
export const checkConversationPassFilter = (
  conversation: Conversation,
  userId: string,
  onlyAssigned?: boolean
) => {
  const conversationFilters = getConversationFiltersStore(false);

  if (conversation.status !== 'Assigned') return false;

  if (onlyAssigned) {
    return conversation.assignedUserIds.some((id) => id === userId);
  }

  if (!conversationFilters.selectedChannels[conversation.channel]) {
    // Check if the conversation channel is enabled
    return false;
  }

  // Check if the conversation is a call and if the filter is enabled
  if (
    !conversationFilters.showFinishedCalls &&
    conversation.channel === 'Call' &&
    checkCallIsFinished(conversation)
  ) {
    return false;
  }

  // Check if the textFilter is included in the conversation. Provisional?
  if (
    !checkTextFilterMatchesConversation(
      conversation,
      conversationFilters.textFilter
    )
  ) {
    return false;
  }

  // Check if the showUnreadOnly filter is enabled and if the conversation has unread messages
  if (
    conversationFilters.showUnreadOnly &&
    !conversation.unreadIncomingMessages
  ) {
    // Apply the filter only if the user is the connected user
    if (userId === AuthManager.getUserId()) {
      return false;
    }
  }

  return true;
};

const checkCallIsFinished = (conversation: Conversation): boolean => {
  const call = getRTCallFromConversation(conversation);

  return !call;
};

/** Returns true if textFilter is included in any of the array of values of the conversation.originAgent or the conversation.lastMessage */
const checkTextFilterMatchesConversation = (
  conversation: Conversation,
  textFilter: string
) => {
  if (!textFilter || textFilter?.trim().length === 0) return true;
  const isIncludedInOriginAgent = Object.entries(conversation.originAgent).some(
    ([key, value]) =>
      typeof value === 'string' &&
      conversation.originAgent[key]
        .toLowerCase()
        .includes(textFilter?.trim()?.toLowerCase())
  );
  const isIncludedInLastMessage = conversation.lastMessage
    ? Object.entries(conversation.lastMessage).some(
        ([key, value]) =>
          typeof value === 'string' &&
          conversation.lastMessage[key]
            .toLowerCase()
            .includes(textFilter?.trim()?.toLowerCase())
      )
    : [];

  return isIncludedInOriginAgent || isIncludedInLastMessage;
};
