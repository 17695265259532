import axios, { AxiosResponse } from 'axios';
import {
  CallResultType,
  DialerBotCampaign,
  DialerbotExecutionStatus,
  ExecutionParams
} from 'src/modules/DialerBot/models/dialerbot';
import { replaceURLParams } from 'src/services/utils';
import { DialerBotCampaignsParams } from '../../models/dialerbot';
import { queryClient } from 'src/utils/queryClient';
import { PaginatedElements } from 'src/models/pagination';
import { CAMPAIGNS, CAMPAIGNS_ID, USERS_MS } from 'src/utils/apiRoutes/msUsers';
import {
  EXECUTION_LIST,
  SCHEDULE_DIALERBOT_REPORT
} from 'src/utils/apiRoutes/msCallSchedule';

export const getDialerBotCampaigns: Function = (
  params: DialerBotCampaignsParams
): Promise<DialerBotCampaign[]> => {
  return axios
    .get(CAMPAIGNS, { params })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const createDialerBotCampaigns = async (
  dialerbot: DialerBotCampaign
): Promise<DialerBotCampaign> => {
  const response: AxiosResponse<DialerBotCampaign> = await axios.post(
    CAMPAIGNS,
    dialerbot
  );

  queryClient.invalidateQueries({
    queryKey: ['campaigns']
  });

  return response.data;
};

export const deleteDialerBotCampaigns: Function = (id: string) => {
  const CAMPAIGNS = replaceURLParams(CAMPAIGNS_ID, {
    '{id}': id
  });
  return axios
    .delete(CAMPAIGNS)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getDialerBotCampaignById: Function = (id: string) => {
  const path = replaceURLParams(CAMPAIGNS, {
    '{id}': id
  });

  return axios
    .get(path)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const updateDialerBotCampaigns = async (
  dialerbot: DialerBotCampaign
) => {
  const path = replaceURLParams(CAMPAIGNS_ID, {
    '{id}': dialerbot.id
  });

  delete dialerbot.id;
  const response: AxiosResponse<DialerBotCampaign> = await axios.put(
    path,
    dialerbot
  );

  queryClient.invalidateQueries({
    queryKey: ['campaigns']
  });

  return response.data;
};

export const reportSchedulesDialerbot = (
  campaignId: string,
  name: string,
  uploadedSchedulesId?: string,
  callResult?: CallResultType
) => {
  const params = {
    campaignId,
    name
  };

  if (uploadedSchedulesId) params['uploadedSchedulesId'] = uploadedSchedulesId;
  if (callResult) params['callResult'] = callResult;
  return axios({
    url: SCHEDULE_DIALERBOT_REPORT,
    method: 'GET',
    responseType: 'blob',
    params: params
  })
    .then((response) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getDialerBotsApi = (): Promise<DialerbotExecutionStatus[]> => {
  return axios
    .get(EXECUTION_LIST)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const getExecutionListApi = async (
  executionParams: ExecutionParams
): Promise<PaginatedElements<DialerbotExecutionStatus>> => {
  const params = executionParams;
  const response: AxiosResponse<PaginatedElements<DialerbotExecutionStatus>> =
    await axios.get(EXECUTION_LIST, { params });
  return response.data;
};
export const getDialerBotInformationApi = (
  id: string
): Promise<DialerBotCampaign> => {
  const path = replaceURLParams(CAMPAIGNS_ID, {
    '{id}': id
  });

  return axios
    .get(path)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};
