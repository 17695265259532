import { getMicDevice } from 'src/services/webrtc/domain/getMicDeviceStore';
import { getDevicesStore } from 'src/services/webrtc/infrastructure/store/devices/getDevicesStore';
import WebRTCDevices from 'src/services/webrtc/webrtc-devices';
import { DeviceStorageKey } from './utils/constants';

export const useMicrophone = () => {
  const voiceDevice = getMicDevice(true);

  const mediaDevices = getDevicesStore(true).filter((d) => {
    return d.kind === 'audioinput';
  });

  const onSelectChange = (labelSelected: string): void => {
    const newDevice = mediaDevices.filter((device) => {
      return device.label === labelSelected;
    })[0];

    WebRTCDevices.requestUserDevices({ exactDevices: { audio: newDevice } });

    //setMicDevice(newDevice);

    localStorage.setItem(DeviceStorageKey.VOICE, JSON.stringify(newDevice));
  };

  return [voiceDevice, mediaDevices, onSelectChange] as [
    MediaDeviceInfo,
    MediaDeviceInfo[],
    Function
  ];
};
