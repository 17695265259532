import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import acdRoutes from '../modules/Acd/routes/acdRoutes';
import contactCenterRoutes from '../modules/ContactCenter/routes/contactCenterRoutes';
import managementRoutes from '../modules/Management/routes/managementRoutes';
import operationsRoutes from '../modules/Operations/routes/operationsRoutes';
import configurationRoutes from '../modules/Configurations/routes/configurationRoutes';
import contactsRoutes from '../modules/Contacts/routes/contactsRoutes';
import qualityRoutes from '../modules/Quality/routes/qualityRoutes';
import integrationsRoutes from '../modules/Integrations/routes/integrationsRoutes';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Acd = Loader(lazy(() => import('../modules/Acd')));
const ContactCenter = Loader(lazy(() => import('../modules/ContactCenter')));
const Contacts = Loader(lazy(() => import('../modules/Contacts')));
const General = Loader(lazy(() => import('../modules/General')));
const Integrations = Loader(lazy(() => import('../modules/Integrations')));
const Management = Loader(lazy(() => import('../modules/Management')));
const Operations = Loader(lazy(() => import('../modules/Operations')));
const Quality = Loader(lazy(() => import('../modules/Quality')));
const Configurations = Loader(lazy(() => import('../modules/Configurations')));

const adminRoutes: RouteObject[] = [
  {
    path: 'acd',
    element: <Acd />,
    children: acdRoutes
  },
  {
    path: 'contact-center',
    element: <ContactCenter />,
    children: contactCenterRoutes
  },
  {
    path: 'contacts',
    element: <Contacts />,
    children: contactsRoutes
  },
  // COULD CHANGE
  {
    path: 'settings',
    element: <General />
  },
  {
    path: 'integrations',
    element: <Integrations />,
    children: integrationsRoutes
  },
  {
    path: 'management',
    element: <Management />,
    children: managementRoutes
  },
  {
    path: 'operations',
    element: <Operations />,
    children: operationsRoutes
  },
  {
    path: 'quality',
    element: <Quality />,
    children: qualityRoutes
  },
  {
    path: 'settings',
    element: <Configurations />,
    children: configurationRoutes
  }
];

export default adminRoutes;
