import {
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Ticket } from '../../../models/tickets';
import { useMemo } from 'react';
import { t } from 'i18next';
import { PersonOutline } from '@mui/icons-material';
import ShowflowInlineVariable from './components/ShowflowInlineVariable';
import useShowflowInvolvedContacts from './hooks/useShoflowInvolvedContacts';
import useTicketShowflows from './hooks/useTicketShowflows';

interface Props {
  ticket: Ticket;
}

function ShowFlowTicketViewer({ ticket }: Props) {
  const theme = useTheme();

  const {
    selectedShowflow,
    setSelectedShowflowId,
    ticketShowflows,
    selectedShowflowId
  } = useTicketShowflows(ticket);

  // Contacts involved in the current conversation
  const {
    involvedContacts,
    selectedContact,
    selectedContactId,
    setSelectedContactId
  } = useShowflowInvolvedContacts();

  // Parse the text of the selected showflow to replace the variables (text between $) with components
  const styledText = useMemo(() => {
    if (!selectedShowflow) return <></>;

    const text = selectedShowflow.text;
    const regex = /\$[^$]*\$/g;
    let match: RegExpExecArray;
    let lastIndex = 0;
    const newText = [];

    while ((match = regex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        newText.push(text.slice(lastIndex, match.index));
      }

      const variableId = match[0].slice(1, -1);

      newText.push(
        <ShowflowInlineVariable
          variableId={variableId}
          contact={selectedContact}
          ticket={ticket}
        />
      );

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < text?.length) {
      newText.push(text.slice(lastIndex));
    }

    return newText;
  }, [selectedShowflow, selectedContact]);

  function LegendItem({ color, label }) {
    return (
      <Box display="flex" flexDirection={'row'} alignItems={'center'} gap={0.5}>
        <Box
          sx={{
            height: '12px',
            width: '12px',
            background: color,
            borderRadius: '50%'
          }}
        ></Box>
        <Typography>{label}</Typography>
      </Box>
    );
  }

  return (
    <Stack direction={'column'} spacing={2} pt={2}>
      <TextField
        size="small"
        select
        label={t('Script')}
        value={selectedShowflowId}
        onChange={(e) => setSelectedShowflowId(e.target.value)}
      >
        {ticketShowflows.map((showflow) => (
          <MenuItem key={showflow.id} value={showflow.id}>
            {showflow.name}
          </MenuItem>
        ))}
      </TextField>
      <Stack
        direction="column"
        style={{
          width: '100%',
          padding: '1rem',
          border: '1px solid #e0e0e0',
          backgroundColor: '#F6F8FB',
          borderRadius: '6px'
        }}
        spacing={2}
      >
        <TextField
          size="small"
          select
          label={t('Contact')}
          value={selectedContactId}
          key={selectedContactId}
          onChange={(e) => setSelectedContactId(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: selectedContactId ? <PersonOutline /> : null
          }}
        >
          {involvedContacts?.map((contact, idx) => (
            <MenuItem key={contact.id} value={contact.id}>
              {contact.label?.value}
            </MenuItem>
          ))}
        </TextField>
        {!selectedShowflowId && (
          <Typography variant="h4" alignSelf={'center'}>
            {t('Select a script to see the content')}
          </Typography>
        )}
        {selectedShowflow && (
          <Typography
            variant="body1"
            height={'max-content'}
            whiteSpace={'pre-wrap'} // to show new lines \n
          >
            {styledText}
          </Typography>
        )}
      </Stack>
      {/* Leyenda con información del color asociado a cada variable */}
      <Stack direction="row" columnGap={2} flexWrap={'wrap'}>
        <LegendItem
          color={theme.palette.success.main}
          label={t('Call variable')}
        />
        <LegendItem color={'#D962D7'} label={t('Company variable')} />

        <LegendItem
          color={theme.palette.secondary.main}
          label={t('Contact field')}
        />
        <LegendItem
          color={theme.palette.warning.main}
          label={t('Ticket field')}
        />
      </Stack>
    </Stack>
  );
}

export default ShowFlowTicketViewer;
