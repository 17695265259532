import AuthManager from '../manager';
import { DEV_COMPANY_SHORTCODE } from 'src/utils/apiRoutes/msApiGateway';
import { loginApi } from '../infraestructure/loginApi';
import { logger } from 'src/utils/logger';
import { AuthData } from 'src/models/auth';

export const getCompanyShortCode = () => {
  // Obtain shortcode of the company (if any)
  const hostname = window.location.hostname;
  const splitHostname = hostname.split('.');

  let companyShortcode = null;
  const shortCode = splitHostname[0];
  if (shortCode === 'localhost') companyShortcode = DEV_COMPANY_SHORTCODE;
  else if (hostname.includes('ngrok-free'))
    companyShortcode = DEV_COMPANY_SHORTCODE;
  else companyShortcode = shortCode;
  return companyShortcode;
};

export const login = async (params: {
  password: string;
  username: string;
  withExtension: boolean;
  doubleFactorCode?: string;
}) => {
  const companyShortcode = getCompanyShortCode();
  try {
    const resp = await loginApi({
      ...params,
      companyShortcode
    });

    if (resp.token) {
      AuthManager.setToken(resp.token, resp.expiration);
      AuthManager.setExpiration(resp.expiration);
      AuthManager.setUserId(resp.userId, resp.expiration);
      AuthManager.setLoggedUserCompanyId(resp.companyId);
      AuthManager.setUserExtension(resp.voipData?.extension);
      AuthManager.setUsername(params.username);
    }
    if (!!resp.voipData) {
      localStorage.setItem('extensionData', JSON.stringify(resp.voipData));
    } else {
      localStorage.removeItem('extensionData');
    }
    const currentCalls = [];
    localStorage.setItem('currentData', JSON.stringify(currentCalls));
    localStorage.setItem('selectedCall', '');
    localStorage.setItem('imageSGI', '');

    return resp;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
