import {
  Box,
  Button,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useTheme
} from '@mui/material';
import { useContext, useState } from 'react';
import { useAlert } from 'src/hooks/useAlert';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkUploadContactPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { t } from 'i18next';
import { UploadCSVContacts } from './components/UploadCSVContacts';
import { LinkContactFieldsWithCSV } from './components/LinkFieldsWithCSV';
import { LinkTagsWithCSV } from './components/LinkTagsWithCSV';
import { ContactsDomain } from 'src/modules/Contacts/domain/ContactsDomain';
import { emitCustomEvent } from 'react-custom-events';
import { UploadCsvParameters, UploadCsvResponse } from './models/models';
import Scrollbar from 'src/components/Scrollbar';

interface Props {
  onClose: () => void;
}

const defaultParameters: UploadCsvParameters = {
  csvDelimiter: undefined,
  file: null,
  fieldMapping: {},
  ignoreInvalid: false,
  contactGroupId: '',
  createTagWhenNotFound: undefined,
  tagsColumn: undefined,
  tagsDelimiter: undefined,
  identificationFieldId: ''
};

const formSteps = [
  {
    label: 'Upload CSV',
    value: 1
  },
  {
    label: 'Contact fields',
    value: 2
  },
  {
    label: 'Contact tags',
    value: 3
  }
];

const UploadCSVContactsForm = (props: Props) => {
  const { onClose } = props;

  const { hasAccess } = useContext(PermissionsContext);
  const hasUploadContactAccess = hasAccess(checkUploadContactPermissions);

  const [uploadCsvParameters, setUploadCsvParameters] =
    useState(defaultParameters);

  const [step, setStep] = useState(formSteps[0]);

  const isFirstStep = step.value === 1;
  const isLastStep = step.value === formSteps.length;
  const isSummary = step.value === 4;

  function nextStep() {
    setStep(formSteps[step.value]);
  }

  function previousStep() {
    setStep(formSteps[step.value - 2]);
  }

  const theme = useTheme();
  const { showAlert } = useAlert();

  const stepStyle = {
    '& .MuiStepIcon-root.Mui-active': {
      color: theme.colors.secondary.main
    },
    '& .MuiStepIcon-root.Mui-completed': {
      color: theme.colors.success.main
    },
    '& .MuiStepConnector-line': {
      color: theme.colors.secondary.main
    }
  };

  const nextStepDisabled =
    !uploadCsvParameters.file ||
    !hasUploadContactAccess ||
    (step.value === 2 &&
      (!uploadCsvParameters.contactGroupId ||
        !uploadCsvParameters.identificationFieldId));

  async function onUploadCSVContacts(uploadParams: UploadCsvParameters) {
    try {
      const success = await ContactsDomain.uploadCSVContacts(uploadParams);
      if (success) {
        showAlert(t('Uploading contacts...'), 'info', 3000);
        onClose();
      } else {
        showAlert(t('Error uploading CSV'), 'error', 3000);
      }
      //showAlert(t('Contacts uploaded successfully'), 'success', 3000);
      emitCustomEvent('table-data-refetch');
      //onClose();
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      showAlert(errorMessage.errorMessage, 'error', 3000);
    }
  }

  return (
    <Stack
      height={'100%'}
      direction={'column'}
      sx={{ flex: 2, display: 'flex', flexDirection: 'column' }}
    >
      <Stack px={2} pt={1} pb={2} sx={{ flex: 2 }}>
        <Scrollbar>
          <Stack>
            <Stepper
              activeStep={step.value - 1}
              sx={{
                ...stepStyle,
                paddingTop: 1
              }}
            >
              {formSteps.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{t(step.label)}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box flexGrow={2}>
              {step.label === 'Upload CSV' && (
                <UploadCSVContacts
                  uploadCsvParameters={uploadCsvParameters}
                  setUploadCsvParameters={setUploadCsvParameters}
                />
              )}
              {step.label === 'Contact fields' && (
                <LinkContactFieldsWithCSV
                  uploadCsvParameters={uploadCsvParameters}
                  setUploadCsvParameters={setUploadCsvParameters}
                />
              )}
              {step.label === 'Contact tags' && (
                <LinkTagsWithCSV
                  uploadCsvParameters={uploadCsvParameters}
                  setUploadCsvParameters={setUploadCsvParameters}
                />
              )}
            </Box>
          </Stack>
        </Scrollbar>
      </Stack>
      <Divider />
      <Stack
        px={1.5}
        py={1.5}
        width="100%"
        direction={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={'space-between'}
        spacing={1.5}
      >
        <Button onClick={previousStep} disabled={isFirstStep}>
          {t('Back')}
        </Button>
        <Button
          variant={isLastStep || isSummary ? 'contained' : 'outlined'}
          color="secondary"
          onClick={() => {
            if (isLastStep) {
              onUploadCSVContacts(uploadCsvParameters);
            } else if (isSummary) {
              onClose();
            } else {
              nextStep();
            }
          }}
          disabled={nextStepDisabled}
        >
          {isSummary ? t('Close') : isLastStep ? t('Submit') : t('Next')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default UploadCSVContactsForm;
