import { PaginatedElements, PaginationValues } from 'src/models/pagination';
import { Option } from './option';
import { ContactQualificationType } from 'src/modules/Productivity/modules/ContactQualification/domain/getReportingContactQualification';

export interface DialTableFilter<T> extends AvailableFilter<T> {
  operator: Operator;
  values: string[];
  label?: string;
  options?: DialTableFilterValueOptions;
}

export interface AvailableFilter<T> {
  fieldName: keyof T | string;
  /** key to retrieve and display the options of the filter */
  dataCategory: DataCategory | null;
  fieldDataType: FieldDataType;
  /** key to display the input */
  fieldType: FieldType;
  required: boolean;
}

// export interface Filter<T> {
//   field: keyof T | string;
//   dataType: FieldDataType;
//   operator: Operator;
//   values: string[];
// }

export interface Filter {
  field: string;
  dataType: FieldDataType;
  operator: Operator;
  values: string[];
  type?: ContactQualificationType;
}

export enum DataCategory {
  ACCOUNT_ID = 'ACCOUNTID',
  AGENT_ID = 'AGENTID',
  AGENT_TYPE = 'AGENTTYPE',
  CALL_MODE = 'CALLMODE',
  CALL_RESULT = 'CALLRESULT',
  CAMPAIGN_ID = 'CAMPAIGNID',
  CHANNEL = 'CHANNEL',
  COMPANY_ID = 'COMPANYID',
  CONTACT_GROUP_ID = 'CONTACTGROUPID',
  CONTACT_ID = 'CONTACTID',
  CONTACT_TAG_ID = 'CONTACTAGID',
  CONVERSATION_SUMMARIES_ID = 'CONVERSATIONSUMMARIESID',
  DATABASE_ID = 'DATABASEID',
  DATE = 'DATE',
  EXTENSION_ID = 'EXTENSIONID',
  SERVICE_ID = 'SERVICEID',
  STATUS = 'STATUS',
  SUBRESOLUTION_ID = 'SUBRESOLUTIONID',
  TAG_ID = 'TAGID',
  TRUE_OR_FALSE_OR_BOTH = 'TRUEORFALSEORBOTH',
  TYPE = 'TYPE',
  UPLOAD_STATUS = 'UPLOADSTATUS',
  USER_STATUS_NAME = 'USERSTATUSNAME',
  USER_STATUS_REASON = 'USERSTATUSREASON',
  USER_STATUS_TYPE = 'USERSTATUSTYPE',
  VARIABLES = 'VARIABLES',
  WHATSAPP_AGENT_TYPE = 'WHATSAPPAGENTTYPE'
}

export enum FieldDataType {
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  OBJECTID = 'OBJECTID',
  /** with no operator */
  MULTIPLE = 'MULTIPLE'
}

export enum FieldType {
  TEXT = 'TEXT',
  SELECTOR = 'SELECTOR',
  MULTI_SELECTOR = 'MULTI_SELECTOR',
  SWITCH = 'SWITCH'
}

export type Operator =
  | IntegerOperator
  | DoubleOperator
  | DateOperator
  | BooleanOperator
  | StringOperator
  | ObjectIdOperator;

export enum IntegerOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATHER_THAN = 'GREATER_THAN',
  GREATHER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS'
}

export const integerOperators = Object.values(IntegerOperator);

export enum DoubleOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATHER_THAN = 'GREATER_THAN',
  GREATHER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS'
}
export const doubleOperators = Object.values(IntegerOperator);

export enum DateOperator {
  BETWEEN = 'BETWEEN',
  NOT_NULL = 'NOT_NULL',
  GREATHER_THAN = 'GREATER_THAN',
  GREATHER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS'
}
export const dateOperators = Object.values(DateOperator);

export enum BooleanOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS'
}
export const booleanOperators = Object.values(BooleanOperator);

export enum StringOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  CONTAINS = 'CONTAINS'
}
export const stringOperators = Object.values(StringOperator);

export enum ObjectIdOperator {
  EQUALS = 'EQUALS'
}

export const objectIdOperators = Object.values(ObjectIdOperator);

export interface DialTableFilterValueOptions {
  getValueOptionFn?: (value: string) => Promise<Option>;
  getValueOptionListFn: (
    pagination: PaginationValues
  ) => Promise<PaginatedElements<Option>>;
}

export interface CustomFilterValueOptions {
  getValueOptionFn: (
    dataCategory: DataCategory,
    value: string
  ) => Promise<Option>;
  getValueOptionListFn: (
    dataCategory: DataCategory,
    companyId: string,
    input?: string
  ) => Promise<PaginatedElements<Option>>;
}

export interface FiltersObject {
  [filterKey: string]: string[];
}
