import { CloseTwoTone } from '@mui/icons-material';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { ForwardedRef, forwardRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Variable, VariableScope } from '../../models/models';
import { createVariableDomain } from '../../domain/createVariableDomain';
import { emitCustomEvent } from 'react-custom-events';
import { useAlert } from 'src/hooks/useAlert';
import { updateVariableDomain } from '../../domain/updateVariableDomain';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreateVariablePermissions,
  checkUpdateVariablePermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import { queryClient } from 'src/utils/queryClient';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import Scrollbar from 'src/components/Scrollbar';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  variable?: Variable;
  callbackAfterCreation?: (variable: Variable) => void;
  companyId: string;
}

export const CreateUpdateVariableModal = forwardRef(
  (
    { isOpen, setIsOpen, variable, callbackAfterCreation, companyId }: Props,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const { hasAccess } = useContext(PermissionsContext);
    const hasVariableAccess =
      (!!variable && hasAccess(checkUpdateVariablePermissions)) ||
      (!variable && hasAccess(checkCreateVariablePermissions));
    const [name, setName] = useState(variable?.name ?? '');
    const [description, setDescription] = useState(variable?.description ?? '');
    const [value, setValue] = useState(variable?.value ?? '');
    const [scope, setScope] = useState(
      variable?.scope ?? ('Conversation' as VariableScope)
    );
    const { t } = useTranslation();

    const { showAlert } = useAlert();

    const onClose = () => {
      setIsOpen(false);
      setName('');
      setDescription('');
      setValue('');
      setScope('Conversation');
    };

    function handleCreate() {
      createVariableDomain(companyId, scope, value, name, description)
        .then((variable) => {
          emitCustomEvent(REFETCH_DATA_EVENT_NAME);
          setIsOpen(false);
          setName('');
          setValue('');
          showAlert(t('Variable created successfully'), 'success', 3000);
          callbackAfterCreation?.(variable);
          queryClient.invalidateQueries({
            queryKey: ['variablesAcd']
          });
        })
        .catch((err) => {
          showAlert(t('Error creating variable'), 'error', 3000);
        });
    }

    function handleUpdate() {
      updateVariableDomain(value, name, variable.id, description)
        .then(() => {
          emitCustomEvent(REFETCH_DATA_EVENT_NAME);
          setIsOpen(false);
          showAlert(t('Variable updated successfully'), 'success', 3000);
        })
        .catch(() => {
          showAlert(t('Error updating variable'), 'warning', 3000);
        });
    }

    return (
      <Popover
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        anchorReference="anchorEl"
        // @ts-ignore
        anchorEl={ref?.current}
        anchorOrigin={{
          // @ts-ignore
          horizontal: ref?.current ? 'left' : 'right',
          vertical: 'top'
        }}
      >
        <Card sx={{ height: '55vh', display: 'flex', flexDirection: 'column' }}>
          <CardHeader
            title={variable ? t('Edit variable') : t('Create variable')}
            subheader={t(
              `Use this dialog window to ${
                variable ? 'update a' : 'add a new'
              } variable`
            )}
            action={
              <IconButton
                sx={{
                  m: 1
                }}
                size="small"
                color="secondary"
                onClick={onClose}
              >
                <CloseTwoTone />
              </IconButton>
            }
          />
          <Divider />
          <Stack height="100%">
            <Stack
              direction={'column'}
              alignItems={'center'}
              px={2}
              pb={2}
              spacing={2}
              height="100%"
            >
              <Scrollbar>
                <Stack>
                  {variable && (
                    <Typography
                      alignSelf={'flex-start'}
                      variant={'h5'}
                      pb={1}
                      pt={2}
                    >
                      {variable.scope === 'Conversation'
                        ? t('Conversation variable')
                        : t('Company variable')}
                    </Typography>
                  )}
                  <TextField
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    id={'nameField'}
                    label={t('Name')}
                    sx={{ mt: 2 }}
                    fullWidth
                  />
                  <TextField
                    multiline
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    sx={{ mt: 2 }}
                    id={'descriptionName'}
                    label={t('Description')}
                    fullWidth
                  />
                  {!variable && (
                    <TextField
                      value={scope}
                      sx={{ mt: 2 }}
                      onChange={(e) => {
                        setScope(e.target.value as VariableScope);
                      }}
                      select
                      id={'nameField'}
                      label={t('Scope')}
                      fullWidth
                    >
                      <MenuItem value={'Conversation'}>
                        {t('Conversation')}
                      </MenuItem>
                      <MenuItem value={'Company'}>{t('Company')}</MenuItem>
                    </TextField>
                  )}

                  <TextField
                    value={value}
                    sx={{ mt: 2 }}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    id={'valueField'}
                    label={
                      scope === 'Company' ? t('Value') : t('Initial value')
                    }
                    fullWidth
                  />
                </Stack>
              </Scrollbar>
            </Stack>
            <Divider />
            <Stack
              px={1.5}
              py={1.5}
              width="100%"
              direction={{ xs: 'column-reverse', sm: 'row' }}
              justifyContent={'space-between'}
              spacing={1.5}
            >
              <Button
                variant="outlined"
                onClick={onClose}
                color="secondary"
                fullWidth
              >
                {t('Cancel')}
              </Button>
              <PermissionsTooltip hasAccess={hasVariableAccess}>
                <Button
                  id="createVariableBtn"
                  variant="contained"
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    const action = variable ? handleUpdate : handleCreate;
                    action();
                  }}
                  disabled={!hasVariableAccess}
                >
                  {variable ? t('Update') : t('Create')}
                </Button>
              </PermissionsTooltip>{' '}
            </Stack>
          </Stack>
        </Card>
      </Popover>
    );
  }
);
