//prettier-ignore
import { Button, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import {
  CTIBarContext,
  NewConversationMenu,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import CallManagerDomain from 'src/modules/CTI/CallManager';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { setActiveRTCallId } from 'src/services/rtusers/domain/rtuser/setActiveRTCall';
import { getNotificationsOffDomain } from 'src/services/users/domain/getNotificationsOffDomain';
import { useAlert } from 'src/hooks/useAlert';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import PhoneInput from 'src/components/PhoneInput';
import { unformatPhoneNumber } from 'src/utils/formatPhoneNumber';
import { getCountryCodeFromOutboundCampaign } from 'src/modules/CTI/domain/utils';
import { setCampaignSelector } from '../utils/setCampaignSelector';
import setAutoselectNextConversationDomain from 'src/services/socialmedia/domain/conversation/setAutoselectNextConversationDomain';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { CallType } from 'src/modules/Admin/modules/Configurations/modules/CallerIds/models/callerId';

export const callKeysCTI = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['*', '0', '#']
];

//Do not let press call button multiple times
let buttonDisabled = false;

interface Props {
  /** Default is true */
  useCTIBarContext?: boolean;
  /** ignored if useCTIBarContext equals to true */
  inputValue?: string;
  /** ignored if useCTIBarContext equals to true */
  setInputValue?: Dispatch<SetStateAction<string>>;
}

export const CallConversation = ({
  inputValue: inputValueProps,
  setInputValue: setInputValueProps,
  useCTIBarContext = true
}: Props) => {
  const connectedRTUser = getConnectedRTUser();
  const doNotDisturb = getNotificationsOffDomain(true);
  const campaignsQuery = useUserCampaignsQuery(true);

  const { t } = useTranslation();
  const { userHasExtension } = useContext(CTIBarContext);
  const { selectedTicket } = useDialpadStateContext();

  const {
    inputValue: inputValueContext,
    setInputValue: setInputValueContext,
    conversationSelected,
    setConversationSelected,
    callType,
    setCallType,
    countryCode,
    setCountryCode
  } = useDialpadStateContext();

  const [inputValue, setInputValue] = useCTIBarContext
    ? [inputValueContext, setInputValueContext]
    : [inputValueProps, setInputValueProps];

  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const theme = useTheme();
  const { showAlert } = useAlert();
  const [countryCallingCode, setCountryCallingCode] = useState('');

  const ticketId = selectedTicket?.id;

  const setCountryCodeFromCampaignId = (campaignId: string) => {
    const campaign = campaigns.find((campaign) => campaign.id === campaignId);
    const code = getCountryCodeFromOutboundCampaign(campaign);
    if (code) {
      setCountryCode(code);
    }
  };

  const onChangeSelect = (e) => {
    const campaignId = e.target.value;
    setSelectedCampaignId(campaignId);
  };

  const onLaunchSuccessCall = () => {
    setAutoselectNextConversationDomain(true);
    if (useCTIBarContext) {
      emitCustomEvent('Close popover'); //Close dialpad
    }
    setActiveRTCallId(null);
    setInputValue('');
    showAlert('Call launched!', 'success', 3000);
  };

  const handleLaunchCall = (e) => {
    if (doNotDisturb) {
      return showAlert(
        'Disable do not disturb mode to launch calls',
        'warning',
        3000
      );
    }
    if (inputValue?.length > 0 && !Number.isNaN(parseInt(inputValue))) {
      buttonDisabled = true;
      const phoneNumber = unformatPhoneNumber(inputValue);
      CallManagerDomain.launchCall({
        campaignId: selectedCampaignId,
        phone: phoneNumber,
        extension: connectedRTUser?.extension?.extension,
        isInternal: callType === 'Internal',
        ticketId,
        isSpecial: callType === 'Special'
      })
        .then((response) => {
          if (response.status === 200) {
            onLaunchSuccessCall();
          } else {
            showAlert(
              t(response?.data?.message ?? 'Error launching the call'),
              'error',
              3000
            );
          }
        })
        .catch((error) => {
          showAlert(
            t(
              getErrorMessage(error)?.errorMessage ?? 'Error launching the call'
            ),
            'error',
            3000
          );
        })
        .finally(() => {
          buttonDisabled = false;
        });
    }
  };
  const onEnterDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (
      !buttonDisabled &&
      e.key === 'Enter' &&
      inputValue?.length > 0 &&
      !Number.isNaN(parseInt(inputValue))
    )
      handleLaunchCall(e);
  };

  useEffect(() => {
    setCampaignSelector(
      'selectedCampaign',
      setCampaigns,
      setSelectedCampaignId,
      campaignsQuery?.data?.elements,
      'Call',
      selectedTicket?.serviceId
    );
  }, [campaignsQuery?.dataUpdatedAt]);

  useEffect(() => {
    if (selectedCampaignId) {
      localStorage.setItem('selectedCampaign', selectedCampaignId);
      setCountryCodeFromCampaignId(selectedCampaignId);
    }
  }, [selectedCampaignId]);

  useEffect(() => {
    if (conversationSelected === 'keyboard' && !userHasExtension) {
      setConversationSelected(NewConversationMenu.Telegram);
    }
  }, [conversationSelected, userHasExtension]);

  useCustomEventListener(
    'Set call data',
    (data: { phone: string; callType: CallType; campaignId: string }) => {
      setInputValue(data.phone);
      setCallType(data.callType);
      if (data.campaignId) {
        setSelectedCampaignId(data.campaignId);
      } else {
        setCampaignSelector(
          'selectedCampaign',
          setCampaigns,
          setSelectedCampaignId,
          campaignsQuery?.data?.elements,
          'Call',
          selectedTicket?.serviceId
        );
      }
    }
  );

  const disableCallButton = inputValue === '' || inputValue.length <= 2;

  return (
    <>
      {doNotDisturb ? (
        <h3>{t('Do not disturb enabled')}</h3>
      ) : (
        <Stack direction="column" alignItems={'center'}>
          <PhoneInput
            autoFocus
            label={null}
            size="small"
            phoneNumber={inputValue}
            setPhoneNumber={setInputValue}
            callType={callType}
            setCallType={setCallType}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            onKeyDown={onEnterDown}
            sx={{ width: '300px' }}
            countryCallingCode={countryCallingCode}
            setCountryCallingCode={setCountryCallingCode}
          />
        </Stack>
      )}
      <Stack
        direction={'column'}
        justifyContent="center"
        alignItems="center"
        width={240}
      >
        {callKeysCTI &&
          callKeysCTI.map((g, idx) => {
            return (
              <Stack
                direction="row"
                key={idx}
                width={'100%'}
                justifyContent="space-around"
              >
                {g.map((k) => {
                  return (
                    <Button
                      key={k}
                      sx={{ fontSize: '20px' }}
                      onClick={() =>
                        setInputValue((oldValue: string) => {
                          if (oldValue === '' && callType === 'External')
                            oldValue = countryCallingCode;
                          return oldValue + k;
                        })
                      }
                      id="btnK-DialPad"
                    >
                      {k}
                    </Button>
                  );
                })}
              </Stack>
            );
          })}
      </Stack>
      {campaigns.length > 0 ? (
        <Select
          sx={{ height: '40px', width: '240px' }}
          value={selectedCampaignId}
          onChange={onChangeSelect}
          id="select-DialPad"
        >
          {campaigns.map((campaign) => {
            return (
              <MenuItem key={campaign.id} value={campaign.id}>
                {campaign.name}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontSize: '16px',
            textAlign: 'center'
          }}
        >
          {t('Not assigned to any campaign')}
        </Typography>
      )}

      <Button
        id="btnCall-DialPad"
        disabled={disableCallButton}
        variant={'contained'}
        color="success"
        onClick={(e) => {
          if (buttonDisabled) return;
          handleLaunchCall(e);
        }}
        style={{
          border: disableCallButton ? `1px solid rgba(34, 51, 84, 0.3)` : 'none'
        }}
      >
        {t('CallAction')}
      </Button>
    </>
  );
};
