import axios, { AxiosResponse } from 'axios';
import { CallModeTypes } from 'src/models/users';
import { replaceURLParams } from 'src/services/utils';
import {
  CAMPAIGNS,
  CAMPAIGNS_ID_ENABLE,
  CAMPAIGNS_ID,
} from 'src/utils/apiRoutes/msUsers';
import { Campaign } from '../../models/campaigns';
import { PaginatedElements } from 'src/models/pagination';
import { DirectionType } from 'src/components/TableReporting/models/direction';

export interface CampaignsParams {
  callMode?: CallModeTypes;
  companyId?: string;
  ids?: string[];
  name?: string;
  page?: number;
  serviceId?: string;
  size?: number;
  sortBy?: keyof Campaign;
  sortDir?: DirectionType;
  userId?: string;
}

export const getUnknownCampaignById = async (
  campaignId: string
): Promise<unknown> => {
  const path = replaceURLParams(CAMPAIGNS_ID, { '{id}': campaignId });
  const response: AxiosResponse<unknown> = await axios.get(path);
  return response.data;
};

export const getCampaignsApi = async (campaignsParams: CampaignsParams) => {
  const resp = await axios.get<PaginatedElements<Campaign>>(CAMPAIGNS, {
    params: campaignsParams,
    paramsSerializer: { indexes: null },
  });
  return resp.data;
};

/** OBSOLETE */
export const getCampaignById: Function = (id: string) => {
  const path = replaceURLParams(CAMPAIGNS_ID, {
    '{id}': id,
  });

  return axios
    .get(path)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response;
    });
};

export const enableCampaigns: Function = (id: string, enabled: boolean) => {
  const path = replaceURLParams(CAMPAIGNS_ID_ENABLE, {
    '{id}': id,
    '{enable}': enabled,
  });

  return axios
    .post(path)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error.response;
    });
};
