import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  Extension,
  ExtensionsRange
} from 'src/modules/Admin/modules/Configurations/modules/Extensions/models/Extension';
import { PaginatedElements } from 'src/models/pagination';
import {
  ExtensionDto,
  ExtensionDtoRanges
} from 'src/modules/Admin/modules/Configurations/modules/Extensions/models/ExtensionDto';
import {
  EXTENSIONS,
  EXTENSIONS_ASSIGN,
  EXTENSIONS_ID,
  EXTENSIONS_RANGES
} from 'src/utils/apiRoutes/msAcd';
import { replaceURLParams } from 'src/services/utils';
import { DirectionType } from 'src/components/TableReporting/models/direction';

export interface ExtensionsRequestParams {
  companyId?: string;
  complexExtension?: string;
  enabled?: boolean;
  extension?: string;
  groupId?: string;
  id?: string;
  inUse?: boolean;
  page?: number;
  size?: number;
  sortBy?: keyof Extension;
  sortDir?: DirectionType;
}

export const getExtensionsByParams = async (
  params: ExtensionsRequestParams
): Promise<PaginatedElements<Extension>> => {
  const response = await axios.get(EXTENSIONS, { params });
  return response.data;
};

export const postExtension = async (
  extensionDto: ExtensionDto
): Promise<Extension> => {
  try {
    const response: AxiosResponse<Extension> = await axios.post(
      EXTENSIONS,
      extensionDto
    );
    return response.data;
  } catch (exception: unknown) {
    throw exception as AxiosError;
  }
};

export const postExtensionsByRanges = async (
  extensionDtoRanges: ExtensionDtoRanges,
  firstExtension: number,
  lastExtension: number
): Promise<Extension[]> => {
  const response: AxiosResponse<Extension[]> = await axios.post(
    EXTENSIONS_RANGES,
    extensionDtoRanges,
    {
      params: {
        firstExtension,
        lastExtension
      }
    }
  );
  return response.data;
};

export const putExtension = async (
  extension: Extension
): Promise<Extension> => {
  const path = replaceURLParams(EXTENSIONS_ID, { '{id}': extension.id });

  const response = await axios.put(path, {
    enabled: extension.enabled,
    external: extension.external,
    groupIds: extension.groupIds
  });
  return response.data;
};

export const deleteExtension = (extensionId: string): Promise<boolean> => {
  const path = replaceURLParams(EXTENSIONS_ID, { '{id}': extensionId });

  return axios
    .delete(path)
    .then((response: AxiosResponse) => {
      return true;
    })
    .catch((error: unknown) => {
      if (error instanceof AxiosError) throw error;
      return false;
    });
};

export const getOccupiedExtensionsByCompanyId = async (
  companyId: string
): Promise<ExtensionsRange> => {
  const response: AxiosResponse<ExtensionsRange> = await axios.get(
    EXTENSIONS_RANGES,
    {
      params: { companyId: companyId }
    }
  );
  return response.data;
};

export const putAssignExtensionsByRanges = async (
  companyId: string,
  groupId: string,
  firstExtension: number,
  lastExtension: number
): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await axios.put(
    EXTENSIONS_ASSIGN,
    null,
    {
      params: {
        assign: true,
        companyId,
        groupId,
        firstExtension,
        lastExtension
      }
    }
  );
  return response.data;
};
