export enum DaServiceModifyClosedTypes {
  ALL_EXCEPT_POSITIVE = 'AllExceptPositive',
  NO = 'No',
  YES = 'Yes'
}

export const daServiceModifyClosedOptions = Object.values(
  DaServiceModifyClosedTypes
);

export interface DaService {
  companyId: string;
  contactGroupId: string;
  defaultTicketSubresolutionId: string | null;
  description: string;
  enabled: boolean;
  externalActionId: string;
  id: string;
  maxAssignedConvsPerAgent: number;
  maxOpenTicketsPerContact: number;
  metadata: {
    creationDate: string;
    modificationDate: string;
  };
  modifyClosed: DaServiceModifyClosedTypes;
  name: string;
  recordingNamePattern: string;
  robinsonList: string[];
  timeZone: string;
  userRoles: string[];
  users: string[];
  companyName?: string;
  contactGroupName?: string;
  allowCloseConvOnOpenTickets: boolean;
  openInboxOnCall: boolean;
  openInboxOnConversation: boolean;
}
