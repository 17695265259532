import JsSIP from 'jssip';
import { ExtensionData } from './ExtensionData';
import WebRTCNotifications from './notifications/webrtc-notifications';
import WebRTCCallManagement from './webrtc-call-management';
import { requestUserDevices } from './webrtc-devices';
import { emitCustomEvent } from 'react-custom-events';
import { DisconnectEvent } from 'jssip/lib/WebSocketInterface';
import store from 'src/store/store';
import { removeRTCSession } from './domain/removeRTCSession';
import { addRTCSession } from './domain/addRTCSession';
import { WebRTCSession } from 'src/store/slices/calls/types';
import { getCallerIdDataFromSession } from './domain/utils';
import { getAutoanswer } from './domain/getAutoanswer';
import { setNextOutgoingCall } from '../rtusers/domain/rtcall/setNextOutgoingCall';
import { getNotificationsOffDomain } from '../users/domain/getNotificationsOffDomain';
import {
  setAudioDevice,
  setRingDevice,
  setVoiceDevice
} from 'src/store/slices/users/configVolumeSlice';
import { checkSessionQueueIsMuted } from '../rtusers/domain/queues/mutedQueues';
import { getConnectedRTUser } from '../rtusers/domain/rtuser/getConnectedRTUser';
import { logger } from 'src/utils/logger';
import snackbar from 'src/utils/snackbar';
import { getCampaignsQuery } from '../users/application/query/getUsersDataQueries';
import {
  CallCampaign,
  CallMode
} from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { RTCSession } from 'jssip/lib/RTCSession';
import { getActiveRTCall } from '../rtusers/domain/rtuser/getActiveRTCall';

import { t } from 'i18next';

const WEBRTC_ADDRESS = process.env.REACT_APP_WEBRTC_ADDRESS as string;
const SIP_ADDRESS = process.env.REACT_APP_SIP_ADDRESS as string;

let WEBCTC_COOL_PHONE: JsSIP.UA = null;

let WEBRTC_SOCKET: JsSIP.WebSocketInterface;

const initWebRTC = (extensionData: ExtensionData) => {
  //JsSIP.debug.enable("JsSIP:*");
  if (WEBRTC_SOCKET?.isConnecting() || WEBRTC_SOCKET?.isConnected()) {
    return WEBRTC_SOCKET;
  }

  WEBRTC_SOCKET = connectWS();
  WEBCTC_COOL_PHONE = registerExtension(WEBRTC_SOCKET, extensionData);

  connectAllDevices();

  setRingTones();

  return WEBRTC_SOCKET;
};

navigator.mediaDevices?.addEventListener('devicechange', () => {
  connectAllDevices();
});

//This function detect if the user selected devices are connected, and connect them.
const connectAllDevices = () => {
  //Load microphone device
  connectMicrophone();

  //Load call audio device
  connectDevices(
    'audioDevice',
    ['webrtc-audio-remote', 'webrtc-answer', 'webrtc-hangup'],
    setAudioDevice
  );

  //Load ring audio device
  connectDevices('ringDevice', ['webrtc-ring'], setRingDevice);
};

const setRingTones = () => {
  const telegramSoundPathKey = 'telegramSoundPath';
  const whatsappSoundPathKey = 'whatsappSoundPath';
  const emailSoundPathKey = 'emailSoundPath';
  const webchatSoundPathKey = 'webchatSoundPath';
  const ringtoneSoundPathKey = 'ringtoneSoundPath';

  const telegramRing = document.getElementById(
    'socialmedia-message-notification-Telegram'
  ) as HTMLMediaElement;
  const whatsappRing = document.getElementById(
    'socialmedia-message-notification-WhatsApp'
  ) as HTMLMediaElement;
  const emailRing = document.getElementById(
    'socialmedia-message-notification-Email'
  ) as HTMLMediaElement;
  const webchatRing = document.getElementById(
    'socialmedia-message-notification-WebChat'
  ) as HTMLMediaElement;
  const ringtone = document.getElementById('webrtc-ring') as HTMLMediaElement;

  telegramRing.src = localStorage.getItem(telegramSoundPathKey);
  if (localStorage.getItem(telegramSoundPathKey) === null) {
    telegramRing.src = '/sounds/messenger.mp3';
    localStorage.setItem(telegramSoundPathKey, '/sounds/messenger.mp3');
  }
  whatsappRing.src = localStorage.getItem(whatsappSoundPathKey);
  if (localStorage.getItem(whatsappSoundPathKey) === null) {
    whatsappRing.src = '/sounds/messenger.mp3';
    localStorage.setItem(whatsappSoundPathKey, '/sounds/messenger.mp3');
  }
  emailRing.src = localStorage.getItem(emailSoundPathKey);
  if (localStorage.getItem(emailSoundPathKey) === null) {
    emailRing.src = '/sounds/messenger.mp3';
    localStorage.setItem(emailSoundPathKey, '/sounds/messenger.mp3');
  }
  webchatRing.src = localStorage.getItem(webchatSoundPathKey);
  if (localStorage.getItem(webchatSoundPathKey) === null) {
    webchatRing.src = '/sounds/messenger.mp3';
    localStorage.setItem(webchatSoundPathKey, '/sounds/messenger.mp3');
  }
  ringtone.src = localStorage.getItem(ringtoneSoundPathKey);
  if (localStorage.getItem(ringtoneSoundPathKey) === null) {
    ringtone.src = '/sounds/ringtones/message-ringtone-21467.mp3';
    localStorage.setItem(
      ringtoneSoundPathKey,
      '/sounds/ringtones/message-ringtone-21467.mp3'
    );
  }
};

const connectMicrophone = async () => {
  const localStorageItem = localStorage.getItem('voiceDevice');

  if (!localStorageItem) {
    requestUserDevices(); //Load default microphone
  }

  const device = JSON.parse(localStorageItem) as MediaDeviceInfo;
  const deviceIsConnected = await isDeviceConnected(device);

  if (!deviceIsConnected) {
    requestUserDevices(); //Load default microphone
    store.dispatch(
      setVoiceDevice({
        deviceId: '',
        groupId: '',
        kind: 'audiooutput',
        label: t('Default'),
        toJSON: () => {}
      })
    );
    return;
  }

  requestUserDevices({
    exactDevices: {
      audio: device
    }
  }); //Load saved microphone
  store.dispatch(setVoiceDevice(device));
};

const isDeviceConnected = async (device: MediaDeviceInfo) => {
  if (!device) return false;
  const connectedDevices = await navigator.mediaDevices.enumerateDevices();
  return (
    connectedDevices.find((d) => d.deviceId === device.deviceId) !== undefined
  );
};

export const connectDevices = async (
  localStorageKey: string,
  htmlElementIds: string[],
  setDevice?
) => {
  const localStorageItem = localStorage.getItem(localStorageKey);
  if (!localStorageItem) {
    return;
  }

  const savedDevice = JSON.parse(localStorageItem) as MediaDeviceInfo;
  const deviceIsConnected = await isDeviceConnected(savedDevice);

  //If the device is not connected, remove it from the local storage
  if (!deviceIsConnected && setDevice) {
    //localStorage.removeItem(localStorageKey);
    store.dispatch(
      setDevice({
        deviceId: '',
        groupId: '',
        kind: 'audioinput',
        label: t('Default'),
        toJSON: () => {}
      })
    );
    return;
  }

  //If the device is connected, load it in the html elements
  htmlElementIds.forEach((htmlElementId) => {
    const audio = document.getElementById(htmlElementId) as HTMLMediaElement & {
      setSinkId(deviceId: string): void;
    };
    const audioKey = audioKeys[localStorageKey];
    const volume = localStorage.getItem(audioKey);
    if (volume) {
      const volumeValue = parseFloat(volume) / 100;
      audio.volume = volumeValue;
    }
    audio.setSinkId(savedDevice.deviceId);
  });

  if (setDevice) {
    store.dispatch(setDevice(savedDevice));
  }
};

const audioKeys = {
  audioDevice: 'audioVolume',
  ringDevice: 'ringVolume'
};

const getWebRtcSocket: Function = (): JsSIP.WebSocketInterface => {
  return WEBRTC_SOCKET;
};

const connectWS = (): JsSIP.WebSocketInterface => {
  logger.log(
    '[JSSip v. ' +
      JsSIP.version +
      '] Initiating WebRTC connection ... \n WebRTC Address: ' +
      WEBRTC_ADDRESS
  );

  const socket = new JsSIP.WebSocketInterface(WEBRTC_ADDRESS);

  socket.ondata = (data): void => {
    logger.log('[WebRTC SOCKET]: ', data);
  };
  socket.onconnect = (): void => {
    logger.log('[WebRTC SOCKET Succesfully Connected !!] :D ');
  };

  socket.ondisconnect = (): void => {
    logger.log('[WEBRTC] socket disconnected');

    // emitCustomEvent('Webrtc-Close', socket)
  };

  return socket;
};

const registerExtension = (
  socket: JsSIP.WebSocketInterface,
  extensionData: ExtensionData
): JsSIP.UA => {
  logger.log('[WebRTC] Extension Data: ', extensionData);

  const configuration = {
    sockets: [socket],
    uri: 'sip:' + extensionData.complexExtension + '@' + SIP_ADDRESS,
    password: extensionData.password,
    session_timers: false
  };

  logger.log('[WebRTC] Extension Configuration: ', configuration);

  JsSIP.debug.enable('JsSIP:*');
  let coolPhone = new JsSIP.UA(configuration);
  coolPhone.start();

  coolPhone.on('connected', (event) => {
    logger.log('[WEBRTC] connected', event);
  });

  coolPhone.on('disconnected', (event: DisconnectEvent) => {
    logger.log('[WEBRTC] disconnected', event);
    emitCustomEvent('webrtc-connection-changed', false);
    //emitCustomEvent('Webrtc-Close', socket);
  });

  coolPhone.on('connecting', (event) => {
    logger.log('[WEBRTC] sipEvent', event);
  });
  coolPhone.on('registered', (event) => {
    logger.log('[WEBRTC] registered', event);
    emitCustomEvent('webrtc-connection-changed', true);
  });
  coolPhone.on('unregistered', (event) => {
    logger.log('[WEBRTC] unregistered', event);
    emitCustomEvent('webrtc-connection-changed', false);
  });
  coolPhone.on('registrationFailed', (event) => {
    logger.log('[WEBRTC] registrationFailed', event);
    emitCustomEvent('webrtc-connection-changed', false);
  });
  coolPhone.on('registrationExpiring', (event) => {
    logger.log('[WEBRTC] registrationExpiring', event);
    coolPhone.register();
    // emitCustomEvent('Webrtc-Close', socket)
  });

  // When a call is received
  coolPhone.on('newRTCSession', onNewRTCSession);

  coolPhone.on('newMessage', (event) => {
    logger.log('[WEBRTC] sipEvent', event);
  });
  coolPhone.on('sipEvent', (event) => {
    logger.log('[WEBRTC] sipEvent', event);
  });

  /*let session = coolPhone.call("1156@f1.dialcata.com", options);
  session.connection.addEventListener("addstream", function (e: any) {
    // set remote audio stream
    const remoteAudio = document.createElement("audio");
    remoteAudio.src = window.URL.createObjectURL(e.stream);
    remoteAudio.play();
  });*/
  return coolPhone;
};

export const getCoolPhone = () => {
  return WEBCTC_COOL_PHONE;
};

export async function showNotificationOnAutoansweredCalls(
  session: WebRTCSession
) {
  const callerIdData = getCallerIdDataFromSession(session);

  const campaignId = callerIdData.campaignId;

  if (!campaignId) {
    showInboundCallToast();
  }

  const campaigns = (await getCampaignsQuery({}))?.elements;

  const campaign = campaigns?.find((c) => {
    return c.id === campaignId;
  }) as CallCampaign;

  // Si es manual, no mostrar notificación
  if (campaign.callMode === CallMode.OUTBOUND && callerIdData.outgoing) {
    return;
  }

  showInboundCallToast();
}

function showInboundCallToast() {
  //startBeepSound();
  snackbar.toast(t('New inbound call'), 'info');
}
/**
 *  If the session to be autoanswered is recieved by 2+ agents, the session will
 *  be tried to be autoanswered by all of them, so we need to check if the session
 *  is hangued up to avoid showing the toast to every agent
 * */
function showInboundCallToastTimeout(
  shouldBeAutoanswered: boolean,
  session: RTCSession
) {
  if (!shouldBeAutoanswered) return null;
  const autoansweredToastTimeout = setTimeout(() => {
    const webRTCSession = {
      status: 'Talking',
      RTCSession: session
    } as WebRTCSession;
    showNotificationOnAutoansweredCalls(webRTCSession);
  }, 500);

  return autoansweredToastTimeout;
}

// session : RTCSession
const onNewRTCSession = async ({ originator, session, request }) => {
  if (originator !== 'remote') return;
  //If no extension
  if (!getConnectedRTUser(false)?.extension?.extension) return;

  const webrtcsession = {
    status: 'Ringing',
    RTCSession: session
  } as WebRTCSession;
  logger.log('[webrtc.service] - On new Call', originator, session, request);

  if (getNotificationsOffDomain(false)) return;

  const shouldBeAutoanswered = shouldWebrtcsessionBeAutoanswered(webrtcsession);

  const autoansweredToastTimeout = showInboundCallToastTimeout(
    shouldBeAutoanswered,
    session
  );

  const queueIsMuted = checkSessionQueueIsMuted(webrtcsession);
  // Show notificaction if the call is INBOUND & shouldBeAutoanswered

  if (queueIsMuted) return;

  session.on('ended', (event) => {
    logger.log('[webrtc.service] CALL ENDED: ', event, session);
    const webRTCSession = {
      status: 'Finished',
      RTCSession: session
    } as WebRTCSession;
    removeRTCSession(webRTCSession);
    WebRTCNotifications.hangUpCallAlert(); //Hangup sound when client hangup but it sounds too when parking
    if (shouldBeAutoanswered && autoansweredToastTimeout)
      clearTimeout(autoansweredToastTimeout);
  });
  session.on('accepted', (event) => {
    logger.log('[webrtc.service] CALL ACCEPTED: ', event);
    // updateFromRTCSession(session, 'Talking');
    const webRTCSession = {
      status: 'Talking',
      RTCSession: session
    } as WebRTCSession;

    addRTCSession(webRTCSession); //Update session
  });
  session.on('connecting', (event) => {
    // logger.log('[webrtc.service] CALL CONNECTING: ', event);
    // emitCustomEvent('WebRTC-Call-Update', session);
  });
  session.on('failed', (event) => {
    logger.error('[webrtc.service] CALL FAILED: ', event);
    const webRTCSession = {
      status: 'Finished',
      RTCSession: session
    } as WebRTCSession;
    removeRTCSession(webRTCSession);
  });
  session.on('getusermediafailed', (event) => {
    logger.error('[webrtc.service] CALL GETUSERMEDIAFAILED: ', event);
    const webRTCSession = {
      status: 'Finished',
      RTCSession: session
    } as WebRTCSession;
    removeRTCSession(webRTCSession);
  });
  session.on('hold', (event) => {
    logger.log('[webrtc.service] CALL IN HOLD: ', event);
    // emitCustomEvent('WebRTC-Call-Update', session);
  });
  session.on('muted', (event) => {
    logger.log('[webrtc.service] CALL MUTED: ', event);
    // emitCustomEvent('WebRTC-Call-Update', session);
  });
  session.on('newDTMF', (event) => {
    logger.log('[webrtc.service] CALL DTMF: ', event);
    // emitCustomEvent('WebRTC-Call-Update', session);
  });
  session.on('peerconnection:createanswerfailed', (event) => {
    logger.error(
      '[webrtc.service] CALL peerconnection:createanswerfailed: ',
      event
    );
    const webRTCSession = {
      status: 'Finished',
      RTCSession: session
    } as WebRTCSession;
    removeRTCSession(webRTCSession);
  });
  // session.connection.addEventListener("addstream", function (e) {
  //   // set remote audio stream
  //   const remoteAudio = document.createElement("audio");
  //   remoteAudio.src = window.URL.createObjectURL(e.stream);
  //   remoteAudio.play();
  // });
  session.on('peerconnection:createofferfailed', (event) => {
    logger.error(
      '[webrtc.service] CALL peerconnection:createofferfailed ',
      event
    );
    removeRTCSession(session);
  });
  session.on('progress', (event) => {
    logger.log('[webrtc.service] CALL PROGRESS: ', event);

    const webRTCSession = {
      status: 'Ringing',
      RTCSession: session
    } as WebRTCSession;

    // If the user has an active call, the following calls will not be autoanswered even if the autoanswer option is selected
    if (shouldBeAutoanswered) {
      WebRTCCallManagement.attendCall(webRTCSession, shouldBeAutoanswered);
      setNextOutgoingCall('');
    }

    addRTCSession(webRTCSession); //Update session
  });
  session.on('update', (event) => {
    logger.log('[webrtc.service] CALL UPDATE: ', event);
    // emitCustomEvent('WebRTC-Call-Update', session);
  });
  if (!shouldBeAutoanswered) WebRTCNotifications.openCallAlert(webrtcsession);
};

/**
 *
 * @param session
 * @param showDialerCalls Para mostrar las llamadas en el AnswerCard
 * @returns
 */

const shouldWebrtcsessionBeAutoanswered = (
  session: WebRTCSession,
  showDialerCalls = false
) => {
  const callerIdData = getCallerIdDataFromSession(session);
  const looggedRTUser = getConnectedRTUser(false);

  //La llamada es una saliente manual
  const isManual = callerIdData.outgoing;
  if (isManual) return true;

  const callAutoanswer = callerIdData.autoAnswer;

  //NO autoresponder si hay una llamada activa
  const thereIsActiveCall = Boolean(getActiveRTCall(looggedRTUser));
  if (thereIsActiveCall) return false;

  //La llamada entrante tiene marcada la opción de autoresponder a true
  if (callAutoanswer && !showDialerCalls) return true;

  //Autoanswer activo manualmente
  const userHasAutoanswer = getAutoanswer(false);
  if (userHasAutoanswer) return true;

  return false;

  // ---------------------------------------------
  //Check if the agent has autoanswer enabled
  /* const autoanswer = getAutoanswer(false);
  if (autoanswer) {
    return true;
  }

  const callerIdData = getCallerIdDataFromSession(session);
  if (callerIdData.autoAnswer) {
    const looggedRTUser = getConnectedRTUser(false);
    const thereIsActiveCall = Boolean(getActiveRTCall(looggedRTUser));

    //The agent launched the call manually (is not progressive, predidictive, etc);
    const isManual = callerIdData.outgoing;
    if (isManual) {
      return true;
    }

    //In the case of dialer campaigns, if the agent has a call in progress, the call will not be autoanswered
    if (!thereIsActiveCall && !showDialerCalls) {
      return true;
    }
  }

  return false; */
};

const closeWebSocket = (client: JsSIP.WebSocketInterface) => {
  client.disconnect();
};

const WebRTCService = {
  initWebRTC,
  closeWebSocket,
  getWebRtcSocket,
  shouldWebrtcsessionBeAutoanswered
};

export default WebRTCService;
