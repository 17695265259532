import { useMemo } from 'react';
import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';

import {
  useCampaignsQueryById,
  useServiceByIdQuery
} from 'src/services/users/application/query/getUsersDataQueries';
import { useEmailSignatureQuery } from 'src/services/email/domain/signature/getEmailSignature';
import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';
import { useCompanyVariablesQuery } from 'src/modules/Admin/modules/Acd/modules/Variables/application/getVariablesQuery';
import { SocialmediaCampaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import AuthManager from 'src/services/authentication/manager';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';

interface Props {
  campaignId: string;
  conversation: Conversation;
}

function useEmailSignature({ campaignId, conversation }: Props) {
  const campaign = useCampaignsQueryById(campaignId)?.data;

  const service = useServiceByIdQuery(campaign?.serviceId)?.data;

  const signaturesQuery = useEmailSignatureQuery({
    companyId: getLoggedUserCompanyId()
  });
  const signatures = signaturesQuery.data?.elements ?? [];

  const signature = signatures.find(
    (s) =>
      (campaign as SocialmediaCampaign)?.channelData?.emailSignatureId === s.id
  );

  const emailVariablesQuery = useCompanyVariablesQuery({
    companyId: getLoggedUserCompanyId(),
    channel: ChannelType.EMAIL
  });

  const emailVariables = emailVariablesQuery.data ?? [];

  function parseSignatureFn() {
    if (!emailVariables) return '';

    let parsedSignature = signature?.content ?? '';
    // ACD variables
    emailVariables.forEach((variable) => {
      const value =
        variable.scope === 'Conversation'
          ? (conversation?.variables[variable.name] ?? '')
          : variable.value;
      parsedSignature = parsedSignature?.replaceAll(
        `$${variable.name}$`,
        value
      );
    });

    // Other variables
    const otherVariables = {
      O_agentName:
        getConnectedRTUser(false)?.username ?? AuthManager.getUsername(),
      O_campaignId: campaign?.name ?? '',
      O_serviceId: service?.name ?? ''
    };

    Object.entries(otherVariables).forEach(([key, value]) => {
      parsedSignature = parsedSignature?.replaceAll(`$${key}$`, value);
    });

    return parsedSignature;
  }

  const parsedSignature = useMemo(() => {
    return parseSignatureFn();
  }, [emailVariables, signature?.content, campaign?.id, service?.id]);

  return { parseSignatureFn, parsedSignature };
}

export default useEmailSignature;
