import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Conversation } from 'src/models/conversations/conversations';
import * as Yup from 'yup';
import { User } from 'src/models/users';
import DaServicesDomain from 'src/modules/Admin/modules/Operations/modules/DaServices/domain/DaServicesDomain';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { TicketsDomain } from 'src/modules/Admin/modules/Operations/modules/Tickets/domain/TicketsDomain';
import { getAllUsers } from 'src/services/users/domain/getUserData';
import {
  Ticket,
  TicketField,
  TicketResolution,
  TicketResolutionUI,
  TicketSubResolution,
  TicketTag
} from '../../models/tickets';
import { getTicketResolutionsUIBy } from '../utils/getTicketResolutionsUI';
import { useTranslation } from 'react-i18next';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';

interface Params {
  conversation?: Conversation;
  ticket?: Ticket;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
  onChangeTicketSubResolution?: (
    subResolution: TicketSubResolution,
    isFirstManualReschedule: boolean,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const useTicketForm = (params: Params) => {
  const {
    conversation,
    ticket,
    setTicket,
    onChangeTicketSubResolution: onChangeTicketSubResolutionParams
  } = params;

  // Ticket service
  const [ticketService, setTicketService] = useState<DaService>(null);
  // Ticket service options
  const [serviceList, setServiceList] = useState<DaService[]>([]);

  // Ticket fields
  const [ticketFields, setTicketFields] = useState<TicketField[]>([]);
  // Ticket tag options
  const [ticketTagsList, setTicketTagsList] = useState<TicketTag[]>([]);
  // Ticket users
  const [ticketUsers, setTicketUsers] = useState<User[]>([]);
  // Ticket user options
  const [ticketUsersList, setTicketUsersList] = useState<User[]>([]);
  // Ticket resolutions from ticket
  const [serviceResolutions, setServiceResolutions] = useState<
    TicketResolutionUI[]
  >([]);
  // Ticket resolutions
  const [ticketResolution, setTicketResolution] = useState<TicketResolution>(
    ticket?.subResolution?.resolution
  );

  const { t }: { t: any } = useTranslation();

  const loggedUser = getConnectedRTUser(false);
  const companyId =
    ticketService?.companyId ??
    conversation?.companyId ??
    loggedUser?.companyId;

  const defaultServiceCompany = serviceList.find(
    (service) => service.companyId === companyId
  );

  const serviceId =
    ticket?.serviceId ?? conversation?.serviceId ?? defaultServiceCompany?.id;

  const initialTicket: Ticket = ticket ?? {
    companyId: companyId,
    description: '',
    conversationIds: [],
    fields: {},
    serviceId: serviceId,
    tagIds: [],
    title: ''
  };

  const initialTicketValidationSchema = Yup.object().shape({
    title: Yup.string().required(t('Title field is required')),
    serviceId: Yup.string().required(t('Service field is required')),
    subResolutionId: Yup.string().required(t('Sub-typology field is required')),
    fields: Yup.object()
  });

  const [ticketValidationSchema, setTicketValidationSchema] = useState(null);

  /** Function to add ticket field validations to the ticket validation schema */
  const setTicketValidationSchemaFields = useCallback(
    (ticketFields: TicketField[]) => {
      if (ticketFields?.length === 0) {
        setTicketValidationSchema(initialTicketValidationSchema);
        return;
      }

      const createFieldValidation = (field: TicketField) => {
        // mixed because it can be string or boolean
        const valueValidation = field.required
          ? Yup.mixed().required(t('The field is required'))
          : Yup.mixed();

        const optionsValueValidation = field.required
          ? Yup.array()
              .min(1, t('The field is required'))
              .required(t('The field is required'))
          : Yup.array();

        return {
          [field.id]: Yup.object().shape({
            type: Yup.string(),
            value:
              field.type === 'Options'
                ? optionsValueValidation
                : valueValidation
          })
        };
      };

      const ticketFieldsSchema = ticketFields.reduce(
        (prev, curr) => ({ ...prev, ...createFieldValidation(curr) }),
        {}
      );
      const newTicketValidationSchema = initialTicketValidationSchema.concat(
        Yup.object().shape({ fields: Yup.object().shape(ticketFieldsSchema) })
      );

      setTicketValidationSchema(newTicketValidationSchema);
    },
    [ticketFields?.length, setTicketValidationSchema, t]
  );

  // Ticket subresolutions
  const [ticketSubResolution, setTicketSubResolution] =
    useState<TicketSubResolution>(ticket?.subResolution);

  // Set the service options
  useEffect(() => {
    DaServicesDomain.retrieveAllDaServices().then((resp) => {
      setServiceList(resp.elements);
    });
  }, []);

  // Set the ticket service AT THE BEGINNING
  useEffect(() => {
    if (serviceList.length > 0) {
      const ticketService = serviceList?.find(
        (service) => service.id === serviceId
      );
      setTicketService(ticketService);
    }
  }, [serviceList, serviceId]);

  // Set the ticket fields
  useEffect(() => {
    if (serviceId && companyId) {
      TicketsDomain.getTicketFieldsQuery({ companyId, serviceId }).then(
        (resp) => {
          setTicketFields(resp);
          setTicketValidationSchemaFields(resp);
        }
      );
    }
  }, [serviceId, companyId]);

  // Set the tag options
  useEffect(() => {
    if (companyId) {
      let isMounted = true;
      TicketsDomain.getTicketTags({ companyId }).then((resp) => {
        if (isMounted) {
          setTicketTagsList(resp);
        }
        return () => {
          isMounted = false;
        };
      });
    }
  }, [companyId]);

  const serviceTicketTagList = ticketTagsList.filter((tag) =>
    tag.serviceIds.includes(serviceId)
  );

  // Set the user options
  useEffect(() => {
    let isMounted = true;
    if (serviceId) {
      getAllUsers({ serviceId }).then((resp) => {
        if (isMounted) {
          setTicketUsersList(resp.elements);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [serviceId]);

  // Set the ticket users
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const ticketUsers = ticketUsersList.filter((userOption) => {
        return (
          (ticket?.assignedUserIds &&
            Object.keys(ticket?.assignedUserIds)
              .filter((id) => ticket?.assignedUserIds[id].fromTicket)
              .includes(userOption?.id)) ||
          // if creating a new ticket, add the logged user to the ticket assigned users
          (!ticket?.id && userOption?.id === loggedUser?.id)
        );
      });

      setTicketUsers(ticketUsers);
    }
    return () => {
      isMounted = false;
    };
  }, [ticketUsersList, ticket?.assignedUserIds]);

  // Set the ticket resolutions filtered by the service
  useEffect(() => {
    if (companyId && serviceId) {
      getTicketResolutionsUIBy({ companyId, serviceId }).then((resp) => {
        setServiceResolutions(resp);
      });
    }
  }, [companyId, serviceId]);

  // Ticket resolution options
  const ticketResolutionList = serviceResolutions;
  // Ticket subresolutions options
  const ticketSubResolutionList = serviceResolutions.find(
    (resolution) => resolution.id === ticketResolution?.id
  )?.subResolutions;

  // Services
  const onChangeTicketService = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const service = serviceList.find(
      (service) => service.id === event.target.value
    );
    setTicketService(service);

    if (setTicket) {
      const compId = service.companyId;
      setTicket((prev) => ({
        ...prev,
        serviceId: event.target.value,
        // Also set the companyId according to the service selected
        companyId: compId
      }));
    }
  };

  // Tags
  const onChangeTicketTags = (_, values: TicketTag[]) => {
    if (setTicket) {
      const tagIds = values.map((tag) => tag.id);
      setTicket((prev) => ({ ...prev, tagIds: tagIds }));
    }
  };

  // Assigned users
  const onChangeTicketUsers = (_, values: User[]) => {
    setTicketUsers([...values]);
    if (setTicket) {
      const userIds = values.map((user) => user.id);
      // assignedUserIds prop is not updated. Only ticketUserIds prop is updated (the prop in charge of indicating which users to add or update).
      setTicket((prev) => ({
        ...prev,
        ticketUserIds: userIds
      }));
    }
  };

  // Resolutions
  const onChangeTicketResolution = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const resolution = ticketResolutionList.find(
      (resolution) => resolution.id === event.target.value
    );
    setTicketResolution(resolution);
  };

  // SubResolutions
  const onChangeTicketSubResolution = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const subResolution = ticketSubResolutionList.find(
      (subResolution) => subResolution.id === event.target.value
    );
    setTicketSubResolution(subResolution);
    const subResolutionId = subResolution.id;
    const conversationId = conversation?.id;
    const ticketId = ticket?.id;
    const isFirstManualReschedule =
      await TicketsDomain.shouldPerformFirstManualReschedule({
        subResolutionId,
        conversationId,
        ticketId
      });
    if (setTicket) {
      setTicket((prev) => ({
        ...prev,
        subResolutionId: event.target.value,
        subResolution
      }));
    }
    onChangeTicketSubResolutionParams(
      subResolution,
      isFirstManualReschedule,
      event
    );
  };

  return {
    companyId,
    initialTicket,
    onChangeTicketResolution,
    onChangeTicketService,
    onChangeTicketSubResolution,
    onChangeTicketTags,
    onChangeTicketUsers,
    serviceList,
    setTicketUsers,
    ticketFields,
    ticketResolution,
    ticketResolutionList,
    ticketService,
    ticketSubResolution,
    ticketSubResolutionList,
    ticketTagsList: serviceTicketTagList,
    ticketUsers,
    ticketUsersList,
    ticketValidationSchema
  };
};

export default useTicketForm;
