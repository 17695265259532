import { FC } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { styled, Typography, Box, Divider } from '@mui/material';

export interface PageHeaderProps {
  heading: string;
  subheading: string;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  subvariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline';
  align?: 'inherit' | 'right' | 'left' | 'center' | 'justify';
  routeImg?: string;
}

const RootWrapper = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

const PageHeader: FC<PageHeaderProps> = ({
  heading,
  subheading,
  variant = 'h1',
  subvariant = 'subtitle2',
  align = 'left',
  routeImg,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation();

  return (
    <RootWrapper {...rest}>
      {heading && (
        <Typography
          align={align}
          variant={variant}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: align
          }}
        >
          {routeImg !== '' && (
            <img
              src={routeImg}
              style={{ marginRight: routeImg === '' ? 10 : 0 }}
            />
          )}
          {t(heading)}
        </Typography>
      )}
      {subheading && (
        <Typography variant={subvariant} align={align}>
          &nbsp;{t(subheading)}
        </Typography>
      )}
    </RootWrapper>
  );
};

PageHeader.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string
};

export default PageHeader;
