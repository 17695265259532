import axios, { AxiosResponse } from 'axios';
import { replaceURLParams } from 'src/services/utils';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;
const BASE_MS = process.env.REACT_APP_BASE_SOCIALMEDIA_MS as string;
const PATH = process.env.REACT_APP_API_NOTES_ID_DELETE as string;

export const deleteNoteApi = async (noteId: string): Promise<AxiosResponse> => {
  const path = replaceURLParams(BASE_URL + BASE_MS + PATH, {
    '{id}': noteId
  });

  return axios
    .delete(path)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
