import { MenuItem, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';
import FileDropZone from 'src/components/FileDropZone';
import { acceptedFiles } from 'src/modules/Contacts/views/utils/acceptedFiles';
import { delimiterOptions } from 'src/modules/Contacts/views/utils/delimiterOptions';
import { getCSVHeadersAndDelimiter } from './LinkFieldsWithCSV/utils/getCSVHeaders';
import { UploadCsvParameters } from '../models/models';

interface Props {
  uploadCsvParameters: UploadCsvParameters;
  setUploadCsvParameters: Dispatch<SetStateAction<UploadCsvParameters>>;
}

export const UploadCSVContacts = ({
  uploadCsvParameters,
  setUploadCsvParameters
}: Props) => {
  const { file } = uploadCsvParameters;

  useEffect(() => {
    if (file) {
      getCSVHeadersAndDelimiter(file).then((response) => {
        setUploadCsvParameters((prevParameters) => ({
          ...prevParameters,
          csvDelimiter: response.delimiter
        }));
      });
    }
  }, [file]);

  return (
    <Stack spacing={2} py={1}>
      <FileDropZone
        files={uploadCsvParameters.file ? [uploadCsvParameters.file] : []}
        setFiles={(files) => {
          if (files.length === 0) return;
          setUploadCsvParameters({
            ...uploadCsvParameters,
            file: files[0]
          });
        }}
        accept={acceptedFiles}
      />

      <TextField
        required
        select
        id={`contacts-delimiter-input`}
        name="delimiter"
        label={t('Delimiter character')}
        placeholder={t('For example: “;”')}
        fullWidth
        onChange={(e) =>
          setUploadCsvParameters({
            ...uploadCsvParameters,
            csvDelimiter: e.target.value
          })
        }
        key={uploadCsvParameters.csvDelimiter}
        value={uploadCsvParameters.csvDelimiter}
      >
        {delimiterOptions.map((delimiter, i) => {
          return (
            <MenuItem key={i} value={delimiter.value}>
              {t(delimiter.label)}
            </MenuItem>
          );
        })}
      </TextField>
    </Stack>
  );
};
