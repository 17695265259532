import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import {
  ContactField,
  ContactGroup
} from 'src/modules/Contacts/models/contacts';
import { CreateUpdateContactFieldForm } from './CreateUpdateContactFieldForm';
import { Box, Popover } from '@mui/material';
import { DialTable, DialTableImperativeHandle } from 'src/components/DialTable';
import { useContactFields } from '../../hooks/useContactFields';
import { useEffect, useRef } from 'react';
import { getContactGroups } from 'src/modules/Contacts/domain/getContactGroups';

export const ContactFieldsTable = () => {
  const {
    fieldToEdit,
    setFieldToEdit,
    contactsToDelete,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    onDeleteContactFields,
    data,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    companyId,
    setContactGroupsData,
    applyFilters
  } = useContactFields();

  useEffect(() => {
    getContactGroups({ companyId }).then((resp) => {
      setContactGroupsData(resp?.elements);
    });
  }, []);
  const tableRef = useRef<DialTableImperativeHandle<ContactField>>();

  return (
    <>
      <Popover
        open={fieldToEdit !== undefined}
        onClose={() => {
          setFieldToEdit(undefined);
        }}
        anchorEl={document.getElementById('root')}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        <CreateUpdateContactFieldForm
          contactField={fieldToEdit}
          onClose={() => {
            setFieldToEdit(undefined);
          }}
          tableRef={tableRef}
        />
      </Popover>

      {/*<UpdateContactFieldModal
        key={fieldToEdit?.id}
        contactField={fieldToEdit}
        onClose={() => {
          setFieldToEdit(undefined);
        }}
      />*/}
      <ConfirmationModal<ContactField, keyof ContactField>
        dataKey={'name'}
        data={contactsToDelete}
        isOpen={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        onConfirm={onDeleteContactFields}
        mode={ConfirmationModalModes.DELETE}
      />

      <Box width={'100%'} p={2}>
        <DialTable<ContactField>
          queryKey={['contactFields']}
          getColumnsFn={getColumns}
          getRowActionsFn={getRowActions}
          data={data}
          getBulkActionsFn={getBulkActions}
          getAvailableFilters={getAvailableFilters}
          onlyEqualsOperator
          ref={tableRef}
          onApplyFilters={applyFilters}
        />
      </Box>
    </>
  );
};
