import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { ChatView } from '../modules/ChatView/views';
import Restricted from 'src/components/Restricted';
import {
  checkReadEmailAccountPermissions,
  checkReadTelegramAccountPermissions,
  checkReadWebChatAccountPermissions,
  checkReadWhatsAppAccountPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { WhatsAppAccountProvider } from '../views/WhatsAppAccountView/views/context/whatsAppAccountContext';
import InstagramAccounts from '../modules/InstagramAccounts';
import { checkReadInstagramAccountPermissions } from 'src/services/permissionGroups/domain/checkPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// example import component
// const Routes = Loader(lazy(() => import('../views/RoutesView')));

const EmailAccountsView = Loader(
  lazy(() => import('../modules/EmailAccountsView'))
);

const TelegramAccountsView = Loader(
  lazy(() => import('../modules/TelegramAccountsView'))
);

const WhatspAAAccountsView = Loader(
  lazy(() => import('../views/WhatsAppAccountView/views'))
);

const contactCenterRoutes: RouteObject[] = [
  {
    path: 'email',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadEmailAccountPermissions}
      >
        <EmailAccountsView />
      </Restricted>
    )
  },
  {
    path: 'telegram',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadTelegramAccountPermissions}
      >
        <TelegramAccountsView />
      </Restricted>
    )
  },
  {
    path: 'whatsapp',
    element: (
      <WhatsAppAccountProvider>
        <Restricted
          navigate
          permissionCheckerFn={checkReadWhatsAppAccountPermissions}
        >
          <WhatspAAAccountsView />
        </Restricted>
      </WhatsAppAccountProvider>
    )
  },
  {
    path: 'chat',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadWebChatAccountPermissions}
      >
        <ChatView />
      </Restricted>
    )
  },
  {
    path: 'instagram',
    element: (
      <Restricted
        navigate
        permissionCheckerFn={checkReadInstagramAccountPermissions}
      >
        <InstagramAccounts />
      </Restricted>
    )
  }
];

export default contactCenterRoutes;
