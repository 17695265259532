import {
  ReactNode,
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  ColumnOrderState,
  VisibilityState,
  OnChangeFn,
  getSortedRowModel,
  SortingState
} from '@tanstack/react-table';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { DropResult } from 'react-beautiful-dnd';
import { TablePaginationProps } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DataStatus } from '../../../models/dataStatus';
import { DialTableFilter } from '../models/filters';
import { getFiltersFromDialTableFilters } from '../utils/getFiltersFromDialTableFilters';
import { getUniqueColumns } from '../utils/getUniqueColumns';
import { PaginatedElements } from 'src/models/pagination';
import { getSelectColumn } from '../utils/getSelectColumn';
import {
  defaultRowsPerPageOptions,
  getDefaultPaginationState
} from '../models/pagination';
import { ACTIONS_COLUMN_ID, SELECT_COLUMN_ID } from '../models/columns';
import { isDataColumnId } from '../utils/isDataColumnId';
import { useDialTableSelectedFilters } from './useDialTableSelectedFilters';
import { DialTableImperativeHandle } from '..';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { getFiltersObject } from '../utils/getFiltersObject';
import { useCustomEventListener } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from '../utils/refetchDataEventName';
import { getPaginationValues } from '../utils/getPaginationValues';
import { getColumnIds } from '../utils/getColumnIds';
import { DialTableState } from '../models/state';
import { DialTableQueryKeys } from '../models/queryKey';
import { getDialTableQueryKeys } from '../utils/getDialTableQueryKeys';
import {
  DialTableFiltersContext,
  DialTableBulkActionsContext,
  DialTableReportContext,
  DialTableDataContext,
  DialTableRowActionsContext,
  DialTableActionsBaseContext
} from '../models/functionContexts';
import { queryClient } from 'src/utils/queryClient';
import { DialTableFallback } from '../components/DialTableFallback';
import useUpdate from 'src/hooks/useUpdateEffect';

interface Params<T> {
  getColumnsFn: () => Promise<ColumnDef<T>[]>;
  ref?: Ref<DialTableImperativeHandle<T>>;
  queryKey?: QueryKey | Partial<DialTableQueryKeys>;
  data?: T[];
  getDataFn?: (
    context: DialTableDataContext<T>
  ) => Promise<PaginatedElements<T>>;
  getAvailableFilters?: () => Promise<DialTableFilter<T>[]>;
  getInitialSelectedFilters?: (
    availableFilters?: DialTableFilter<T>[]
  ) => Promise<DialTableFilter<T>[]>;
  getRowActionsFn?: (context: DialTableRowActionsContext<T>) => ReactNode;
  getBulkActionsFn?: (context: DialTableBulkActionsContext<T>) => ReactNode;
  getInitialColumnVisibilityFn?: (columns: ColumnDef<T>[]) => VisibilityState;
  getDataOnInit?: boolean;
  onApplyFilters?: (context: DialTableFiltersContext<T>) => void;
  onGenerateReport?: (context: DialTableReportContext<T>) => Promise<void>;
  disableSetUrlSearchParams?: boolean;
  state?: Partial<DialTableState<T>>;
  onSelectedFiltersChange?: OnChangeFn<DialTableFilter<T>[]>;
  onSortingChange?: OnChangeFn<SortingState>;
  manualSorting?: boolean;
  enableSortingRemoval?: boolean;
  rowsPerPageOptions?: number[];
  renderFallback?: (error: any) => ReactNode | ReactNode;
  getCustomHeaderActionsFn?: (
    context: DialTableActionsBaseContext<T>
  ) => ReactNode;
  showTimeZoneSelector?: boolean;
}

export const useDialTable = <T extends Object>(params: Params<T>) => {
  const {
    ref,
    data: dataParams,
    getAvailableFilters,
    getColumnsFn,
    getDataFn,
    getDataOnInit,
    getInitialSelectedFilters,
    getRowActionsFn,
    getBulkActionsFn,
    queryKey: queryKeyParams,
    getInitialColumnVisibilityFn,
    onApplyFilters: onApplyFiltersParams,
    onGenerateReport: onGenerateReportParams,
    disableSetUrlSearchParams,
    state,
    onSelectedFiltersChange,
    onSortingChange,
    manualSorting = true,
    enableSortingRemoval = true,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    renderFallback = <DialTableFallback />,
    getCustomHeaderActionsFn
  } = params;
  const { sorting } = state ?? {};

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsState, setColumnsState] = useState<ColumnDef<T>[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const defaultPaginationState = getDefaultPaginationState(rowsPerPageOptions);
  const [pagination, setPagination] = useState<PaginationState>(
    defaultPaginationState
  );

  const refetchData = () => {
    // Invalidate data queries
    queryClient.invalidateQueries({
      queryKey: [...queryKeys.data, 'data'],
      refetchType: 'none'
    });
    // Refetch data
    return queryResult.refetch();
  };

  useCustomEventListener(REFETCH_DATA_EVENT_NAME, () => {
    refetchData();
  }, []);

  useImperativeHandle(ref, () => {
    // EXPOSED METHODS
    return {
      refetchData: refetchData
    };
  }, []);

  const [timeZone, setTimeZone] = useState<string | null>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  /** If not passed, generated randomly. */
  const queryKeys = useMemo<DialTableQueryKeys>(() => {
    const keys = getDialTableQueryKeys(queryKeyParams);
    return keys;
  }, [queryKeyParams]);

  const placeHolderDataRef = useRef<PaginatedElements<T>>(null);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const { hasAccess } = useContext(PermissionsContext);

  const { t }: { t: any } = useTranslation();

  /**  Enables the select column to be able to select rows and apply the bulk actions */
  const checkboxSelection = !!getBulkActionsFn;

  // COLUMNS ///////////////////////////////////////////////////////
  useEffect(() => {
    getColumnsFn?.().then((resp) => {
      let columns: ColumnDef<T>[] = getUniqueColumns(resp);
      if (checkboxSelection) {
        // Add the select (utility) column
        const selectColumn: ColumnDef<T> = getSelectColumn<T>();
        columns = [selectColumn, ...columns];
      }
      if (!!getRowActionsFn) {
        // Add the row actions (utility) column
        const actionsColumn: ColumnDef<T> = {
          id: ACTIONS_COLUMN_ID,
          header: t('Actions'),
          cell: ({ table, row }) =>
            getRowActionsFn({
              rowData: row.original,
              hasAccess,
              queryResult,
              selectedRowModel: table.getRowModel()
            }),
          enableSorting: false
        };
        columns = [...columns, actionsColumn];
      }

      setColumnsState(columns);
      setColumnOrder(() => columns.map((c) => c.id));

      if (!!getInitialColumnVisibilityFn) {
        // Set the column visibility
        const visibilityColumns = columns.filter((column) =>
          isDataColumnId(column.id)
        );
        const columnVisibility =
          getInitialColumnVisibilityFn(visibilityColumns);
        setColumnVisibility(columnVisibility);
      }
    });
  }, [getColumnsFn, getInitialColumnVisibilityFn]);

  const onColumnDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    // Not allowed to drag the select column, which is the first one
    const sourceIndex = !!checkboxSelection ? source.index + 1 : source.index;
    const destinationIndex = !!checkboxSelection
      ? destination.index + 1
      : destination.index;
    const newColumnOrder = [...columnOrder];
    const header = newColumnOrder.splice(sourceIndex, 1)?.[0];
    newColumnOrder.splice(destinationIndex, 0, header);
    setColumnOrder(newColumnOrder);
  };

  // DATA AND FILTERS ///////////////////////////////////////////////////////
  const {
    availableFilters,
    isLoadingSelectedFilters,
    selectedFilters,
    setSelectedFilters,
    setUrlSearchParamsFromDialTableFilters
  } = useDialTableSelectedFilters({
    queryKey: queryKeyParams,
    getAvailableFilters,
    getInitialSelectedFilters,
    selectedFilters: state?.selectedFilters,
    onSelectedFiltersChange
  });

  const enabledData =
    !!getDataFn &&
    getDataOnInit &&
    isLoadingSelectedFilters === DataStatus.SUCCESS &&
    columnsState?.length > 0;

  const queryFn = async () => {
    const filters = getFiltersFromDialTableFilters(selectedFilters);
    const filtersObject = getFiltersObject(selectedFilters);
    const paginationValues = getPaginationValues(pagination);
    const columnIds = getColumnIds(columnsState);
    const data = getDataFn?.({
      filters,
      paginationValues,
      columnIds,
      filtersObject,
      state,
      timeZone
    });
    // Update the placeholder data with the current data
    placeHolderDataRef.current = await data;
    return data;
  };

  const dataQueryKey: QueryKey = [
    ...queryKeys.data,
    'data',
    pagination,
    state?.sorting,
    timeZone
  ];

  const queryResult = useQuery({
    queryKey: dataQueryKey,
    queryFn,
    placeholderData: placeHolderDataRef.current,
    enabled: enabledData,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 15000
  });

  // TABLE ///////////////////////////////////////////////////////
  /**
   * manualPagination is enabled when the DialTable receives the getDataFn prop. If getDataFn prop passed, data prop is omitted.
   */
  const manualPagination = !!getDataFn;
  const data =
    (manualPagination ? queryResult?.data?.elements : dataParams) ?? [];
  const count =
    (manualPagination ? queryResult.data?.totalItems : dataParams?.length) ??
    -1;
  const pageCount =
    (manualPagination ? queryResult?.data?.currentPage : -1) ?? -1;

  const table = useReactTable({
    data,
    columns: columnsState,
    pageCount,
    state: { columnVisibility, columnOrder, pagination, sorting },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination,
    getCoreRowModel: getCoreRowModel(),
    manualSorting,
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    onSortingChange,
    enableSortingRemoval
  });

  /** The table colums but discarding the select column */
  const columns = table.getAllLeafColumns().filter((header) => {
    return header.id !== SELECT_COLUMN_ID;
  });

  /** 'true' if any column is selected. 'false' otherwise. */
  const selectedBulkActions =
    table.getIsSomeRowsSelected() || table.getIsAllRowsSelected();
  /** Number of columns displayed on the current page. */
  const displayedRowsLength = table.getRowModel().rows.length;

  const selectedRowModel = table.getSelectedRowModel();
  /** The selected original data */
  const selectedData = selectedRowModel.rows.map((row) => row.original);
  const bulkActions = getBulkActionsFn?.({
    selectedData,
    hasAccess,
    queryResult,
    selectedRowModel
  });
  const customHeaderActions = getCustomHeaderActionsFn?.({
    hasAccess,
    queryResult,
    selectedRowModel
  });

  const onApplyFilters = (selectedFilters: DialTableFilter<T>[]) => {
    if (!disableSetUrlSearchParams)
      setUrlSearchParamsFromDialTableFilters(selectedFilters);
    // Because the filters may have changed, it is necessary to:
    // 1. Invalidate data query when clicking on the apply filters button.
    // (refetchType equals to 'none' because the refetch is manual)
    queryClient.invalidateQueries({
      queryKey: [...queryKeys.data, 'data'],
      refetchType: 'none'
    });
    // 2. Reset the page index
    table?.setPagination({
      ...pagination,
      pageIndex: defaultPaginationState?.pageIndex
    });
    // 3. Refetch data
    queryResult?.refetch();

    if (!!onApplyFiltersParams) {
      const filters = getFiltersFromDialTableFilters(selectedFilters);
      const paginationValues = getPaginationValues(pagination);
      const columnIds = getColumnIds(columnsState);
      const filtersObject = getFiltersObject(selectedFilters);
      onApplyFiltersParams?.({
        filters,
        paginationValues,
        columnIds,
        filtersObject,
        state
      });
    }
  };

  const onGenerateReport = !!onGenerateReportParams
    ? async (reportName: string) => {
        const filters = getFiltersFromDialTableFilters(selectedFilters);
        const paginationValues = getPaginationValues(pagination);
        /** The visible table column ids but removing those of the utility columns */
        const visibleColumnIds = getColumnIds(table.getVisibleFlatColumns());
        const filtersObject = getFiltersObject(selectedFilters);

        const report = await onGenerateReportParams?.({
          filters,
          paginationValues,
          reportName,
          visibleColumnIds,
          filtersObject,
          state
        });
        return report;
      }
    : undefined;

  // PAGINATION ///////////////////////////////////////////////////////
  const totalPages = manualPagination
    ? queryResult?.data?.totalPages
    : Math.ceil(dataParams?.length / table.getState().pagination.pageSize);

  const disablePagination = table.getIsSomeRowsSelected();

  const tablePaginationProps: TablePaginationProps = {
    component: 'div',
    count,
    onPageChange: (_e, page) => {
      // table.setPageIndex(page); // <-- Should work but does not work
      table.setPagination({ ...pagination, pageIndex: page });
    },
    onRowsPerPageChange: (e) => {
      table.setPageSize(Number(e.target.value));
    },
    disabled: disablePagination,
    page: table.getState().pagination.pageIndex,
    rowsPerPage: table.getState().pagination.pageSize,
    rowsPerPageOptions,
    showFirstButton: table.getCanPreviousPage(),
    showLastButton: table.getState().pagination.pageIndex + 1 < totalPages,
    labelRowsPerPage: `${t('Rows per page')}:`,
    getItemAriaLabel: (type) => t(`Go to ${type} page`),
    slotProps: {
      actions: {
        nextButton: {
          disabled:
            disablePagination ||
            table.getState().pagination.pageIndex + 1 >= totalPages ||
            queryResult.isFetching
        },
        previousButton: {
          disabled:
            disablePagination ||
            !table.getCanPreviousPage() ||
            queryResult.isFetching
        },
        firstButton: {
          disabled:
            disablePagination ||
            !table.getCanPreviousPage() ||
            queryResult.isFetching
        }
      }
    }
  };

  const isError = !!getDataFn && !queryResult?.data && queryResult?.isError;

  const Fallback =
    typeof renderFallback === 'function'
      ? renderFallback?.(queryResult?.error)
      : renderFallback;

  return {
    availableFilters,
    bulkActions,
    columns,
    customHeaderActions,
    data,
    displayedRowsLength,
    fallback: Fallback,
    isError,
    isLoadingSelectedFilters,
    onApplyFilters,
    onColumnDragEnd,
    onGenerateReport,
    pagination,
    queryResult,
    selectedBulkActions,
    selectedFilters,
    setPagination,
    setSelectedFilters,
    setUrlSearchParams,
    table,
    tablePaginationProps,
    urlSearchParams,
    timeZone,
    setTimeZone
  };
};
