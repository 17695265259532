//prettier-ignore
import { EmailOutlined, PhoneOutlined, Telegram, WhatsApp, Videocam } from '@mui/icons-material';
//prettier-ignore
import { Box, IconButton, useTheme } from '@mui/material';
import { Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  CTIBarContext,
  NewConversationMenu,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import { EmailConversation } from './conversations/EmailConversation';
import { TelegramConversation } from './conversations/TelegramConversation';
import { WhatsAppConversation } from './conversations/WhatsAppConversation';
import { CallConversation } from './conversations/CallConversation';
import { VideoCallConversation } from './conversations/VIdeoCallConversation';
import InstagramConversation from './conversations/InstagramConversation';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useUserCampaignsQuery } from 'src/services/users/domain/getUserCampaignsByChannel';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  DialPadChannelVisibilities,
  defaultDialpadChannelVisibilities,
  getChannelVisibilities
} from './utils/getChannelVisibilities';
import NoCampaginPlaceHolder from './components/NoCampaignPlaceholder';

export const callKeysCTI = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['*', '0', '#']
];

interface CallKeyboardTypes {
  phone?: string;
  setShowKeyboard?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DialPad: React.FC<CallKeyboardTypes> = ({}) => {
  const { channelsWithAccess } = useContext(CTIBarContext);
  const { conversationSelected, setConversationSelected, setUsername } =
    useDialpadStateContext();

  const theme = useTheme();

  if (channelsWithAccess.length === 0) {
    return <NoCampaginPlaceHolder />;
  }

  return (
    <Box p={2} width="100%">
      <Stack direction={'column'} alignItems="center" spacing={1} width="100%">
        <Stack
          direction={'row'}
          justifyContent="center"
          spacing={3}
          mb={0.5}
          sx={{
            background: theme.colors.alpha.black[5],
            width: '100%',
            borderRadius: 0.5
          }}
        >
          <Box display="flex" width="50%" justifyContent="center" columnGap={1}>
            {channelsWithAccess.includes(NewConversationMenu.Call) && (
              <IconButton
                id="dialpad-call-button"
                sx={{
                  backgroundColor:
                    conversationSelected === 'keyboard'
                      ? theme.colors.primary.lighter
                      : 'transparent'
                }}
                onClick={() => {
                  setConversationSelected(NewConversationMenu.Call);
                }}
              >
                <PhoneOutlined fontSize={'medium'} />
              </IconButton>
            )}

            {channelsWithAccess.includes(NewConversationMenu.Email) && (
              <IconButton
                sx={{
                  backgroundColor:
                    conversationSelected === 'email'
                      ? theme.colors.primary.lighter
                      : 'transparent'
                }}
                id="dialpad-email-button"
                onClick={() => {
                  setUsername('');
                  setConversationSelected(NewConversationMenu.Email);
                }}
              >
                <EmailOutlined fontSize={'medium'} />
              </IconButton>
            )}
            {channelsWithAccess.includes(NewConversationMenu.Telegram) && (
              <IconButton
                id="dialpad-telegram-button"
                sx={{
                  backgroundColor:
                    conversationSelected === 'telegram'
                      ? theme.colors.primary.lighter
                      : 'transparent'
                }}
                onClick={() => {
                  setUsername('');
                  setConversationSelected(NewConversationMenu.Telegram);
                }}
              >
                <Telegram fontSize={'medium'} color="secondary" />
              </IconButton>
            )}
            {channelsWithAccess.includes(NewConversationMenu.WhatsApp) && (
              <IconButton
                sx={{
                  backgroundColor:
                    conversationSelected === 'whatsapp'
                      ? theme.colors.primary.lighter
                      : 'transparent'
                }}
                onClick={() => {
                  setConversationSelected(NewConversationMenu.WhatsApp);
                }}
              >
                <WhatsApp fontSize={'medium'} color="success" />
              </IconButton>
            )}
            {channelsWithAccess.includes(NewConversationMenu.Instagram) && (
              <IconButton
                sx={{
                  backgroundColor:
                    conversationSelected === 'instagram'
                      ? theme.colors.primary.lighter
                      : 'transparent'
                }}
                onClick={() => {
                  setUsername('');
                  setConversationSelected(NewConversationMenu.Instagram);
                }}
              >
                <InstagramIcon fontSize="medium" sx={{ color: '#c13584' }} />
              </IconButton>
            )}
            {channelsWithAccess.includes(NewConversationMenu.Videocall) && (
              <IconButton
                sx={{
                  backgroundColor:
                    conversationSelected === 'videocall'
                      ? theme.colors.primary.lighter
                      : 'transparent'
                }}
                onClick={() => {
                  setConversationSelected(NewConversationMenu.Videocall);
                }}
              >
                <Videocam fontSize={'medium'} color="error" />
              </IconButton>
            )}
          </Box>
        </Stack>
        {conversationSelected === NewConversationMenu.Call && (
          <CallConversation />
        )}
        {conversationSelected === NewConversationMenu.Telegram && (
          <TelegramConversation />
        )}
        {conversationSelected === NewConversationMenu.Email && (
          <EmailConversation />
        )}
        {conversationSelected === NewConversationMenu.WhatsApp && (
          <WhatsAppConversation />
        )}
        {conversationSelected === NewConversationMenu.Instagram && (
          <InstagramConversation />
        )}
        {conversationSelected === NewConversationMenu.Videocall && (
          <VideoCallConversation />
        )}
      </Stack>
    </Box>
  );
};
