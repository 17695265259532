import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { getVariablesApi } from '../infrastructure/getVariablesApi';
import { Variable, VariableScope } from '../models/models';
import { createVariableApi } from '../infrastructure/createVariableApi';

export const createVariableDomain = async (
  companyId: string,
  scope: VariableScope,
  value: string,
  name: string,
  description: string
) => {
  const variable = {
    companyId,
    scope,
    value,
    name,
    description
  } as Variable;
  try {
    return await createVariableApi(variable);
  } catch (error: unknown) {
    throw error;
  }
};
