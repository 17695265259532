import { Add, Delete, DragHandle, DragIndicator } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SearchItemPopover } from './SearchFieldPopover.tsx';

interface Props<T> {
  onDragEnd?: (result: any) => void;
  rowItems: DraggableItem<T>[];
  setRowItems: Dispatch<SetStateAction<DraggableItem<T>[]>>;
  droppableId: string;
  renderItem?: (item: DraggableItem<T>) => JSX.Element;
  renderFullItem?: (item: DraggableItem<T>) => ReactNode;
  hideDeleteIcon?: (item: DraggableItem<T>) => boolean;
}
export interface DraggableItem<T> {
  id: number;
  name: string;
  value: T;
}

export const DraggableColumn = <T extends {}>({
  setRowItems,
  rowItems,
  droppableId,
  renderItem,
  renderFullItem,
  hideDeleteIcon
}: Props<T>) => {
  const theme = useTheme();
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(rowItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRowItems(items);
  };

  function onDelete(id: number) {
    const newItems = rowItems.filter((item) => item.id !== id);
    setRowItems(newItems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                height: rowItems.length * 43 + 'px'
              }}
            >
              {rowItems.map((item, index) => {
                return (
                  <>
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const [isHovering, setIsHovering] = useState(false);
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {renderFullItem?.(item) ?? (
                              <>
                                {index !== 0 && !snapshot.isDragging && (
                                  <Divider
                                    sx={{
                                      width: '100%',
                                      color: snapshot.isDragging
                                        ? 'transparent'
                                        : 'inherit'
                                    }}
                                  />
                                )}
                                <Box
                                  p={1}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: '42px',
                                    gap: '6px',
                                    justifyContent: 'space-between',
                                    borderTop: snapshot.isDragging
                                      ? '1px solid #E0E0E0'
                                      : 'none',
                                    borderBottom: snapshot.isDragging
                                      ? '1px solid #E0E0E0'
                                      : 'none',
                                    borderRight: snapshot.isDragging
                                      ? '1px solid #E0E0E0'
                                      : 'none',
                                    borderLeft: snapshot.isDragging
                                      ? '1px solid #E0E0E0'
                                      : 'none',
                                    boxShadow: snapshot.isDragging
                                      ? `0 2rem 8rem 0 ${alpha(
                                          theme.colors.alpha.black[100],
                                          0.1
                                        )}, 
                                                    0 0.6rem 1.6rem ${alpha(
                                                      theme.colors.alpha
                                                        .black[100],
                                                      0.2
                                                    )}, 
                                                    0 0.2rem 0.2rem ${alpha(
                                                      theme.colors.alpha
                                                        .black[100],
                                                      0.15
                                                    )}`
                                      : undefined,
                                    backgroundColor: isHovering
                                      ? theme.colors.alpha.black[5]
                                      : undefined
                                  }}
                                  onMouseOver={() => setIsHovering(true)}
                                  onMouseLeave={() => setIsHovering(false)}
                                >
                                  {renderItem ? (
                                    renderItem(item)
                                  ) : (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      sx={{
                                        width: '100%',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <DragHandle />
                                      <Typography
                                        sx={{
                                          fontSize: '14px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap'
                                        }}
                                      >
                                        {item.name}
                                      </Typography>
                                    </Stack>
                                  )}

                                  {isHovering && !hideDeleteIcon?.(item) && (
                                    <IconButton
                                      onClick={() => {
                                        onDelete(item.id);
                                      }}
                                      color="error"
                                      size="small"
                                    >
                                      <Delete />
                                    </IconButton>
                                  )}
                                </Box>
                              </>
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  </>
                );
              })}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
