import axios from 'axios';
import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import { replaceURLParams } from 'src/services/utils';
import { CAMPAIGNS_ID, USERS_MS } from 'src/utils/apiRoutes/msUsers';
import { queryClient } from 'src/utils/queryClient';

export const putCampaign = async (
  campaignDto: Campaign,
  campaignId: string
): Promise<Campaign> => {
  const path = replaceURLParams(CAMPAIGNS_ID, { '{id}': campaignId });
  const response = await axios.put(path, campaignDto);

  queryClient.invalidateQueries({
    queryKey: ['campaigns']
  });

  return response.data;
};
