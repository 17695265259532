import { Tooltip } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  hasAccess: boolean;
  children: ReactElement;
}

export const PermissionsTooltip = ({ hasAccess, children }: Props) => {
  const { t }: { t: any } = useTranslation();

  if (hasAccess) return children;

  return (
    <Tooltip arrow title={t("You don't have permissions")}>
      {children}
    </Tooltip>
  );
};
