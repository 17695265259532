import { emitCustomEvent } from 'react-custom-events';
import { setWebsocketIdDomain } from 'src/services/websocket/domain/setWebsocketIdDomain';
import { refreshResponse } from '../domain/refreshResponse';
import AuthManager from '../manager';
import { logger } from 'src/utils/logger';
import { CustomEventNames } from 'src/services/websocket/utils/notifications/dataMsgs';
import { RenewWSMessage } from 'src/models/auth';

let ignoreInvalidToken = false;

export const SessionChange = (eventWS, client) => {
  const { subtype, websocketId } = eventWS as any;

  if (subtype === 'Login') {
    setWebsocketIdDomain(websocketId);
    const subMsg = {
      type: 'Subscription',
      subscriptionType: 'All',
      timestamp: new Date().toJSON()
    };
    client.send(JSON.stringify(subMsg));
  }
  // /*
  //  * En este caso, hay que forzar un rel  ogin del usuario porque el token ya no está en bd
  //  */
  if (subtype === 'InvalidToken' && !ignoreInvalidToken) {
    logger.error('Invalid token provided or expired! Loging out');
    AuthManager.logout();
  }
  if (subtype === 'TokenExpiration') {
    logger.error('Invalid token provided or expired! Loging out');
    AuthManager.logout();
  }
  if (subtype === 'Renew') {
    //Message with the new token
    refreshResponse(eventWS);
    emitCustomEvent(CustomEventNames.RENEW, eventWS as RenewWSMessage);
  }
};
