import axios, { AxiosError } from 'axios';
import { DaService } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/daService';
import { invalidateServicesQuery } from '../views/hooks/daServiceQueries';
import { USERS_MS } from 'src/utils/apiRoutes/msUsers';
import { DirectionType } from 'src/components/TableReporting/models/direction';
import { PaginatedElements } from 'src/models/pagination';

const API_SERVICES = process.env.REACT_APP_DA_SERVICES as string;

export interface DaServicesRequestParams {
  assignedUser?: string;
  campaignTypes?: 'Call' | 'Email' | 'Telegram' | 'WebChat' | 'Whatsapp';
  companyId?: string;
  contactGroupId?: string;
  creationDateLowerBound?: string;
  creationDateUpperBound?: string;
  description?: string;
  findSubstring?: boolean; // if true, find substring at name and description
  ids?: string[];
  lastUpdateLowerBound?: string;
  lastUpdateUpperBound?: string;
  name?: string;
  showDisabled?: boolean;
  withContactGroupNames?: boolean;
  sortBy?: keyof DaService;
  sortDir?: DirectionType;
  page?: number;
  size?: number;
}

export const getDaServices = async (
  params: DaServicesRequestParams
): Promise<PaginatedElements<DaService>> => {
  const path = USERS_MS + API_SERVICES;

  const queryParams = { ...params };
  delete queryParams.withContactGroupNames;

  const resp = await axios.get(path, {
    params: queryParams,
    paramsSerializer: { indexes: null }
  });
  return resp.data;
};
//TODO: must be typed and change when it is used
export const getDaServiceById = async (
  daServiceId: string
): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES + '/' + daServiceId;

  try {
    const response = await axios.get(path);
    return response.data;
  } catch (exception: unknown) {
    throw exception as AxiosError;
  }
};

export const postDaService = async (
  daService: DaService
): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES;
  const response = await axios.post(path, daService);
  invalidateServicesQuery();
  return response.data;
};

export const putDaService = async (
  daService: DaService
): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES + '/' + daService.id;
  const response = await axios.put(path, daService);
  invalidateServicesQuery();
  return response.data;
};

export const deleteDaService = (daServiceId: string): Promise<DaService> => {
  const path = USERS_MS + API_SERVICES + '/' + daServiceId;
  return axios
    .delete(path)
    .then((response) => {
      invalidateServicesQuery();
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
