import axios from 'axios';
import { BASE_MS, BASE_URL, BASE_SHOWFLOW } from '../constants';
import qs from 'qs';
import { Showflow } from '../../models/showflow';

export interface ShowflowsFilters {
  companyId?: string;
  serviceIds?: string[];
}

export async function getShowflowsApi(filters?: ShowflowsFilters) {
  const path = `${BASE_URL}${BASE_MS}${BASE_SHOWFLOW}`;

  const response = await axios.get(path, {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

  return response.data as Showflow[];
}
