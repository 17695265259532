import { useQuery } from '@tanstack/react-query';
import {
  ScheduleCalendarFilter,
  getScheduleCalendarsApi
} from '../infrastructure/getScheduleCalendarsApi';
import { queryClient } from 'src/utils/queryClient';

export const schedulesQueryKey = 'schedulesCalendar';

export const useGetSchedulesCalendarQuery = (
  filter: ScheduleCalendarFilter
) => {
  return useQuery(
    [schedulesQueryKey, ...Object.values(filter)],
    () => {
      return getScheduleCalendarsApi(filter);
    },
    {
      enabled: true
    }
  );
};

export const useGetSchedulesCalendarQueryNoHook = (
  filter: ScheduleCalendarFilter
) => {
  const data = queryClient.getQueryData([schedulesQueryKey]);

  if (!data) {
    return queryClient.fetchQuery([schedulesQueryKey]);
  }

  return data;
};
