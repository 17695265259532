import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { ContactUI } from 'src/modules/Contacts/models/contacts';
import { useContactActionButtons } from '../../hooks/useContactActionButton';
import ContactInfoModal from './ContactInfoModal';
import {
  TableRowActionButton,
  TableToolbarActionButton
} from 'src/components/Table/models/tableActionButton';
import { DialTable } from 'src/components/DialTable';
import { Box, Stack } from '@mui/material';

interface Props {
  buttons?: {
    multipleAction: TableToolbarActionButton<ContactUI>[];
    singleAction: TableRowActionButton<ContactUI>[];
  };
}

const ContactsTable = (props: Props) => {
  const {
    isOpenEditModal,
    onCloseEditModal,
    isOpenDeleteModal,
    selectedContact,
    setIsOpenDeleteModal,
    setSelectedContact,
    contactsToDelete,
    getColumns,
    getRowActions,
    getBulkActions,
    onDeleteContacts,
    getAvailableFilters,
    getData
  } = useContactActionButtons();

  return (
    <>
      <ContactInfoModal
        key={selectedContact?.id}
        contact={selectedContact}
        setContact={setSelectedContact}
        open={isOpenEditModal}
        onClose={onCloseEditModal}
      />
      <ConfirmationModal<ContactUI, keyof ContactUI>
        dataKey={'name'}
        data={contactsToDelete}
        isOpen={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        onConfirm={onDeleteContacts}
        mode={ConfirmationModalModes.DELETE}
      />

      <Box width={'100%'} p={2}>
        <DialTable<ContactUI>
          queryKey={['contacts']}
          getColumnsFn={getColumns}
          getRowActionsFn={getRowActions}
          getDataFn={getData}
          getBulkActionsFn={getBulkActions}
          getAvailableFilters={getAvailableFilters}
          onlyEqualsOperator
        />
      </Box>
    </>
  );
};

export default ContactsTable;
