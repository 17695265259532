import { getIsMuted } from './domain/getIsMuted';
import { getMicVolume } from './domain/getMicVolume';
import { setMicDevice } from './domain/setMicDevice';
import WebRTCCallManagement from './webrtc-call-management';
import { RTCSession } from 'jssip/lib/RTCSession';
import { logger } from 'src/utils/logger';
import { setDevicesStore } from './infrastructure/store/devices/setDevices';
import { emitCustomEvent } from 'react-custom-events';
import { CustomEventNames } from '../websocket/utils/notifications/dataMsgs';
import { setMicrophoneStateStore } from 'src/modules/CTI/infrastructure/store/microphoneState';

let WEBRTC_OUT_STREAM: MediaStream;
let WEBRTC_STREAM: MediaStream;
let WEBRTC_GAIN_NODE: GainNode;
let WEBRTC_MEDIA_DEVICES: MediaDeviceInfo[];

/**
 * Request the user's media devices
 * @property exactDevices - The exact devices to request
 * @property enableVideo - Whether to enable video or not
 * @property constraints - Full constraints object to request
 */
interface RequestDevicesProps {
  exactDevices?: {
    audio?: MediaDeviceInfo;
    video?: MediaDeviceInfo;
  };
  enableVideo?: boolean;
  constraints?: MediaStreamConstraints;
}

/**
 *
 * @param props - If constraints are provided, the rest of props are ignored
 * @returns
 */
export const requestUserDevices = async (
  props?: RequestDevicesProps,
  retryNumber?: number
) => {
  const { exactDevices, enableVideo, constraints } = props ?? {
    exactDevices: undefined,
    enableVideo: false,
    constraints: undefined
  };

  try {
    setMicrophoneStateStore('prompt');
    const audioDeviceId = exactDevices?.audio?.deviceId;
    const videoDeviceId = exactDevices?.video?.deviceId;

    const audio: boolean | MediaTrackConstraints = audioDeviceId
      ? { deviceId: audioDeviceId }
      : true;
    const video: boolean | MediaTrackConstraints = enableVideo
      ? videoDeviceId
        ? { deviceId: videoDeviceId }
        : true
      : false;

    const stream = await navigator.mediaDevices?.getUserMedia?.(
      constraints ?? {
        audio,
        video
      }
    );
    setMicrophoneStateStore('granted');

    logger.log(
      exactDevices
        ? '[WEBRTC.DEVICES] Got requested device'
        : '[WEBRTC.DEVICES] Got default device',
      stream,
      exactDevices
    );

    // Create a new AudioContext, gain node, and media stream
    let audioContext = new AudioContext();
    let gainNode = audioContext.createGain();
    let audioSource = audioContext.createMediaStreamSource(stream);
    let audioDestination = audioContext.createMediaStreamDestination();

    // Connect the gain node to the new media stream
    audioSource.connect(gainNode);
    gainNode.connect(audioDestination);

    // Set the gain value to the current microphone volume
    gainNode.gain.value = getMicVolume(false);

    WEBRTC_OUT_STREAM = audioDestination.stream;
    WEBRTC_GAIN_NODE = gainNode;

    // If there is an active call, replace the audio track with the new one
    const audioTrack = stream.getAudioTracks()[0];
    const sender =
      WebRTCCallManagement.getWebRTCSession()?.connection?.getSenders()[0];
    if (sender && audioTrack) {
      sender.replaceTrack(audioTrack);
    }

    //Keep the audio track muted if the user is muted
    const isMuted = getIsMuted(false);

    const audioTracks = stream.getAudioTracks();
    audioTracks.forEach((track) => {
      track.enabled = !isMuted;
    });

    // Store the available media devices
    navigator.mediaDevices.enumerateDevices().then((devicesList) => {
      WEBRTC_MEDIA_DEVICES = devicesList;
      setDevicesStore(devicesList);
    });

    WEBRTC_STREAM = stream;
    emitCustomEvent('streamUpdated', stream);
    if (exactDevices?.audio) {
      setMicDevice(exactDevices.audio);
    }

    return stream;
  } catch (err) {
    logger.error('[WEBRTC] Error requesting user devices', err);
    const errorMessage: string = err.message;
    if (errorMessage.includes('Permission dismissed')) {
      setMicrophoneStateStore('not-enabled');
    } else {
      setMicrophoneStateStore('denied');
    }

    if (err.name === 'OverconstrainedError') {
      return;
    }

    const MAX_RETRY_NUMBER = 3;
    if (err.name !== 'NotAllowedError' && !props) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (devices.length === 0) return;

      //If the device selected is not available, try again with the default device
      if (retryNumber === undefined || retryNumber < MAX_RETRY_NUMBER) {
        requestUserDevices(props, retryNumber ? retryNumber + 1 : 1);
      }
    }
  }
};

export const sendDtmfTone = (tone: string) => {
  const session: RTCSession = WebRTCCallManagement.getWebRTCSession();
  if (session) {
    session.sendDTMF(tone);
  }
};

/**
 * Get the user's media stream without any modifications
 * @returns The user's media stream
 */
const getStream = (): MediaStream => {
  return WEBRTC_STREAM;
};

/**
 * Get the stream that is being sent to the other user with gain node applied
 * @returns Media stream
 */
const getOutStream = (): MediaStream => {
  return WEBRTC_OUT_STREAM;
};

const getGainNode = (): GainNode => {
  return WEBRTC_GAIN_NODE;
};

const getMediaDevices = (): MediaDeviceInfo[] => {
  return WEBRTC_MEDIA_DEVICES || [];
};

export const audioDeviceKey = 'audioDevice';
export const ringDeviceKey = 'ringDevice';
export const ringtoneSoundPathKey = 'ringtoneSoundPath';
export const telegramSoundPathKey = 'telegramSoundPath';
export const whatsappSoundPathKey = 'whatsappSoundPath';
export const emailSoundPathKey = 'emailSoundPath';
export const webchatSoundPathKey = 'webchatSoundPath';

const WebRTCDevices = {
  getOutStream,
  getGainNode,
  getMediaDevices,
  getStream,
  requestUserDevices
};

export default WebRTCDevices;
