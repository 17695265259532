import { queryClient } from 'src/utils/queryClient';
import { ContactsDomain } from '../../domain/ContactsDomain';
import { ContactsRequestParams } from '../../models/contacts';

export const getContactsQuery = async (params: ContactsRequestParams) => {
  return queryClient.fetchQuery(
    ['contacts', params],
    () => ContactsDomain.getContacts(params),
    { staleTime: 60 * 1000 }
  );
};
