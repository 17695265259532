import { queryClient } from 'src/utils/queryClient';
import {
  ShowflowsFilters,
  getShowflowsApi
} from '../../infrastructure/showflow/getShowflowsApi';
import { useQuery } from '@tanstack/react-query';

const queryDependencies = (filters?: ShowflowsFilters) => {
  return ['showflows', filters?.companyId, ...filters?.serviceIds];
};

export function getShowflowsQuery(filters?: ShowflowsFilters) {
  return queryClient.fetchQuery(queryDependencies(filters), {
    queryFn: () => getShowflowsApi(filters),
    staleTime: 60 * 5 * 1000
  });
}

export function useShowflowsQuery(filters?: ShowflowsFilters) {
  return useQuery(
    queryDependencies(filters),
    () => {
      if (!filters.serviceIds || filters.serviceIds.length === 0) {
        return [];
      }
      return getShowflowsApi(filters);
    },
    {
      staleTime: 60 * 5 * 1000
    }
  );
}
