import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useContext } from 'react';
import {
  CTIBarContext,
  NewConversationMenu,
  useDialpadStateContext
} from 'src/contexts/CTIBarContext';
import parsePhoneNumber from 'libphonenumber-js';

interface ContactDataHeaderProps {
  name: string;
  phoneNumber: string;
  contactHeaderDirection: 'column' | 'row';
}

const ContactDataHeader = (props: ContactDataHeaderProps) => {
  const { name, phoneNumber, contactHeaderDirection = 'column' } = props;
  const theme = useTheme();
  const { setActiveTab, openPopover } = useContext(CTIBarContext);
  const { setInputValue, setConversationSelected } = useDialpadStateContext();

  return (
    <>
      {contactHeaderDirection === 'row' ? (
        <Stack
          direction="row"
          justifyContent={'center'}
          alignItems="center"
          width="100%"
          spacing={2}
          marginY={2}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: theme.colors.secondary.lighter,
              borderRadius: '50%',
              height: theme.spacing(7),
              width: theme.spacing(7),
              fontWeight: 'bold',
              fontSize: '25px'
            }}
          >
            {name?.toUpperCase().charAt(0)}
          </Stack>
          <Stack
            direction="column"
            justifyContent={'center'}
            alignItems="flex-start"
          >
            <h3 style={{ margin: 0 }}>{name}</h3>

            <h4
              style={{ margin: 0, color: theme.colors.secondary.main }}
              onClick={() => {
                openPopover('New conversation');
                setActiveTab('dialpad');
                setConversationSelected(NewConversationMenu.Call);
                setInputValue(phoneNumber);
              }}
            >
              {phoneNumber &&
                parsePhoneNumber(`+${phoneNumber}`)?.formatInternational?.()}
            </h4>
          </Stack>
        </Stack>
      ) : (
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Avatar
            sx={{
              mx: 'auto',
              my: 1,
              width: theme.spacing(12),
              height: theme.spacing(12)
            }}
            alt={name ? name : 'Avatar'}
          >
            <Typography variant="h1">{name ? name[0] : ''}</Typography>
          </Avatar>
          <Typography variant="h4">{name ? name : ''}</Typography>
          {phoneNumber && (
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              sx={{
                boxShadow: 0,
                '&:hover': { backgroundColor: 'transparent' }
              }}
              onClick={() => {
                openPopover('New conversation');
                setActiveTab('dialpad');
                setConversationSelected(NewConversationMenu.Call);
                setInputValue(phoneNumber);
              }}
              id="btnNumber-ContactDataHeader"
            >
              <Typography variant="subtitle2" color="secondary">
                {parsePhoneNumber(`+${phoneNumber}`).formatInternational()}
              </Typography>
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default ContactDataHeader;
