import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { CountryCode } from 'libphonenumber-js';
import {
  ContactField,
  ContactFieldType,
  ContactFieldTypeNames,
  Contact,
  ContactTag
} from 'src/modules/Contacts/models/contacts';
import { FieldArray, Formik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  styled,
  TextField,
  useTheme
} from '@mui/material';
import HelperAccordion from '../../components/HelperAccordion';
import { Box } from '@mui/system';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getContactFieldsFromForm } from 'src/services/contacts/application/utils';
import { useAlert } from 'src/hooks/useAlert';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { unformatPhoneNumber } from 'src/utils/formatPhoneNumber';
import i18n from 'src/utils/react-libreria-html/src/i18n';
import { logger } from 'src/utils/logger';
import { ContactGroupsSelector } from 'src/modules/Contacts/views/components/contactGroups/selector';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkCreateContactPermissions,
  checkDeleteContactPermissions,
  checkUpdateContactPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import { emitCustomEvent } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { CreateTagPopoverForm } from 'src/modules/Contacts/views/components/tags/CreateTagPopoverForm';
import AddIcon from '@mui/icons-material/Add';
import Scrollbar from 'src/components/Scrollbar';
import useContactDataForm, {
  ContactDataFormValues
} from './useContactDataForm';
import { t } from 'i18next';

const ListItemIconWrapper = styled(ListItemIcon)(
  ({ theme }) => `
		  min-width: 36px;
		  color: ${theme.colors.secondary.main};
  `
);

interface Props {
  contact?: Contact;
  setContact?: Dispatch<SetStateAction<Contact>>;
  onSubmitContact: (contact: Contact) => Promise<Contact>;
  /**Required when isCreatingContact is equals to false*/
  onDeleteContact?: (contact: Contact) => Promise<void>;
  onClose: (contact: Contact) => void;
  /**Function triggered after triggering onDeleteContact function. By default, it is equals to onClose*/
  onDeleteClose?: () => void;
  isCreatingContact: boolean;
  /** If true (default), it sets the contact to the added or updated contact. If false, it sets the  contact to null */
  isSetContact?: boolean;
  /** If true (default), it sets the contact to null when cancelling.*/
  cancelToNull?: boolean;
  defaultContactGroupId?: string;
  saveButtonLabel?: string;
  disableContactGroupSelector?: boolean;
}

const ContactDataForm = (props: Props) => {
  const {
    contact,
    setContact,
    onSubmitContact,
    onDeleteContact,
    onClose,
    onDeleteClose = onClose,
    isCreatingContact,
    isSetContact = true,
    cancelToNull = true,
    defaultContactGroupId,
    saveButtonLabel,
    disableContactGroupSelector = false
  } = props;

  const theme = useTheme();
  const { showAlert } = useAlert();

  //permissions
  const { hasAccess } = useContext(PermissionsContext);
  const hasContactAccess =
    (isCreatingContact && hasAccess(checkCreateContactPermissions)) ||
    (!isCreatingContact && hasAccess(checkUpdateContactPermissions));
  const hasDeleteContactAccess = hasAccess(checkDeleteContactPermissions);

  //language and default call country code
  const navigatorLanguage = navigator.language.split('-');
  const langOfCountryName = i18n.language || navigatorLanguage[0];

  const accordionExcludedTypes: ContactFieldType[] = ['Name', 'TelegramChatId'];

  const [isDeleting, setIsDeleting] = useState(false);

  const {
    //general
    companyId,
    contactGroupId,
    setContactGroupId,

    //contact fields
    contactFields,
    fieldTypes,
    fieldNames,
    requiredFields,
    hasEmptyRequiredFields,

    //tags
    allContactTags,
    setAllContactTags,
    openNewTagModal,
    setOpenNewTagModal,
    addNewTag,
    selectedTags,
    setSelectedTags,

    //formik props
    initialValues,
    validationSchema
  } = useContactDataForm({ contact, defaultContactGroupId });

  return (
    <>
      {isCreatingContact && (
        <Stack px={2} pt={1}>
          <ContactGroupsSelector
            contactGroupId={contactGroupId}
            setContactGroupId={setContactGroupId}
            disabled={disableContactGroupSelector}
          />
        </Stack>
      )}
      <Formik
        // Add submit to initialFieldValues
        key={JSON.stringify(initialValues)}
        initialValues={{
          ...initialValues,
          submit: null
        }}
        validationSchema={validationSchema}
        onSubmit={async (
          _values: ContactDataFormValues,
          { setErrors, setStatus, setSubmitting }
        ) => {
          // SUBMIT
          const newFields = getContactFieldsFromForm(contactFields, _values);

          // TO DO: Convert, transform or adapt phone contact fields

          const newContact: Contact = {
            ...contact,
            companyId: companyId,
            fields: newFields,
            tags: selectedTags,
            contactGroupId: contactGroupId
          };

          try {
            setStatus({ success: true });
            setSubmitting(false);
            const contact = await onSubmitContact(newContact);
            if (setContact && isSetContact) {
              setContact(contact);
            } else if (setContact && !isSetContact) {
              setContact(null);
            }
            onClose(contact);
            emitCustomEvent(REFETCH_DATA_EVENT_NAME);
          } catch (err) {
            const errorMessage = t(getErrorMessage(err).errorMessage);
            const errorString = isCreatingContact ? 'created' : 'updated';

            showAlert(
              `Contact could not be ${errorString}. ${errorMessage}`,
              'error',
              2000
            );

            logger.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setValues,
          isValid
        }) => {
          const onChangePhoneNumber = (
            phoneNumber: string,
            data: MuiTelInputInfo,
            fieldType: ContactFieldType,
            position: number
          ) => {
            const newFieldsArray = [...values[fieldType]];

            newFieldsArray[position].value =
              `+${data.countryCallingCode}` === phoneNumber
                ? data.countryCallingCode
                : unformatPhoneNumber(phoneNumber);

            setValues({
              ...values,
              [fieldType]: newFieldsArray
            });
          };
          return (
            <form
              onSubmit={handleSubmit}
              style={{
                height: '100%',
                flex: 2,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Stack pt={1} pb={2} px={2} spacing={2} height="100%">
                <Scrollbar>
                  <Stack spacing={2} pb={2} pt={1}>
                    {/* NAME TEXTFIELDS */}
                    {/* Return a Textfield for each ContactField with type equals to 'Name'*/}
                    {values['Name'] &&
                      values['Name'].map((field: ContactField, i) => {
                        return (
                          <TextField
                            id={`contact-name-${field.name.replace(
                              /\s/g,
                              '-'
                            )}-input`}
                            key={field.name + i}
                            error={
                              touched?.[field.type]?.[i]?.value &&
                              Boolean(errors?.[field.type]?.[i]?.value)
                            }
                            fullWidth
                            helperText={
                              touched?.[field.type]?.[i]?.value &&
                              errors?.[field.type]?.[i]?.value
                            }
                            label={t(field.name)}
                            name={`Name.${i}.value`}
                            value={field.value}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            required={requiredFields.includes(field.name)}
                            sx={{ mt: 1 }}
                          />
                        );
                      })}
                    {/* TAGS AUTOCOMPLETE */}
                    {/* Return the tags */}
                    {contactGroupId && (
                      <Box display="flex" gap={1} alignItems="flex-end">
                        <Autocomplete
                          multiple
                          fullWidth
                          id="tags-outlined"
                          options={allContactTags}
                          getOptionLabel={(tag: ContactTag) => t(tag?.name)}
                          onChange={(_, values: ContactTag[]) =>
                            setSelectedTags([...values.map((v) => v.id)])
                          }
                          sx={{ marginTop: 1 }}
                          value={allContactTags.filter((tag) => {
                            return selectedTags.includes(tag.id);
                          })}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tags"
                              placeholder={t('Contact Tags')}
                              name="tags"
                            />
                          )}
                        />
                        <Box justifyContent="center">
                          <Button
                            variant="outlined"
                            color="secondary"
                            title={t('Create new tag')}
                            onClick={addNewTag}
                            sx={{ width: '53px', height: '53px' }}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Stack>
                  {/* CONTACT DATA ACCORDION */}
                  {/* Return a ContactDataAccordion with a FieldArray for each field type */}
                  {fieldTypes &&
                    fieldTypes
                      .filter((f) => !accordionExcludedTypes.includes(f))
                      .map((fieldType: ContactFieldType, i) => {
                        const accordionTitle = `${t(ContactFieldTypeNames[fieldType])} ${
                          hasEmptyRequiredFields(values[fieldType], fieldType)
                            ? '*'
                            : ''
                        }`;

                        return (
                          <HelperAccordion
                            key={fieldType + i}
                            title={accordionTitle}
                          >
                            {/* ARRAY OF FIELDS */}
                            <FieldArray
                              name={fieldType}
                              render={(arrayHelpers) => (
                                <List key={fieldType + i + i}>
                                  {/* return an input list item for each field (ContactField)*/}
                                  {values[fieldType] &&
                                    values[fieldType].length > 0 &&
                                    values[fieldType].map(
                                      (field: ContactField, i: number) => {
                                        return (
                                          <ListItem
                                            key={field.id + i}
                                            sx={{ width: '100%' }}
                                          >
                                            <Box
                                              display="flex"
                                              flexDirection="column"
                                              justifyContent="start"
                                              width="100%"
                                            >
                                              <Stack
                                                direction="row"
                                                alignItems="flex-start"
                                                spacing={theme.spacing(0.5)}
                                              >
                                                {/* DELETE A FIELD */}
                                                <ListItemIcon
                                                  sx={{
                                                    minWidth: '36px',
                                                    pt: 2
                                                  }}
                                                >
                                                  <IconButton
                                                    disableRipple
                                                    disableFocusRipple
                                                    disableTouchRipple
                                                    sx={{
                                                      boxSizing: 0,
                                                      padding: 0
                                                    }}
                                                    onClick={() =>
                                                      // remove a field from the list
                                                      arrayHelpers.remove(i)
                                                    }
                                                    disabled={requiredFields.includes(
                                                      field.name
                                                    )}
                                                    color="error"
                                                  >
                                                    <RemoveCircleIcon />
                                                  </IconButton>
                                                </ListItemIcon>
                                                {/* SELECT FIELD NAME */}
                                                <TextField
                                                  id={`${fieldType.toLowerCase()}-${i}-contact-select`}
                                                  select
                                                  label={t('Select')}
                                                  // disableUnderline
                                                  fullWidth
                                                  name={`${fieldType}.${i}.name`}
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  value={
                                                    values?.[fieldType]?.[i]
                                                      ?.name
                                                  }
                                                  variant="standard"
                                                  disabled={requiredFields.includes(
                                                    field.name
                                                  )}
                                                >
                                                  {contactFields
                                                    .filter(
                                                      (f) =>
                                                        f.type === fieldType &&
                                                        f.autoGenerated ===
                                                          false
                                                    )
                                                    .map((field, i) => (
                                                      <MenuItem
                                                        key={
                                                          field.name + field.id
                                                        }
                                                        value={field.name}
                                                        disabled={values?.[
                                                          fieldType
                                                        ]
                                                          ?.map(
                                                            (
                                                              contactField: ContactField
                                                            ) =>
                                                              contactField.name
                                                          )
                                                          .includes(
                                                            field?.name
                                                          )}
                                                      >
                                                        {`${field?.name}${requiredFields.includes(field.name) ? '*' : ''}`}
                                                      </MenuItem>
                                                    ))}
                                                </TextField>
                                                {/* TYPE FIELD VALUE */}

                                                {fieldType === 'Phone' ? (
                                                  <MuiTelInput
                                                    name={`${fieldType}.${i}.value`}
                                                    fullWidth
                                                    langOfCountryName={
                                                      langOfCountryName
                                                    }
                                                    defaultCountry={
                                                      (navigatorLanguage[1] as CountryCode) ??
                                                      i18n.language
                                                    }
                                                    sx={{ pt: '16px' }}
                                                    variant="standard"
                                                    forceCallingCode
                                                    value={
                                                      '+' +
                                                      values?.[fieldType]?.[i]
                                                        ?.value
                                                    }
                                                    onChange={(
                                                      phoneNumber,
                                                      data
                                                    ) => {
                                                      return onChangePhoneNumber(
                                                        phoneNumber,
                                                        data,
                                                        fieldType,
                                                        i
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={
                                                      touched?.[fieldType]?.[i]
                                                        ?.value &&
                                                      Boolean(
                                                        errors?.['Phone']?.[i]
                                                      )
                                                    }
                                                    helperText={
                                                      touched?.[fieldType]?.[i]
                                                        ?.value &&
                                                      errors?.[fieldType]?.[
                                                        i
                                                      ]?.['value']
                                                    }
                                                  />
                                                ) : (
                                                  <TextField
                                                    id={`${fieldType.toLowerCase()}-${i}-contact-textfield`}
                                                    label={t('Type')}
                                                    name={`${fieldType}.${i}.value`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={
                                                      values?.[fieldType]?.[i]
                                                        ?.value
                                                    }
                                                    variant="standard"
                                                    error={
                                                      touched?.[fieldType]?.[i]
                                                        ?.value &&
                                                      Boolean(
                                                        errors?.[fieldType]?.[i]
                                                          ?.value
                                                      )
                                                    }
                                                    fullWidth
                                                    helperText={
                                                      touched?.[fieldType]?.[i]
                                                        ?.value &&
                                                      errors?.[fieldType]?.[i]
                                                        ?.value
                                                    }
                                                  />
                                                )}
                                              </Stack>
                                            </Box>
                                          </ListItem>
                                        );
                                      }
                                    )}
                                  {/* for each field type, display a Button for adding fields 
                          but only if the length of the array of values of that type is less than 
                          the length of the company contact fields of that type*/}
                                  {fieldNames &&
                                    values[fieldType] &&
                                    values[fieldType].length <
                                      contactFields.filter(
                                        (field) => field.type === fieldType
                                      ).length && (
                                      // ADD NEW FIELD
                                      <ListItemButton
                                        id={`add-${fieldType.toLowerCase()}-contact-button`}
                                        onClick={() =>
                                          arrayHelpers.push({
                                            type: fieldType,
                                            name: '',
                                            value: ''
                                          })
                                        }
                                      >
                                        <Box
                                          display="flex"
                                          flexDirection="column"
                                          justifyContent="start"
                                        >
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <ListItemIconWrapper>
                                              <AddCircleIcon />
                                            </ListItemIconWrapper>
                                            <ListItemText
                                              color="primary"
                                              primary={t('Add')}
                                              primaryTypographyProps={{
                                                variant: 'h5'
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      </ListItemButton>
                                    )}
                                </List>
                              )}
                            />
                          </HelperAccordion>
                        );
                      })}
                  {/* DELETE CONTACT */}
                  {/* if the contact is to be edited, a delete button is rendered */}
                  {!isCreatingContact && hasDeleteContactAccess && (
                    <PermissionsTooltip hasAccess={hasDeleteContactAccess}>
                      <Button
                        fullWidth
                        startIcon={
                          isDeleting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={
                          Boolean(errors.submit) ||
                          isSubmitting ||
                          isDeleting ||
                          !hasDeleteContactAccess
                        }
                        variant="outlined"
                        size="large"
                        color="error"
                        sx={{ mt: 2, mb: 2 }}
                        onClick={async () => {
                          if (!onDeleteContact) {
                            throw new Error(
                              'It is required to add the onDeleteContact prop'
                            );
                          }
                          setIsDeleting(true);
                          try {
                            await onDeleteContact(contact);
                            setIsDeleting(false);
                            if (setContact) setContact(null);
                            onDeleteClose(contact);
                          } catch (error) {
                            logger.error(error);
                            showAlert(
                              `Contact could not be updated. ${t(
                                getErrorMessage(error)?.errorMessage
                              )}`,
                              'error',
                              2000
                            );
                          }
                        }}
                      >
                        {t('Delete Contact')}
                      </Button>
                    </PermissionsTooltip>
                  )}
                </Scrollbar>
              </Stack>
              <Divider />
              <Stack
                px={1.5}
                py={1.5}
                width="100%"
                direction={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent={'space-between'}
                spacing={1.5}
              >
                {/* CANCEL */}
                <Button
                  disabled={
                    Boolean(errors.submit) || isSubmitting || isDeleting
                  }
                  variant="outlined"
                  onClick={() => {
                    if (setContact && cancelToNull) setContact(null);
                    onClose(null);
                  }}
                  color="secondary"
                >
                  {t('Cancel')}
                </Button>
                {/* SAVE CONTACT */}
                <PermissionsTooltip hasAccess={hasContactAccess}>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={
                      Boolean(errors.submit) ||
                      !isValid ||
                      isSubmitting ||
                      isDeleting ||
                      !contactGroupId ||
                      !hasContactAccess
                    }
                    variant="contained"
                    color="secondary"
                  >
                    {!saveButtonLabel?.length ? t('Save') : saveButtonLabel}
                  </Button>
                </PermissionsTooltip>
              </Stack>
            </form>
          );
        }}
      </Formik>

      <Popover
        open={openNewTagModal}
        onClose={() => setOpenNewTagModal(false)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        <CreateTagPopoverForm
          defaultContactGroupId={contactGroupId}
          onClose={() => setOpenNewTagModal(false)}
          onData={(resp) => {
            setAllContactTags([resp, ...allContactTags]);
            setSelectedTags([...selectedTags, resp.id]);
          }}
          disableContactGroupSelector={disableContactGroupSelector}
        />
      </Popover>
    </>
  );
};

export default ContactDataForm;
