import { PaginatedElements } from 'src/models/pagination';
import { getReportingAttachmentsApi } from '../infrastructure/getReportingAttachmentsApi';
import {
  ReportingAttachment,
  ReportingAttachmentUI,
  RetrieveReportingAttachmentsParams
} from '../models/reportingAttachment';
import { getReportingAttachmentDetailsListText } from './getReportingAttachmentDetailsText';
import i18n from 'src/utils/react-libreria-html/src/i18n';
import moment from 'moment';

export const getReportingAttachments = async (
  params: RetrieveReportingAttachmentsParams
) => {
  const resp = await getReportingAttachmentsApi(params);
  return resp;
};

export const getReportingAttachmentUI = async (
  reportingAttachment: ReportingAttachment
) => {
  const detailsText =
    await getReportingAttachmentDetailsListText(reportingAttachment);
  const timeStampWithFraction = reportingAttachment?.creationDate;
  // Convert the timestamp to a Moment.js object
  const momentObject = moment.unix(timeStampWithFraction);
  // Set the locale to the selected language
  momentObject.locale(i18n.language);
  // Format the date to a locale string
  const formattedCreationDate = momentObject.format('LLLL');
  const reportingAttachmentUI: ReportingAttachmentUI = {
    ...reportingAttachment,
    detailsText,
    formattedCreationDate
  };
  return reportingAttachmentUI;
};

export const getReportingAttachmentsUI = async (
  params: RetrieveReportingAttachmentsParams
) => {
  const resp = await getReportingAttachments(params);
  const attachmentsUIPromises = resp.elements.map(getReportingAttachmentUI);
  const attachementsUI = await Promise.all(attachmentsUIPromises);

  const paginatedAttachmentsUI: PaginatedElements<ReportingAttachmentUI> = {
    ...resp,
    elements: attachementsUI
  };
  return paginatedAttachmentsUI;
};
