import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { Permissions } from 'src/models/permissionGroups';

interface Props {
  permissionCheckerFn: (permissions: Permissions) => boolean;
  navigate?: boolean;
  fallback?: JSX.Element | string;
  loadingElement?: JSX.Element | string;
}

const Restricted: FC<Props> = ({
  permissionCheckerFn,
  fallback,
  loadingElement,
  navigate = false,
  children
}) => {
  // const [isLoading, allowed] = usePermissions(permissionCheckerFn);
  const { hasAccess, loading } = useContext(PermissionsContext);
  const isAllowed = hasAccess(permissionCheckerFn);

  // When the navigate param is sent and the user does not have permissions to access the route, the user is redirected to a PUBLIC route
  if (navigate && loading === 'succeeded' && !isAllowed) {
    return <Navigate to="/dashboard/home" replace />;
  }
  // When the user permissions are loading, a loading element is returned
  if (loading === 'pending') return <>{loadingElement}</>;

  // When the user is allowed to access to the route, the children is returned
  if (isAllowed) {
    return <>{children}</>;
  }

  // When the navigate param is not sent, a fallback element is returned
  return <>{fallback}</>;
};

export default Restricted;
