import { useContext, useState } from 'react';
import { getContactTagsQuery } from 'src/services/socialmedia/application/query/getCompanyContactTagsQuery';
import { Delete, Edit } from '@mui/icons-material';
import { ContactGroup, ContactTag } from '../../models/contacts';
import AuthManager from 'src/services/authentication/manager';
import { ContactsDomain } from '../../domain/ContactsDomain';
import { emitCustomEvent } from 'react-custom-events';
import { useAlert } from 'src/hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { ColumnDef, Row } from '@tanstack/react-table';
import { IconButton, Stack } from '@mui/material';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import {
  checkDeleteContactTagsPermissions,
  checkUpdateContactGroupPermissions
} from 'src/services/permissionGroups/domain/checkPermissions';
import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import { getNameContactGroup } from '../../domain/getContactGroups';
import { TagFilters } from '../../infraestructure/api/getContactTagsApi';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import {
  DialTableBulkActionsContext,
  DialTableFiltersContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';

export const useTags = () => {
  const companyId = AuthManager.getLoggedUserCompanyId();
  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const { hasAccess } = useContext(PermissionsContext);
  const [tagToEdit, setTagToEdit] = useState<ContactTag | undefined>(undefined);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [tagsToDelete, setTagsToDelete] = useState<ContactTag[]>([]);
  const [contactGroupsData, setContactGroupsData] = useState<ContactGroup[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<Row<ContactTag>[]>([]);
  const [filters, setFilters] = useState<TagFilters>({
    companyId,
    contactGroupId: undefined
  });

  const tagsQuery = getContactTagsQuery(filters, true);
  const data = tagsQuery.data?.elements ?? [];

  const onDeleteTags = async (tags: ContactTag[]) => {
    try {
      await ContactsDomain.deleteTags(tags);
      showAlert(
        t(
          `The tag${tags.length > 1 ? 's' : ''} ${tags
            .map((contact) => contact.name)
            .join(', ')} was successfully deleted`
        ),
        'success',
        2000
      );
      emitCustomEvent(REFETCH_DATA_EVENT_NAME);
    } catch (error) {
      showAlert(
        t(
          `The tag${tags.length > 1 ? 's' : ''} ${tags
            .map((tag) => tag.name)
            .join(', ')} could not be deleted`
        ),
        'error',
        2000
      );
    }
    selectedRows.map((row) => row.toggleSelected());
    setSelectedRows([]);
    setTagsToDelete([]);
    setIsOpenDeleteModal(false);
  };

  const getColumns: () => Promise<ColumnDef<ContactTag>[]> = async () => [
    {
      accessorKey: 'name',
      id: 'name',
      header: t('Name')
    },
    {
      accessorKey: 'contactGroupId',
      id: 'contactGroupId',
      header: t('Group'),
      cell: (props) => (
        <>
          {getNameContactGroup(props.getValue() as string, contactGroupsData)}
        </>
      )
    }
  ];

  const getRowActions = ({
    rowData: tag
  }: DialTableRowActionsContext<ContactTag>) => [
    <IconButton
      key="editTag"
      title={t('Edit')}
      size="small"
      color="secondary"
      onClick={() => {
        setTagToEdit(tag);
      }}
      disabled={!hasAccess(checkUpdateContactGroupPermissions)}
    >
      <Edit fontSize="small" />
    </IconButton>,
    <IconButton
      key="deleteTag"
      title={t('Delete')}
      size="small"
      color="error"
      onClick={() => {
        setTagsToDelete([tag]);
        setIsOpenDeleteModal(true);
      }}
      disabled={
        !hasAccess(checkUpdateContactGroupPermissions) ||
        !hasAccess(checkDeleteContactTagsPermissions)
      }
    >
      <Delete fontSize="small" />
    </IconButton>
  ];

  const getBulkActions = ({
    selectedData: tags,
    selectedRowModel
  }: DialTableBulkActionsContext<ContactTag>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          color="error"
          title={t('Delete tags')}
          size="small"
          onClick={() => {
            setTagsToDelete([...tags]);
            setIsOpenDeleteModal(true);
            setSelectedRows(selectedRowModel.rows);
          }}
          disabled={!hasAccess(checkUpdateContactGroupPermissions)}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Stack>
    );
  };

  const getAvailableFilters = () => {
    const filters: DialTableFilter<ContactTag>[] = [
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'contactGroupId',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: DataCategory.CONTACT_GROUP_ID
      }
    ];
    return Promise.resolve(filters);
  };

  function applyFilters({
    filtersObject
  }: DialTableFiltersContext<ContactTag>) {
    const params: TagFilters = {
      companyId,
      contactGroupId: filtersObject['contactGroupId']?.[0] ?? undefined
    };
    setFilters(params);
  }

  return {
    tagToEdit,
    setTagToEdit,
    tagsToDelete,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    onDeleteTags,
    getColumns,
    getRowActions,
    getBulkActions,
    getAvailableFilters,
    companyId,
    setContactGroupsData,
    filters,
    applyFilters,
    data
  };
};
