import { Download, FilePresent, Panorama } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { ChannelType } from 'src/models/conversations/conversations';
import { EmailAttachment } from 'src/models/conversations/messages';
import {
  downloadAttachment,
  formatAttachmentUrl,
  getBlobUrl
} from 'src/modules/Shared/utils/attachments';

interface Props {
  attachment: EmailAttachment;
}

function EmailAttachmentItem({ attachment }: Props) {
  const { uploadedFile } = attachment;
  const type = uploadedFile.name?.split('.').pop();

  const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(type);

  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    const fileUrl = formatAttachmentUrl(uploadedFile, ChannelType.EMAIL);
    getBlobUrl(fileUrl).then((url) => {
      setBlobUrl(url);
    });
  }, [attachment.uploadedFile.name]);

  const variants: Variants = {
    initial: {
      height: '36px'
    },
    attachmentLoaded: {
      height: '137px'
    }
  };

  const sizeString = useMemo(() => {
    if (uploadedFile.sizeKb < 1000) {
      return `${uploadedFile.sizeKb} KB`;
    }
    return `${(uploadedFile.sizeKb / 1000).toFixed(2)} MB`;
  }, [uploadedFile.sizeKb]);

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Tooltip arrow title={`${uploadedFile.name}`}>
      <motion.div
        onClick={() => downloadAttachment(uploadedFile, ChannelType.EMAIL)}
        animate={blobUrl.length > 0 ? 'attachmentLoaded' : 'initial'}
        variants={variants}
        aria-label="attachment"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '160px',
          background: '#F5F5F5',
          borderRadius: '5px',
          border: '1px solid #E0E0E0',
          cursor: 'pointer',
          overflow: 'auto'
        }}
      >
        {blobUrl && (
          <motion.div
            initial={{
              opacity: 0,
              height: '0px'
            }}
            animate={{
              opacity: 1,
              height: '100px'
            }}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isImage ? (
              <img
                src={blobUrl}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '5px 5px 0 0'
                }}
                alt="Image preview"
              />
            ) : (
              <Typography>{type?.toLocaleUpperCase()}</Typography>
            )}
          </motion.div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '8px',
            background: '#F5F5F5',
            padding: '5px',
            position: 'relative'
          }}
        >
          {isHovering ? <Download /> : isImage ? <Panorama /> : <FilePresent />}

          <div
            style={{
              overflow: 'hidden',
              width: '116px',
              display: 'flex',
              flexDirection: 'row-reverse',
              position: 'relative',
              height: '100%'
            }}
          >
            <AnimatePresence>
              {!isHovering && (
                <motion.div
                  key={uploadedFile.name}
                  initial={{ opacity: 0, x: 3 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 3 }}
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '116px',
                    position: 'absolute',
                    top: 0,
                    left: 0
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '12px',
                      display: 'block',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      maxWidth: '116px',
                      overflow: 'hidden'
                    }}
                  >
                    {uploadedFile.name}
                  </Typography>
                </motion.div>
              )}
              {isHovering && (
                <motion.div
                  key={sizeString}
                  initial={{ opacity: 0, x: -3 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -3 }}
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '12px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold'
                    }}
                  >
                    {sizeString}
                  </Typography>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </Tooltip>
  );
}

export default EmailAttachmentItem;
