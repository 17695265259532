import { Campaign } from 'src/modules/Admin/modules/Operations/modules/DaServices/models/campaigns';
import {
  CampaignsParams,
  getCampaignsApi
} from '../infrastructure/campaigns/getCampaigns';
import { putCampaign } from '../infrastructure/campaigns/putCampaigns';

/** GET ALL CAMPAIGNS
 *
 * Get all campaigns in database
 *
 *
 * @returns array of campaigns
 */
export const getAllCampaigns = async (campaignsParams: CampaignsParams) => {
  const { page = 0, size = -1 } = campaignsParams;
  const allCampaigns = await getCampaignsApi({
    ...campaignsParams,
    page,
    size
  });
  return allCampaigns;
};

export const enableCampaign = async (campaign: Campaign): Promise<Campaign> => {
  const campaignDto: Campaign = { ...campaign, enabled: true };
  const campaignId = campaign.id;
  const response = await putCampaign(campaignDto, campaignId);
  return response;
};

export const disableCampaign = async (
  campaign: Campaign
): Promise<Campaign> => {
  const campaignDto: Campaign = { ...campaign, enabled: false };
  const campaignId = campaign.id;
  const response = await putCampaign(campaignDto, campaignId);
  return response;
};
