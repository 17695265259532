import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { Variable } from '../../models/models';
import { IconButton, Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useState } from 'react';
import { deleteVariablesDomain } from '../../domain/deleteVariablesDomain';
import { useAlert } from 'src/hooks/useAlert';
import { useCompanyVariablesQuery } from '../../application/getVariablesQuery';
import {
  DataCategory,
  DialTableFilter,
  FieldDataType,
  FieldType,
  StringOperator
} from 'src/components/DialTable/models/filters';
import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';
import { getVariablesApi } from '../../infrastructure/getVariablesApi';
import { getPaginatedElements } from 'src/components/DialTable/utils/getOptionValues';
import { emitCustomEvent } from 'react-custom-events';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { ChannelType } from 'src/models/conversations/conversations';
import {
  DialTableBulkActionsContext,
  DialTableDataContext,
  DialTableRowActionsContext
} from 'src/components/DialTable/models/functionContexts';

export const useVariables = (companyId: string) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const variablesQuery = useCompanyVariablesQuery({ companyId });
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false);
  const [variableToEdit, setVariableToEdit] = useState<Variable | undefined>();
  const [variablesToDelete, setVariablesToDelete] = useState<Variable[]>([]);
  const isDeleteModalOpened = variablesToDelete.length > 0;

  async function getDataFn({ filtersObject }: DialTableDataContext<Variable>) {
    const companyId = filtersObject.companyId?.[0];
    const channel = filtersObject.channel?.[0] as ChannelType;
    const contactGroupId = filtersObject.contactGroupId?.[0];

    const onlyDefault = filtersObject.default?.[0] === 'true';

    const response = await getVariablesApi({
      companyId,
      channel,
      contactGroupId
    });

    if (onlyDefault) {
      return getPaginatedElements(
        response.filter((variable) => variable.default)
      );
    }

    return getPaginatedElements(response);
  }

  async function handleDelete(variables: Variable[]) {
    try {
      await deleteVariablesDomain(variables);
      emitCustomEvent(REFETCH_DATA_EVENT_NAME);
      showAlert(
        t(
          `The variable${variables.length > 1 ? 's' : ''} ${variables
            .map((contact) => contact.name)
            .join(', ')} was successfully deleted`
        ),
        'success',
        2000
      );
    } catch (err) {
      showAlert(
        t(
          `The variable${variables.length > 1 ? 's' : ''} ${variables
            .map((contact) => contact.name)
            .join(', ')} could not be deleted`
        ),
        'warning',
        2000
      );
    }
  }

  const getColumns: () => Promise<ColumnDef<Variable>[]> = async () => [
    {
      accessorKey: 'name',
      id: 'name',
      header: t('Name')
    },
    {
      accessorKey: 'description',
      id: 'description',
      header: t('Description')
    },
    {
      accessorKey: 'scope',
      id: 'scope',
      header: t('Scope')
    },
    {
      accessorKey: 'value',
      id: 'value',
      header: t('Value')
    }
  ];

  const getRowActions = ({
    rowData: variable
  }: DialTableRowActionsContext<Variable>) => [
    <IconButton
      key="editVariable"
      title={t('Edit variable')}
      size="small"
      color="secondary"
      onClick={async () => {
        setIsUpdateModalOpened(true);
        setVariableToEdit(variable);
      }}
    >
      <Edit fontSize="small" />
    </IconButton>,
    <IconButton
      key="deleteVariable"
      title={t('Delete variable')}
      size="small"
      color="error"
      onClick={async () => {
        setVariablesToDelete([variable]);
      }}
    >
      <Delete fontSize="small" />
    </IconButton>
  ];

  const getMultiActions = ({
    selectedData: selectedVariables
  }: DialTableBulkActionsContext<Variable>) => {
    return (
      <Stack direction="row" columnGap={1}>
        <IconButton
          color="error"
          title={'Delete variables'}
          size="small"
          onClick={() => {
            setVariablesToDelete([...selectedVariables]);
          }}
        >
          <Delete />
        </IconButton>
      </Stack>
    );
  };

  const getMultiFilters = async () => {
    const filters: DialTableFilter<Variable>[] = [
      {
        fieldType: FieldType.TEXT,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'contactGroupId',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null,
        label: t('Contact group')
      },
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'companyId',
        required: true,
        operator: StringOperator.EQUALS,
        values: [getLoggedUserCompanyId()],
        dataCategory: DataCategory.COMPANY_ID,
        label: t('Company')
      },
      {
        fieldType: FieldType.SELECTOR,
        fieldDataType: FieldDataType.STRING,
        fieldName: 'channel',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: DataCategory.CHANNEL,
        label: t('Channel')
      },
      {
        fieldType: FieldType.SWITCH,
        fieldDataType: FieldDataType.BOOLEAN,
        fieldName: 'default',
        required: false,
        operator: StringOperator.EQUALS,
        values: [],
        dataCategory: null,
        label: t('Only default variables')
      }
    ];
    return filters;
  };

  return {
    getColumns,
    getRowActions,
    isUpdateModalOpened,
    setIsUpdateModalOpened,
    variableToEdit,
    isDeleteModalOpened,
    variablesToDelete,
    handleDelete,
    variablesQuery,
    getMultiActions,
    getMultiFilters,
    getDataFn,
    setVariablesToDelete
  };
};
