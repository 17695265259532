import { Variable } from '../models/models';
import { updateVariableApi } from '../infrastructure/updateVariableApi';

export const updateVariableDomain = async (
  value: string,
  name: string,
  id: string,
  description: string
) => {
  const variable = {
    value,
    name,
    id,
    description
  } as Variable;
  try {
    return await updateVariableApi(variable);
  } catch (error: unknown) {
    throw error;
  }
};
