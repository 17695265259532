import {
  Group,
  LocalParking,
  MultipleStop,
  PhoneForwarded
} from '@mui/icons-material';
import { Box, Grow, Stack, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import Timer from 'src/components/Timer/timer';
import { CTIBarContext } from 'src/contexts/CTIBarContext';
import { RTCall } from 'src/models/rtcall';
import { getActiveRTCall } from 'src/services/rtusers/domain/rtuser/getActiveRTCall';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { motion } from 'framer-motion';
import { useCustomEventListener } from 'react-custom-events';
import { getContactNameQuery } from 'src/services/socialmedia/application/query/getContactsQuery';
import { getNameFromCallQuery } from 'src/services/socialmedia/application/query/getDisplayNameQuery';

export interface ActiveCallInfoProps {
  call?: RTCall;
}

export const ActiveCallInfo: React.FC<ActiveCallInfoProps> = (
  props: ActiveCallInfoProps
) => {
  const theme = useTheme();
  const { callToTransferID } = useContext(CTIBarContext);
  const user = getConnectedRTUser();

  let activeCall = props.call;
  if (!activeCall) {
    const callToTransfer = callToTransferID
      ? user.calls.find((c) => {
          return c.linkedEndpoint.rtCallId === callToTransferID; // If theres is an attended transfer show the parked call on cti
        })
      : null;

    activeCall = callToTransfer || getActiveRTCall(user);
  }

  const isParked = activeCall?.status === 'Parked';
  const isConference = activeCall?.status === 'Conference';

  const parkedCallsCount = user?.calls.filter(
    (c) => c.status === 'Parked'
  ).length;
  const showPopover = isParked ? parkedCallsCount > 1 : parkedCallsCount > 0;

  let endpoint =
    activeCall?.linkedEndpoint?.endpoint ??
    activeCall?.currentEndpoint?.endpoint;

  if (endpoint === user?.extension?.complexExtension) {
    endpoint = activeCall?.currentEndpoint?.endpoint;
  }

  const isInternal = endpoint?.includes('-');

  const displayNameQuery =
    !activeCall?.contactId || isInternal
      ? getNameFromCallQuery(endpoint, activeCall?.serviceId)
      : getContactNameQuery({ id: activeCall.contactId });

  let display_name =
    displayNameQuery.data || activeCall?.linkedEndpoint?.endpoint;
  display_name = isConference ? 'Conference' : display_name;

  const [isTransferInProgress, setIsTransferInProgress] = useState(false);

  useCustomEventListener('Transfer in progress', () => {
    setIsTransferInProgress(true);
    setTimeout(() => {
      setIsTransferInProgress(false);
    }, 5100);
  });

  if (!activeCall) return <></>;

  return (
    <Grow
      in={activeCall !== null}
      style={{ transformOrigin: 'left', marginRight: '10px' }}
    >
      <Box
        sx={{
          cursor: showPopover ? 'pointer' : 'default'
        }}
      >
        <Stack sx={{ position: 'relative' }}>
          {/* BLUE SHADOW IF THERE ARE PARKED CALLS*/}
          {showPopover && (
            <>
              <Box
                sx={{
                  border: '1px solid #B9B9BB',
                  borderRadius: '10px',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  backgroundColor: theme.colors.secondary.lighter,
                  left: 5,
                  top: -5
                }}
              ></Box>
            </>
          )}
          <motion.div
            initial={{
              backgroundColor: isConference
                ? 'inherited'
                : isParked
                  ? '#EBF9FF'
                  : '#EEFAE8'
            }}
            animate={{
              backgroundColor: isTransferInProgress
                ? 'rgba(255, 163, 25, 0.39)'
                : isConference
                  ? 'inherited'
                  : isParked
                    ? '#EBF9FF'
                    : '#EEFAE8'
            }}
            style={{
              border: showPopover ? '1px solid #B9B9BB' : 'none',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              backgroundColor: isConference
                ? 'inherited'
                : isParked
                  ? '#EBF9FF'
                  : '#EEFAE8', //Theme colors can't be used because they have transparency
              height: 40,
              borderRadius: '10px',
              zIndex: 100,
              position: 'relative',
              padding: '0 9px'
            }}
          >
            {isTransferInProgress && (
              <motion.div
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: 5, ease: 'easeOut' }}
                style={{
                  zIndex: -1,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255, 163, 25, 0.72)',
                  originX: 0,
                  borderRadius: '10px'
                }}
              >
                {/** BARRA DE PROGRESO */}
              </motion.div>
            )}
            {isParked ? (
              <LocalParking fontSize="medium" color="secondary" />
            ) : isConference ? (
              <Group fontSize="medium" color="secondary" />
            ) : isTransferInProgress ? (
              <MultipleStop fontSize="medium" color="action" />
            ) : (
              <PhoneForwarded
                fontSize="medium"
                color={isTransferInProgress ? 'action' : 'success'}
              />
            )}
            <label
              style={{
                color: theme.colors.primary.main,
                fontWeight: 'bold',
                cursor: 'inherit',
                whiteSpace: 'nowrap'
              }}
            >
              {display_name}
            </label>
            {!isConference && (
              <Stack minWidth={38}>
                <Timer
                  style={{
                    color: 'gray'
                  }}
                  updateTime={activeCall.lastStatusUpdateTime}
                />
              </Stack>
            )}
          </motion.div>
        </Stack>
      </Box>
    </Grow>
  );
};
