import {
  Autocomplete,
  FormControlLabel,
  Switch,
  TextField
} from '@mui/material';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import {
  Ticket,
  TicketField,
  TicketFieldsValues
} from '../../../../models/tickets';
import { getIsoDateTimeLocal } from '../../../../domain/getFormattedDateTimeTicketField';
import { useFormikContext } from 'formik';

interface Props {
  ticketField: TicketField;
  value: string | boolean | string[];
  setTicketField?: Dispatch<SetStateAction<TicketField>>;
  setTicket?: Dispatch<SetStateAction<Ticket>>;
  disabled?: boolean;
  options?: string[];
}
/**
 * TicketFieldInput can be manaaged by seting the ticket with setTicket prop, with Formik or both.
 * In order to be managed with Formik, there must be a parent Formik React Context
 * from which it can pull from. If called without a parent context
 * (i.e. a descendent of a <Formik> component or withFormik higher-order component),
 *  you will get a warning in your console.*/
const TicketFieldInput = ({
  ticketField,
  value,
  setTicketField,
  setTicket,
  disabled,
  options
}: Props) => {
  const {
    id,
    multipleChoice,
    name,
    options: ticketFieldOptions,
    type
  } = ticketField;
  const inputName = `fields.${id}`;
  const fieldOptions = options ?? ticketFieldOptions ?? [];

  /** Useful for disabling buttons or displaying error messages from other components */
  const formikProps = useFormikContext<Ticket>();

  const isDisabled = disabled;

  const handleChange = (fieldValues, ticketField?: TicketField) => {
    const value = multipleChoice
      ? ([...fieldValues] as string[])
      : (fieldValues as string);
    const field: TicketFieldsValues = {
      [id]: {
        type: type,
        value: value
      }
    };
    if (setTicketField) {
      setTicketField({ ...ticketField, ...field });
    }
    if (setTicket) {
      setTicket((prev) => {
        return {
          ...prev,
          fields: {
            ...prev?.fields,
            ...field
          }
        };
      });
    }
    if (formikProps) {
      formikProps?.setFieldValue(`fields.${id}`, field[id]);
    }
  };

  if (type === 'Text') {
    const inputId = `ticket-fields-text-input-${id}`;
    return (
      <TextField
        size="small"
        id={inputId}
        required={ticketField.required}
        disabled={isDisabled}
        label={t(name)}
        fullWidth
        name={inputName}
        error={
          !!formikProps &&
          Boolean(
            (formikProps?.values?.id || formikProps?.touched?.fields?.[id]) &&
              formikProps?.errors.fields?.[id]?.value
          )
        }
        onBlur={formikProps?.handleBlur}
        helperText={
          !!formikProps &&
          (formikProps?.values?.id || formikProps?.touched?.fields?.[id]) &&
          formikProps?.errors.fields?.[id]?.value
        }
        placeholder={`${t(name)} ${t('here')}...`}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        value={formikProps?.values.fields?.[id]?.value ?? value}
        variant="outlined"
      />
    );
  }
  if (type === 'DateTime') {
    const date = formikProps
      ? getIsoDateTimeLocal(formikProps?.values.fields?.[id]?.value as string)
      : getIsoDateTimeLocal(value as string);
    const inputId = `ticket-fields-date-time-input-${id}`;
    return (
      <TextField
        id={inputId}
        disabled={isDisabled}
        required={ticketField.required}
        label={t(name)}
        fullWidth
        name={inputName}
        error={
          formikProps &&
          Boolean(
            (formikProps?.values?.id || formikProps?.touched?.fields?.[id]) &&
              formikProps?.errors.fields?.[id]?.value
          )
        }
        onBlur={formikProps?.handleBlur}
        helperText={
          formikProps &&
          (formikProps?.values?.id || formikProps?.touched?.fields?.[id]) &&
          formikProps?.errors.fields?.[id]?.value
        }
        value={date}
        variant="outlined"
        type="datetime-local"
        InputLabelProps={{
          shrink: true
        }}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    );
  }
  if (type === 'Options') {
    const values = fieldOptions?.filter((option) =>
      (value as string[])?.includes(option)
    );
    const inputId = `ticket-fields-options-input-${id}`;
    return (
      <Autocomplete
        size="small"
        id={inputId}
        multiple={multipleChoice}
        fullWidth
        disabled={isDisabled}
        options={fieldOptions}
        onChange={(_, values) => {
          handleChange(values);
        }}
        value={values}
        renderInput={(params) => (
          <TextField
            {...params}
            required={ticketField.required}
            label={t(name)}
            placeholder={`${t(name)} here...`}
            name={inputName}
            error={
              formikProps &&
              Boolean(
                (formikProps?.values?.id ||
                  formikProps?.touched?.fields?.[id]) &&
                  formikProps?.errors.fields?.[id]?.value
              )
            }
            onBlur={formikProps?.handleBlur}
            helperText={
              (formikProps?.values?.id || formikProps?.touched?.fields?.[id]) &&
              formikProps?.errors.fields?.[id]?.value
            }
          />
        )}
      />
    );
  }

  if (type === 'Boolean') {
    const inputId = `ticket-fields-switch-input-${id}`;
    return (
      <FormControlLabel
        id={inputId}
        sx={{ pl: 1 }}
        control={
          <Switch
            name={name}
            disabled={isDisabled}
            checked={
              (formikProps?.values.fields?.[id]?.value as boolean) ??
              (value as boolean)
            }
            color="secondary"
            onChange={(_, checked) => {
              handleChange(checked);
            }}
          />
        }
        label={name}
      />
    );
  }
};

export default TicketFieldInput;
