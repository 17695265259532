import {
  ChannelType,
  Conversation
} from 'src/models/conversations/conversations';
import {
  ReadMessagesApiParams,
  readTelegramMessageApi
} from 'src/services/telegram/infrastructure/message/readTelegramMessagesApi';
import { readWebChatMessageApi } from 'src/services/webchat/infraestructure/readWebChatMessageApi';
import { readMessagesStore } from '../../infraestructure/api/messages/readMessagesStore';
import { readInstagramMessageApi } from 'src/services/instagram/infrastructure/message/readInstagramMessageApi';
import { readEmailMessageApi } from 'src/services/email/infrastructure/message/readEmailApi';
import { readWhatsAppMessageApi } from 'src/services/whatsapp/infrastructure/message/readWhatsAppMessage';

export const readMessages = async (conversation: Conversation) => {
  const messageIds = conversation.messages
    .filter((message) => message.status === 'Received')
    .map((message) => message.messageId);

    if(!messageIds) return
  const params: ReadMessagesApiParams = {
    conversationId: conversation.id,
    accountId: conversation?.accountId,
    messageIds
  };

  try {
    switch (conversation.channel) {
      case ChannelType.TELEGRAM:
        await readTelegramMessageApi(params);
        break;
      case ChannelType.WEBCHAT:
        await readWebChatMessageApi(params);
        break;
      case ChannelType.EMAIL:
        await readEmailMessageApi(params);
        break;
      case ChannelType.INSTAGRAM:
        await readInstagramMessageApi(params);
        break;
      case ChannelType.WHATSAPP:
        await readWhatsAppMessageApi(params);
        break;
    }

    // Mark as read messages in local store
    readMessagesStore(conversation);
    return true;
  } catch (error) {
    return false;
  }
};
