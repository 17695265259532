import {
  Box,
  Button,
  CardHeader,
  Dialog,
  Divider,
  Stack,
  Typography,
  IconButton,
  styled
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useAlert } from 'src/hooks/useAlert';
import { createWebChatAccount } from 'src/services/webchat/domain/webChatAccount/createWebChatAccount';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { checkCreateWebChatAccountPermissions } from 'src/services/permissionGroups/domain/checkPermissions';
import { PermissionsTooltip } from 'src/components/PermissionsTooltip';
import CreateWebChatAccountForm from './CreateWebChatAccountForm';
import { t } from 'i18next';
import { Formik } from 'formik';
import { MatchByForm, WebChatAccount } from '../../../models/webChatAccount';
import { getErrorMessage } from 'src/modules/Shared/utils/apiFunctions';
import { updateWebChatAccount } from 'src/services/webchat/domain/webChatAccount/updateWebChatAccount';
import { useWebChatAccount } from '../../utils/useWebChatAccount';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'background'])};
        transform: scale(1);
        transform-origin: center;
    
        &:hover {
            transform: scale(1.1);
        }
      `
);
import { WebChat } from 'src/modules/Admin/modules/ContactCenter/modules/ChatView/views/components/ConversationPreview';
import { WebChatStyle } from '../../../models/webChatStyle';
import { useWebChatStyle } from '../../utils/useWebChatStyle';
import { REFETCH_DATA_EVENT_NAME } from 'src/components/DialTable/utils/refetchDataEventName';
import { CloseTwoTone } from '@mui/icons-material';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  webChatAccount?: WebChatAccount;
  formikProps?: any;
  setSelected?: Dispatch<SetStateAction<WebChatAccount>>;
}

export const CreateWebChatAccount: React.FC<Props> = ({
  show,
  setShow,
  webChatAccount,
  formikProps,
  setSelected
}) => {
  const { defaultWebChatStyle } = useWebChatStyle();
  const { hasAccess } = useContext(PermissionsContext);
  const hasWebChatPermissions = hasAccess(checkCreateWebChatAccountPermissions);
  const [webChatStyle, setWebChatStyle] =
    useState<WebChatStyle>(defaultWebChatStyle);

  const getMatchById = () => {
    if (webChatAccount?.initialForm.length > 0) {
      const field = webChatAccount?.initialForm.find((field) => field?.matchBy);
      const index = webChatAccount?.initialForm.findIndex(
        (field) => field.matchBy
      );
      return { contactFieldId: field?.contactFieldId, index: index };
    }
    return null;
  };
  const formMode = webChatAccount ? 'Edit' : 'Create';

  const [matchBy, setMatchBy] = useState<MatchByForm>(null);

  const { showAlert } = useAlert();

  const { validationSchema, defaultAccount, companyId, styles } =
    useWebChatAccount();

  useEffect(() => {
    if (webChatAccount && styles) {
      setWebChatStyle(styles.find((s) => s.id === webChatAccount.styleId));
    }
  }, [webChatAccount]);

  useEffect(() => {
    const res = getMatchById();
    setMatchBy(res);
  }, [webChatAccount?.initialForm]);

  const handleCreate = async (webChatAccount: WebChatAccount) => {
    webChatAccount = { ...webChatAccount, companyId: companyId };

    webChatAccount?.initialForm.map((field, i) => {
      if (i === matchBy.index) webChatAccount.initialForm[i].matchBy = true;
      else webChatAccount.initialForm[i].matchBy = false;
    });

    try {
      if (formMode === 'Create') {
        await createWebChatAccount(webChatAccount).then((isCreated) => {
          if (isCreated) {
            setShow(false);
            formikProps?.setFieldValue('accountId', isCreated?.id);
            if (setSelected) setSelected(webChatAccount);
            showAlert('Account created succesfully', 'success', 3000);
            emitCustomEvent(REFETCH_DATA_EVENT_NAME);
          }
        });
      } else {
        setShow(false);
        updateWebChatAccount(webChatAccount).then(() => {
          emitCustomEvent(REFETCH_DATA_EVENT_NAME);
        });
        showAlert('Account edited succesfully', 'success', 3000);
      }
    } catch (error) {
      const { errorMessage } = getErrorMessage(error);
      showAlert(errorMessage, 'error', 3000);
    }
    setMatchBy(null);
    setWebChatStyle(defaultWebChatStyle);
  };

  return (
    <Dialog open={show} id="modal-CreateWebChatAccount" maxWidth="lg" fullWidth>
      <Stack direction="column" height="100%" width="100%">
        <CardHeader
          title={
            formMode === 'Create'
              ? t('Create WebChat account')
              : t('Update WebChat account')
          }
          subheader={
            formMode === 'Create'
              ? t('Use this dialog window to create new webchat account')
              : t('Use this dialog window to update webchat account')
          }
          action={
            <IconButton
              sx={{
                m: 1
              }}
              size="small"
              color="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              <CloseTwoTone />
            </IconButton>
          }
        />
        <Divider />
        <Formik
          initialValues={webChatAccount ?? defaultAccount}
          validationSchema={validationSchema}
          onSubmit={handleCreate}
        >
          {(formikProps): JSX.Element => (
            <form onSubmit={formikProps.handleSubmit} style={{ padding: 0 }}>
              <Box height={'calc(95vh - 150px)'}>
                <Stack
                  justifyContent="space-between"
                  height="100%"
                  overflow="visible"
                >
                  <Stack
                    px={1}
                    py={1}
                    spacing={2}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                    overflow="scroll"
                    height="100%"
                  >
                    <Stack width="100%">
                      <CreateWebChatAccountForm
                        formikProps={formikProps}
                        formType={formMode}
                        setWebChatStyle={setWebChatStyle}
                        matchBy={matchBy}
                        setMatchBy={setMatchBy}
                      />
                    </Stack>
                    <Stack padding="15px" minHeight="600px" alignItems="center">
                      <WebChat formikProps={webChatStyle} />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Divider />
                    <Stack
                      px={1.5}
                      py={1.5}
                      width="100%"
                      direction={{ xs: 'column-reverse', sm: 'row' }}
                      justifyContent={'space-between'}
                      spacing={1.5}
                    >
                      <Button
                        id="webchat-cancel-button-id"
                        variant="outlined"
                        onClick={() => {
                          setShow(false);
                          setWebChatStyle(defaultWebChatStyle);
                          setMatchBy(null);
                        }}
                      >
                        {t('Cancel')}
                      </Button>
                      <PermissionsTooltip hasAccess={hasWebChatPermissions}>
                        <Button
                          id="webchat-create-button-id"
                          type="submit"
                          disabled={
                            !hasWebChatPermissions ||
                            formikProps.values.name.trim() === '' ||
                            formikProps.values.styleId === '' ||
                            formikProps.values.contactGroupId === '' ||
                            (formikProps.values.initialForm.length > 0 &&
                              matchBy === null)
                          }
                          variant="contained"
                          color="secondary"
                        >
                          {t(formMode)}
                        </Button>
                      </PermissionsTooltip>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Stack>
    </Dialog>
  );
};
