import { useEffect, useMemo, useState } from 'react';
import {
  KnowledgeDocumentation,
  KnowledgeDocumentationStatus
} from '../../InboundRoutes/infrastructure/api/knowledgeModel/getKnowledgeDocumentationApi';
import {
  Button,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Popover,
  PopoverProps,
  Stack,
  TextField
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { t } from 'i18next';
import { useAlert } from 'src/hooks/useAlert';
import { createKnowledgeDocumentation } from '../../InboundRoutes/domain/knowledgeDocumentation/createKnowledgeModel';
import { getLoggedUserCompanyId } from 'src/services/authentication/domain/getAuthenticationData';
import DialModal from 'src/components/DialModal';
import DeleteModalForm from 'src/modules/DialerBot/views/components/Forms/DeleteModalForm';
import {
  ConfirmationModal,
  ConfirmationModalModes
} from 'src/components/Table/components/Modals/ConfirmationModal';
import { deleteKnowledgeDocumentationItems } from '../../InboundRoutes/domain/knowledgeDocumentation/deleteKnowledgeModel';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  popoverRef?: React.RefObject<HTMLElement>;
}

export function CreateKnowledgeModel({ isOpen, onClose, popoverRef }: Props) {
  const defaultModel = useMemo(
    () =>
      ({
        name: '',
        companyId: getLoggedUserCompanyId(),
        webpage: '',
        contentLabel: '',
        removeLabels: []
      }) as KnowledgeDocumentation,
    []
  );

  const [model, setModel] = useState<KnowledgeDocumentation>(defaultModel);

  const [removeLabel, setRemoveLabel] = useState<string>('');
  const { showAlert } = useAlert();

  const handleAddRemoveLabel = () => {
    if (removeLabel.trim()) {
      if (model.removeLabels.includes(removeLabel)) {
        setRemoveLabel('');
        showAlert(t('Label already added'), 'error');
        return;
      }
      setModel({
        ...model,
        removeLabels: [...model.removeLabels, removeLabel]
      });
      setRemoveLabel('');
    }
  };

  function handleClose() {
    onClose();
    setTimeout(() => {
      setModel(defaultModel);
      setRemoveLabel('');
    }, 300);
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isOpen) {
      timeout = setTimeout(() => {
        setModel(defaultModel);
        setRemoveLabel('');
      }, 300);
    }

    return () => clearTimeout(timeout);
  }, [isOpen]);

  async function handleSubmit() {
    try {
      await createKnowledgeDocumentation(model);
      showAlert(t('Knowledge model created'), 'success');
      handleClose();
    } catch (error) {
      showAlert(t('Error creating knowledge model'), 'error');
    }
  }

  const disabledSubmit = useMemo(() => {
    return !model.name || !model.webpage || !model.contentLabel;
  }, [model]);

  const popoverProps: Partial<PopoverProps> = popoverRef?.current
    ? {
        anchorEl: popoverRef.current,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      }
    : {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      };

  return (
    <Popover open={isOpen} onClose={onClose} {...popoverProps}>
      <Stack width={400}>
        <CardHeader
          title={t(`Create knowledge model`)}
          action={
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          }
        />

        <Divider />
        <Stack p={1} rowGap={1}>
          <TextField
            label={t('Name')}
            value={model.name}
            onChange={(e) => setModel({ ...model, name: e.target.value })}
          />
          <TextField
            label={t('Webpage')}
            value={model.webpage}
            onChange={(e) => setModel({ ...model, webpage: e.target.value })}
          />
          <TextField
            label={t('Content label')}
            value={model.contentLabel}
            onChange={(e) =>
              setModel({ ...model, contentLabel: e.target.value })
            }
          />
          <Stack direction="row" gap={1}>
            <TextField
              fullWidth
              label={t('Label to remove')}
              value={removeLabel}
              onChange={(e) => setRemoveLabel(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddRemoveLabel();
                }
              }}
              helperText={t('Press enter to add a label to remove')}
            />
            <IconButton
              color="secondary"
              onClick={handleAddRemoveLabel}
              sx={{
                width: '53px',
                height: '53px'
              }}
            >
              <Add />
            </IconButton>
          </Stack>
          {model.removeLabels.length > 0 && (
            <Stack
              direction="row"
              rowGap={1}
              columnGap={1}
              flexWrap="wrap"
              maxHeight={'200px'}
              sx={{
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888'
                }
              }}
            >
              {model.removeLabels.map((label) => (
                <Chip
                  key={label}
                  label={label}
                  onDelete={() =>
                    setModel({
                      ...model,
                      removeLabels: model.removeLabels.filter(
                        (l) => l !== label
                      )
                    })
                  }
                />
              ))}
            </Stack>
          )}
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" p={1} rowGap={1}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={disabledSubmit}
          >
            {t('Create')}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
}
