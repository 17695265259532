// VariablesContext.js
import React, { useContext } from 'react';
import { Variable } from 'src/modules/Admin/modules/Acd/modules/Variables/models/models';

export const VariablesContext = React.createContext({
  variables: []
} as { variables: Variable[] });

export function useVariablesContext() {
  return useContext(VariablesContext);
}
