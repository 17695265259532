import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatusTypes } from 'src/models/users';
import { getConnectedRTUser } from 'src/services/rtusers/domain/rtuser/getConnectedRTUser';
import { useGetCompaniesQuery } from 'src/services/users/application/query/getUsersDataQueries';
import UserManager from 'src/services/users/manager';

export const ChangeStatusForm: React.FC = () => {
  const user = getConnectedRTUser();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [errorOnChange, setErrorOnChange] = useState(false);
  const userStatus = user?.currentStatus.name;

  const companies = useGetCompaniesQuery()?.data;

  const statusList = companies
    ?.find((c) => c.id === user?.companyId)
    .userStatuses.filter((s) => {
      return !['InCall', 'Disconnected'].includes(s.type);
    });

  const updateStatus = (e) => {
    UserManager.changeStatus(user.id, e.target.value).then((res) => {
      if (res.data.success) {
        setErrorOnChange(false);
      } else {
        setErrorOnChange(true);
      }
    });
  };

  return (
    <>
      {errorOnChange && (
        <Box
          pt={2}
          pl={2}
          sx={{
            color: theme.colors.error.main,
            margin: 0,
            fontSize: '13px',
            fontWeight: 'bold'
          }}
        >
          {t('Error changing status')}
        </Box>
      )}
      <Box p={2}>
        <FormControl disabled={user?.currentStatus?.name === 'InCall'}>
          <FormLabel>
            {user?.currentStatus?.name === 'InCall'
              ? t('Disabled while in call')
              : t('Status')}
          </FormLabel>
          <RadioGroup
            defaultValue={userStatus}
            value={userStatus}
            onChange={updateStatus}
          >
            {statusList.map((status) => {
              return (
                <FormControlLabel
                  key={status.name}
                  value={status.name}
                  disabled={status.type === UserStatusTypes.ADMIN_TIME}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: status.color
                        }
                      }}
                      id="radioStatus-ChangeStatusForm"
                      disabled={status.type === UserStatusTypes.ADMIN_TIME}
                    />
                  }
                  label={status.name}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};
