import { initWebSocket } from './utils/initWs';
import { statusMsgs } from './utils/notifications/statusMsgs';
import { dataMsgs } from './utils/notifications/dataMsgs';
import AuthManager from '../authentication/manager';
import { closeWebSocket } from './utils/closeWs';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { getAllUsers } from '../users/domain/getUserData';
import { logger } from 'src/utils/logger';
import { logoutCurrentUser } from '../authentication/domain/logoutCurrentUser';

let WS_CLIENT: W3CWebSocket;

const startWs = async () => {
  try {
    // Make a request to check if the token has expired
    await getAllUsers({ page: 0, size: 1 });
  } catch (error) {
    // If token has expired, logout the user
    logger.error(error);
    logoutCurrentUser();
  }

  const token = AuthManager.getToken();
  WS_CLIENT = initWebSocket(token, 'All');
  statusMsgs(WS_CLIENT);
  dataMsgs(WS_CLIENT);

  return WS_CLIENT;
};

const getWSClient = (): W3CWebSocket => {
  return WS_CLIENT;
};

const sendPing = () => {
  if (!WS_CLIENT) return;

  //if still connecting return
  if (WS_CLIENT.readyState !== WS_CLIENT.OPEN) return;

  WS_CLIENT.send(
    JSON.stringify({
      type: 'Ping',
      keyword: 'Ping',
      timestamp: new Date().toJSON()
    })
  );
};

const wsManager = {
  startWs,
  closeWebSocket,
  getWSClient,
  sendPing
};

export default wsManager;
