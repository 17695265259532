import { Contact } from 'src/modules/Contacts/models/contacts';
import { Ticket } from '../../../../models/tickets';
import useShowflowVariable from '../hooks/useShowflowVariable';
import {
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Variable } from 'src/modules/Admin/modules/Acd/modules/Variables/models/models';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { t } from 'i18next';
import ReactDOM from 'react-dom';

interface Props {
  variableId: string;
  contact: Contact;
  ticket: Ticket;
}

function ShowflowInlineVariable({ variableId, ticket, contact }: Props) {
  const { field, type, value } = useShowflowVariable({
    variableId,
    contact,
    ticket
  });
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const color = {
    Call: theme.colors.success.main,
    Company: '#D962D7',
    Ticket: theme.colors.warning.main,
    Contact: theme.colors.secondary.main
  };

  const isLoading = field === undefined;
  const hasValue = !isLoading && value !== undefined;

  //Show variable name if there is no value
  const valueText = !hasValue ? `"${(field as any)?.name}"` : value;

  const getTypeLabel = {
    Call: t('Call variable'),
    Company: t('Company variable'),
    Ticket: t('Ticket field'),
    Contact: t('Contact field')
  };
  const ref = useRef<HTMLElement>();
  const rect = ref.current?.getBoundingClientRect();
  const top = rect?.bottom + 10;
  const left = rect?.left + rect?.width / 2;

  return (
    <span
      ref={ref}
      style={{
        borderRadius: '6px',
        border: hasValue ? 'none' : `1px solid ${color[type]}`,
        height: '26px',
        background: hasValue ? color[type] + '20' : 'white',
        color: hasValue ? color[type] : 'black',
        fontWeight: 'bold',
        padding: '0px 6px',
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {ReactDOM.createPortal(
        <AnimatePresence>
          {isHovered && (
            <motion.div
              //tooltip on the bottom
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              style={{
                position: 'fixed',
                top,
                left,
                translateX: '-50%',
                width: '160px',
                padding: '5px',
                background: 'rgba(255,255,255,0.5)',
                border: `1px solid ${color[type]}`,
                backdropFilter: 'blur(6px)',
                color: 'black',
                borderRadius: '5px',
                fontSize: '12px',
                zIndex: 10
              }}
            >
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '-15px', // Ajusta la posición vertical de la punta de flecha
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: `10px solid ${color[type]}` // Cambia el color de la punta de flecha
                  }}
                ></div>
              </div>
              <Stack direction={'column'}>
                <Typography
                  variant="body1"
                  fontSize={16}
                  style={{ fontWeight: 'bold' }}
                >
                  {field?.name}
                </Typography>
                <Typography variant="body2" fontSize={11}>
                  {getTypeLabel[type]}
                </Typography>
                {!hasValue && (
                  <>
                    <Divider sx={{ my: 0.5 }} />
                    <Typography variant="caption" fontSize={14}>
                      {t('No value set')}
                    </Typography>
                  </>
                )}

                {['Company', 'Call'].includes(type) &&
                  (field as Variable)?.description && (
                    <>
                      <Divider sx={{ my: 0.5 }} />
                      <Typography variant="body1" fontSize={14}>
                        {(field as Variable)?.description}
                      </Typography>
                    </>
                  )}
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
      {isLoading && (
        <CircularProgress
          size={12}
          style={{
            color: color[type]
          }}
        />
      )}
      {valueText}
    </span>
  );
}

export default ShowflowInlineVariable;
