import FullCalendar, {
  EventContentArg,
  EventSourceInput
} from '@fullcalendar/react';
import { ScheduleCalendar } from '../../createScheduleCalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';

import esLocale from '@fullcalendar/core/locales/es';
import { Box, Stack, Theme, useTheme } from '@mui/material';
import '../styles.css';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NEW_SCHEDULE_ID } from '../../ScheduleCalendarForm';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { DailyTimeline } from './dailyTimeline';
import en from 'src/utils/react-libreria-html/src/i18n/en';

interface Props {
  schedules: ScheduleCalendar[];
  selectedSchedule: ScheduleCalendar;
  calendarRef: React.MutableRefObject<FullCalendar>;
  onDateClick?: (date: Date) => void;
  onEventDrop?: (schedule: ScheduleCalendar) => void;
  onEventClick?: (schedule: ScheduleCalendar) => void;
  height?: string;
  selectedDay: Date;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date | null>>;
  enableTimeGrid?: boolean;
  onNewEventButtonClick: (date: Date) => void;
}

export const MonthlyCalendar = ({
  schedules,
  selectedSchedule,
  calendarRef,
  onDateClick,
  onEventDrop,
  onEventClick,
  height,
  setSelectedDay,
  enableTimeGrid,
  selectedDay,
  onNewEventButtonClick
}: Props) => {
  const theme = useTheme();

  const eventsMap: EventSourceInput = schedules?.reduce((acc, schedule) => {
    const dateStr = schedule?.scheduleTimestamp.split('T')[0]; // Formato 'YYYY-MM-DD'

    const isNew = schedule?.id === selectedSchedule?.id;

    // Verifica si ya existe un evento para este día
    if (acc[dateStr] && isNew) {
      // Si el id del evento actual es el seleccionado, actualiza el id del evento existente
      acc[dateStr].id = selectedSchedule.id;
    } else {
      // Si no hay evento para este día, añade uno nuevo
      acc[dateStr] = {
        id: schedule?.id,
        title: schedule?.title,
        date: dateStr
      };
    }

    return acc;
  }, {});

  // Convertir el objeto de eventos en un arreglo
  const eventsPerDay = Object.values(eventsMap);

  function onCalendarClick(date: Date) {
    setSelectedDay(date);
    if (enableTimeGrid) {
      setShowTimeGrid(true);
    }
  }

  const [showTimeGrid, setShowTimeGrid] = useState(false);

  useEffect(() => {
    if (!enableTimeGrid) {
      setShowTimeGrid(false);
    }
  }, [enableTimeGrid]);

  if (showTimeGrid) {
    return (
      <DailyTimeline
        selectedDay={selectedDay}
        onDateClick={onDateClick}
        schedules={schedules}
        selectedSchedule={selectedSchedule}
        onEventDrop={onEventDrop}
        onEventClick={onEventClick}
        customButtons={{
          goBack: {
            text: t('Back'),
            click: () => {
              setShowTimeGrid(false);
            }
          }
        }}
        headerToolbar={{
          right: 'goBack',
          left: 'title',
          center: 'newSchedule'
        }}
        onNewEventButtonClick={onNewEventButtonClick}
      />
    );
  }

  return (
    <FullCalendar
      key={schedules.join('')}
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      firstDay={1}
      locale={esLocale}
      events={eventsPerDay}
      eventContent={(e) => {
        return renderEventContent(e, theme, selectedSchedule);
      }}
      headerToolbar={{
        right: 'today prev,next',
        left: 'title',
        center: ''
      }}
      contentHeight={height ?? '320px'}
      titleFormat={{
        year: 'numeric',
        month: 'short'
      }}
      dateClick={(dateClick) => {
        const date = dateClick.date;
        onCalendarClick(date);
      }}
      eventClick={(eventClick) => {
        const date = eventClick.event.start;
        calendarRef.current?.getApi().select(date);
        onCalendarClick(date);
      }}
      selectAllow={(selectInfo) => {
        // Allow to select only 1 day
        const date1 = new Date(selectInfo.startStr);
        const date2 = new Date(selectInfo.endStr);

        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays <= 1;
      }}
      slotDuration={'00:15:00'}
      eventDurationEditable={false}
      scrollTime={(new Date().getHours() - 1).toString() + ':00:00'}
      allDaySlot={false}
      selectable
    />
  );
};

// This function render the dots in the calendar
function renderEventContent(
  eventInfo: EventContentArg,
  theme: Theme,
  selectedSchedule: ScheduleCalendar
) {
  const isNew = eventInfo.event.id === selectedSchedule?.id;
  const background = isNew
    ? theme.palette.error.main
    : theme.palette.secondary.main;

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box
        width={'10px'}
        height={'10px'}
        sx={{
          borderRadius: '50%',
          background
        }}
      ></Box>
    </Stack>
  );
}
