import { logger } from 'src/utils/logger';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getToken = () => {
  const token = cookies.get('token');
  if (!token) logger.warn('token not found on cookies');
  return token;
};

export const getUserId = () => {
  const userId: string = cookies.get('userId');
  if (!userId) logger.warn('userId not found on cookies');
  return userId;
};

export const getLoggedUserCompanyId = () => {
  const companyId = cookies.get('companyId') as string;
  if (!companyId) logger.warn('companyId not found on cookies');
  return companyId;
};

export const getUsername = () => {
  const username = cookies.get('username') as string;
  if (!username) logger.warn('username not found on cookies');
  return username;
};

export const getUserExtension = () => {
  const userExtension = cookies.get('userExtension');
  if (!userExtension) logger.warn('userExtension not found on cookies');
  return userExtension;
};

export const getExpiration = () => {
  const expiration = cookies.get('expiration');
  if (!expiration) logger.warn('expiration not found on cookies');
  return expiration;
};

export const setToken = (token: string, expiration: string) => {
  cookies.set('token', token, {
    expires: new Date(expiration),
    path: '/',
    sameSite: 'lax'
  });
};

export const setExpiration = (expiration: string) => {
  cookies.set('expiration', new Date(expiration).getTime(), {
    expires: new Date(expiration),
    path: '/',
    sameSite: 'lax'
  });
};

export const setUserId = (userId: string, expiration: string) => {
  cookies.set('userId', userId, {
    expires: new Date(expiration),
    path: '/',
    sameSite: 'lax'
  });
};

export const setLoggedUserCompanyId = (companyId: string) => {
  cookies.set('companyId', companyId, {
    path: '/',
    sameSite: 'lax'
  });
};

export const setUsername = (username: string) => {
  cookies.set('username', username, {
    path: '/',
    sameSite: 'lax'
  });
};

export const setUserExtension = (userExtension: string) => {
  cookies.set('userExtension', userExtension, {
    path: '/',
    sameSite: 'lax'
  });
};

export const removeUserId = () => {
  cookies.remove('userId', { path: '/' });
};

export const removeToken = () => {
  cookies.remove('token', { path: '/' });
};

export const removeCompanyId = () => {
  cookies.remove('companyId', { path: '/' });
};

export const removeExpiration = () => {
  cookies.remove('expiration', { path: '/' });
};

export const removeUsername = () => {
  cookies.remove('username', { path: '/' });
};

export const removeUserExtension = () => {
  cookies.remove('userExtension', { path: '/' });
};
